import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  ConversionType,
  DataFrameType,
  RedactionType,
  SAR,
  SARChannel,
  SARMap,
  jsonValues,
} from 'src/app/features/datadelivery/datadelivery/models/SARRedaction';

@Component({
  selector: 'app-sarredaction-table',
  templateUrl: './sarredaction-table.component.html',
  styleUrls: ['./sarredaction-table.component.css'],
})
export class SARRedactionTableComponent {
  cols: any[] = [];
  @Input() SARredactions: SAR[] = [];
  @Input() SARredaction!: SAR;
  @Input() dataframe: DataFrameType[] = [];
  @Input() convtype: ConversionType[] = [];
  @Input() redactiontype: RedactionType[] = [];
  @Input() sarmap: SARMap[] = [];
  @Input() sarchannel: SARChannel[] = [];
  @Input() storeddata: any;
  @Input() loading = true;
  @Input() defaultTableRows: number;
  @Input() currentPageReport: string;
  @Input() pageTitle: string;
  @Input() canAdd: boolean;
  @Input() canDelete: boolean;
  @Input() jsonParameters: jsonValues;
  @Input() messageLabel: string;
  @Input() modalDialog: boolean;
  @Input() submitted: boolean;

  @Output() onRowSelectEvent = new EventEmitter<SAR>();
  @Output() cloneSAREvent = new EventEmitter<SAR>();
  @Output() deleteSAREvent = new EventEmitter<SAR>();

  ngOnInit(): void {
    this.cols = [
      {
        field: 'name',
        header: 'Name',
      },
      {
        field: 'description',
        header: 'Description',
      },
      {
        field: 'conversiontype_name',
        header: 'Conversion Type',
      },
      {
        field: 'clone',
        header: 'Action',
      },
      {
        field: 'delete',
        header: 'Action',
      },
    ];
  }

  onRowSelect(sar: SAR) {
    this.onRowSelectEvent.emit(sar);
  }

  cloneSAR(sar: SAR) {
    this.cloneSAREvent.emit(sar);
  }

  deleteSAR(sar: SAR) {
    this.deleteSAREvent.emit(sar);
  }
}
