export const permissions = {
  endpointData: {
    create: 'data_delivery_insights.endpoints.create',
    manage: 'data_delivery_insights.endpoints.manage',
    delete: 'data_delivery_insights.endpoints.delete',
    view: 'data_delivery_insights.endpoints.view',
  },
  datadelivery_flight_redaction: {
    create: 'data_delivery.flight_data_redactions.create',
    manage: 'data_delivery.flight_data_redactions.manage',
    delete: 'data_delivery.flight_data_redactions.delete',
    view: 'data_delivery.flight_data_redactions.view',
  },
  sarRedactionsData: {
    create: 'data_delivery_insights.sar_redactions.create',
    manage: 'data_delivery_insights.sar_redactions.manage',
    delete: 'data_delivery_insights.sar_redactions.delete',
    view: 'data_delivery_insights.sar_redactions.view',
  },
  clientpackagesData: {
    create: 'data_delivery_insights.client_packages.create',
    manage: 'data_delivery_insights.client_packages.manage',
    delete: 'data_delivery_insights.client_packages.delete',
    view: 'data_delivery_insights.client_packages.view',
  },
  endpointdatadelivery_flight_redaction: {
    view: 'data_delivery_insights.flight_data_redactions.view',
  },
  endpointdatadelivery_acms_redaction: {
    view: 'data_delivery_insights.acms_report_redactions.view',
  },
  endpointdatadelivery_sar_redaction: {
    view: 'data_delivery_insights.sar_redactions.view',
  },
  endpointdatadelivery_client_packages: {
    view: 'data_delivery_insights.client_packages.view',
  },
  datadeliveryinsights_roles: {
    create: 'data_delivery_insights.data_delivery_insights_roles.create',
    manage: 'data_delivery_insights.data_delivery_insights_roles.manage',
    delete: 'data_delivery_insights.data_delivery_insights_roles.delete',
    view: 'data_delivery_insights.data_delivery_insights_roles.view',
  },
  datadeliveryinsights_users: {
    manage: 'data_delivery_insights.data_delivery_insights_users.manage',
    view: 'data_delivery_insights.data_delivery_insights_users.view',
  },
  endpoint: {
    view: 'data_delivery_insights.endpoints.view',
  },
  acmsredactionData: {
    create: 'data_delivery_insights.acms_report_redactions.create',
    manage: 'data_delivery_insights.acms_report_redactions.manage',
    delete: 'data_delivery_insights.acms_report_redactions.delete',
    view: 'data_delivery_insights.acms_report_redactions.view',
  },
};
