import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { EndPoints } from 'src/app/features/datadelivery/datadelivery/models/ConfigEndPoints';

@Component({
  selector: 'app-endpoints-table',
  templateUrl: './endpoints-table.component.html',
  styleUrls: ['./endpoints-table.component.scss'],
})
export class EndpointsTableComponent implements OnInit {
  cols: any[] = [];

  @Input() endpoints: EndPoints[] = [];
  @Input() canAdd = false;
  @Input() canDelete = false;
  @Input() canEdit = false;
  @Input() readOnly = false;
  @Output() deleteEndpoint = new EventEmitter<EndPoints>();
  @Output() selectEndpoint = new EventEmitter<EndPoints>();

  ngOnInit(): void {
    this.cols = [
      {
        field: 'name',
        header: 'Name',
      },
      {
        field: 'transfertype_name',
        header: 'Transfer Type',
      },
      {
        field: 'description',
        header: 'Description',
      },
      {
        field: 'isenabled',
        header: 'Enabled',
      },
      {
        field: 'jsonparameters',
        header: 'Date',
      },
      {
        field: 'organization_type_name',
        header: 'Organization',
      },
      {
        field: 'action',
        header: 'Action',
      },
    ];
  }

  findDate(val: any): string {
    let dateCreated = '';
    try {
      const endpointDate = JSON.parse(val)?.endpoint_date;
      const isDateValid = !new Date(endpointDate)
        .toString()
        ?.includes('Invalid Date');
      if (isDateValid) {
        dateCreated = endpointDate;
      }
      return dateCreated;
    } catch (e) {
      return dateCreated;
    }
  }

  onDelete(endpoint: EndPoints) {
    this.deleteEndpoint.emit(endpoint);
  }

  onSelect(endpoint: EndPoints) {
    this.selectEndpoint.emit(endpoint);
  }
}
