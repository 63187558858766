import { ChangeDetectorRef, Component } from '@angular/core';
import { Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/common/services/breadcrumb.service';
import { DataFrameService } from 'src/app/features/configuration/services/data-frame.service';
import { SecurityUserService } from 'src/app/security/services/security-user.service';
import { Components } from '../../../integration/configuration.components';
import { DFDetailsInputCategory767 } from '../../dataframe-acms/dataframe/shared/enums/formEnums';
import { ValidationHelper } from '../../dataframe-acms/dataframe/shared/helpers/validation.helper';
import { FrameDetails767DxsComponent } from "../../dataframe-acms/dataframe/frame-details/frame-details-767-DXS/frame-details-767-dxs.component";

@Component({
  selector: 'app-framedetails767',
  templateUrl: './framedetails767.component.html',
  styleUrls: ['./framedetails767.component.css'],
})
export class Framedetails767Component extends FrameDetails767DxsComponent {

  constructor(
    router: Router,
    breadcrumbService: BreadcrumbService,
    confirmationService: ConfirmationService,
    messageService: MessageService,
    route: ActivatedRoute,
    realUserService: SecurityUserService,
    dataframeservice: DataFrameService,
    changeDetectorRef: ChangeDetectorRef,
  ) {
    super(
      messageService,
      confirmationService,
      router,
      route,
      breadcrumbService,
      realUserService,
      dataframeservice,
      changeDetectorRef
    );
    this.inputCategoryList = Object.values(DFDetailsInputCategory767);
    this.messageLabel = 'Data Frame 767';
    this.dataFrameMapView = Components.MapView767;
    this.dataFrameListView = Components.ListView767;
    this.breadcrumbService.setItems(route, [
      { label: Components.ListView767.label, routerLink: Components.ListView767.path },
      { label: Components.DataFrame767.label },
    ]);
  }

  override setFormConfiguration() {
    this.formConfiguration = {
      [DFDetailsInputCategory767.FRED.toString()]: [
        this.createField(DFDetailsInputCategory767.FRED, 'File_Revision', 'File Revision', [Validators.required, ValidationHelper.validateString()]),
        this.createField(DFDetailsInputCategory767.FRED, 'Source_Document', 'SourceDocument'),
        this.createField(DFDetailsInputCategory767.FRED, 'File_Comments', 'File Comments'),
      ],
      [DFDetailsInputCategory767.Recorder.toString()]: [
        this.createField(DFDetailsInputCategory767.Recorder, 'Recorder_Make_and_Part_Number', 'Recorder Make and Part Number'),
        this.createField(DFDetailsInputCategory767.Recorder, 'FDAU_Make_and_Part_Number', 'FDAU Make and Part Number'),
      ],

      [DFDetailsInputCategory767.Aircrat.toString()]: [
        this.createField(DFDetailsInputCategory767.Aircrat, 'Aircraft_Make_and_Model', 'Aircraft Make and Model'),
        this.createField(DFDetailsInputCategory767.Aircrat, 'Aircraft_Registration', 'Aircraft Registration'),
        this.createField(DFDetailsInputCategory767.Aircrat, 'Aircraft_Alternate_ID', 'Aircraft Alternate ID'),
        this.createField(DFDetailsInputCategory767.Aircrat, 'Aircraft_Serial_Number', 'Aircraft Serial Number'),
      ],
    };
  }
}
