<p-blockUI [blocked]="loadingCount > 0">
  <tdy-spinner style="position:absolute;left:50%;top:50%;z-index: 10000"></tdy-spinner>
</p-blockUI>
<p-confirmDialog header="Confirm Deletion" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<div class="col-12 card">
  <div class="card-header m-2">
    <h5>{{pageTitle}}</h5>
    <div>
      <span class="p-input-icon-left ml-3" style="float: right">
        <p-dropdown id="aircraftGroupList" [options]="aircraftGroups" [(ngModel)]="selectedAircraftGroup"
          (onChange)="onChangeAircraftGroup()" tooltip="Filter by Aircraft Group" [autoDisplayFirst]="true"
          [showClear]="true" class="mr-3" [style]="{'width':'200px'}" [dropdownIcon]="'pi pi-filter'"></p-dropdown>
        <span class="p-input-icon-align">
          <button *ngIf="canEdit" (click)="onClickUpdate()" [disabled]="updateButtonDisabled" icon="pi pi-pencil"
            id="addButton" label="Update" pButton pRipple type="button"></button>
        </span>
        <span class="p-input-icon-align  mr-0">
          <button *ngIf="canEdit" (click)="dt.exportCSV()" [disabled]="downloadButtonDisabled" icon="pi pi-download"
            id="downloadButton" pButton pRipple type="button"></button>
        </span>
      </span>
    </div>
  </div>
  <div class="m-2">
    <p-table #dt [filterDelay]="0" [(selection)]="selectAllCheckbox" [responsive]="true" [sortOrder]="1"
      [columns]="cols" exportFilename="{{dynamicdownloadName}}" [value]="tableData" [showCurrentPageReport]="true"
      [paginator]="tableData.length > defaultTableRows" [rowsPerPageOptions]="[20,50,100,200]" [rows]="defaultTableRows"
      currentPageReportTemplate="{{currentPageReport + ' ' + pageTitle}}" dataKey="aircraftrecorder_id"
      id="airCraftRecorderTable" selection="single" (onRowSelect)="onClickCheckBox()"
      (onRowUnselect)="onClickCheckBox()" sortField="aircraft_registration" [tableStyle]="{ 'min-width': '100rem' }">
      <ng-template pTemplate="header">
        <th style="width: 3rem"></th>
        <tr>
          <th>
            <p-tableHeaderCheckbox *ngIf="canEdit" (click)="onClickCheckBox()"></p-tableHeaderCheckbox>
          </th>
          <th pSortableColumn="aircraft_registration">Aircraft Registration
            <p-sortIcon field="aircraft_registration"></p-sortIcon>
            <p-columnFilter [showAddButton]="false" [showOperator]="false" class="ml-0" display="menu"
              field="aircraft_registration" header="aircraft_registration" matchMode="contains"
              type="text"></p-columnFilter>
          </th>

          <th pSortableColumn="aircraftmodel">Aircraft Model
            <p-sortIcon field="aircraftmodel"></p-sortIcon>
            <p-columnFilter [showAddButton]="false" [showOperator]="false" class="ml-0" display="menu"
              field="aircraftmodel" header="aircraftmodel" matchMode="contains" type="text"></p-columnFilter>
          </th>

          <th pSortableColumn="enginetype_name">Engine Type
            <p-sortIcon field="enginetype_name"></p-sortIcon>
            <p-columnFilter [showAddButton]="false" [showOperator]="false" class="ml-0" display="menu"
              field="enginetype_name" header="enginetype_name" matchMode="contains" type="text"></p-columnFilter>
          </th>

          <th pSortableColumn="recorder1_name">Primary Recorder
            <p-sortIcon field="recorder1_name"></p-sortIcon>
            <p-columnFilter [showAddButton]="false" [showOperator]="false" class="ml-0" display="menu"
              field="recorder1_name" header="recorder1_name" matchMode="contains" type="text"></p-columnFilter>
          </th>

          <th pSortableColumn="dataframe1_name">Primary Data Frame
            <p-sortIcon field="dataframe1_name"></p-sortIcon>
            <p-columnFilter [showAddButton]="false" [showOperator]="false" class="ml-0" display="menu"
              field="dataframe1_name" header="dataframe1_name" matchMode="contains" type="text"></p-columnFilter>
          </th>

          <th pSortableColumn="dataframe1_version">Primary Data Frame Version
            <p-sortIcon field="dataframe1_version"></p-sortIcon>
            <p-columnFilter [showAddButton]="false" [showOperator]="false" class="ml-0" display="menu"
              field="dataframe1_version" header="dataframe1_version" matchMode="contains"
              type="numeric"></p-columnFilter>
          </th>

        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-tableData let-tableRow>
        <tr class="p-selectable-row cursor-pointer"
          (click)="onRowClick($event, tableData.aircraftrecorder_id, tableData.aircraft_registration)">
          <td>
            <p-tableCheckbox *ngIf="canEdit" [value]="tableData" [index]="tableRow"></p-tableCheckbox>
          </td>
          <td>
            <span class="p-column-title">Registrations</span>
            {{tableData.aircraft_registration}}
          </td>

          <td>
            <span class="p-column-title">Aircraft Model</span>
            {{tableData.aircraftmodel}}
          </td>

          <td>
            <span class="p-column-title">Engine Type</span>
            {{tableData.enginetype_name}}
          </td>

          <td>
            <span class="p-column-title">Primary Recorder</span>
            {{tableData.recorder1_name}}
          </td>

          <td>
            <span class="p-column-title">Primary Data Frame</span>
            {{tableData.dataframe1_name}}
          </td>

          <td>
            <span class="p-column-title">Primary Data Frame Version</span>
            {{tableData.dataframe1_version}}
          </td>

        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="8">No Aircraft Recorder Found.</td>
        </tr>
      </ng-template>
    </p-table>
    <div *ngIf="tableData.length > 0 && defaultTableRows >= tableData.length" style="text-align: center;">
      <p class="row-paging">Showing 1 to
        {{tableData.length}} of {{tableData.length}} {{pageTitle}}</p>
    </div>
  </div>
</div>
