import { EMPTY, throwError } from 'rxjs';
import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable()
export class BaseService {
  // For Testing
  private static jwtToken = '';
  protected airlineId = 0;
  protected baseUrl = environment.apiBase;
  private headers = new HttpHeaders();
  protected options = { headers: this.headers };

  getSessionStorageJWTToken(): string {
    return BaseService.jwtToken;
  }

  /**
   * Handles an HTTP operation that failed. Allows the app continue.
   * @param error - Optional value to return as the observable result.
   */
  protected httpHandleError(error: HttpErrorResponse | any) {
    if (error instanceof HttpErrorResponse) {
      if (error.status === 200) {
        return EMPTY;
      }
      const message = error?.error?.message ?? error?.message;
      console.error(`Teledyne AppLink HTTP ERROR: ${message}`);  // Use 'Teledyne AppLink' in message so it's obvious that this is our code, not the browser.
      return throwError(() => message);
    }
  }
  protected httpGetHeadersForJSON(contentTypeJson = false) {
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set('Accept', 'application/json');
    // httpHeaders = httpHeaders.set('Cache-Control', 'no-cache');
    // httpHeaders = httpHeaders.set('Pragma', 'no-cache');
    // httpHeaders = httpHeaders.set('Expires', 'Sat, 01 Jan 2050 00:00:00 GMT');

    // todo: to uncomment once ready to release. this header will prevent access from port 4200 during dev mode
    // httpHeaders = httpHeaders.set('X-Frame-Options', 'SAMEORIGIN');

    if (contentTypeJson) {
      httpHeaders = httpHeaders.set('Content-Type', 'application/json');
    }
    return httpHeaders;
  }

}
