<p-blockUI [blocked]="loadingCount > 0">
    <tdy-spinner style="position:absolute;left:50%;top:50%;z-index: 10000"></tdy-spinner>
</p-blockUI>
<div class="card" *ngIf="loadingCount == 0">
    <div class="card-header m-2">
        <h5>{{pageTitle}}</h5>

    </div>
    <p-table #dt_terminalNDBNavaids [value]="data" dataKey="ndb_identifier" [showCurrentPageReport]="true"
        [paginator]="data.length > defaultTableRows" [rowsPerPageOptions]="[20,50,100,200]" [rows]="defaultTableRows"
        currentPageReportTemplate="{{currentPageReport + ' ' + pageTitle}}" styleClass="" selectionMode="single"
        [(selection)]="selected_terminalNDBNavaid" (onRowSelect)="onRowSelect()" sortField="ndb_identifier">
        <ng-template pTemplate="caption">
            <div class="flex flex-row-reverse">
                <p-dropdown [options]="file_dates" [(ngModel)]="selected_date" optionLabel="dte" [filter]="true"
                    (onChange)="onChange()" tooltip="Filter by AIRAC date" filterBy="dte" [showClear]="false"
                    placeholder="Select a date">
                    <ng-template pTemplate="selectedItem">
                        <div *ngIf="selected_date">
                            <div>{{selected_date.dte}}</div>
                        </div>
                    </ng-template>
                    <ng-template let-filedate pTemplate="item">
                        <div class="country-item">
                            <div>{{filedate.dte}}</div>
                        </div>
                    </ng-template>
                </p-dropdown>
            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th pSortableColumn="airport_icao_identifier" [ngStyle]="{'width':'10%'}">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        Airport ICAO identifier
                        <p-sortIcon field="airport_icao_identifier"></p-sortIcon>
                        <p-columnFilter type="text" field="airport_icao_identifier" display="menu"
                            [showOperator]="false" [showAddButton]="false"></p-columnFilter>
                    </div>
                </th>
                <th pSortableColumn="ndb_identifier" [ngStyle]="{'width':'10%'}">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        NDB identifier
                        <p-sortIcon field="ndb_identifier"></p-sortIcon>
                        <p-columnFilter type="text" field="ndb_identifier" display="menu" [showOperator]="false"
                            [showAddButton]="false"></p-columnFilter>
                    </div>
                </th>
                <th pSortableColumn="name" [ngStyle]="{'width':'10%'}">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        Name
                        <p-sortIcon field="name"></p-sortIcon>
                        <p-columnFilter type="text" field="name" display="menu" [showOperator]="false"
                            [showAddButton]="false"></p-columnFilter>
                    </div>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-terminalNDBNavaid>
            <tr [pSelectableRow]="terminalNDBNavaid">
                <td>
                    {{terminalNDBNavaid.airport_icao_identifier || "-"}}
                </td>
                <td>
                    {{terminalNDBNavaid.ndb_identifier || "-"}}
                </td>
                <td>
                    {{terminalNDBNavaid.name || "-"}}
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="5">No terminal NDB navaids found.</td>
            </tr>
        </ng-template>
    </p-table>
    <div *ngIf="data.length > 0 && defaultTableRows >= data.length" style="text-align: center;">
        <p class="row-paging">Showing 1 to
            {{data.length}} of {{data.length}} {{pageTitle}}</p>
    </div>
</div>
