import { Component } from '@angular/core';

@Component({
  selector: 'tdy-spinner',
  template: `
 <div class='svg-container'>
<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 232.7 114.9"  width="232.6999969482422" height="114.9000015258789"><defs>
 
  </defs> 
  <g>
    <g id="Layer_1">
      <g id="Layer_1-2" data-name="Layer_1">
        <g>
          <g>
            <polygon class="cls-1 svg-elem-1" points="46.8 2.8 2.4 47.4 65.6 47.4 110 2.8 46.8 2.8"></polygon>
            <polygon class="cls-1 svg-elem-2" points="113.7 6.8 113.7 69.2 68.7 112.2 68.7 51.7 113.7 6.8"></polygon>
          </g>
          <g>
            <polygon class="cls-1 svg-elem-3" points="185.9 2.8 230.3 47.4 167 47.4 122.6 2.8 185.9 2.8"></polygon>
            <polygon class="cls-1 svg-elem-4" points="119 6.8 119 69.2 164 112.2 164 51.7 119 6.8"></polygon>
          </g>
        </g>
      </g>
    </g>
  </g>
</svg>
 </div>

`,
  styleUrls: ['./spinner.component.css']
})
export class SpinnerComponent { }

 