import { Directive } from "@angular/core";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import { ConfirmationService, MenuItem, MessageService } from "primeng/api";
import { Observable, takeUntil } from "rxjs";
import { BreadcrumbService } from "src/app/common/services/breadcrumb.service";
import { Action, BaseViewComponent } from "src/app/common/components/base-view.component";
export type ApiType = { request: Observable<any>, api_name: string, callback: (data) => void }

@Directive()
export abstract class NavigationInformationBaseViewComponent extends BaseViewComponent {
  private route: ActivatedRoute;
  data: any;
  messageLabel = 'Navigation';
  constructor(

    messageService: MessageService,
    confirmationService: ConfirmationService,
    router: Router,
    breadcrumbService: BreadcrumbService,
    route: ActivatedRoute) {
    super(messageService, confirmationService, router, breadcrumbService);
    this.route = route;
  }


  protected getDataList(data, level, target) {
    let index = [];

    index = Object.keys(data)

    if (typeof (data[index[0]]) == "object") {
      if (level == 1)
        index.map(x => { target.lst.push(data[x]) });
      else {
        level--;

        index.map(x => {
          const sub_index = Object.keys(data[x]);


          sub_index.map(y => {
            this.getDataList(data[x][y], level, target)
          })

        })
      }
    }
    else {
      target.lst.push(data)
    }

  }

  protected setBreadcrumb(items: MenuItem[]) {
    this.breadcrumbService.setItems(this.route, items)
  }

  protected callAPI(arg: ApiType) {
    this.loadingCount++;
    arg.request.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe({
      next: arg.callback,
      error: (err) => {
        this.showErrorMsg(err, Action.Get, `${this.messageLabel}`, `${arg.api_name}`);
        this.loadingCount--;
      },
      complete: () => this.loadingCount--
    })
  }

  public onChange(commands: any[], extras?: NavigationExtras) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(commands, extras)

  }

  dateToMDY(date) {
    const [dd, mm, yy] = date.split('-')
    return `${mm}-${dd}-${yy}`
  }

  dateToDMY(date) {
    const [mm, dd, yy] = date.split('-')
    return `${dd}-${mm}-${yy}`
  }

  comparefn(a, b): number {
    const dte1 = new Date(a);
    const dte2 = new Date(b);

    return (dte1 < dte2) ? -1 : 1;

  }
}
