import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {NgModule, Optional, SkipSelf} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {RouterModule} from '@angular/router';
import {FooterComponent} from 'src/app/platform/components/footer/footer.component';
import {TopMenuComponent} from 'src/app/platform/components/top-menu/top-menu.component';
import {EnsureModuleLoadedOnceGuard} from './guards/ensure.module.loaded.once.guard';
import {BaseService} from '../common/services/base.service';
import {AircraftService} from '../features/administration/services/aircraft.service';
import {AvatarModule} from 'primeng/avatar';
import {ButtonModule} from 'primeng/button';
import {DialogModule} from 'primeng/dialog';
import {MessageModule} from 'primeng/message';
import {DividerModule} from 'primeng/divider';
import {MessagesModule} from 'primeng/messages';
import {RippleModule} from 'primeng/ripple';
import {TableModule} from 'primeng/table';
import {ToastModule} from 'primeng/toast';
import {TooltipModule} from 'primeng/tooltip';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {SharedModule} from './shared.module';
import { SecurityModule } from "../security/security.module";


@NgModule({
    exports: [
        FooterComponent,
        TopMenuComponent
    ],
    declarations: [
        FooterComponent,
        TopMenuComponent
    ],
    providers: [BaseService, AircraftService],
    imports: [
        CommonModule,
        HttpClientModule,
        RouterModule,
        MessageModule,
        MessagesModule,
        ToastModule,
        DialogModule,
        ButtonModule,
        MatIconModule,
        TooltipModule,
        RippleModule,
        TableModule,
        AvatarModule,
        OverlayPanelModule,
        DividerModule,
        SharedModule,
        SecurityModule
    ]
})
export class CoreModule extends EnsureModuleLoadedOnceGuard {   // Ensure that CoreModule is only loaded into AppModule

  // Looks for the module in the parent injector to see if it's already been loaded (only want it loaded once)
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    super(parentModule);
  }
}
