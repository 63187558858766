import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, combineLatest, Observable, switchMap } from "rxjs";
import { environment } from "src/environments/environment";
import { DataFrameByRecorderModel, DataFrameCompareModel, DataFrameModel, DataFrameVersionByID } from "../models/dataframe";
import { RecorderType } from "../models/dataframeEnums.enum";
import { BaseService } from "src/app/common/services/base.service";
import { CommonService } from "src/app/common/services/common.service";

@Injectable({
    providedIn: 'root'
})
export class DataFrameService extends BaseService {
    dataframeUrl = environment.apiUrl.config + 'data-frame';

    constructor(private http: HttpClient, private commonService: CommonService) {
        super();
        this.baseUrl = environment.apiUrl.config;
    }

    // populate dataframe list by Recorder Standard ID as an input
    public getDataFrameByRecorderID(recorderStandardId: number): Observable<DataFrameByRecorderModel[]> {
        return this.http.get<DataFrameByRecorderModel[]>(`${this.baseUrl}data-frame-filters/${recorderStandardId}`).
            pipe(catchError(this.httpHandleError));
    }

    //populate the list by Dataframe Version of the selected Data Frame - used in recorder details
    public getDataFrameVersionByID(dataFrameID: number): Observable<DataFrameVersionByID[]> {
        return this.http.get<DataFrameVersionByID[]>(this.baseUrl + `data-frame-versions` + `/${dataFrameID}`).
            pipe(catchError(this.httpHandleError));
    }

    public getDataFrameById(Id: number): Observable<DataFrameModel[]> {
        return this.http.get<DataFrameModel[]>(`${this.baseUrl}data-frame/${Id}`).pipe(catchError(this.httpHandleError));
    }

    // Add new dataframe
    public addDataFrame(dataframe_data: DataFrameModel, content: any, rec_type: string) {
        const options = { headers: this.httpGetHeadersForJSON() };
        const model = {
            "name": dataframe_data.name,
            "dataframeversion": dataframe_data.version,
            "self_reference": null,
            "filename": dataframe_data.filename
        };
        let url: string;

        switch (rec_type) {
            case RecorderType.A717:
            case RecorderType.PRM:
                content = content.replace('data:application/octet-stream;base64,', '');
                if (rec_type === RecorderType.PRM) {
                    rec_type = RecorderType.A717; // now we support PRM and use the same API-Endpoint
                }
                url = this.dataframeUrl + rec_type;
                break;
            case RecorderType.DXS:
                content = content.replace('data:text/csv;base64,', '');
                url = this.dataframeUrl + "-" + rec_type;
                break;
            case RecorderType.TDF:
                content = content.replace('data:application/octet-stream;base64,', '').replace('…', '');
                url = `${environment.apiUrl.config}data-frame-tdf`;
                break;
            default:
                content = content.replace('data:text/xml;base64,', '');
                url = this.dataframeUrl + rec_type;
                break;
        }

        const req_presigned_url = this.http.post(`${this.baseUrl}create-presigned-post`, { "object_name": dataframe_data.filename }, options);
        const formData = new FormData();

        return req_presigned_url
            .pipe(
                switchMap(val => {
                    Object.keys(val["fields"]).forEach(key => {
                        formData.append(key, val["fields"][key]);
                    });
                    formData.append("file", content);

                    return this.http.post(val["url"], formData)
                        .pipe(
                            switchMap(() => {
                                return this.http.post<number>(url, model, options)
                                    .pipe(
                                        catchError(this.httpHandleError)
                                    );
                            })
                        );
                })
            );
    }

    public uploadDataFrame(dataframe_data: DataFrameModel, content: any, rec_type: string) {
        const options = { headers: this.httpGetHeadersForJSON() };

        switch (rec_type) {
            case RecorderType.A717:
            case RecorderType.PRM:
                content = content.replace('data:application/octet-stream;base64,', '');
                break;
            case RecorderType.DXS:
                content = content.replace('data:text/csv;base64,', '');
                break;
            case RecorderType.TDF:
                content = content.replace('data:application/octet-stream;base64,', '').replace('…', '');
                break;
            default:
                content = content.replace('data:text/xml;base64,', '');
                break;
        }

        const req_presigned_url = this.http.post(`${this.baseUrl}create-presigned-post`, { "object_name": dataframe_data.filename }, options);
        const formData = new FormData();
        return req_presigned_url
            .pipe(
                switchMap(val => {
                    Object.keys(val["fields"]).forEach(key => {
                        formData.append(key, val["fields"][key]);
                    });
                    formData.append("file", content);
                    return this.http.post(val["url"], formData)
                        .pipe(
                            catchError(this.httpHandleError)
                        ); // Upload to s3-Bucket using Presigned URL
                })
            );
    }

    // delete object from s3 bucket
    public deletefilefromS3(filename) {
        const url = `${this.baseUrl}delete-s3-object`
        return this.http.post(url, { "object_name": filename }).pipe(catchError(this.httpHandleError))
    }

    // List the DataFrames for comparison based on the Recording Standard ID
    public getDataFrameForComparison(dataFrame_id): Observable<DataFrameModel[]> {
        const url = environment.apiUrl.config + 'data-frame-for-comparison' + `/${dataFrame_id}`;
        return this.http.get<DataFrameModel[]>(url, { headers: this.httpGetHeadersForJSON() }).pipe(catchError(this.httpHandleError));
    }

    // delete dataframe with dataframe_id and dataframe_version_id
    public deleteDataFrameVersion(dataframe_id: number) {
        const url = `${this.baseUrl}data-frame/${dataframe_id}`;

        const options = { headers: this.httpGetHeadersForJSON() }
        return this.http.delete(url, options).pipe(catchError(this.httpHandleError));
    }


    // get airlines attached to dataframe by id
    public getDataFrameDataWithAirlines(dataframeId: number) {
        const url = `${this.baseUrl}airlines-by-data-frame/${dataframeId}`;

        const result = combineLatest({

            model: this.http.get<DataFrameModel>(url, { headers: this.httpGetHeadersForJSON() }).pipe(catchError(this.httpHandleError)),
            airlines: this.commonService.listAirlinesFilter()
        })


        return result;
    }

    public manageDataFrame(dataframeVersionId: number, listofairlineid: any) {
        return this.http.put(`${this.baseUrl}data-frame/${dataframeVersionId}`, { "listofairlineid": listofairlineid }, { headers: this.httpGetHeadersForJSON() }).
            pipe(catchError(this.httpHandleError));
    }

    // clone new dataframe
    public cloneDataFrame(dataframeVersionId: number, name: string) {

        const model = { "dataframeversion_id": dataframeVersionId, "dataframe_name": name };
        const options = { headers: this.httpGetHeadersForJSON() }
        const url = `${this.baseUrl}data-frame-clone`;
        return this.http.post<number>(url, model, options).pipe(catchError(this.httpHandleError));

    }


    //get dataframeversion by id
    public getDataFrameVersionById(dataframeVersionId: number, fileType: string) {
        let params = new HttpParams().set('file_type', fileType);
        const url = `${this.baseUrl}data-frame-file/${dataframeVersionId}`;
        return this.http.get<string>(url,{params}).pipe(catchError(this.httpHandleError));
    }


    //compare dataframe versions
    public compareDataFrameVersions(versionId1: number, versionId2: number) {
        const url = `${this.baseUrl}data-frame-map-compare/${versionId1}/${versionId2}`;
        return this.http.get<DataFrameCompareModel>(url).pipe(catchError(this.httpHandleError));
    }

    public IdentifyDataFrame(dataframeVersionId: number): Observable<any> {
        const url = `${this.baseUrl}data-frame-content-by-data-frame-version-id/${dataframeVersionId}`;
        return this.http.get<any>(url, { headers: this.httpGetHeadersForJSON() })
            .pipe(catchError(this.httpHandleError)
            );
    }

    // seems to be removed in latest code?
    updateDataFrame(dataFrameId: number, dataframedetails: any): Observable<any> {
        const url = this.baseUrl + 'data-frame-content-by-data-frame-id' + `/${dataFrameId}`;
        const passData = JSON.stringify(dataframedetails);
        const pass = { "dataframe_id": dataFrameId, "content": passData }
        return this.http.put<number>(url, pass, { headers: this.httpGetHeadersForJSON() })
            .pipe(catchError(this.httpHandleError)
            );
    }

    public DataFrameByFrameId(dataFrameId: number): Observable<any> {
        const url = `${environment.apiUrl.common}data-frame-parameters/${dataFrameId}`;
        return this.http.get<any>(url, { headers: this.httpGetHeadersForJSON() })
            .pipe(catchError(this.httpHandleError)
            );
    }


    public getDataFrameParameters(dataFrameVersionID: number, fileName: string) {
        return this.http.get<string[]>(this.baseUrl + `data-frames-selectable-parameters` + `/${dataFrameVersionID}` + `/${fileName}`, { headers: this.httpGetHeadersForJSON() })
            .pipe(catchError(this.httpHandleError)
            );
    }

    //get labels
    public getDXSLabels(data_frame_version_id: number, file_name: string){
        return this.http.get<string[]>(this.baseUrl +`data-frames-dxs-selectable-labels`+`/${data_frame_version_id}`+`/${file_name}`, { headers: this.httpGetHeadersForJSON()})
        .pipe(catchError(this.httpHandleError)
        );
    }

    public updateDataFrameParams(data: any) {
        return this.http.put(`${this.baseUrl}data-frames-selectable-parameters`,  data , { headers: this.httpGetHeadersForJSON() }).
            pipe(catchError(this.httpHandleError));
    }

    public updateDataFrameLabels(data: any) {

        return this.http.put(`${this.baseUrl}data-frames-dxs-selectable-labels`,  data , { headers: this.httpGetHeadersForJSON() }).
          pipe(catchError(this.httpHandleError));
  }

    public getTargetRecorders() {
        return this.http.get<string[]>(this.baseUrl + `target-recorders`, { headers: this.httpGetHeadersForJSON() })
            .pipe(catchError(this.httpHandleError)
            );
    }
}
