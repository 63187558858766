import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/common/services/base.service';
import { Aircraft, AircraftPair } from '../models/aircraft';
import { environment } from 'src/environments/environment';

@Injectable()
export class AircraftService extends BaseService {
  private aircraftUrl: string;

  constructor(
    private http: HttpClient) {
    super();
    this.aircraftUrl = environment.apiUrl.admin + 'aircraft';

  }

  getAircraft(aircraftID: number): Observable<Aircraft> {
    const url = this.aircraftUrl + `/${aircraftID}`;
    return this.http.get<Aircraft>(url, { headers: this.httpGetHeadersForJSON() })
      .pipe(catchError(this.httpHandleError)
      );
  }

  listAircraft(aircraftGroupId?: number): Observable<Aircraft[]> {
    let parameters = new HttpParams();
    if (aircraftGroupId) {
      parameters = parameters.append("aircraftgroup_id", aircraftGroupId);
    }

    return this.http.get<Aircraft[]>(this.aircraftUrl, { headers: this.httpGetHeadersForJSON(), params: parameters })
      .pipe(map(aircraft => aircraft.map(ac => {
        if (ac.aircraftinterior && ac.aircraftinterior.length > 0) {
          const deckList = ac.aircraftinterior.map(i => i.deck);
          ac.aircraftinterior_display = deckList.join(', ');
        }
        return ac;
      })),
        catchError(this.httpHandleError)
      );
  }

  listAircraftFiltersByOrgID(OrgID: number, applicationName: string): Observable<AircraftPair[]> {
    const url = environment.apiUrl.admin + 'aircraft-filters' + `/${OrgID}/${applicationName}`;
    return this.http.get<AircraftPair[]>(url, { headers: this.httpGetHeadersForJSON() })
      .pipe(
        catchError(this.httpHandleError)
      );
  }

  addAircraft(newAircraft: Aircraft) {
    return this.http.post<number>(this.aircraftUrl, newAircraft, { headers: this.httpGetHeadersForJSON() })
      .pipe(catchError(this.httpHandleError)
      );
  }

  deleteAircraft(aircraftID: number): Observable<number> {
    const url = this.aircraftUrl + `/${aircraftID}`;
    const headers = this.httpGetHeadersForJSON(true);
    return this.http.delete<number>(url, { headers: headers })
      .pipe(
        catchError(this.httpHandleError)
      );
  }

  updateAircraft(updatedAircraft: Aircraft) {
    const url = this.aircraftUrl + `/${updatedAircraft.aircraft_id}`;
    return this.http.put(url, updatedAircraft, { headers: this.httpGetHeadersForJSON() })
      .pipe(catchError(this.httpHandleError)
      );
  }
}
