import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormGroup, NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { forkJoin, takeUntil } from 'rxjs';
import { Action, BaseViewComponent } from 'src/app/common/components/base-view.component';
import { SecurityUserService } from 'src/app/security/services/security-user.service';
import { BreadcrumbService } from '../../../../../common/services/breadcrumb.service';
import { Components } from '../../integration/datadelivery.components';
import { permissions } from '../../integration/datadelivery.permissions';
import {
  ConversionType,
  RedactionType,
  SAR,
  SARChannel,
  SARMap,
  jsonValues,
} from '../../models/SARRedaction';
import { SARRedactionService } from '../../services/sarRedaction.service';
@Component({
  selector: 'app-sarredaction',
  templateUrl: './sarredaction.component.html',
  styleUrls: ['./sarredaction.component.css'],
})
export class SARRedactionComponent extends BaseViewComponent implements OnInit {
  @ViewChild('SARFormData') SARFormData!: NgForm;
  pageTitle = Components.SARRedactions.label;
  public canAdd: boolean;
  public canDelete: boolean;
  public canEdit: boolean;
  public canView: boolean;
  public modalDialog?: boolean;
  public submitted?: boolean;
  uniqueError = false;
  today = new Date();
  todayAsStr = `${this.today.getFullYear()}-${this.today.getMonth()}-${this.today.getDate()}`;
  SARredactions: SAR[] = [];
  SARredaction!: SAR;
  isClone = 1;
  convtype: ConversionType[] = [];
  redactiontype: RedactionType[] = [];
  sarmap: SARMap[] = [];
  sarchannel: SARChannel[] = [];
  storeddata: any;
  jsonParameters: jsonValues;
  messageLabel = 'SAR Redaction';
  intialVal() {
    this.SARredaction = new SAR();
  }
  SARForm!: FormGroup;
  constructor(
    router: Router,
    private route: ActivatedRoute,
    breadcrumbService: BreadcrumbService,
    confirmationService: ConfirmationService,
    messageService: MessageService,
    private sarRedactionService: SARRedactionService,
    private realUserService: SecurityUserService,
    private ngZone: NgZone
  ) {
    super(messageService, confirmationService, router, breadcrumbService);
    this.getBreadCrums();
  }
  getBreadCrums() {
    this.breadcrumbService.setItems(this.route, [
      { label: Components.SARRedactions.label },
    ]);
  }
  ngOnInit(): void {
    this.canAdd = this.realUserService.userHasPermission(
      permissions.datadelivery_sar_redaction.create
    );
    this.canDelete = this.realUserService.userHasPermission(
      permissions.datadelivery_sar_redaction.delete
    );
    this.canEdit = this.realUserService.userHasPermission(
      permissions.datadelivery_sar_redaction.manage
    );
    this.getBreadCrums();
    this.intialVal();
    this.loadSARData();
  }

  openNew() {
    if (!this.canAdd) {
      this.showErrorMsg(
        'User is not authorized to add a SAR Redaction.',
        Action.Add,
        `${this.messageLabel}`
      );
    } else {
      this.ngZone.run(() => {
        this.router.navigate([Components.AddSARRedactions.path]).then();
      });
    }
  }

  loadSARData() {
    this.loadingCount++;
    forkJoin({
      sarData: this.sarRedactionService.getSARRedactions(),
    })
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (results) => {
          this.SARredactions = [];
          results.sarData.forEach((element) => {
            if (element.defaultparametervaluesconfig != null) {
              this.jsonParameters = JSON.parse(
                element.defaultparametervaluesconfig
              );
            }
            this.SARredactions.push({
              conversiontype_name: element.conversiontype_name,
              name: element.name,
              description: element.description,
              redaction_id: element.redaction_id,
            });
          });
        },
        error: (error) => {
          this.showErrorMsg(`${error}`, Action.Get, `${this.messageLabel}s`);
          this.loadingCount--;
        },
        complete: () => {
          this.loadingCount--;
        },
      });
  }

  onRowSelect(event) {
    this.ngZone.run(() => {
      this.router.navigate([Components.AddSARRedactions.path], {
        state: { sardata: event.data, isReadOnly: !this.canEdit },
        queryParams: {
          redactionId: event.data.redaction_id,
          name: event.data.name,
        },
      })
        .then();
    });
  }

  deleteSAR(sar: SAR) {
    if (!this.canDelete) {
      this.showErrorMsg(
        `User is not authorized to delete a SAR Redaction.`,
        Action.Delete,
        `${this.messageLabel}s`
      );
    } else {
      this.confirmationService.confirm({
        message:
          'Are you sure that you want to delete the selected SAR Redaction?',
        accept: () => {
          this.sarRedactionService
            .deleteSARRedaction(sar.redaction_id)
            .subscribe({
              next: () => {
                this.showSuccessMsg(
                  Action.Delete,
                  `${this.messageLabel}`,
                  `${sar.name}`
                );
                // Refresh the list of SAR
                this.loadSARData();
              },
              error: (error) => {
                this.showErrorMsg(
                  `${error}`,
                  Action.Delete,
                  `${this.messageLabel}s`
                );
              },
            });
        },
      });
    }
  }

  hideDialog() {
    this.modalDialog = false;
    this.submitted = false;
  }

  resetDialog() {
    this.SARFormData.reset();
  }
  cloneSAR(cloned: SAR) {
    if (!this.canAdd) {
      this.showErrorMsg(
        'User is not authorized to add a SAR Redaction.',
        Action.Add,
        `${this.messageLabel}`
      );
      return;
    }
    this.ngZone.run(() => {
      this.router.navigate([Components.AddSARRedactions.path], {
        state: { sardata: cloned },
        queryParams: {
          redactionId: cloned.redaction_id,
          name: cloned.name,
          clone: true,
        },
      })
        .then();
    });
  }
}
