import {Component, Input, OnInit,} from '@angular/core';
import {TopLevel} from "../../../../../models/dataframe717-767";

interface ILabelInformationTableRow {
  label: number,
  busName: string,
  SDI: string,
}

@Component({
  selector: 'app-frame-label-information-table',
  templateUrl: './frame-label-information-table.component.html',
  styleUrls: ['./frame-label-information-table.component.css'],
})
export class FrameLabelInformationTableComponent implements OnInit {
  @Input() order: TopLevel;
  public tableData: ILabelInformationTableRow[] = [];
  public columns = [
    { field: 'label', header: 'Label' },
    { field: 'busName', header: 'Bus Name' },
    { field: 'SDI', header: 'SDI' },
  ];

  ngOnInit(): void {
    const fredType = this.getFREDType();
    this.order.FREDFile[fredType].LabelList?.forEach(item => {
      item.Busses.forEach(bus => {
        const busName = this.order.FREDFile[fredType].BusList?.find(b => b.Port === bus.Port)?.Name;
        bus.SDIs.forEach(sdi => {
          this.tableData.push({
            label: item.Label,
            busName: busName,
            SDI: sdi,
          });
        });
      });
    });
  }

  getFREDType(): string {
    return this.order['ARINC STANDARD'] === 'A767' ? 'FRED767' : 'FREDDXS';
  }
}
