export const permissions = {
    endpoint: {
        "create": "data_delivery.endpoints.create",
        "manage": "data_delivery.endpoints.manage",
        "delete": "data_delivery.endpoints.delete",
        "view": "data_delivery.endpoints.view"
    },
    datadelivery_flight_redaction: {
        "create": "data_delivery.flight_data_redactions.create",
        "manage": "data_delivery.flight_data_redactions.manage",
        "delete": "data_delivery.flight_data_redactions.delete",
        "view": "data_delivery.flight_data_redactions.view"
    },
    datadelivery_acms_redaction: {
        "create": "data_delivery.acms_report_redactions.create",
        "manage": "data_delivery.acms_report_redactions.manage",
        "delete": "data_delivery.acms_report_redactions.delete",
        "view": "data_delivery.acms_report_redactions.view"
    },
    datadelivery_sar_redaction: {
        "create": "data_delivery.sar_redactions.create",
        "manage": "data_delivery.sar_redactions.manage",
        "delete": "data_delivery.sar_redactions.delete",
        "view": "data_delivery.sar_redactions.view"
    },
    datadelivery_client_packages: {
        "create": "data_delivery.client_packages.create",
        "manage": "data_delivery.client_packages.manage",
        "delete": "data_delivery.client_packages.delete",
        "view": "data_delivery.client_packages.view"
    },
    datadelivery_client_authentication: {
        "create": "data_delivery.client_authentication.create",
        "manage": "data_delivery.client_authentication.manage",
        "delete": "data_delivery.client_authentication.delete",
        "view": "data_delivery.client_authentication.view",
        "activate":"data_delivery.client_authentication.activate",
        "deactivate":"data_delivery.client_authentication.deactivate"
    },
    datadelivery_roles: {
        "create": "data_delivery.data_delivery_roles.create",
        "manage": "data_delivery.data_delivery_roles.manage",
        "delete": "data_delivery.data_delivery_roles.delete",
        "view": "data_delivery.data_delivery_roles.view"
    },
    datadelivery_users: {
        "manage": "data_delivery.data_delivery_users.manage",
        "view": "data_delivery.data_delivery_users.view"
    },
    datadelivery_enhanced_redaction: {
        "create": "data_delivery.enhanced_data_redactions.create",
        "manage": "data_delivery.enhanced_data_redactions.manage",
        "delete": "data_delivery.enhanced_data_redactions.delete",
        "view": "data_delivery.enhanced_data_redactions.view"
    },
    datadelivery_enhanced_packages: {
        "create": "data_delivery.enhanced_packages.create",
        "manage": "data_delivery.enhanced_packages.manage",
        "delete": "data_delivery.enhanced_packages.delete",
        "view": "data_delivery.enhanced_packages.view"
    },
    datadelivery_recording_alert:{
        "create": "data_delivery.client_recording_alerts.create",
        "manage": "data_delivery.client_recording_alerts.manage",
        "delete": "data_delivery.client_recording_alerts.delete",
        "view": "data_delivery.client_recording_alerts.view"
    },
    datadelivery_recording_users:{
        "view": "data_delivery.client_recording_alerts.view",
        "manage" : "data_delivery.user_client_recording_alerts.manage"
    }
}