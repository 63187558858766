import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { RecorderRegexModel } from 'src/app/features/configuration/models/recorderRegex';
import { BaseService } from 'src/app/common/services/base.service';

@Injectable({
  providedIn: 'root'
})
export class RecorderRegexService extends BaseService {
  recorderRegexUrl = environment.apiUrl.config + 'recorder-regex';

  constructor(private http: HttpClient) {
    super();
  }

  getRecorderRegexDetails(recorderRegexID: number) {
    const url = this.recorderRegexUrl + `/${recorderRegexID}`;
    return this.http.get<RecorderRegexModel>(url, { headers: this.httpGetHeadersForJSON() })
      .pipe(catchError(this.httpHandleError));
  }

  listRecorderRegexes() {
    return this.http.get<RecorderRegexModel[]>(this.recorderRegexUrl)
      .pipe(catchError(this.httpHandleError)
      );
  }

  addRecorderRegex(newRecorderRegex: RecorderRegexModel) {
    return this.http.post<number>(this.recorderRegexUrl, newRecorderRegex, { headers: this.httpGetHeadersForJSON() })
      .pipe(catchError(this.httpHandleError));
  }

  deleteRecorderRegex(recorderRegexID: number) {
    const url = this.recorderRegexUrl + `/${recorderRegexID}`;
    return this.http.delete(url, { headers: this.httpGetHeadersForJSON() })
      .pipe(catchError(this.httpHandleError));
  }

  updateRecorderRegex(updatedRecorderRegex: RecorderRegexModel) {
    const url = this.recorderRegexUrl + `/${updatedRecorderRegex.regex_id}`;
    return this.http.put<number>(url, updatedRecorderRegex, { headers: this.httpGetHeadersForJSON() })
      .pipe(catchError(this.httpHandleError));
  }
}
