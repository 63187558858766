export class RecorderRegexModel {
  name: string;
  regex_id: number;
}

export interface RegexPair {
  regex_id?: number;
  regex_name?: string;
}

export interface FinalRegexPair {
  regex_id?: number;
}
