import { forkJoin, of } from 'rxjs';
import { concatMap, takeUntil } from 'rxjs/operators';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService, SelectItem } from 'primeng/api';
import { Aircraft } from 'src/app/features/administration/models/aircraft';
import { AircraftService } from 'src/app/features/administration/services/aircraft.service';
import { SortUtil } from 'src/app/platform/util/sortUtil';
import { AircraftHistory } from 'src/app/features/administration/models/aircraftHistory';
import { AircraftModelFilter } from 'src/app/features/administration/models/aircraftModel';
import { BreadcrumbService } from 'src/app/common/services/breadcrumb.service';
import { Action, BaseViewComponent } from 'src/app/common/components/base-view.component';
import { AircraftInteriorPair } from 'src/app/features/administration/models/aircraftInterior';
import { Airframer } from 'src/app/features/administration/models/airframer';
import { EngineType } from 'src/app/features/administration/models/engineType';
import { SecurityUserService } from 'src/app/security/services/security-user.service';
import { permissions } from 'src/app/security/models/permissions';
import { CommonService } from 'src/app/common/services/common.service';
import { Components } from '../../integration/administration.components';

@Component({
  selector: 'app-aircraft-details',
  templateUrl: './aircraft-details.component.html'
})
export class AircraftDetailsComponent extends BaseViewComponent implements OnInit {
  pageTitle = Components.AircraftDetails.label;
  aircraft: Aircraft = null;
  models: AircraftModelFilter[];
  engineTypes: EngineType[] = [];
  airframers: Airframer[] = [];
  aircraftInteriors: AircraftInteriorPair[];
  interiorChange = false;
  aircraftCloneID = 0;
  isReadOnly = false;
  messageLabel = 'Aircraft';

  @ViewChild('aircraftForm') aircraftForm: NgForm;

  /**
   * Constructor for the component.
   *
   * @param aircraftService - Interacts with aircraft data.
   * @param confirmationService - Provides confirmation of configuration report deletion.
   * @param userService - Gets user information.
   * @param router - Used to route to another page.
   * @param route - Used to get the route's query parameters.
   * @param location - Used to navigate the user back to the previous page.
   * @param messageService - Used to display messages.
   * @param breadcrumbService - Used to update the breadcrumb.
   */
  constructor(private aircraftService: AircraftService,
    private commonService: CommonService,
    private route: ActivatedRoute,
    private realUserService: SecurityUserService,
    confirmationService: ConfirmationService,
    router: Router,
    messageService: MessageService,
    breadcrumbService: BreadcrumbService) {
    super(messageService, confirmationService, router, breadcrumbService);

    // Note: Service won't update breadcrumb if caller already did so.
    this.breadcrumbService.setItems(route, [
      { label: Components.Aircraft.label, routerLink: Components.Aircraft.path },
      { label: (this.route.snapshot.queryParamMap.get('c')) ? 'Clone Aircraft' : Components.AircraftDetails.label }    // Update breadcumb for clone/details accordingly...
    ]);
  }

  ngOnInit(): void {

    const canAdd = this.realUserService.userHasPermission(permissions.admin.aircraft.create);
    const canEdit = this.realUserService.userHasPermission(permissions.admin.aircraft.manage);

    const isEdit = !!this.route.snapshot.queryParamMap.get('t'); //tail number
    const isClone = isEdit && !!this.route.snapshot.queryParamMap.get('c'); //clone 'flag'

    if (!isEdit) {
      this.isReadOnly = !canAdd;
      this.addAircraft();
    }
    else {
      this.isReadOnly = isClone ? !canAdd : !canEdit;
      // ARO: note - i dont think we support '?c=false' as valid param value, if c exists, its a clone
      this.loadAircraft(+this.route.snapshot.queryParamMap.get('t'), isClone);
    }
  }

  /**
   * Loads the aircraft specified in query parameters.
   *
   * @param aircraftId - The tail identifier.
   * @param clone - Specifies if this tail is a clone of an existing tail.
   */
  loadAircraft(aircraftId: number, clone: boolean) {
    this.loadingCount++;

    // If cloning, update page title and breadcrumb with New...
    if (clone) {
      this.pageTitle = "Clone Aircraft";
    }

    // Get the aircraft and then the aircraft interior list
    this.aircraftService.getAircraft(aircraftId).pipe(concatMap(ac => {
      // this.aircraft = ac;
      return forkJoin([
        this.commonService.listAircraftInteriorFilters(ac.aircraftmodel_id),
        this.commonService.listAircraftModelFilters(),
        this.commonService.listAirframersFilters(),
        this.commonService.listEngineTypes(),
        of(ac)
      ])
    }), takeUntil(this.ngUnsubscribe)).subscribe({
      next: ([aircraftInteriors, models, airframers, engineTypes, ac]) => {
        this.aircraftInteriors = aircraftInteriors;
        this.airframers = airframers;
        this.engineTypes = engineTypes;
        this.models = models;
        this.aircraft = ac;

        // Check the interiors and filter the source list
        if (this.aircraftInteriors && this.aircraft.aircraftinterior) {
          this.aircraftInteriors = this.aircraftInteriors.filter(p => this.aircraft.aircraftinterior.findIndex(i => i.id == p.id) == -1)
        }

        if (!this.aircraft.aircraftinterior) {
          this.aircraft.aircraftinterior = [];
        }

        if (!clone) {
          this.updateBreadcrumb(Components.AircraftDetails.label + ' (' + this.aircraft.aircraft_registration + ')');
        } else {
          this.updateBreadcrumb(this.pageTitle + ' (' + this.aircraft.aircraft_registration + ')');
          this.isNew = true;
          this.aircraftCloneID = aircraftId;
          this.aircraft.aircraft_id = -1;
          this.aircraft.airline_id = this.userAirlineId;
          this.aircraft.aircraft_registration = '';
          this.aircraft.nose_number = this.aircraft.serial_number = null;
        }
      },
      error: error => {
        this.showErrorMsg(error, Action.Get, `${this.messageLabel}`);
        this.loadingCount--;
      },
      complete: () => this.loadingCount--
    });
  }

  addAircraft() {
    this.loadingCount++;

    // Update page title and breadcrumb with New...
    this.updateBreadcrumb('New Aircraft');
    this.pageTitle = "New Aircraft";

    // Don't get aircraft interiors yet as it is dependent on aircraft model
    forkJoin([
      this.commonService.listAircraftModelFilters(),
      this.commonService.listAirframersFilters(),
      this.commonService.listEngineTypes(),
    ])
      .pipe(takeUntil(this.ngUnsubscribe)).subscribe({
        next: ([models, airframers, engineTypes]) => {

          this.models = models;
          this.airframers = airframers;
          this.engineTypes = engineTypes;
          // Create the aircraft
          this.isNew = true;
          this.aircraft = new Aircraft();
          this.aircraft.aircraft_id = -1;
          this.aircraft.airline_id = this.userAirlineId;
          this.aircraft.aircraftinterior = [];
        },
        error: error => {
          this.showErrorMsg(`${error}`, Action.Add, `${this.messageLabel}`);
          this.loadingCount--;
        },
        complete: () => {
          this.loadingCount--;
        }
      }
      );
  }

  onSubmit() {
    this.loadingCount++;
    this.aircraft.airline_id = this.userAirlineId;
    // Map the interior ids
    if (this.aircraft.aircraftinterior) {
      this.aircraft.aircraftinterior_id = this.aircraft.aircraftinterior.map(i => i.id);
    }

    if (this.isNew) {
      this.aircraftService.addAircraft(this.aircraft).pipe(
        takeUntil(this.ngUnsubscribe)).subscribe({
          next: result => {
            this.aircraft.aircraft_id = result;
            // Update query param
            this.router.navigate([], {
              relativeTo: this.route,
              queryParams: {
                t: this.aircraft.aircraft_id
              },
              queryParamsHandling: 'merge'
            }).then();

            this.updateBreadcrumb(Components.AircraftDetails.label + ' (' + this.aircraft.aircraft_registration + ')');
            this.interiorChange = false;
          },
          error: err => {
            //
            this.showErrorMsg(`${err}`, Action.Add, `${this.messageLabel}`);
            this.loadingCount--;

          },
          complete: () => {
            this.aircraftCloneID = 0;
            this.showSuccessMsg(Action.Add, `${this.messageLabel}`, `${this.aircraft.aircraft_registration}`);
            this.pageTitle = Components.AircraftDetails.label;
            this.isNew = false;
            this.aircraftForm.resetForm(this.aircraftForm.value);
            this.loadingCount--;
          }
        });
    } else {
      this.aircraftService.updateAircraft(this.aircraft).pipe(
        takeUntil(this.ngUnsubscribe)).subscribe({
          next: () => {
            this.updateBreadcrumb(Components.AircraftDetails.label + ' (' + this.aircraft.aircraft_registration + ')');
            this.interiorChange = false;
          },
          error: err => {
            this.showErrorMsg(err, Action.Update, `${this.messageLabel}`, `${this.aircraft.aircraft_registration}`);
            this.loadingCount--;
          },
          complete: () => {
            this.showSuccessMsg(Action.Update, `${this.messageLabel}`, `${this.aircraft.aircraft_registration}`);
            this.aircraftForm.resetForm(this.aircraftForm.value);
            this.loadingCount--;
          }
        });
    }
  }

  onReset() {
    this.aircraftInteriors = [];
    this.interiorChange = false;
    this.ngOnInit();
    if (this.aircraftCloneID <= 0) {
      // Only allow reset if not cloning.
      this.aircraftForm.resetForm();
    }
  }

  onCancel() {
    if (this.aircraftForm.dirty || this.interiorChange) {
      this.confirmCancel(Components.Aircraft.label);
    }
    else {
      this.router.navigate([Components.Aircraft.path]).then();
    }
  }

  populateDropdownItems<T>(items: T[], labelProp: string, valueProp: string): SelectItem[] {
    const options = [];

    // Some error checking
    if (!items || items.length == 0) {
      return options;
    }

    const keys = Object.keys(items[0]);
    if (!keys.includes(labelProp) || !keys.includes(valueProp)) {
      return options;
    }

    items.forEach(i => {
      options.push({ label: i[labelProp], value: i[valueProp] });
    });

    SortUtil.sortByLabel(options);

    return options;
  }

  toAircraftInterior(interior: AircraftInteriorPair): AircraftInteriorPair {
    return interior;
  }


  /**
   * Used in HTML to get strongly-typed reference when using let-
   * @param history The AircraftHistory object.
   */
  toAircraftHistory(history: AircraftHistory): AircraftHistory {
    return history;
  }

  onInteriorChange() {
    this.interiorChange = true;
  }

  onModelChange() {
    if (!this.aircraft.aircraftmodel_id) {
      return;
    }
    this.loadingCount++;
    this.commonService
      .listAircraftInteriorFilters(this.aircraft.aircraftmodel_id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: res => {
          this.aircraftInteriors = res;
          // Reset the aircraft interiors as model has changed
          this.aircraft.aircraftinterior = this.aircraft.aircraftinterior_id = [];
        },
        error: err => {
          this.showErrorMsg(`${err}`, Action.Get, `${this.messageLabel}`);
          this.loadingCount--;
        },
        complete: () => this.loadingCount--
      })

  }
}
