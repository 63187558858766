import { Injectable } from "@angular/core";
import { BaseService } from "./base.service";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Observable, catchError, map } from "rxjs";
import { AircraftPair } from "src/app/features/administration/models/aircraft";
import { AircraftGroup } from "src/app/features/administration/models/aircraftGroup";
import { AircraftInteriorPair } from "src/app/features/administration/models/aircraftInterior";
import { AircraftModelFilter } from "src/app/features/administration/models/aircraftModel";
import { Airframer } from "src/app/features/administration/models/airframer";
import { EngineType } from "src/app/features/administration/models/engineType";
import { RoleFilter } from "../models/role";
import { Application } from "../../features/administration/models/application";
import { AcmsReportMapModel } from "../../features/configuration/models/acmsReportMap";
import { DataFrameModel } from "src/app/features/configuration/models/dataframe";
import { sarMapsModel } from "src/app/features/configuration/models/sarMaps";
import { AircraftGroups } from "src/app/features/sendtoteledyne/models/TeledynePackages";

@Injectable({
  providedIn: 'root'
})
export class CommonService extends BaseService {

  constructor(
    private http: HttpClient) {
    super();
  }

  listAirlinesFilter(): Observable<any[]> {
    return this.http.get<any[]>(environment.apiUrl.common + 'airlines', { headers: this.httpGetHeadersForJSON(true) })
      .pipe(catchError(this.httpHandleError));
  }

  listCompaniesFilter(): Observable<any[]> {
    return this.http.get<any[]>(environment.apiUrl.common + 'companies', { headers: this.httpGetHeadersForJSON(true) })
      .pipe(catchError(this.httpHandleError));
  }

  listAircraftFilters(applicationName: string): Observable<AircraftPair[]> {
    const url = environment.apiUrl.common + 'aircraft-filters/' + applicationName;
    return this.http.get<AircraftPair[]>(url, { headers: this.httpGetHeadersForJSON() })
      .pipe(catchError(this.httpHandleError));
  }

  listAircraftGroupFilters(): Observable<AircraftGroup[]> {
    const url = environment.apiUrl.common + 'aircraft-group-filters';
    return this.http.get<AircraftGroup[]>(url, { headers: this.httpGetHeadersForJSON(true) })
      .pipe(catchError(this.httpHandleError));
  }

  listAircraftGroupIdFilters(applicationName:string,aircarftGroupId?: number): Observable<AircraftGroups[]> {
    const url = environment.apiUrl.common + 'aircraft-group-by-id-filters/' + aircarftGroupId + '/' + applicationName;
    return this.http.get<AircraftGroups[]>(url, { headers: this.httpGetHeadersForJSON() })
      .pipe(catchError(this.httpHandleError));
  }

  listAircraftInteriorFilters(aircraftModelId?: number): Observable<AircraftInteriorPair[]> {
    const url = environment.apiUrl.common + 'aircraft-interior-arrangement-filters';
    let parameters = new HttpParams();
    if (aircraftModelId) {
      parameters = parameters.append("aircraftmodel_id", aircraftModelId);
    }
    return this.http.get<any[]>(url, { headers: this.httpGetHeadersForJSON(true), params: parameters })
      .pipe(map(interiors => interiors.map(i => ({ 'id': i.aircraftinterior_id, 'deck': i.aircraftinterior_deck }))), catchError(this.httpHandleError));
  }

  listAircraftModelFilters(): Observable<AircraftModelFilter[]> {
    const url = environment.apiUrl.common + `aircraft-model-filters`;
    return this.http.get<AircraftModelFilter[]>(url, { headers: this.httpGetHeadersForJSON() })
      .pipe(catchError(this.httpHandleError));
  }

  listAirframersFilters(): Observable<Airframer[]> {
    const url = environment.apiUrl.common + `airframer-filters`;
    return this.http.get<Airframer[]>(url, { headers: this.httpGetHeadersForJSON() })
      .pipe(catchError(this.httpHandleError));
  }

  listEngineTypes(): Observable<EngineType[]> {
    const url = environment.apiUrl.common + `engine-type-filters`;
    return this.http.get<EngineType[]>(url, { headers: this.httpGetHeadersForJSON() })
      .pipe(catchError(this.httpHandleError));
  }

  listRoleFilters(applicationID: number): Observable<RoleFilter[]> {
    const url = environment.apiUrl.common + 'role-filters';
    let params = new HttpParams();
    params = params.append("application_id", applicationID);
    return this.http.get<RoleFilter[]>(url, { params, headers: this.httpGetHeadersForJSON() })
      .pipe(map(roles => roles.map(r => {
        r.role_admin = Boolean(r.role_admin);
        return r;
      })), catchError(this.httpHandleError));
  }

  // get all available applications
  listApplication(): Observable<Application[]> {
    const url = environment.apiUrl.common + 'application';
    return this.http.get<Application[]>(url, { headers: this.httpGetHeadersForJSON() })
      .pipe(catchError(this.httpHandleError));
  }

  // populate dataframe list
  public getDataFrameData(): Observable<DataFrameModel[]> {
    const url = environment.apiUrl.common + 'data-frame';
    return this.http.get<DataFrameModel[]>(url, { headers: this.httpGetHeadersForJSON() })
      .pipe(catchError(this.httpHandleError));
  }

  //populate the acms-report-map list
  public getAcmsReportMapData(): Observable<AcmsReportMapModel[]> {
    const url = environment.apiUrl.common + 'acms-report-map';
    return this.http.get<AcmsReportMapModel[]>(url, { headers: this.httpGetHeadersForJSON() })
      .pipe(catchError(this.httpHandleError));
  }

  public getSARMapsList(): Observable<sarMapsModel[]> {
    const url = environment.apiUrl.common + 'sar-map';
    return this.http.get<sarMapsModel[]>(url, { headers: this.httpGetHeadersForJSON() })
      .pipe(catchError(this.httpHandleError));
  }

  public getACMSMapReports(mapId: number): Observable<string[]> {
    const url = environment.apiUrl.common + 'acms-report-map-reports/' + mapId;
    return this.http.get<string[]>(url, { headers: this.httpGetHeadersForJSON() })
      .pipe(catchError(this.httpHandleError));
  }

  public getSARMapReports(sarmap_id: number): Observable<string[]> {
    const url = environment.apiUrl.common + 'sarmap-channels/' + sarmap_id;
    return this.http.get<string[]>(url, { headers: this.httpGetHeadersForJSON() })
      .pipe(catchError(this.httpHandleError));
  }

  public getAwsRegions(): Observable<string[]> {
    const url = environment.apiUrl.common + 'get-aws-regions';
    return this.http.get<string[]>(url, { headers: this.httpGetHeadersForJSON() })
      .pipe(catchError(this.httpHandleError));
  }

}
