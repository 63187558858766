<p-blockUI [blocked]="loadingCount > 0">
  <tdy-spinner style="position:absolute;left:50%;top:50%;z-index: 10000"></tdy-spinner>
</p-blockUI>
<p-confirmDialog header="Confirm Deletion" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<div *ngIf="users != null" class="col-12 card">
  <div class="card-header m-2">
      <h5>{{pageTitle}}</h5>
  </div>
  <div class="col-12 ">
      <form #userForm="ngForm"  novalidate>
          <p-table id="dtUsers" (onRowSelect)="onRowSelect($event)" [value]="users" [filterDelay]="0" dataKey="user_id"
              [showCurrentPageReport]="true" [paginator]="users.length > defaultTableRows" [rowsPerPageOptions]="[20,50,100,200]"
              [rows]="defaultTableRows" currentPageReportTemplate="{{currentPageReport + ' ' + pageTitle}}" 
              selectionMode="single" 
              [sortOrder]="1" sortField="display_name" sortMode="single">
              <ng-template pTemplate="header">
                  <tr>
                      <th pSortableColumn="display_name">
                          <div class="flex justify-content-start align-items-center">
                              Display Name
                              <p-sortIcon field="display_name"></p-sortIcon>
                              <p-columnFilter [showAddButton]="false" [showOperator]="false" class="ml-0" display="menu"
                                  field="display_name" type="text" matchMode="contains"></p-columnFilter>
                          </div>
                      </th>
                      <th pSortableColumn="first_name">
                          <div class="flex justify-content-start align-items-center">
                              First Name
                              <p-sortIcon field="first_name"></p-sortIcon>
                              <p-columnFilter [showAddButton]="false" [showOperator]="false" class="ml-0" display="menu"
                                  field="first_name" type="text" matchMode="contains"></p-columnFilter>
                          </div>
                      </th>
                      <th pSortableColumn="last_name">
                          <div class="flex justify-content-start align-items-center">
                              Last Name
                              <p-sortIcon field="last_name"></p-sortIcon>
                              <p-columnFilter [showAddButton]="false" [showOperator]="false" class="ml-0" display="menu"
                                  field="last_name" type="text" matchMode="contains"></p-columnFilter>
                          </div>
                      </th>
                  </tr>
              </ng-template>
              <ng-template let-user pTemplate="body">
                  <ng-container *ngIf="toUser(user); let user">
                      <tr [pSelectableRow]="user">
                          <td style="word-wrap: break-word;">
                              {{user.display_name}}
                          </td>
                          <td style="word-wrap: break-word;">
                              {{user.first_name}}
                          </td>
                          <td style="word-wrap: break-word;">
                              {{user.last_name}}
                          </td>
                       
                      </tr>
                  </ng-container>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                  <tr>
                      <td [attr.colspan]="5">
                          No records found
                      </td>
                  </tr>
              </ng-template>
          </p-table>
          <div *ngIf="users.length > 0 && defaultTableRows >= users.length" style="text-align: center;">
            <p class="row-paging">Showing 1 to
              {{users.length}} of {{users.length}} {{pageTitle}}</p>
          </div>
      </form>            
  </div>    
</div>