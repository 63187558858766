import { Component, OnInit, ViewChild } from '@angular/core';
import {
  ConvertType,
  DataFrameType,
  FilePathType,
} from '../../models/FlightDataRedactions';
import { EnhancedRedactions } from '../../models/EnhancedRedaction';
import { ConfirmationService, MessageService } from 'primeng/api';
import {
  FormGroup,
  NgForm,
} from '@angular/forms';
import { Components } from '../../integration/datadelivery.components';
import { BreadcrumbService } from '../../../../../common/services/breadcrumb.service';
import { Action, BaseViewComponent } from 'src/app/common/components/base-view.component';
import { Router, ActivatedRoute } from '@angular/router';
import { FlightDataRedactionService } from '../../services/flightDataRedaction.service';
import { forkJoin, takeUntil } from 'rxjs';
import { Components as ConfigComponents } from '../../../../configuration/integration/configuration.components';
import { SecurityUserService } from 'src/app/security/services/security-user.service';
import { permissions } from '../../integration/datadelivery.permissions';
import { EnhancedRedactionService } from '../../services/enhancedRedaction.service';
@Component({
  selector: 'app-enhancedredaction',
  templateUrl: './enhancedredaction.component.html',
  styleUrls: ['./enhancedredaction.component.css'],
})
export class EnhancedRedactionComponent extends BaseViewComponent implements OnInit {
  @ViewChild('dataredactionForm') dataredactionForm!: NgForm;
  pageTitle = Components.EnhancedRedaction.label;
  public canAdd: boolean;
  public canDelete: boolean;
  public canEdit: boolean;
  public modalDialog?: boolean;
  public submitted?: boolean;

  today = new Date();
  todayAsStr = `${this.today.getFullYear()}-${this.today.getMonth()}-${this.today.getDate()}`;
  cols: any[] = [];
  flightdataredactions: EnhancedRedactions[] = [];
  flightdataredaction!: EnhancedRedactions;
  convertionType: ConvertType[] = [];
  dataframe: DataFrameType[] = [];
  file_path: FilePathType[] = [];
  files: any = '';
  empty = false;
  displayError = false;
  redactionForm!: FormGroup;
  allnames: any[] = [];
  uploadedFiles: any[] = [];
  uniqueError = false;
  names: any[] = [];
  checked!: boolean;
  acceptedFiles = '.csv';
  messageLabel = "Enhanced Data Redaction";
  selectedCloneData: EnhancedRedactions[] = [];
  isButtonDisabled = false;


  constructor(router: Router,
    private route: ActivatedRoute,
    breadcrumbService: BreadcrumbService, confirmationService: ConfirmationService,
    messageService: MessageService, private flightredactionservice: FlightDataRedactionService,
    private realUserService: SecurityUserService, private enhancedRedactionService: EnhancedRedactionService) {
    super(messageService, confirmationService, router, breadcrumbService);
    this.getBreadCrums();
  }

  getBreadCrums() {
    this.breadcrumbService.setItems(this.route, [
      { label: Components.EnhancedRedaction.label }
    ]);
  }

  ngOnInit(): void {
    this.getBreadCrums();
    this.canAdd = this.realUserService.userHasPermission(permissions.datadelivery_enhanced_redaction.create);
    this.canEdit = this.realUserService.userHasPermission(permissions.datadelivery_enhanced_redaction.manage);
    this.canDelete = this.realUserService.userHasPermission(permissions.datadelivery_enhanced_redaction.delete);
    this.cols = [
      {
        field: 'name',
        header: 'Name',
      },
      {
        field: 'description',
        header: 'Description',
      },
      {
        field: 'ziptype',
        header: 'Redaction Type',
      },
      {
        field: 'textualoutput',
        header: 'Textual Output',
      },
      {
        field: 'clone',
        header: 'Action',
      },
      {
        field: 'delete',
        header: 'Action',
      },
    ];
    this.flightdataredaction = new EnhancedRedactions;
    this.selectedCloneData = [];
    this.loadRedactionData();
  }

  openNew() {
    if (!this.canAdd) {
      this.showErrorMsg('User is not authorized to add an Enhanced Redaction.', Action.Add, `${this.messageLabel}`);
    } else {
      this.router.navigate([Components.AddEnhancedRedaction.path]).then();
    }
  }

  onRowEdit(event) {
    this.router.navigate([Components.AddEnhancedRedaction.path], {
      state: { flightData: event },
      queryParams: { redactionId: event.redaction_id, name: event.name }
    }).then();

  }


  deleteDataRedaction(dataredaction: EnhancedRedactions) {
    if (!this.canDelete) {
      this.showErrorMsg('User is not authorized to delete an Enhanced Data Redaction.', Action.Add, `${this.messageLabel}`);
    } else {
      this.confirmationService.confirm({
        message: 'Are you sure that you want to delete the selected Enhanced Data Redaction?',
        accept: () => {
          this.enhancedRedactionService.deleteFlightRedaction(dataredaction.redaction_id).subscribe({
            next: () => {
              this.showSuccessMsg(Action.Delete, `${this.messageLabel}`, `${dataredaction.name}`);
              // Refresh the list of redactions
              this.loadRedactionData();
            },
            error: error => {
              this.showErrorMsg(`${error}`, Action.Delete, `${this.messageLabel}s`);
            }
          });
        }
      });
    }
  }

  loadRedactionData() { //get all the redaction data to populate the Table
    this.loadingCount++;
    forkJoin({
      flightdataredactions: this.enhancedRedactionService.getFligtRedactions()
    }).pipe(
      takeUntil(this.ngUnsubscribe)).subscribe({
        next: results => {
          this.flightdataredactions = results.flightdataredactions;
        },
        error: error => {
          this.showErrorMsg(`${error}`, Action.Get, `${this.messageLabel}s`);
          this.loadingCount--;
        },
        complete: () => {
          this.loadingCount--;
        }
      });
  }

  cloneredaction(cloned: any) {
    this.router.navigate([Components.AddEnhancedRedaction.path], {
      queryParams: {
        redactionId: cloned.redaction_id,
        name: cloned.name,
        clone: true
      }
    }).then();
  }



  onRowClick(val: any) {
    this.isButtonDisabled = this.selectedCloneData.length > 0;
  }

  onRowSelect(event) {
    this.router
      .navigate([Components.AddEnhancedRedaction.path], {
        queryParams: {
          redactionId: event.data.redaction_id,
          name: event.data.name,
        },
      })
      .then();
  }

}
