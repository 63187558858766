<app-spinner [isLoading]="loadingCount > 0"></app-spinner>
<div *ngIf="order && frameDetailsForm" class="col-12 card p-3">
  <form [formGroup]="frameDetailsForm" (ngSubmit)="OnSubmit()" novalidate>
    <div class="card-header m-2">
      <h5>{{pageTitle}}</h5>
    </div>
    <hr style="margin: 0 -1rem;">
    <ng-container *ngFor="let category of inputCategoryList">
      <div class="card">
        <h6>{{category}}</h6>
        <div class="formgrid grid">
          <div *ngFor="let inputConf of formConfiguration[category]" class="lg:col-4 {{inputConf.class}}">
            <div class="p-float-label">
              <ng-container [ngSwitch]="inputConf.formType">
                <!-- Input Type -->
                <ng-container *ngSwitchCase="FormType.Input">
                  <ng-container [ngSwitch]="inputConf.type">
                    <input *ngSwitchCase="'number'" [id]="inputConf.valuePath" [name]="inputConf.valuePath"
                      type="number" pInputText [formControlName]="inputConf.valuePath"
                      [attributes]="inputConf.extraAttributes" [style]="inputConf.style"
                      (input)="inputConf.onInput && inputConf.onInput()">
                    <input *ngSwitchDefault [id]="inputConf.valuePath" [name]="inputConf.valuePath"
                      [type]="inputConf.type" pInputText [formControlName]="inputConf.valuePath"
                      [attributes]="inputConf.extraAttributes" [style]="inputConf.style"
                      (input)="inputConf.onInput && inputConf.onInput()">
                  </ng-container>
                </ng-container>
                <!-- Dropdown Type -->
                <p-dropdown *ngSwitchCase="FormType.Dropdown" [id]="inputConf.valuePath" [name]="inputConf.valuePath"
                  [formControlName]="inputConf.valuePath" [options]="inputConf.options" [autoDisplayFirst]="false"
                  autoWidth="false" [style]="inputConf.style" [attributes]="inputConf.extraAttributes"
                  [attr.disabled]="inputConf.disabled" [required]="inputConf.required"></p-dropdown>

              </ng-container>
              <label for="{{inputConf.valuePath}}">{{inputConf.label}}</label>

     

            </div>
            <app-validation-message [control]="frameDetailsForm?.controls[inputConf.valuePath]"
            [inputConf]="inputConf"></app-validation-message>
          </div>
        </div>
      </div>
      <hr style="margin: 0 -1rem;">
    </ng-container>
    <app-frame-label-information-table [order]="order">
    </app-frame-label-information-table>
    <app-data-frame-footer-action
      [saveDisabled]="(!frameDetailsForm.dirty && !isTableDirty) || frameDetailsForm.invalid || !isTableValid"
      [resetDisabled]="!frameDetailsForm.dirty && !isTableDirty" [userCanManage]="userCanManage"
      (onCancel)="formCancel()" (onReset)="formReset()" (onSave)="OnSubmit()">
    </app-data-frame-footer-action>
  </form>
</div>
