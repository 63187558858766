<app-data-frame-analyse [isLoading]="isLoading"
                        [ParamerterErrors]="parameterErrors"
                        [dfErrors]="dfErrors"
                        [errorMessages]="errorMessages"
                        [dataFrameAnalyseValue]="Components.DataFrameAnalyseDXS"
                        [mapViewPath]="Components.MapViewDXS.path"
                        [listViewPath]="Components.ListViewDXS.path"
                        [editPath]="Components.DataFrameDynamicDXS.path"
                        [dfPath]="Components.DataFrameDXS.path"
                        >
</app-data-frame-analyse>
