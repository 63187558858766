import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { takeUntil } from 'rxjs';
import { Action, BaseViewComponent } from 'src/app/common/components/base-view.component';
import { BreadcrumbService } from 'src/app/common/services/breadcrumb.service';
import { Components } from 'src/app/features/configuration/integration/configuration.components';
import { DataFrameModel } from 'src/app/features/configuration/models/dataframe';
import { DataFrameService } from 'src/app/features/configuration/services/data-frame.service';
import { SecurityUserService } from 'src/app/security/services/security-user.service';

//
import { FileUpload } from "primeng/fileupload";
import { NgForm } from "@angular/forms";
import { permissions } from '../../../../integration/configuration.permissions';

@Component({
  selector: 'app-update-dxs',
  templateUrl: './update-dxs.component.html',
})
export class UpdateDxsComponent extends BaseViewComponent implements OnInit {
  //variables
  availableLabels: { label: string; }[] = [];
  selectedLabels: { label: string; }[] = [];
  allowedFileType: string;
  isFileSelected = false;
  isSaveDisabled: boolean;
  fileToSave: any;
  record: DataFrameModel = new DataFrameModel();
  dataFrameVersionID: number;

  pageTitle = Components.UpdateDXS.label
  messageLabel = 'Update DXS';

  @ViewChild('contentUpload') content: FileUpload;
  @ViewChild('ngForm') addForm: NgForm;

  constructor(
    messageService: MessageService,
    confirmationService: ConfirmationService,
    router: Router,
    breadcrumbService: BreadcrumbService,
    private route: ActivatedRoute,
    private dataFrameService: DataFrameService,
    private realUserService: SecurityUserService
  ) {
    super(messageService, confirmationService, router, breadcrumbService);
    this.breadcrumbService.setItems(route, [
      { label: Components.Dataframe.label, routerLink: Components.Dataframe.path },
      { label: Components.DataframeVersions.label + ` (${this.route.snapshot.queryParamMap.get('name')})`, routerLink: Components.DataframeVersions.path },
      { label: Components.UpdateDXS.label, routerLink: Components.UpdateDXS.path }
    ]);
  }

  ngOnInit(): void {
    this.loadingCount++;
    this.userCanModify = this.realUserService.userHasPermission(permissions.dataframes.manage);
    this.breadcrumbService.setItems(this.route, [
      { label: Components.Dataframe.label, routerLink: Components.Dataframe.path },
      { label: Components.DataframeVersions.label + ` (${this.route.snapshot.queryParamMap.get('name')})`, routerLink: Components.DataframeVersions.path },
      { label: Components.UpdateDXS.label, routerLink: Components.UpdateDXS.path }
    ]);

    this.dataFrameVersionID = +this.route.snapshot.queryParamMap.get('dataframeversion_id');

    this.loadingCount--;
  }

  onFileSelect(event) {
    //Implement the upload logic here
    this.record.filename = event.files[0].name;
    const allowedFileType = [".csv"];
    if (allowedFileType.includes(event.files[0].name.split('.').pop())) {
      this.messageService.add({
        key: 'message',
        severity: 'error',
        summary: 'Incorrect File Type',
        detail: `${event.files[0].name} is not the correct type . Only ${this.allowedFileType} types are supported.`,
      });
    }
    this.loadFileToSave(event);

    setTimeout(() => {
      this.addDXSFile();
    }, 300)

  }

  loadFileToSave(event) {
    //reading file
    this.loadingCount++;
    const reader = new FileReader();
    const file = event.files[0];
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.fileToSave = reader.result;

      const base64Data = this.fileToSave.replace('data:text/csv;base64,', '');
      const decodedData = atob(base64Data);
      const content = new TextDecoder('utf-8').decode(new Uint8Array(decodedData.length).map((_, i) => decodedData.charCodeAt(i)));
      this.fileToSave = base64Data;
      this.loadingCount--;
    }
  }


  addDXSFile() {
    this.loadingCount++;
    this.dataFrameService.uploadDataFrame(this.record, this.fileToSave, "").subscribe({
      next: res => {
        this.isSaveDisabled = true;
      },
      error: err => {
        this.showErrorMsg(`${err}`, Action.Add, `error occured`);
        this.loadingCount--;
      },
      complete: () => {
        this.getLabels(this.dataFrameVersionID, this.record.filename);
        this.isFileSelected = true;
        this.loadingCount--;
      }
    })
    this.loadingCount--;
  }

  getLabels(data_frame_version_id: number, file_name: string) {
    this.loadingCount++;
    //add api call here
    this.dataFrameService.getDXSLabels(data_frame_version_id, file_name).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: res => {
        try {
          this.availableLabels = res.sort((a, b) => Number(a) - Number(b)).map(label => ({ label: label.toString() }));
          this.loadingCount--
        } catch (e) {
          this.loadingCount--;
        }
      },
      error: err => {
        this.showErrorMsg(err, Action.Get, `${this.messageLabel}`, `${data_frame_version_id}`);
        this.loadingCount--
      }
    })
  }

  onMoveToTarget(event) {
    this.isSaveDisabled = this.selectedLabels.length === 0;
    this.selectedLabels.sort((a, b) => Number(a.label) - Number(b.label));
  }
  
  onMoveToSource() {
    this.availableLabels.sort((a, b) => Number(a.label) - Number(b.label));
    this.isSaveDisabled = this.availableLabels.length === 0;
  }
  onFileRemove() { this.onCancel() }

  onCancel() {
    this.availableLabels = [];
    this.selectedLabels = [];
    this.isFileSelected = false;
  }
  onReset() {
    this.ngOnInit();
    this.availableLabels = [];
    this.selectedLabels = [];
    this.isFileSelected = false;
  }

  onSubmit() {
    this.loadingCount++;
    const finalObject = {
      dataFrameID: +this.route.snapshot.queryParamMap.get('dataframe_id'),
      dataFrameVersion: +this.route.snapshot.queryParamMap.get('version'),
      filename: this.record.filename,
      listofLabels: this.selectedLabels.map(sl => Number(sl.label))
    }
    this.dataFrameService.updateDataFrameLabels(finalObject).subscribe({
      error: (err) => {
        this.showErrorMsg(err, Action.Update, `${err}`);
        this.loadingCount--;
      },
      complete: () => {
        this.showSuccessMsg(Action.Update, "the Data Frame with selected Labels.");
        this.ngOnInit();
      }
    })
  }

}
