import { Directive } from "@angular/core";
import { Router } from "@angular/router";
import { ConfirmationService, MessageService } from "primeng/api";
import { takeUntil } from "rxjs";
import { BreadcrumbService } from "src/app/common/services/breadcrumb.service";
import { Action, BaseViewComponent } from "src/app/common/components/base-view.component";
import { IComponentCompare } from "../input-model/IComponentInput";

@Directive()
export class BaseCompareComponent extends BaseViewComponent {
  pageTitle: string;
  acmsName: string;
  versionOptions1: any[] = [];
  versionOptions2: any[] = [];
  selectedV1: number;
  selectedV2: number;
  versionLst: any[];
  comparisonResult: any;
  model: string;
  constructor(
    messageService: MessageService,
    confirmationService: ConfirmationService,
    router: Router,
    breadcrumbService: BreadcrumbService,
    private componentInput: IComponentCompare
  ) {
    super(messageService, confirmationService, router, breadcrumbService)
    this.model = componentInput.modelName;
  }

  load_Compare(Id: number, Name = "", versionList) {
    this.pageTitle = this.componentInput.InputClassName;
    this.acmsName = Name;
    this.breadcrumbService.pushItems([{ label: `${this.pageTitle}` }])

    const versionObj = JSON.parse(versionList);

    this.versionLst = [...Object.values(versionObj)];
    this.selectedV1 = Id;
    this.versionOptions1 = this.versionLst;
    this.versionOptions2 = this.filterList(Id, [...this.versionLst]);
    this.selectedV2 = Math.max(...this.versionOptions2.map(x => x["aid"]));

    setTimeout(() => this.getResult(this.selectedV1, this.selectedV2), 0)
  }

  filterList(item, list: any[]) {
    for (let i = 0; i < list.length; i++)
      if (list[i]["aid"] === item) {
        list.splice(i, 1);
        break;

      }
    return list;
  }

  onChange($event) {
    this.versionOptions2 = this.filterList($event.value, [...this.versionLst]);
    setTimeout(() => {
      this.selectedV2 = Math.max(...this.versionOptions2.map(x => x["aid"]));
    }, 0);
  }

  onChangeVersion2() {
    // Override
  }

  onClick() {
    this.getResult(this.selectedV1, this.selectedV2);
  }

  getResult(version1: number, version2: number) {
    this.loadingCount++;

    this.componentInput.getDiff(version1, version2).pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {

          if (res.length == 0)
            this.comparisonResult = this.componentInput.noRecordMessage;
          else
            this.comparisonResult = res;

          (<any>window).res = this.comparisonResult;
        },
        error: (err) => {
          this.showErrorMsg(`${err}`, Action.Get, " compare 2 version");
          this.comparisonResult = "";
          this.loadingCount--;
        },
        complete: () => this.loadingCount--
      })
  }
}
