<p-blockUI [blocked]="loadingCount > 0">
  <tdy-spinner style="position:absolute;left:50%;top:50%;z-index: 10000"></tdy-spinner>
</p-blockUI>
<p-confirmDialog icon="pi pi-exclamation-triangle"></p-confirmDialog>
<div class="col-12 card">
  <div class="card-header m-2">
    <h5>{{pageTitle}}</h5>
    <div class="p-toolbar-group-end">
      <button pButton pRipple label="Add" *ngIf="canAdd" id="addButton" icon="pi pi-plus" (click)="openNew()"></button>
    </div>
  </div>
  <div class="card">
    <p-table [value]="clientauths" datakey="dt" [filterDelay]="0" [responsive]="true" sortField="Name"
    [showCurrentPageReport]="true" [paginator]="clientauths.length > defaultTableRows"
    [rowsPerPageOptions]="[20,50,100,200]" [rows]="defaultTableRows"
    currentPageReportTemplate="{{currentPageReport + ' ' + pageTitle}}" [tableStyle]="{ width: '100%' }" #dt
    [globalFilterFields]="[
      'Name',
    ]">

    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="Name">
          <div class="flex justify-content-start align-items-center">
            Name<p-sortIcon field="Name"></p-sortIcon>
            <p-columnFilter [showAddButton]="false" [showOperator]="false" class="ml-0" display="menu" field="Name"
              type="text" matchMode="contains"></p-columnFilter>
          </div>
        </th>
        <th pSortableColumn="ClientId">
          <div class="flex justify-content-start align-items-center">
            Client ID <p-sortIcon field="ClientId"></p-sortIcon>
            <p-columnFilter [showAddButton]="false" [showOperator]="false" class="ml-0" display="menu" field="ClientId"
              type="text" matchMode="contains"></p-columnFilter>
          </div>
        </th>
        <th pSortableColumn="ClientSecret">
          <div class="flex justify-content-start align-items-center">
            Client Secret <p-sortIcon field="ClientSecret"></p-sortIcon>
            <p-columnFilter [showAddButton]="false" [showOperator]="false" class="ml-0" display="menu"
              field="ClientSecret" type="text" matchMode="contains"></p-columnFilter>
          </div>
        </th>
        <th pSortableColumn="date_of_generation">
          <div class="flex justify-content-start align-items-center">
            Generated Date <p-sortIcon field="date_of_generation"></p-sortIcon>
            <p-columnFilter type="text" matchMode="contains" [showOperator]="false" field="date_of_generation"
              display="menu" [showAddButton]="false">
            </p-columnFilter>
          </div>
        </th>
        <th></th>
 
    
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-data>
      <tr [pSelectableRow]="data">
        <td>{{ data.Name }}</td>
        <td>{{ data.ClientId }}</td>
        <td>{{ data.ClientSecret }}</td>
        <td>{{ data.date_of_generation }}</td>

 
        <td>
          <button id="activateButton" *ngIf="data.IsActive==false ? canActivate : data.IsActive ? canDeactivate : false"
          name="activateButton" class="p-button-rounded p-button-text"
          [ngClass]="{'p-button-danger':data.IsActive==false,'p-button-success':data.IsActive==true}" pButton pRipple
          icon="pi pi-check-circle" [pTooltip]="data.IsActive==true?'Activated':'Deactivated'"
          style="margin-left: 0.5em" (click)="statusUpdate(data)"></button>
          <button id="generateButton" *ngIf="canEdit" name="generateButton" type="button"
            class="p-button-rounded p-button-text mr-1 ml-1" pButton icon="pi pi-refresh" pRipple
            pTooltip="Generate key..." (click)="generateClientSecret(data)">
          </button>
          <button id="copyButton" name="copyButton" type="button" class="p-button-rounded p-button-text mr-1 ml-1"
            pButton icon="pi pi-clone" pRipple pTooltip="Copy ClientId..." [disabled]="copyClicked"
            (click)="copyClientId(data)">
          </button>
          <button id="copyButton" name="copyButton" type="button" class="p-button-rounded p-button-text mr-1 ml-1"
            pButton icon="pi pi-clone" pRipple pTooltip="Copy Client Secret..." [disabled]="copyClicked"
            (click)="copyClientSecret(data)">
          </button>
          <button id="deleteButton" name="deleteButton" type="button" *ngIf="canDelete"
            class="p-button-rounded p-button-text p-button-danger mr-1 ml-1" pButton pRipple icon="pi pi-trash"
            pTooltip="Delete..." (click)="deleteClientAuth(data)"></button>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td [attr.colspan]="6">No Client Authentication Data Found.</td>
      </tr>
    </ng-template>
  </p-table>
  </div>

  <div *ngIf="clientauths.length > 0 && defaultTableRows >= clientauths.length" style="text-align: center;">
    <p class="row-paging">Showing 1 to
      {{clientauths.length}} of {{clientauths.length}} {{pageTitle}}</p>
  </div>
</div>
