import { Component, OnInit } from '@angular/core';
import { EngineType } from 'src/app/features/administration/models/engineType';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbService } from 'src/app/common/services/breadcrumb.service';
import { EngineTypeService } from 'src/app/features/administration/services/engine-type.service';
import { Action, BaseViewComponent } from 'src/app/common/components/base-view.component';
import { SecurityUserService } from 'src/app/security/services/security-user.service';
import { permissions } from 'src/app/security/models/permissions';
import { Components } from '../../integration/administration.components';

@Component({
  selector: 'app-engine-types',
  templateUrl: './engine-types.component.html'
})
export class EngineTypesComponent extends BaseViewComponent implements OnInit {

  pageTitle = Components.EngineTypes.label;
  engineTypes: EngineType[] = null;
  public canAdd: boolean;
  public canDelete: boolean;
  public canView: boolean;
  messageLabel = 'Engine Type';

  constructor(private engineTypeService: EngineTypeService,
    private route: ActivatedRoute,
    private securityUserService: SecurityUserService,
    messageService: MessageService,
    confirmationService: ConfirmationService,
    router: Router,
    breadcrumbService: BreadcrumbService) {
    super(messageService, confirmationService, router, breadcrumbService);

    // Note: Service won't update breadcrumb if caller already did so.
    this.breadcrumbService.setItems(route, [
      { label: Components.EngineTypes.label }  // No routerLink value for current page
    ]);
  }

  ngOnInit(): void {
    this.canView = this.securityUserService.userHasPermission(permissions.admin.engine_types.view);
    this.canAdd = this.securityUserService.userHasPermission(permissions.admin.engine_types.create);
    this.canDelete = this.securityUserService.userHasPermission(permissions.admin.engine_types.delete);

    this.breadcrumbService.setItems(this.route, [{ label: Components.EngineTypes.label }]);
    if (this.canView) {
      this.loadEngineTypes();
    }
  }

  loadEngineTypes() {
    this.loadingCount++;
    this.engineTypeService.listEngineTypes().subscribe({
      next: engines => {
        this.engineTypes = engines;
      },
      error: err => {
        this.showErrorMsg(err, Action.Get, `${this.messageLabel}`);
        this.loadingCount--;
      },
      complete: () => this.loadingCount--
    });
  }

  onRowSelect(event) {
    const engineType = event.data as EngineType;
    this.router.navigate([Components.EngineTypeDetails.path], {
      queryParams: {
        e: engineType?.enginetype_id, // e = engine Type ID
        name: engineType?.enginetype_name
      }
    }).then();
  }

  toEngineType(engineType: EngineType): EngineType {
    return engineType;
  }

  onClickAdd() {
    if (!this.canAdd) {
      this.showErrorMsg('User is not authorized to add an Engine Type:', Action.Add, `${this.messageLabel}`);
    } else {
      this.router.navigate([Components.EngineTypeDetails.path]).then();
    }
  }

  onDeleteEngineType(engineType: EngineType) {
    if (!this.canDelete) {
      this.showErrorMsg('User is not authorized to delete an Engine Type:', Action.Delete, `${this.messageLabel}`, `${engineType.enginetype_name}`);
    } else {
      this.confirmationService.confirm({
        message: 'Are you sure that you want to delete the selected Engine Type?',
        accept: () => {
          this.engineTypeService.deleteEngineType(engineType.enginetype_id).subscribe({
            next: () => {
              this.showSuccessMsg(Action.Delete, `${this.messageLabel}`, `${engineType.enginetype_name}`);

              // Refresh the list of engine types
              this.loadEngineTypes();
            },
            error: error => {
              this.showErrorMsg(`${error}`, Action.Delete, `${this.messageLabel}`, `${engineType.enginetype_name}`);
            }
          });
        }
      });
    }
  }

}
