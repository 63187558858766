import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from '../../../../common/services/base.service';
import { catchError } from 'rxjs/operators';
import { environment } from "src/environments/environment";
import { UserRecordingSubscription, ResponseUserRecordingSubscription, UsersRecordingNotifications } from '../models/userRecordingSubscriptionModel';
@Injectable({
  providedIn: 'root'
})
export class UserRecordingSubscriptionService extends BaseService {

  constructor(
    private http: HttpClient) {
    super();
    this.baseUrl = environment.apiUrl.datadelivery;
  }


  getUserSubscriptionByUserId(userId: number): Observable<UserRecordingSubscription[]> {
    const url = this.baseUrl + "user-recording-notifications" + `/${userId}`;
    return this.http.get<UserRecordingSubscription[]>(url, { headers: this.httpGetHeadersForJSON() })
      .pipe(catchError(this.httpHandleError)
      );
  }

  addusersubscription(usersubscription: ResponseUserRecordingSubscription) {
    const url = this.baseUrl + 'user-recording-notifications';
    return this.http.put<number>(url, usersubscription, { headers: this.httpGetHeadersForJSON() })
      .pipe(
        catchError(this.httpHandleError)
      )
  }

  getRecordingUsers(applicationId: number): Observable<UsersRecordingNotifications[]> {
    const url = this.baseUrl + "get-application-users" + `/${applicationId}`;
    return this.http.get<UsersRecordingNotifications[]>(url, { headers: this.httpGetHeadersForJSON() })
      .pipe(catchError(this.httpHandleError)
      );
  }
}
