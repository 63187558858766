<p-blockUI [blocked]="loadingCount > 0">
  <tdy-spinner style="position:absolute;left:50%;top:50%;z-index: 10000"></tdy-spinner>
</p-blockUI>


<app-organization-details [organization]="airline"
codeDisplay="ICAO Code"
[availableApplications]="availableApplications"
[(assignedApplications)]="assignedApplications"
[airline]="airline"
[aircraftApps]="airlineAircraftApps"
[filterAircraftApps]="airlineAircraftApps"
[aircraftGroupsOptions]="aircraftGroupsOptions"
[airlineApplication] ="unchangedApplicationsPayload"
[airlineAircraft]="airlineAircraft"
[aircraftGroups]="aircraftGroups"
[codeIsReadOnly]="codeIsReadOnly"
[settings]="settings"
[isNew]="isNew"
[organizationType]="orgType"
[canEditOrg]="hasAirlineManage"
[canViewOrg]="canView"
[canViewOrgDetail]="airlineDetailsView"
[canEditOrgDetail]="airlineDetailsEdit"
[loggedIn]="loggedIn"
pageTitle="{{getPageTitle()}}"
(onCancelClick)="onCancel()"
(onResetClick)="onReset()"
(airlineAircraftChanged)="onAircraftAppsChanged($event)"
(onSubmitClick)="onSubmit($event)"
></app-organization-details>
