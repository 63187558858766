import { AfterViewChecked, ChangeDetectorRef, Directive } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BreadcrumbService } from "../../../../../../../common/services/breadcrumb.service";
import { ConfirmationService, MessageService } from "primeng/api";
import { FrameDetails } from "../frame-details.component";
import { FormControl, FormGroup } from "@angular/forms";
import { InputConfiguration } from "../../shared/models/form.models";
import { Frame } from "../../../../../models/dataframe717-767";
import { Subject } from "rxjs";
import { DFDetailsInputCategory767, DFDetailsInputCategoryDXS, FormType } from "../../shared/enums/formEnums";
import { getProp } from "../../shared/utils";
import { SecurityUserService } from "../../../../../../../security/services/security-user.service";
import { DataFrameService } from "../../../../../services/data-frame.service";

@Directive()
export class FrameDetails767DxsComponent extends FrameDetails implements AfterViewChecked {

  frameDetailsForm: FormGroup;
  formConfiguration: { [key: string]: Array<InputConfiguration> };
  public dataparameter: Frame[] = [];

  frameRate: any;
  lblHz = 'Hz';
  frameErrorMsg: any[] = [];
  Index = null;

  FileRevision = false;
  FrameIDs = [];
  validateFrame = new Subject<void>();
  onReset = new Subject<void>();
  isTableValid = true;
  isTableDirty;
  inputCategoryList: string[];
  FormType = FormType;

  constructor(
    messageService: MessageService,
    confirmationService: ConfirmationService,
    router: Router,
    route: ActivatedRoute,
    breadcrumbService: BreadcrumbService,
    realUserService: SecurityUserService,
    dataframeservice: DataFrameService,
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {
    super(
      messageService,
      confirmationService,
      router,
      route,
      breadcrumbService,
      realUserService,
      dataframeservice
    );
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  override processDataFrame(response: any) {
    const updatedframe = JSON.parse(sessionStorage.getItem('FrameDetails'));
    this.order = updatedframe != (undefined || null) ? updatedframe : response;
    this.processTableDataParameters();
    this.setForm();
    this.validateFrame.next();
  }

  setForm() {
    this.setFormConfiguration();
    const allConfigurations = Object.keys(this.formConfiguration).reduce((acc, currentKey) => [...acc, ...this.formConfiguration[currentKey]], []);
    const group = {};
    allConfigurations.forEach((controlConf) => {
      group[controlConf.valuePath] = new FormControl(getProp(this.order, `FREDFile.${this.getFREDType()}.Header.${controlConf.valuePath}`), controlConf.validators);
    });
    this.frameDetailsForm = new FormGroup(group);
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setFormConfiguration(): void {
  }

  getFREDType(): string {
    return this.order['ARINC STANDARD'] === 'A767' ? 'FRED767' : 'FREDDXS';
  }

  processTableDataParameters() {
    this.FrameIDs = [];
    this.dataparameter = [];
    const fredType = this.getFREDType();
    for (const key in this.order.FREDFile[fredType]?.Frame) {
      for (const key1 in this.order.FREDFile[fredType].Frame[key]) {
        if (this.order.FREDFile[fredType].Frame[key][key1].Recording_Rate.Numerator?.toString() != undefined) {
          this.frameRate = this.order.FREDFile[fredType].Frame[key][key1].Recording_Rate.Numerator;
        }
        if (this.order.FREDFile[fredType].Frame[key][key1].Recording_Rate.Denominator?.toString() != undefined) {
          this.frameRate += '/' + this.order.FREDFile[fredType].Frame[key][key1].Recording_Rate.Denominator;
        }
        this.frameRate += this.lblHz;
        this.order.FREDFile[fredType].Frame[key][key1].Recording_Rate = this.frameRate;
        this.dataparameter.push(this.order.FREDFile[fredType].Frame[key][key1]);
        this.dataparameter.sort((a, b) => {
          return a.Frame_ID - b.Frame_ID;
        });
        this.FrameIDs.push(this.order.FREDFile[fredType].Frame[key][key1].Frame_ID);
      }
    }
  }

  OnSubmit() {
    const frameArray = [];
    this.frameErrorMsg = [];
    const fredType = this.getFREDType();
    this.dataparameter.forEach((element: any) => {
      const val = element.Recording_Rate.split(/[/Hz_]+/);
      const nume = Number(val[0]);
      const denom = Number(val[1]);
      const rate = {
        Numerator: nume,
      };
      denom != 0 ? (rate['Denominator'] = denom) : null;
      element.Recording_Rate = rate;
      frameArray.push({ [element.Frame_ID]: element });
    });
    this.order.FREDFile[fredType].Frame = frameArray;

    this.order.FREDFile[fredType].Header = Object.assign(this.order.FREDFile[fredType].Header, this.frameDetailsForm.getRawValue());
    sessionStorage.setItem('FrameDetails', JSON.stringify(this.order));
    this.backToScreen();
  }

  override formReset(): void {
    this.onReset.next();
    this.frameDetailsForm.reset();
    this.dataparameter = [];
    this.FrameIDs = [];
    this.getDataFrame(this.versionId);
  }

  createField(category: DFDetailsInputCategory767 | DFDetailsInputCategoryDXS, name: string, label: string, validators = []) {
    const isDisabled = this.userCanManage ? null : '';
    return {
      category,
      formType: FormType.Input,
      type: 'text',
      name,
      label,
      valuePath: name,
      style: { width: '100%' },
      extraAttributes: { disabled: isDisabled, pInputText: '' },
      validators
    }
  }

  createSelect(category: DFDetailsInputCategory767 | DFDetailsInputCategoryDXS, name: string, label: string, options: string[], validators = []) {
    return {
      category,
      formType: FormType.Dropdown,
      name: name,
      label: label,
      valuePath: name,
      style: { width: '100%' },
      options: options,
      disabled: !this.userCanManage,
      required: true,
      validators: validators,
    }
  }
}