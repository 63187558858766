import { Component, NgZone, OnInit } from '@angular/core';
import {
  FlightDataRedactions,
  DataFrameType,
  FilePathType,
  RedactionType,
  ResponseFlightDataRedaction,
  AvailableDataFrame,
  mappedRedactionTypes,
} from '../../models/FlightDataRedactions';
import { GetErrorMessage } from 'src/app/features/sendtoteledyne/models/SendToTdyFlightRedaction';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Router, ActivatedRoute } from '@angular/router';
import { Components } from '../../integration/datadelivery.components';
import { BreadcrumbService } from '../../../../../common/services/breadcrumb.service';
import { Action, BaseViewComponent } from 'src/app/common/components/base-view.component';
import { DataFrameService } from '../../../../../features/configuration/services/data-frame.service';
import { FlightDataRedactionService } from '../../services/flightDataRedaction.service';
import { forkJoin, takeUntil } from 'rxjs';
import { CommonService } from 'src/app/common/services/common.service';
import { SecurityUserService } from 'src/app/security/services/security-user.service';
import { permissions } from '../../integration/datadelivery.permissions';
import { Message } from 'primeng/api';

@Component({
  selector: 'app-dataredaction-add',
  templateUrl: './dataredaction-add.component.html',
  styleUrls: ['./dataredaction-add.component.css'],
})
export class DataRedactionAddComponent
  extends BaseViewComponent
  implements OnInit {
  pageTitle = Components.AddDataRedactions.label;
  public canAdd: boolean;
  public canDelete: boolean;
  public canEdit: boolean;
  public submitted?: boolean;
  showButtons = true;
  picklistChange = false;
  isButtonDisabled = true;
  flightdataredactions: FlightDataRedactions[] = [];
  flightdataredaction!: FlightDataRedactions;
  redactionType: RedactionType[] = mappedRedactionTypes;
  availableDataFrame: AvailableDataFrame[] = [];
  availableDataFramelist: AvailableDataFrame[] = [];
  dataframe: DataFrameType[] = [];
  selecteduploadDataframes: DataFrameType[] = [];
  uploadfilepath!: FilePathType;
  files: any = '';
  empty = false;
  uniqueError = false;
  selectedDataframes: AvailableDataFrame[] = [];
  selectedDataframesIDs: string[] = [];
  editselectedDataframesIDs: string[] = [];
  isReadonly = true;
  selectedDataframeId: number;
  selectedRedactionTypeId: number;
  initialRecordersCount: number;
  responseFlightDataRedaction: ResponseFlightDataRedaction;
  fileButtonEnabled = false;
  acceptedFiles = '.csv';
  filename: string;
  fileToSave: any;
  arr: string[] = [];
  editarr: string[] = [];
  fileString: string;
  isValid = true;
  isFileValid = true;
  readactionType = 1;
  messageLabel = 'Flight Data Redaction';
  newLabel = 'New Flight Data Redaction';
  messages!: Message[];
  showMessage = false;

  constructor(
    router: Router,
    private route: ActivatedRoute,
    breadcrumbService: BreadcrumbService,
    confirmationService: ConfirmationService,
    messageService: MessageService,
    private dataFrameService: DataFrameService,
    private flightdataredactionservice: FlightDataRedactionService,
    private commonservice: CommonService,
    private realUserService: SecurityUserService,
    private ngZone: NgZone
  ) {
    super(messageService, confirmationService, router, breadcrumbService);
  }

  getBreadCrums() {
    this.breadcrumbService.setItems(this.route, [
      {
        label: Components.DataRedactions.label,
        routerLink: Components.DataRedactions.path,
      },
      {
        label:
          Components.AddDataRedactions.label +
          ` (${this.route.snapshot.queryParamMap.get('name')})`,
      },
    ]);
  }

  ngOnInit(): void {
    this.canAdd = this.realUserService.userHasPermission(
      permissions.datadelivery_flight_redaction.create
    );
    this.canEdit = this.realUserService.userHasPermission(
      permissions.datadelivery_flight_redaction.manage
    );
    this.canDelete = this.realUserService.userHasPermission(
      permissions.datadelivery_flight_redaction.delete
    );
    this.fileButtonEnabled = true;
    this.uploadfilepath = new FilePathType();
    this.flightdataredaction = new FlightDataRedactions();
    this.getBreadCrums();
    const redactionId = +this.route.snapshot.queryParamMap.get('redactionId');
    const isClone = !!this.route.snapshot.queryParamMap.get('clone');
    if (redactionId) {
      if (isClone) {
        this.isNew = true;
        this.isReadonly = !this.canAdd;
        this.showButtons = this.canAdd;
        this.pageTitle = 'Clone Flight Data Redaction';
        this.updateBreadcrumb(
          `${this.pageTitle} (${this.route.snapshot.queryParamMap.get('name')})`
        );
      } else {
        this.isNew = false;
        this.isReadonly = !this.canEdit;
        this.showButtons = this.canEdit;
      }
      this.loadFlightDataRedactionDetails(redactionId, isClone);
    } else {
      this.isReadonly = !this.canAdd
      this.showButtons = this.canAdd;
      this.intialVal();
    }
    this.loadDataFrames();
  }

  intialVal() {
    this.isNew = true;
    this.pageTitle = this.newLabel;
    this.updateBreadcrumb(this.pageTitle);
    this.flightdataredaction = new FlightDataRedactions();
    this.flightdataredaction.redaction_id = -1;
    this.flightdataredaction.textualoutput = false;
    this.availableDataFrame = [];
    this.availableDataFramelist = [];
    this.uploadfilepath = new FilePathType();
  }

  onDataFrameChange(e: any) {
    this.updateSelectedDataframeId(e);
    this.loadDataRedactionDetails(this.selectedDataframeId);

    if (this.selecteduploadDataframes.length > 0) {
      this.resetDataframes();
      this.updateAvailableDataFrame();
      this.updateSelectedDataframesIDs();
      this.filterAvailableDataFramelist();
      this.updateInitialRecordersCount();
    }
  }

  updateSelectedDataframeId(e: any): void {
    this.selectedDataframeId = e.value;
  }

  resetDataframes(): void {
    this.selectedDataframesIDs = [];
    this.availableDataFrame = [];
  }

  updateAvailableDataFrame(): void {
    this.availableDataFramelist.forEach((ele) => {
      if (
        this.selecteduploadDataframes.find(
          (a) => a.dataframe_type == ele.dataframe_type
        )
      ) {
        this.availableDataFrame.push({ dataframe_type: ele.dataframe_type });
      }
    });
  }

  updateSelectedDataframesIDs(): void {
    this.selecteduploadDataframes.forEach((ele) => {
      this.selectedDataframesIDs.push(ele.dataframe_type);
    });
  }

  filterAvailableDataFramelist(): void {
    if (this.availableDataFramelist && this.availableDataFrame) {
      this.availableDataFramelist = this.availableDataFramelist.filter(
        (p) =>
          this.selecteduploadDataframes.findIndex(
            (i) => i.dataframe_type == p.dataframe_type
          ) == -1
      );
    }
  }

  updateInitialRecordersCount(): void {
    this.initialRecordersCount = this.availableDataFramelist
      ? this.availableDataFramelist.length
      : 0;
    this.initialRecordersCount = this.selecteduploadDataframes.length;
  }

  loadFlightDataRedactionDetails(redaction_id: number, clone: boolean) {
    this.incrementLoadingCount();
    this.fetchFlightDataRedactionDetails(redaction_id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (results) =>
          this.handleFlightDataRedactionDetails(results, clone),
        error: (error) => {
          this.showErrorMsg(`${error}`, Action.Get, `${this.messageLabel}s`);
          this.decrementLoadingCount();
        },
        complete: () => this.decrementLoadingCount(),
      });
  }

  fetchFlightDataRedactionDetails(redaction_id: number) {
    return forkJoin({
      getflightredactiondetails:
        this.flightdataredactionservice.getFligtRedaction_by_id(redaction_id),
      dataframe: this.commonservice.getDataFrameData(),
    });
  }

  handleFlightDataRedactionDetails(results: any, clone: boolean) {
    const getDataframes = this.parseDataframes(
      results.getflightredactiondetails.mapjson
    );
    this.populateDataframes(results.dataframe);
    this.flightdataredaction = results.getflightredactiondetails;
    this.editselectedDataframesIDs = getDataframes.selected_values;
    this.selectedRedactionTypeId = this.flightdataredaction.redactiontype_id;
    this.selectedDataframeId = this.flightdataredaction.dataframe_id;
    this.flightdataredaction.textualoutput = !!this.flightdataredaction.textualoutput;
    this.onEditloadDataframeDetails(this.flightdataredaction.dataframe_id);
    if (clone) {
      this.flightdataredaction.name = '';
    }
    this.empty = true;
  }

  parseDataframes(mapjson: string) {
    return JSON.parse(mapjson);
  }

  populateDataframes(dataframes: any[]) {
    dataframes.forEach((ele) => {
      this.dataframe.push({
        dataframe_id: ele.dataframe_id,
        dataframe_type: ele.name,
      });
    });
  }

  isTextualOutput(textualoutput: boolean) {
    return textualoutput === true;
  }

  onEditloadDataframeDetails(dataframeId?: number) {
    this.loadingCount++;
    this.fetchDataFrameDetails(dataframeId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (results) => {
          this.processDataFrameDetails(results);
        },
        error: (error) => {
          this.handleError(error);
        },
        complete: () => {
          this.loadingCount--;
        },
      });
  }

  fetchDataFrameDetails(dataframeId?: number) {
    return forkJoin({
      availableredaction: this.dataFrameService.DataFrameByFrameId(dataframeId),
    });
  }

  processDataFrameDetails(results: any) {
    this.availableDataFrame = [];
    this.populateAvailableDataFrame(results.availableredaction);
    this.populateSelectedDataframes();
    this.filterAvailableDataFramelistFromSelectedDataFrames();
    this.updateInitialRecordersCountFromSelectedDataFrames();
    this.sortDataFrames();
  }

  populateAvailableDataFrame(availableredaction: any) {
    availableredaction.forEach((ele: any) => {
      if (this.editselectedDataframesIDs.find((a) => a == ele)) {
        this.availableDataFrame.push({ dataframe_type: ele });
        this.selectedDataframes.push({ dataframe_type: ele });
      }
      this.availableDataFramelist.push({ dataframe_type: ele });
    });
  }

  populateSelectedDataframes() {
    this.selectedDataframes.forEach((ele) => {
      this.selectedDataframesIDs.push(ele.dataframe_type);
    });

    this.editarr = this.editselectedDataframesIDs.filter(
      (p) =>
        this.selectedDataframes.findIndex((i) => i.dataframe_type == p) == -1
    );
  }

  filterAvailableDataFramelistFromSelectedDataFrames() {
    if (this.availableDataFramelist && this.availableDataFrame) {
      this.availableDataFramelist = this.availableDataFramelist.filter(
        (p) =>
          this.selectedDataframes.findIndex(
            (i) => i.dataframe_type == p.dataframe_type
          ) == -1
      );
    }
  }

  updateInitialRecordersCountFromSelectedDataFrames() {
    this.initialRecordersCount = this.availableDataFramelist
      ? this.availableDataFramelist.length
      : 0;
    this.initialRecordersCount = this.selectedDataframes.length;
  }

  sortDataFrames() {
    this.availableDataFramelist.sort((a, b) => {
      return a.dataframe_type.localeCompare(b.dataframe_type);
    });
    this.availableDataFrame.sort((a, b) => {
      return a.dataframe_type.localeCompare(b.dataframe_type);
    });
  }

  handleError(error: any) {
    this.showErrorMsg(`${error}`, Action.Get, `${this.messageLabel}s`);
    this.loadingCount--;
  }

  loadDataFrames() {
    this.incrementLoadingCount();
    forkJoin({
      dataframe: this.commonservice.getDataFrameData(),
    })
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (results) => {
          results.dataframe.forEach((ele) => {
            this.dataframe.push({
              dataframe_id: ele.dataframe_id,
              dataframe_type: ele.name,
            });
          });
        },
        error: (error) => {
          this.showErrorMsg(`${error}`, Action.Get, `${this.messageLabel}s`);
          this.loadingCount--;
        },
        complete: () => {
          this.decrementLoadingCount();
        },
      });
  }

  loadDataRedactionDetails(frameId: number) {
    this.incrementLoadingCount();
    this.resetDataFrames();

    this.fetchAvailableDataFrames(frameId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (results) => this.processAvailableDataFrames(results),
        error: (error) => this.handleDataRedactionDetailsError(error),
        complete: () => this.decrementLoadingCount(),
      });
  }

  incrementLoadingCount() {
    this.loadingCount++;
  }

  decrementLoadingCount() {
    this.loadingCount--;
  }

  resetDataFrames() {
    this.availableDataFramelist = [];
    this.availableDataFrame = [];
  }

  fetchAvailableDataFrames(frameId: number) {
    return forkJoin({
      availableDataFrame: this.dataFrameService.DataFrameByFrameId(frameId),
    });
  }

  processAvailableDataFrames(results: any) {
    this.populateAvailableDataFrameList(results.availableDataFrame);
    this.populateSelectedDataFramesFromAvailableDataFramse();
    this.filterAvailableDataFrame();
    this.updateInitialRecordersCountFromSelectedDataFrames();
    this.sortAvailableDataFrameList();
    this.clearSelectedDataframesIDs();
    this.setEmptyFlag(true);
  }

  populateAvailableDataFrameList(dataFrames: any[]) {
    dataFrames.forEach((ele) => {
      this.availableDataFramelist.push({ dataframe_type: ele });
    });
  }

  populateSelectedDataFramesFromAvailableDataFramse() {
    this.availableDataFramelist.forEach((ele) => {
      this.selectedDataframes.push({ dataframe_type: ele.dataframe_type });
    });
  }

  filterAvailableDataFrame() {
    if (this.availableDataFrame && this.selectedDataframes) {
      this.availableDataFrame = this.availableDataFrame.filter(
        (p) =>
          this.selectedDataframes.findIndex(
            (i) => i.dataframe_type == p.dataframe_type
          ) == -1
      );
    }
  }

  sortAvailableDataFrameList() {
    this.availableDataFramelist.sort((a, b) => {
      return a.dataframe_type.localeCompare(b.dataframe_type);
    });
  }

  clearSelectedDataframesIDs() {
    this.selectedDataframesIDs = [];
  }

  setEmptyFlag(value: boolean) {
    this.empty = value;
  }

  handleDataRedactionDetailsError(error: any) {
    this.showErrorMsg(`${error}`, Action.Get, `${this.messageLabel}s`);
    this.decrementLoadingCount();
  }

  onChangeRedaction(e: any) {
    this.selectedRedactionTypeId = e.value;
    if (e.value == this.readactionType) {
      this.flightdataredaction.textualoutput = false;
    }
  }

  handleSubmit(event: FlightDataRedactions) {
    this.flightdataredaction = event;
    this.onSubmit();
  }

  onSubmit() {
    this.loadingCount++;
    const getValue = {
      selected_values: this.selectedDataframesIDs,
    };
    this.responseFlightDataRedaction = {
      redaction_id: this.flightdataredaction.redaction_id,
      redaction_name: this.flightdataredaction.name,
      redaction_description: this.flightdataredaction.description,
      redaction_textualoutput:
        this.flightdataredaction.textualoutput == null
          ? false
          : this.flightdataredaction.textualoutput,
      redaction_mapjson: JSON.stringify(getValue),
      redaction_defaultparametervaluesconfig: '',
      redaction_dataframe_id: this.selectedDataframeId,
      redaction_redactiontype_id: this.selectedRedactionTypeId,
      organization_id: 0,
      enhanced: false,
    };
    this.loadingCount--;
    if (this.isNew) {
      this.flightdataredactionservice
        .addFlightRedaction(this.responseFlightDataRedaction)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (result) => {
            this.flightdataredaction.redaction_id = result;
            // Update query param
            this.ngZone.run(() => {
              this.router.navigate([], {
                relativeTo: this.route,
                queryParams: {
                  redactionId: this.flightdataredaction.redaction_id,
                  name: this.flightdataredaction.name,
                },
                queryParamsHandling: 'merge',
              });
            });
            this.updateBreadcrumb(
              Components.AddDataRedactions.label +
              ' (' +
              this.flightdataredaction.name +
              ')'
            );
            this.pageTitle = Components.AddDataRedactions.label;
            this.picklistChange = false;
          },
          error: (err) => {
            this.showErrorMsg(`${err}`, Action.Add, `${this.messageLabel}s`);
            this.loadingCount--;
            this.isButtonDisabled = true;
            this.showMessage = false;
          },
          complete: () => {
            this.showSuccessMsg(
              Action.Add,
              `${this.messageLabel}`,
              `${this.flightdataredaction.name}`
            );
            this.isNew = false;
            this.loadingCount--;
            this.isButtonDisabled = true;
            this.showMessage = false;
            this.ngOnInit;
          },
        });
    } else {
      this.flightdataredactionservice
        .updateFlightRedaction(this.responseFlightDataRedaction)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: () => {
            this.updateBreadcrumb(
              Components.AddDataRedactions.label +
              ' (' +
              this.flightdataredaction.name +
              ')'
            );
            this.picklistChange = false;
          },
          error: (err) => {
            this.showErrorMsg(`${err}`, Action.Update, `${this.messageLabel}s`);
            this.loadingCount--;
            this.isButtonDisabled = true;
            this.showMessage = false;
          },
          complete: () => {
            this.showSuccessMsg(
              Action.Update,
              `${this.messageLabel}`,
              `${this.flightdataredaction.name}`
            );
            this.loadingCount--;
            this.isButtonDisabled = true;
            this.showMessage = false;
            this.ngOnInit;
          },
        });
    }
  }

  onReset() {
    this.onFileRemove();
    this.uploadfilepath['filename'] = '';
    this.picklistChange = false;
    this.selectedDataframes = [];
    this.selectedDataframesIDs = [];
    this.availableDataFramelist = [];
    this.selectedRedactionTypeId = 0;
    this.editarr = [];
    this.isButtonDisabled = true;
    this.isFileValid = true;
    this.empty = false;
    this.selecteduploadDataframes = [];
    this.showMessage = false;
    this.ngOnInit();
  }

  onFileRemove() {
    this.submitted = true;
    this.uploadfilepath['filename'] = null;
    this.fileButtonEnabled = this.uploadfilepath['filename'] != '';
    this.isValid = true;
    this.isFileValid = true;
    this.showMessage = false;
    this.uploadedParamaterValues();
  }

  uploadedParamaterValues() {
    if (this.selecteduploadDataframes.length > 0) {
      this.selectedDataframesIDs = [];
      this.availableDataFrame = this.availableDataFrame.filter(
        (p) =>
          this.selecteduploadDataframes.findIndex(
            (i) => i.dataframe_type == p.dataframe_type
          ) == -1
      );
      this.availableDataFrame.forEach((ele) => {
        this.selectedDataframesIDs.push(ele.dataframe_type);
      });
      this.selecteduploadDataframes.forEach((ele) => {
        if (
          !this.availableDataFramelist.find(
            (a) => a.dataframe_type == ele.dataframe_type
          )
        ) {
          this.availableDataFramelist.push({
            dataframe_type: ele.dataframe_type,
          });
        }
      });
      this.availableDataFramelist.sort((a, b) => {
        return a.dataframe_type.localeCompare(b.dataframe_type);
      });
      this.selecteduploadDataframes = [];
    }
  }

  onUpload(event) {
    const fileValidated = this.fileValidation(event);
    if (fileValidated) {
      this.submitted = true;
    }
  }

  processFile(reader) {
    this.fileString = reader.result as string;
    this.arr = this.parseFileString(this.fileString);

    const getMatch = this.getMatchedDataFrames(
      this.arr,
      this.availableDataFramelist
    );
    const findDuplicateVal = this.findDuplicates(this.arr);
    const getUnmatches = this.getUnmatchedDataFrames(this.arr, getMatch);

    this.selecteduploadDataframes = [];
    this.populateSelectedDataFrames(getMatch);

    this.selectedDataframesIDs = this.selecteduploadDataframes.map(
      (ele) => ele.dataframe_type
    );

    this.filterAvailableDataFrames();

    this.initialRecordersCount = this.selecteduploadDataframes.length;

    this.validateFile(getMatch, findDuplicateVal, getUnmatches);

    // if (this.content?.clear) this.content.clear();
  }

  parseFileString(fileString: string): string[] {
    return fileString.split(/[\r\n]+/).map((item) => item.toLowerCase());
  }

  getMatchedDataFrames(
    arr: string[],
    availableDataFramelist: AvailableDataFrame[]
  ): AvailableDataFrame[] {
    return availableDataFramelist.filter((a) =>
      arr.includes(a.dataframe_type.toLowerCase())
    );
  }

  findDuplicates(arr: string[]): string[] {
    return arr.filter((item, index) => arr.indexOf(item) !== index);
  }

  getUnmatchedDataFrames(
    arr: string[],
    getMatch: AvailableDataFrame[]
  ): string[] {
    return arr.filter(
      (a) => !getMatch.find((item) => item.dataframe_type.toLowerCase() === a)
    );
  }

  populateSelectedDataFrames(getMatch: AvailableDataFrame[]) {
    this.availableDataFramelist.forEach((ele) => {
      if (
        getMatch.find(
          (a) =>
            a.dataframe_type.toLowerCase() === ele.dataframe_type.toLowerCase()
        )
      ) {
        this.availableDataFrame.push({ dataframe_type: ele.dataframe_type });
        this.selecteduploadDataframes.push({
          dataframe_type: ele.dataframe_type,
        });
      }
    });
  }

  filterAvailableDataFrames() {
    if (this.availableDataFramelist && this.availableDataFrame) {
      this.availableDataFramelist = this.availableDataFramelist.filter(
        (p) =>
          this.selecteduploadDataframes.findIndex(
            (i) =>
              i.dataframe_type.toLowerCase() === p.dataframe_type.toLowerCase()
          ) === -1
      );
    }
  }

  validateFile(
    getMatch: AvailableDataFrame[],
    findDuplicateVal: string[],
    getUnmatches: string[]
  ) {
    if (this.hasDuplicates(findDuplicateVal)) {
      this.handleValidationError(GetErrorMessage.Duplicates, findDuplicateVal);
    } else if (this.hasNoMatches(getMatch)) {
      this.handleValidationError(GetErrorMessage.Matching, getMatch);
    } else if (this.hasUnmatches(getUnmatches)) {
      this.handleValidationError(GetErrorMessage.Selected, getUnmatches);
    } else {
      this.isValid = true;
    }

    this.isValid = getMatch.length > 0;
  }

  hasDuplicates(findDuplicateVal: string[]): boolean {
    return findDuplicateVal.length > 1;
  }

  hasNoMatches(getMatch: AvailableDataFrame[]): boolean {
    return getMatch.length < 1;
  }

  hasUnmatches(getUnmatches: string[]): boolean {
    return getUnmatches.length > 1;
  }

  handleValidationError(errorType: string, values: any[]) {
    this.isValid = false;
    this.removefile();
    this.messages = this.flightdataredactionservice.getErrorMessage(
      errorType + values.join(',').slice(0, -1)
    );
    this.showMessage = true;
  }

  fileValidation(event) {
    const file = (event.files?.[0] || event.currentFiles?.[0]);
    const fileType = file?.name?.split(".")?.pop() ?? file?.type?.split("/")?.pop();
    this.isFileValid = false;
    if (!this.acceptedFiles.includes(`.${fileType.toLowerCase()}`)) {
      this.showErrorMsg(
        `${file.name} is not the correct type . Only ${this.acceptedFiles} types are supported.`,
        Action.Upload,
        `${this.messageLabel}s`
      );
      this.isValid = false;
      this.removefile();
      this.isFileValid = true;
    } else {
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onload = () => {
        this.processFile(reader);
      };
      this.isFileValid = false;
    }
    return this.isValid;
  }

  onMoveToTarget(event) {
    this.addDataframesToSelected(event);
  }

  onMoveAllToTarget(event) {
    this.addDataframesToSelected(event);
  }

  onMoveToSource(event) {
    this.removeDataframesFromSelected(event);
  }

  onMoveAllToSource(event) {
    this.removeDataframesFromSelected(event);
  }

  addDataframesToSelected(event) {
    event.items.forEach((item) => {
      if (!this.selectedDataframesIDs.includes(item.dataframe_type)) {
        this.selectedDataframesIDs.push(item.dataframe_type);
      }
    });
    this.selectedDataframesIDs = [...new Set(this.selectedDataframesIDs)]; // Remove duplicates
    this.isButtonDisabled = false;
    this.picklistChange = true;
    this.sortDataframes();
  }

  removeDataframesFromSelected(event) {
    event.items.forEach((item) => {
      const index = this.selectedDataframesIDs.indexOf(item.dataframe_type);
      if (index > -1) {
        this.selectedDataframesIDs.splice(index, 1);
      }
    });
    this.isButtonDisabled = false;
    this.picklistChange = true;
    this.sortDataframes();
  }

  sortDataframes() {
    this.availableDataFrame = this.availableDataFrame.sort((a, b) => a.dataframe_type.localeCompare(b.dataframe_type));
    this.availableDataFramelist = this.availableDataFramelist.sort((a, b) => a.dataframe_type.localeCompare(b.dataframe_type));
  }

  onCancel() {
    this.ngZone.run(() => {
      this.router.navigate([Components.DataRedactions.path]);
    });
  }

  removefile() {
    this.arr = [];
    this.uploadfilepath.filename = '';
    this.isValid = true;
    this.showMessage = false;
  }
}
