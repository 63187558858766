<app-data-frame-analyse [isLoading]="isLoading"
                        [ParamerterErrors]="parameterErrors"
                        [dfErrors]="dfErrors"
                        [dataFrameAnalyseValue]="Components.DataFrameAnalyse717"
                        [mapViewPath]="Components.MapView717.path"
                        [listViewPath]="Components.ListView717.path"
                        [editPath]="Components.DataFrameDynamic717.path"
                        [dfPath]="Components.DataFrame717.path">
</app-data-frame-analyse>

