import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/common/services/breadcrumb.service';
import { TerminalNDBNavaidService } from 'src/app/features/administration/services/terminal-ndb-navaid.service';
import { ApiType, NavigationInformationBaseViewComponent } from '../navigation-information-base/navigation-information-base-view.component';
import { Components } from 'src/app/features/administration/integration/administration.components';


@Component({
  selector: 'app-terminal-ndb-navaids',
  templateUrl: './terminal-ndb-navaids-details.component.html',
})

export class TerminalNDBNavaidsDetailsComponent extends NavigationInformationBaseViewComponent implements OnInit {
  pageTitle = Components.TerminalNDBNavaidsDetails.label;
  selected_date: any;
  ndb_identifier: any;
  icao: any;
  file_dates: any;
  display_icao_iata: any;
  procedureBreadcrumb: any;

  constructor(
    private terminalNDBService: TerminalNDBNavaidService,
    messageService: MessageService,
    confirmationService: ConfirmationService,
    router: Router,
    route: ActivatedRoute,
    breadcrumbService: BreadcrumbService) {
    super(messageService, confirmationService, router, breadcrumbService, route);
    this.icao = route.snapshot.queryParamMap.get('a');
    this.display_icao_iata = route.snapshot.queryParamMap.get('b');
    const dte = route.snapshot.queryParamMap.get('c');
    this.selected_date = { "dte": dte };
    const file_dates = route.snapshot.queryParamMap.get('d');
    this.ndb_identifier = route.snapshot.queryParamMap.get('e');
    this.procedureBreadcrumb = JSON.parse(route.snapshot.queryParamMap.get('f'));

    this.file_dates = JSON.parse(file_dates);
    this.setBreadcrumb();
    this.getTerminalNDBNavaidDetail(dte, this.icao, this.ndb_identifier);
  }

  ngOnInit(): void {
    this.setBreadcrumb();
  }

  getTerminalNDBNavaidDetail(dte, airport_icao_identifier, ndb_identifier) {
    const arg: ApiType = { request: this.terminalNDBService.getTerminalNDBNavaid(dte, airport_icao_identifier, ndb_identifier), api_name: `ndb terminal navaid`, callback: this.callback.bind(this) }
    this.callAPI(arg);
  }

  callback(data) {
    this.data = data;
  }

  override onChange() {
    super.onChange([Components.TerminalWaypointDetails.path],
      {
        queryParams: {
          a: this.icao,
          b: this.display_icao_iata,
          c: this.selected_date["dte"],
          d: JSON.stringify(this.file_dates),
          e: this.ndb_identifier,
          f: JSON.stringify(this.procedureBreadcrumb)
        }
      });
  }

  override setBreadcrumb() {
    if (this.procedureBreadcrumb) {
      super.setBreadcrumb(
        [
          {
            label: Components.AirportList.label,
            routerLink: Components.AirportList.path,
            queryParams: { a: this.selected_date["dte"] }
          },
          {
            label: Components.RunwayList.label + ` (${this.display_icao_iata})`,
            routerLink: Components.RunwayList.path,
            queryParams: {
              a: this.icao,
              b: this.display_icao_iata,
              c: this.selected_date["dte"],
              d: JSON.stringify(this.file_dates)
            }
          },
          {
            label: Components.ProceduresList.label + ` (${this.procedureBreadcrumb["transition_identifier"] || "-"})`,
            routerLink: Components.ProceduresList.path,
            queryParams: {
              a: this.icao,
              b: this.display_icao_iata,
              c: this.selected_date["dte"],
              d: JSON.stringify(this.file_dates),
              e: this.procedureBreadcrumb["transition_identifier"]
            }
          },
          {
            label: Components.ProcedureDetails.label + ` (${this.procedureBreadcrumb["sid_star_approach_identifier"] || "-"})`,
            routerLink: Components.ProcedureDetails.path,
            queryParams: {
              a: this.icao,
              b: this.display_icao_iata,
              c: this.selected_date["dte"],
              d: JSON.stringify(this.file_dates),
              e: this.procedureBreadcrumb["transition_identifier"],
              f: this.procedureBreadcrumb["procedure_id"],
              g: this.procedureBreadcrumb["sid_star_approach_identifier"]
            }
          },
          {
            label: Components.TerminalNDBNavaidsDetails.label + ` (${this.ndb_identifier || "-"})`
          }
        ]);
    }
    else {
      super.setBreadcrumb(
        [
          {
            label: Components.AirportList.label,
            routerLink: Components.AirportList.path,
            queryParams: { a: this.selected_date["dte"] }
          },
          {
            label: Components.TerminalNDBNavaidsList.label + ` (${this.display_icao_iata})`,
            routerLink: Components.TerminalNDBNavaidsList.path,
            queryParams: {
              a: this.icao,
              b: this.display_icao_iata,
              c: this.selected_date["dte"],
              d: JSON.stringify(this.file_dates)
            }
          },
          {
            label: Components.TerminalNDBNavaidsDetails.label + ` (${this.ndb_identifier || "-"})`
          }
        ]);
    }
  }
}
