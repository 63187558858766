import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, Observable, combineLatest } from "rxjs";
import { AcmsReportMapModel, AcmsReportMapVersion } from "src/app/features/configuration/models/acmsReportMap";
import { BaseService } from "src/app/common/services/base.service";
import { AirlineService } from 'src/app/features/administration/services/airline.service'
import { environment } from "src/environments/environment";
import { CommonService } from "src/app/common/services/common.service";

@Injectable({
  providedIn: 'root'
})
export class AcmsReportMapService extends BaseService {

  constructor(private http: HttpClient, private airlineService: AirlineService, private commonService: CommonService) {
    super();
    this.baseUrl = environment.apiUrl.config;
  }

  // populate acms-report-map by id
  public getAcmsReportMapById(Id: number): Observable<AcmsReportMapModel[]> {
    return this.http.get<AcmsReportMapModel[]>(`${this.baseUrl}acms-report-map/${Id}`)
      .pipe(catchError(this.httpHandleError));
  }

  // populate acms-report-map by id - used in aircraft recorder details
  public getAcmsReportMapVersion(Id: number): Observable<AcmsReportMapVersion[]> {
    return this.http.get<AcmsReportMapVersion[]>(`${this.baseUrl}acms-report-map-versions/${Id}`)
      .pipe(catchError(this.httpHandleError));
  }

  // get airlines attached to acmsreport version by id
  public getAcmsReportMapDataWithAirlines(acmsVersionMapId: number) {
    const url = `${this.baseUrl}airline-acms-report-map/${acmsVersionMapId}`;

    const result = combineLatest({
      model: this.http.get<AcmsReportMapModel>(url, { headers: this.httpGetHeadersForJSON() }),
      airlines: this.commonService.listAirlinesFilter()
    }).pipe(catchError(this.httpHandleError));

    return result;
  }

  public compareAcmsReportMap(acmsVersionMapId1: number, acmsVersionMapId2: number) {
    const url = `${this.baseUrl}acms-report-map-compare/${acmsVersionMapId1}/${acmsVersionMapId2}`;
    return this.http.get(url, { headers: this.httpGetHeadersForJSON() }).pipe(catchError(this.httpHandleError));
  }

  // add new acms-report-map
  public addAcmsReportMap(acms_data: AcmsReportMapModel, content: any) {

    const model = { "name": acms_data.name, content, filename: acms_data.filename };

    const httpHeaders = this.httpGetHeadersForJSON();
    const options = { headers: httpHeaders };
    model.content = model.content.replace('data:application/octet-stream;base64,', '');
    return this.http.post<number>(`${this.baseUrl}acms-report-map`, model, options)
      .pipe(
        catchError(this.httpHandleError)
      )
  }

  //delete existing acms-report-map
  public deleteAcmsReportMap(acms_report_map_id: number) {
    const url = `${this.baseUrl}acms-report-map/${acms_report_map_id}`;

    const options = { headers: this.httpGetHeadersForJSON() }
    return this.http.delete(url, options)
      .pipe(catchError(this.httpHandleError));
  }

  public manageAcmsReportMap(acmsVersionMapId: number, listofairlineid: any) {
    return this.http.put(`${this.baseUrl}acms-report-map/${acmsVersionMapId}`, { "listofairlineid": listofairlineid }, { headers: this.httpGetHeadersForJSON() }).
      pipe(catchError(this.httpHandleError));
  }
}
