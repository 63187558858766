import {Component, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {DataFrameService} from "../../../services/data-frame.service";
import {BreadcrumbService} from "src/app/common/services/breadcrumb.service";
import {ConfirmationService, MessageService} from "primeng/api";
import { Components } from "../../../integration/configuration.components";
import { Analyse767DXSDfComponent } from "../../dataframe-acms/dataframe/data-frame-analyse/data-frame-analyse-767-dxs/analyse-767-dxs.component";

@Component({
  selector: "app-analyse-767",
  templateUrl: "./analyse-767.component.html",
})
export class Analyse767DfComponent extends Analyse767DXSDfComponent implements OnInit {
  public Components = Components;
  constructor(router: Router,
              dataFrameService: DataFrameService,
              route: ActivatedRoute,
              breadcrumbService: BreadcrumbService,
              confirmationService: ConfirmationService,
              messageService: MessageService
  ) {
    super(router, dataFrameService, route, breadcrumbService, confirmationService, messageService);
  }

  
}
