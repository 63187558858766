<p-blockUI [blocked]="loadingCount > 0">
  <tdy-spinner style="position:absolute;left:50%;top:50%;z-index: 10000"></tdy-spinner>
</p-blockUI>

<p-confirmDialog header="Confirm Deletion" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<div class="col-12 card">
  <div class="card-header m-2">
    <h5>Recorders</h5>
    <div>
      <button (click)="onClickAdd()" *ngIf="canAdd" icon="pi pi-plus" id="addButton" label="Add" pButton pRipple
        type="button"></button>
    </div>
  </div>
  <div class="col-12">
    <p-table [filterDelay]="0" (onRowSelect)="onRowSelect($event)" [responsive]="true" [value]="tableData"
      [showCurrentPageReport]="true" [paginator]="tableData.length > defaultTableRows"
      [rowsPerPageOptions]="[20,50,100,200]" [rows]="defaultTableRows"
      currentPageReportTemplate="{{currentPageReport + ' ' + pageTitle}}" dataKey="name" id="recordersTable"
      selectionMode="single" sortField="name" [customSort]="true" (sortFunction)="customSort($event)">
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="name">
            <div class="flex justify-content-start align-items-center">
              Name
              <p-sortIcon field="name"></p-sortIcon>
              <p-columnFilter [showAddButton]="false" [showOperator]="false" class="ml-0" display="menu" field="name"
                matchMode="contains" type="text"></p-columnFilter>
            </div>
          </th>

          <th pSortableColumn="recordertype_type">
            <div class="flex justify-content-start align-items-center">
              Recorder Type
              <p-sortIcon field="recordertype_type"></p-sortIcon>
              <p-columnFilter [showAddButton]="false" [showOperator]="false" class="ml-0" display="menu"
                field="recordertype_type" matchMode="contains" type="text"></p-columnFilter>
            </div>
          </th>

          <th pSortableColumn="recorderformat_name">
            <div class="flex justify-content-start align-items-center">
              Recorder Format
              <p-sortIcon field="recorderformat_name"></p-sortIcon>
              <p-columnFilter [showAddButton]="false" [showOperator]="false" class="ml-0" display="menu"
                field="recorderformat_name" matchMode="contains" type="text"></p-columnFilter>
            </div>
          </th>

          <th pSortableColumn="subframesize_name">
            <div class="flex justify-content-start align-items-center">
              Subframe Size
              <p-sortIcon field="subframesize_name"></p-sortIcon>
              <p-columnFilter [showAddButton]="false" [showOperator]="false" class="ml-0" display="menu"
                field="subframesize_name" matchMode="contains"></p-columnFilter>
            </div>
          </th>

          <th pSortableColumn="datapacking_type">
            <div class="flex justify-content-start align-items-center">
              Data Packing
              <p-sortIcon field="datapacking_type"></p-sortIcon>
              <p-columnFilter [showAddButton]="false" [showOperator]="false" class="ml-0" display="menu"
                field="datapacking_type" matchMode="contains" type="text"></p-columnFilter>
            </div>
          </th>

          <th pSortableColumn="miniframe_name">
            <div class="flex justify-content-start align-items-center">
              Miniframes
              <p-sortIcon field="miniframe_name"></p-sortIcon>
              <p-columnFilter [showAddButton]="false" [showOperator]="false" class="ml-0" display="menu"
                field="miniframe_name" matchMode="contains" type="text"></p-columnFilter>
            </div>
          </th>

          <th>

          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-tableData>
        <tr [pSelectableRow]="tableData" class="p-selectable-row">
          <td>
            <span class="p-column-title">Name</span>
            {{tableData.name}}
          </td>

          <td>
            <span class="p-column-title">Recorder Type</span>
            {{tableData.recordertype_type}}
          </td>

          <td>
            <span class="p-column-title">Recorder Format</span>
            {{tableData.recorderformat_name}}
          </td>

          <td>
            <span class="p-column-title">Subframe Size</span>
            {{tableData.subframesize_name}}
          </td>

          <td>
            <span class="p-column-title">Data Packing</span>
            {{tableData.datapacking_type}}
          </td>

          <td>
            <span class="p-column-title">Miniframes</span>
            {{tableData.miniframe_name}}
          </td>

          <td *ngIf="canDelete">
            <div class="flex justify-content-end">
              <button id="deleteButton" name="deleteButton" type="button"
                class="p-button-rounded p-button-text p-button-danger" pButton pRipple icon="pi pi-trash"
                pTooltip="Delete..." tooltipPosition="left" (click)="onClickDelete(tableData)"></button>
            </div>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="8">No Recorders Found.</td>
        </tr>
      </ng-template>
    </p-table>
    <div *ngIf="tableData.length > 0 && defaultTableRows >= tableData.length" style="text-align: center;">
      <p class="row-paging">Showing 1 to
        {{tableData.length}} of {{tableData.length}} {{pageTitle}}</p>
    </div>
  </div>
</div>
