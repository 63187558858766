import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService, ConfirmationService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/common/services/breadcrumb.service';
import { Airframer } from 'src/app/features/administration/models/airframer';
import { AirframerService } from 'src/app/features/administration/services/airframer.service';
import { Action, BaseViewComponent } from 'src/app/common/components/base-view.component';
import { Location } from '@angular/common';
import { takeUntil } from 'rxjs';
import { SecurityUserService } from 'src/app/security/services/security-user.service';
import { permissions } from 'src/app/security/models/permissions';
import { Components } from '../../integration/administration.components';

@Component({
  selector: 'app-airframer-details',
  templateUrl: './airframer-details.component.html'
})
export class AirframerDetailsComponent extends BaseViewComponent implements OnInit {

  pageTitle = Components.AirframerDetails.label;
  airframer: Airframer;
  isReadOnly = false;
  messageLabel = 'Airframer';

  @ViewChild('airframerForm') airframerForm: NgForm;

  constructor(private airframerService: AirframerService,
    private route: ActivatedRoute,
    private location: Location,
    messageService: MessageService,
    confirmationService: ConfirmationService,
    router: Router,
    private securityUserService: SecurityUserService,
    breadcrumbService: BreadcrumbService) {
    super(messageService, confirmationService, router, breadcrumbService);

    // Note: Service won't update breadcrumb if caller already did so.
    this.breadcrumbService.setItems(route, [
      { label: Components.Airframer.label, routerLink: Components.Airframer.path },
      { label: (this.route.snapshot.queryParamMap.get('name')) ? Components.AirframerDetails.label + ` (${this.route.snapshot.queryParamMap.get('name')})` : Components.AirframerDetails.label },
    ]);
  }
  ngOnInit(): void {

    this.userCanAdd = this.securityUserService.userHasPermission(permissions.admin.airframers.create);
    this.userCanModify = this.securityUserService.userHasPermission(permissions.admin.airframers.manage);

    // Optional parameter is:
    // a = airframer ID
    if (this.route.snapshot.queryParamMap.get('a')) {
      this.isReadOnly = !this.userCanModify
      this.loadAirframer(+this.route.snapshot.queryParamMap.get('a'));
    } else {
      // No parameters, new airframer
      if (this.userCanAdd) {
        this.isReadOnly = !this.userCanAdd
        this.addAirframer();
      } else {
        this.showErrorMsg('User is not authorized to add an Airframer.', Action.Add, `${this.messageLabel}`);
        // Wait a few seconds for user to see the error, and send them back to where they came from.
        setTimeout(() => {
          this.location.back();
        }, 3000);
      }
    }

  }

  loadAirframer(airframerID: number) {
    this.isNew = false;
    this.loadingCount++;
    this.airframerService.getAirframer(airframerID).pipe(
      takeUntil(this.ngUnsubscribe)).subscribe({
        next: result => {
          this.airframer = result;
          this.updateBreadcrumb(Components.AirframerDetails.label + ' (' + this.airframer.airframer_name + ')');
        }, error: error => {
          this.showErrorMsg(error, Action.Get, `${this.messageLabel}`, `${airframerID}`);
          this.loadingCount--;
        }, complete: () => {
          this.loadingCount--;
        }
      });

  }

  addAirframer() {
    // Update page title and breadcrumb with New...
    this.updateBreadcrumb('New Airframer');
    this.pageTitle = "New Airframer";
    this.isNew = true;
    this.airframer = new Airframer();
    this.airframer.airframer_id = -1;
  }

  onCancel() {
    if (this.airframerForm.dirty)
      this.confirmCancel(Components.Airframer.path);
    else
      this.router.navigate([Components.Airframer.path]).then();
  }

  onSubmit() {
    this.loadingCount++;

    if (this.isNew) {
      this.airframerService.addAirframer(this.airframer).pipe(
        takeUntil(this.ngUnsubscribe)).subscribe({
          next: id => {
            this.airframer.airframer_id = id;
            this.router.navigate([], {
              relativeTo: this.route,
              queryParams: {
                a: this.airframer.airframer_id
              },
              queryParamsHandling: 'merge'
            }).then();

            this.updateBreadcrumb(Components.AirframerDetails.label + ' (' + this.airframer.airframer_name + ')');
          },
          error: error => {
            this.showErrorMsg(error, Action.Add, `${this.messageLabel}`);
            this.loadingCount--;
          },
          complete: () => {
            this.showSuccessMsg(Action.Add, `${this.messageLabel}`, `${this.airframer.airframer_name}`);
            this.pageTitle = Components.AirframerDetails.label;
            this.isNew = false;
            this.airframerForm.resetForm(this.airframerForm.value);
            this.loadingCount--;
          }
        }
        );
    } else {
      this.airframerService.updateAirframer(this.airframer).pipe(
        takeUntil(this.ngUnsubscribe)).subscribe({
          next: () => {
            this.updateBreadcrumb(Components.AirframerDetails.label + ' (' + this.airframer.airframer_name + ')');
          },
          error: error => {
            this.showErrorMsg(error, Action.Update, `${this.messageLabel}`);
            this.loadingCount--;
          },
          complete: () => {
            this.showSuccessMsg(Action.Update, `${this.messageLabel}`, `${this.airframer.airframer_name}`);
            this.airframerForm.resetForm(this.airframerForm.value);
            this.loadingCount--;
          }
        }
        );
    }
  }

  onReset() {
    this.airframerForm.resetForm();
    this.ngOnInit();
  }

}
