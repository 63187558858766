import { Component, ViewChild, OnInit } from '@angular/core';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Router, ActivatedRoute } from '@angular/router';
import { DataDeliveryAlerts, Datadelivery_alert_type, ResponseDDSAlerts } from '../../models/DatadeliveryAlerts';
import { Components } from '../../integration/datadelivery.components';
import { BreadcrumbService } from '../../../../../common/services/breadcrumb.service';
import { Action, BaseViewComponent } from 'src/app/common/components/base-view.component';
import { forkJoin, takeUntil } from 'rxjs';
import { SecurityUserService } from 'src/app/security/services/security-user.service';
import { permissions } from '../../integration/datadelivery.permissions';
import { DataDeliveryAlertService } from '../../services/DataDeliveryAlert.service'
@Component({
  selector: 'app-datadeliveryalerts',
  templateUrl: './datadeliveryalerts.component.html',
  styleUrls: ['./datadeliveryalerts.component.css']
})
export class DataDeliveryAlertsComponent extends BaseViewComponent implements OnInit {
  @ViewChild('DDSAlertsFormData') AlertsFormData!: NgForm
  pageTitle = Components.DataDeliveryAlert.label;
  public canAdd: boolean;
  public canDelete: boolean;
  public canEdit: boolean;
  public modalDialog?: boolean;
  public submitted?: boolean;
  isDataFrame = false;
  uniqueError = false;
  cols: any[] = [];
  ddsAlerts: DataDeliveryAlerts[] = [];
  alertType: Datadelivery_alert_type[] = [];
  messageLabel = "data delivery alert";

  constructor(router: Router,
    private route: ActivatedRoute,
    breadcrumbService: BreadcrumbService, confirmationService: ConfirmationService,
    messageService: MessageService, private dataDeliveryAlertService: DataDeliveryAlertService,
    private realUserService: SecurityUserService) {
    super(messageService, confirmationService, router, breadcrumbService);
    this.getBreadCrums();
  }
  getBreadCrums() {
    this.breadcrumbService.setItems(this.route, [
      { label: Components.DataDeliveryAlert.label }
    ]);
  }
  ngOnInit(): void {
    this.canAdd = this.realUserService.userHasPermission(permissions.datadelivery_recording_alert.create);
    this.canDelete = this.realUserService.userHasPermission(permissions.datadelivery_recording_alert.delete);
    this.canEdit = this.realUserService.userHasPermission(permissions.datadelivery_recording_alert.manage);
    this.getBreadCrums();
    this.cols = [
      {
        field: 'name',
        header: 'Name',
      },
      {
        field: 'alert_type',
        header: 'Type of Alert',
      },
      {
        field: 'time_to_look_back',
        header: 'Time to look back',
      },
      {
        field: 'rate',
        header: 'Rate',
      },
      {
        field: 'action',
        header: 'Action',
      },
    ];

    this.loadRecordingAlertData();
  }

  openNew() {
    if (!this.canAdd) {
      this.showErrorMsg('User is not authorized to add a Data Delivery Alert.', Action.Add, `${this.messageLabel}`);
    } else {
      this.router.navigate([Components.DataDeliveryAlertDetails.path]).then();
    }
  }

  loadRecordingAlertData() {
    this.loadingCount++;
    forkJoin({
      ddsalertData: this.dataDeliveryAlertService.getRecordingAlerts(),

    }).pipe(
      takeUntil(this.ngUnsubscribe)).subscribe({
        next: results => {
          this.ddsAlerts = results.ddsalertData;
        },
        error: error => {
          this.showErrorMsg(`${error}`, Action.Get, `${this.messageLabel}s`);
          this.loadingCount--;
        },
        complete: () => {
          this.loadingCount--;
        }
      });
  }

  onRowSelect(event) {
    this.router.navigate([Components.DataDeliveryAlertDetails.path], {
      state: { sardata: event.data },
      queryParams: { alertId: event.data.alert_id, name: event.data.name }
    }).then();
  }


  deleteAlert(ddsalerts: DataDeliveryAlerts) {
    if (!this.canDelete) {
      this.showErrorMsg(`User is not authorized to delete a data delivery alert.`, Action.Delete, `${this.messageLabel}s`);
    } else {
      this.confirmationService.confirm({
        message: 'Are you sure that you want to delete the selected Data Delivery Alert?',
        accept: () => {
          this.dataDeliveryAlertService.deleteRecordingAlert(ddsalerts.alert_id).subscribe({
            next: () => {
              this.showSuccessMsg(Action.Delete, `${this.messageLabel}`, `${ddsalerts.name}`);
              this.loadRecordingAlertData();
            },
            error: error => {
              this.showErrorMsg(`${error}`, Action.Delete, `${this.messageLabel}s`);
            }
          });
        }
      });
    }
  }



  hideDialog() {
    this.modalDialog = false;
    this.submitted = false;
  }

  resetDialog() {
    this.AlertsFormData.reset();
  }
}
