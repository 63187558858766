import {Component} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router"
import {DataFrameService} from "../../../services/data-frame.service";
import {BreadcrumbService} from "src/app/common/services/breadcrumb.service";
import {ConfirmationService, MessageService} from "primeng/api";
import {hasOwnPropertyCheck} from "../../dataframe-acms/dataframe/shared/utils";
import {
  AcquiredParams,
  DisplayFormatEnum,
  EuTypeEnum,
  IAnalyseDataFromServer, Lcp,
  Signed,
  StorageFormatEnum
} from "../../../models/dataframe717-767";
import {DataFrameAnalyseAttributes} from "../../dataframe-acms/dataframe/shared/constants/constants";
import {
  DataFrameAnalyseAbstract
} from "../../dataframe-acms/dataframe/data-frame-analyse/data-frame-analyse-abstract.component";
import { Components } from "../../../integration/configuration.components";

@Component({
  selector: "app-analyse-717",
  templateUrl: "./analyse-717.component.html",
})
export class Analyse717DfComponent extends DataFrameAnalyseAbstract {
  public Components = Components;
  public mnemonicRegex = /^[a-zA_Z0-9_-]+$/i;
  public wordsPerSecond = 0;
  public componentType = ["Regular", "Superframe"];
  public subFrameNum = [1, 2, 3, 4];
  public miniFrame = [0, 1, 2, 4, 8];

  constructor(router: Router,
              dataFrameService: DataFrameService,
              route: ActivatedRoute,
              breadcrumbService: BreadcrumbService,
              confirmationService: ConfirmationService,
              messageService: MessageService
  ) {
    super(router, route, breadcrumbService, dataFrameService, messageService, confirmationService );
  }

  public processReceivedData(data: IAnalyseDataFromServer): void {
    const response = JSON.parse(data.content);
    this.dataFrame = response;
    this.wordsPerSecond = response.FRA.FRA_SUBFRAME_WORD_NB;
    for (const key in response.FRA.AcquiredParams) {
      for (const key1 in response.FRA.AcquiredParams[key]) {
        this.dataParameter.push(response.FRA.AcquiredParams[key][key1]);
      }
    }
    this.dataParameter.sort((a, b) => {
      const fa = a.PRM_MNEMONIC.toLowerCase();
      const fb = b.PRM_MNEMONIC.toLowerCase();
      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    });
    this.generateErrorMessages();
  }

  public generateErrorMessages(): void {
    this.isLoading = true;
    this.parameterErrors = [];
    this.dfErrors = [];
    this.validateDataFrame();
    this.validateParameter();
    this.isLoading = false;
  }

  public validateDataFrame(): void {
    const param = this.dataFrame.FRA;
    if (hasOwnPropertyCheck(param, "FRA_NAME")) {
      if (param.FRA_NAME == ("" || null)) {
        this.pushError("Name", DataFrameAnalyseAttributes.GENERAL, "Name cannot be left blank", true);
      }
      if (!this.mnemonicRegex.test(param.FRA_NAME)) {
        this.pushError("Name", DataFrameAnalyseAttributes.GENERAL, "Name should be alphanumeric", true);
      }
      if (param.FRA_NAME?.length > 64) {
        this.pushError("Name", DataFrameAnalyseAttributes.GENERAL, "Name length should not exceed 64 characters", true);
      }
    }
    if (hasOwnPropertyCheck(param, "FRA_SUBFRAME_WORD_NB")) {
      if (isNaN(param.FRA_SUBFRAME_WORD_NB)) {
        this.pushError("Words Per Second", DataFrameAnalyseAttributes.DATA_FRAME_COUNTER, "Words Per Second should not be string type", true);
      }
      if (!Number.isInteger(param.FRA_SUBFRAME_WORD_NB)) {
        this.pushError("Words Per Second", DataFrameAnalyseAttributes.DATA_FRAME_COUNTER, "Words Per Second should be an integer", true);
      }
      if (param.FRA_SUBFRAME_WORD_NB < 0) {
        this.pushError("Words Per Second", DataFrameAnalyseAttributes.DATA_FRAME_COUNTER, "Words Per Second should not be less than 0", true);
      }
    }
    if (hasOwnPropertyCheck(param, "FRA_DFC_LOC_F")) {
      if (isNaN(param.FRA_DFC_LOC_F)) {
        this.pushError("DFC Subframe Number", DataFrameAnalyseAttributes.DATA_FRAME_COUNTER, "Subframe Number in Data Frame Counter should not be string type", true);
      }
      if (!Number.isInteger(param.FRA_DFC_LOC_F)) {
        this.pushError("DFC Subframe Number", DataFrameAnalyseAttributes.DATA_FRAME_COUNTER, "Subframe Number in Data Frame Counter should be an integer", true);
      }
      const found = this.subFrameNum.includes(param.FRA_DFC_LOC_F);
      if (!found) {
        this.pushError("DFC Subframe Number", DataFrameAnalyseAttributes.DATA_FRAME_COUNTER, "Invalid Subframe Number in Data Frame Counter value", true);
      }
    }
    if (hasOwnPropertyCheck(param, "FRA_DFC_BIT_SOURCE_LSB")) {
      if (isNaN(param.FRA_DFC_BIT_SOURCE_LSB)) {
        this.pushError("DFC Starting Bit", DataFrameAnalyseAttributes.DATA_FRAME_COUNTER, "Starting Bit in Data Frame Counter should not be string type", true);
      }
      if (!Number.isInteger(param.FRA_DFC_BIT_SOURCE_LSB)) {
        this.pushError("DFC Starting Bit", DataFrameAnalyseAttributes.DATA_FRAME_COUNTER, "Starting Bit in Data Frame Counter should be an integer", true);
      }
      if (param.FRA_DFC_BIT_SOURCE_LSB < 0) {
        this.pushError("DFC Starting Bit", DataFrameAnalyseAttributes.DATA_FRAME_COUNTER, "Starting Bit in Data Frame Counter should be less than 0", true);
      }
      if (param.FRA_DFC_BIT_SOURCE_LSB > 12) {
        this.pushError("DFC Starting Bit", DataFrameAnalyseAttributes.DATA_FRAME_COUNTER, "Starting Bit in Data Frame Counter should not be greater than 12", true);
      }

    }
    if (hasOwnPropertyCheck(param, "FRA_DFC_LOC_W")) {
      if (isNaN(param.FRA_DFC_LOC_W)) {
        this.pushError("DFC Word Number", DataFrameAnalyseAttributes.DATA_FRAME_COUNTER, "Word Number in Data Frame Counter should not be string type", true);
      }
      if (!Number.isInteger(param.FRA_DFC_LOC_W)) {
        this.pushError("DFC Word Number", DataFrameAnalyseAttributes.DATA_FRAME_COUNTER, "Word Number in Data Frame Counter should be an integer", true);
      }
      if (param.FRA_DFC_LOC_W < 0) {
        this.pushError("DFC Word Number", DataFrameAnalyseAttributes.DATA_FRAME_COUNTER, "Word Number in Data Frame Counter should not be less than 0", true);
      }
      if (param.FRA_DFC_LOC_W > param.FRA_SUBFRAME_WORD_NB) {
        this.pushError("DFC Word Number", DataFrameAnalyseAttributes.DATA_FRAME_COUNTER, "Word Number in Data Frame Counter should not be greater than Words Per Second", true);
      }

    }

    if (hasOwnPropertyCheck(param, "FRA_SFC_LOC_F")) {
      if (isNaN(param.FRA_SFC_LOC_F)) {
        this.pushError("SFC Subframe Number", DataFrameAnalyseAttributes.DATA_FRAME_SUPER_COUNTER, "Subframe Number in Super Frame Counter should not be string", true);
      }
      if (!Number.isInteger(param.FRA_SFC_LOC_F)) {
        this.pushError("SFC Subframe Number", DataFrameAnalyseAttributes.DATA_FRAME_SUPER_COUNTER, "Subframe Number in Super Frame Counter should not be an integer", true);
      }
      const found = this.subFrameNum.includes(param.FRA_SFC_LOC_F);
      if (!found) {
        this.pushError("SFC Subframe Number", DataFrameAnalyseAttributes.DATA_FRAME_SUPER_COUNTER, "Invalid Subframe Number in Super Frame Counter value", true);
      }
    }
    if (hasOwnPropertyCheck(param, "FRA_SFC_BIT_SOURCE_LSB")) {
      if (isNaN(param.FRA_SFC_BIT_SOURCE_LSB)) {
        this.pushError("SFC Starting Bit", DataFrameAnalyseAttributes.DATA_FRAME_SUPER_COUNTER, "Starting Bit in Super Frame Counter should not be string type", true);
      }
      if (!Number.isInteger(param.FRA_SFC_BIT_SOURCE_LSB)) {
        this.pushError("SFC Starting Bit", DataFrameAnalyseAttributes.DATA_FRAME_SUPER_COUNTER, "Starting Bit in Super Frame Counter should be an integer", true);
      }
      if (param.FRA_SFC_BIT_SOURCE_LSB < 0) {
        this.pushError("SFC Starting Bit", DataFrameAnalyseAttributes.DATA_FRAME_SUPER_COUNTER, "Starting Bit in Super Frame Counter should not be less than 0", true);
      }
      if (param.FRA_SFC_BIT_SOURCE_LSB > 12) {
        this.pushError("SFC Starting Bit", DataFrameAnalyseAttributes.DATA_FRAME_SUPER_COUNTER, "Starting Bit in Super Frame Counter should not be greater than 12", true);
      }

    }
    if (hasOwnPropertyCheck(param, "FRA_SFC_LOC_W")) {
      if (isNaN(param.FRA_SFC_LOC_W)) {
        this.pushError("SFC Word Number", DataFrameAnalyseAttributes.DATA_FRAME_SUPER_COUNTER, "Word Number in Super Frame Counter should not be string type", true);
      }
      if (!Number.isInteger(param.FRA_SFC_LOC_W)) {
        this.pushError("SFC Word Number", DataFrameAnalyseAttributes.DATA_FRAME_SUPER_COUNTER, "Word Number in Super Frame Counter should be an integer", true);
      }
      if (param.FRA_SFC_LOC_W < 0) {
        this.pushError("SFC Word Number", DataFrameAnalyseAttributes.DATA_FRAME_SUPER_COUNTER, "Word Number in Super Frame Counter should not be less than 0", true);
      }
      if (param.FRA_SFC_LOC_W > param.FRA_SUBFRAME_WORD_NB) {
        this.pushError("SFC Word Number", DataFrameAnalyseAttributes.DATA_FRAME_SUPER_COUNTER, "Word Number in Super Frame Counter should not be greater than words per seconds", true);
      }

    }
    if (hasOwnPropertyCheck(param, "FRA_SFC_BIT_LENGTH")) {
      if (isNaN(param.FRA_SFC_BIT_LENGTH)) {
        this.pushError("SFC Bit Length", DataFrameAnalyseAttributes.DATA_FRAME_SUPER_COUNTER, "Word Number in Super Frame Counter should not be string type", true);
      }
      if (!Number.isInteger(param.FRA_SFC_BIT_LENGTH)) {
        this.pushError("SFC Bit Length", DataFrameAnalyseAttributes.DATA_FRAME_SUPER_COUNTER, "Word Number in Super Frame Counter should be an integer", true);
      }
      if (param.FRA_SFC_BIT_LENGTH < 0) {
        this.pushError("SFC Bit Length", DataFrameAnalyseAttributes.DATA_FRAME_SUPER_COUNTER, "Word Number in Super Frame Counter should not be less than 0", true);
      }
      if (param.FRA_SFC_BIT_LENGTH > 12) {
        this.pushError("SFC Bit Length", DataFrameAnalyseAttributes.DATA_FRAME_SUPER_COUNTER, "Word Number in Super Frame Counter should not be greater than 12", true);
      }

    }
    if (hasOwnPropertyCheck(param, "FRA_NB_MINIFRAMES_IN_SUBFRAME")) {
      if (isNaN(param.FRA_NB_MINIFRAMES_IN_SUBFRAME)) {
        this.pushError("Mini-frames per Subframe", DataFrameAnalyseAttributes.DATA_FRAME_SUPER_COUNTER, "Mini-frames per Subframe value should not be string type", true);
      }
      if (!Number.isInteger(param.FRA_NB_MINIFRAMES_IN_SUBFRAME)) {
        this.pushError("Mini-frames per Subframe", DataFrameAnalyseAttributes.DATA_FRAME_SUPER_COUNTER, "Mini-frames per subframe value should be an integer", true);
      }
      const found = this.miniFrame.includes(param.FRA_NB_MINIFRAMES_IN_SUBFRAME);
      if (!found) {
        this.pushError("Mini-frames per Subframe", DataFrameAnalyseAttributes.DATA_FRAME_SUPER_COUNTER, "Invalid Mini-frames per Subframe", true);
      }

    }

  }

  public validateParameter(): void {
    this.dataParameter.forEach(row => {
      if (hasOwnPropertyCheck(row, "PRM_MNEMONIC")) {
        if (row.PRM_MNEMONIC == ("" || null)) {
          this.pushError(row.PRM_MNEMONIC, DataFrameAnalyseAttributes.MNEMONIC, "Mnemonic name cannot be left blank");
        }
        if (!this.mnemonicRegex.test(row.PRM_MNEMONIC)) {
          this.pushError(row.PRM_MNEMONIC, DataFrameAnalyseAttributes.MNEMONIC, "Mnemonic name should be alphanumeric");
        }
        if (row.PRM_MNEMONIC?.length > 20) {
          this.pushError(row.PRM_MNEMONIC, DataFrameAnalyseAttributes.MNEMONIC, "Mnemonic name length should not exceed 20 characters");
        }
      }
      if (hasOwnPropertyCheck(row, "PRM_NAME")) {
        if (row.PRM_NAME == ("" || null)) {
          this.pushError(row.PRM_MNEMONIC, DataFrameAnalyseAttributes.NAME, "Name cannot be left blank");
        }
        if (row.PRM_NAME.length > 64) {
          this.pushError(row.PRM_MNEMONIC, DataFrameAnalyseAttributes.NAME, "Name length should not exceed 64 characters");
        }
      }
      if (hasOwnPropertyCheck(row, "PRM_DESCRIPTION")) {
        if (typeof row.PRM_DESCRIPTION != "string") {
          this.pushError(row.PRM_MNEMONIC, DataFrameAnalyseAttributes.DESCRIPTION, "Description should be string type");
        }
        if (row.PRM_DESCRIPTION == ("" || null)) {
          this.pushError(row.PRM_MNEMONIC, DataFrameAnalyseAttributes.DESCRIPTION, "Description cannot be left blank");
        }
        if (row.PRM_DESCRIPTION.length > 64) {
          this.pushError(row.PRM_MNEMONIC, DataFrameAnalyseAttributes.DESCRIPTION, "Description length should not exceed 64 characters");
        }
      }
      if (hasOwnPropertyCheck(row, "FMT_ID")) {
        if (!Object.values(StorageFormatEnum).includes(row.FMT_ID)) {
          this.pushError(row.PRM_MNEMONIC, DataFrameAnalyseAttributes.RECORDING, "Invalid Storage Format");
        }
        if (row.FMT_ID === (StorageFormatEnum.ISO || StorageFormatEnum.Discrete || StorageFormatEnum.ASCII) && row.FMT_ID_DISPLAY != DisplayFormatEnum.ASCII) {
          this.pushError(row.PRM_MNEMONIC, DataFrameAnalyseAttributes.RECORDING, "If Storage Format is ISO#5 or Discrete or ASCII, Display Format should be ASCII");
        }
      }
      if (hasOwnPropertyCheck(row, "FMT_ID_DISPLAY")) {
        if (!Object.values(DisplayFormatEnum).includes(row.FMT_ID_DISPLAY)) {
          this.pushError(row.PRM_MNEMONIC, DataFrameAnalyseAttributes.GENERAL, "Invalid Display Format");
        }
      }
      if (hasOwnPropertyCheck(row, "EUT_ID")) {
        if (!Object.values(EuTypeEnum).includes(row.EUT_ID)) {
          this.pushError(row.PRM_MNEMONIC, DataFrameAnalyseAttributes.GENERAL, "Invalid EU Type");
        }
      }
      if (hasOwnPropertyCheck(row, "PRM_DECIMAL_PLACES")) {
        if (!Number.isInteger(row.PRM_DECIMAL_PLACES)) {
          this.pushError(row.PRM_MNEMONIC, DataFrameAnalyseAttributes.GENERAL, "Decimal places should be an integer");
        }
        if (row.FMT_ID_DISPLAY === 16 && row.PRM_DECIMAL_PLACES < 0) {
          this.pushError(row.PRM_MNEMONIC, DataFrameAnalyseAttributes.GENERAL, "Decimal places should not be less than 0");
        }
      }
      if (hasOwnPropertyCheck(row, "PRM_MIN_OP_RANGE")) {
        if (isNaN(row.PRM_MIN_OP_RANGE)) {
          this.pushError(row.PRM_MNEMONIC, DataFrameAnalyseAttributes.GENERAL, "Minimum Operational Range should be numeric value");
        }
        if (row.PRM_MIN_OP_RANGE > row.PRM_MAX_OP_RANGE) {
          this.pushError(row.PRM_MNEMONIC, DataFrameAnalyseAttributes.GENERAL, "Minimum Operational Range value should not be greater than Maximum Operational Range");
        }
        if (isNaN(row.PRM_MAX_OP_RANGE)) {
          this.pushError(row.PRM_MNEMONIC, DataFrameAnalyseAttributes.GENERAL, "Maximum Operational Range should be numeric value");
        }
        if (row.PRM_MIN_OP_RANGE > row.PRM_MAX_OP_RANGE) {
          this.pushError(row.PRM_MNEMONIC, DataFrameAnalyseAttributes.GENERAL, "Maximum Operational Range value should not be less than Minimum Operational Range");
        }
      }
      if (hasOwnPropertyCheck(row, "SRT_ID")) {
        if (!Object.values(Signed).includes(row.SRT_ID)) {
          this.pushError(row.PRM_MNEMONIC, DataFrameAnalyseAttributes.GENERAL, "Signed can only be true or false");
        }
      }
      if (hasOwnPropertyCheck(row, "PRA_CONV_CONF")) {
        if (typeof row.PRA_CONV_CONF != "string") {
          this.pushError(row.PRM_MNEMONIC, DataFrameAnalyseAttributes.GENERAL, "Convo Config should be string type");
        }
        if (row.FMT_ID === StorageFormatEnum.ISO && row.PRA_CONV_CONF.length != 1) {
          this.pushError(row.PRM_MNEMONIC, DataFrameAnalyseAttributes.GENERAL, "Convo Config should be 1 if Storage Format is ISO");
        }
        if (row.FMT_ID === StorageFormatEnum.BCD && row.PRA_CONV_CONF.length === 0) {
          this.pushError(row.PRM_MNEMONIC, DataFrameAnalyseAttributes.GENERAL, "Convo Config must be entered if Storage Format is BCD");
        }
      }
      if (hasOwnPropertyCheck(row, "LCP")) {
        this.recordingTable(row)
      } else {
        this.pushError(row.PRM_MNEMONIC, DataFrameAnalyseAttributes.LOCATION_PARTS, "At least one item is required in Recording tab");
      }
      if (hasOwnPropertyCheck(row, "EUC")) {
        if (row.EUT_ID === 1 && row.EUC.length === 0 ) {
          this.pushError(row.PRM_MNEMONIC, DataFrameAnalyseAttributes.EU_CONVERSION, "At least one of the 5 coefficient is required (the one with a corresponding exp) in EU Conversion Segments tab");
        }

      }
      if (hasOwnPropertyCheck(row, "EUC") && row.EUT_ID === 17) {
        if (hasOwnPropertyCheck(row, "EUL")) {
          if (row.length > 0)
            this.rawDataTable(row)
        } else {
          this.pushError(row.PRM_MNEMONIC, DataFrameAnalyseAttributes.DISCRETE, "At least 2 pairs of Raw Values and EU values is required in EU Conversion Segments tab");
        }
      }
      if (row.FMT_ID === 3 && hasOwnPropertyCheck(row, "TXD")) {
        this.discreteTable(row)
      }
      if (row.FMT_ID === 3 && row.TXD.length < 2) {
        this.pushError(row.PRM_MNEMONIC, DataFrameAnalyseAttributes.DISCRETE, "At least 2 pairs of Raw values and Discrete text is required in Discrete Text tab");
      }
    });
  }

  private recordingTable(row: AcquiredParams): void {
    const lcpArray = row.LCP;
    lcpArray.map((lcp, index) => {
      if (hasOwnPropertyCheck(lcp, "LCP_SAMPLE_NUMBER")) {
        if (isNaN(lcp.LCP_SAMPLE_NUMBER)) {
          this.pushError(row.PRM_MNEMONIC, DataFrameAnalyseAttributes.LOCATION_PARTS, "Sample Number should be numeric type");
        }
        if (index === 0) {
          if (lcp.LCP_SAMPLE_NUMBER != 1) {
            this.pushError(row.PRM_MNEMONIC, DataFrameAnalyseAttributes.LOCATION_PARTS, "Sample Number should start with 1");
          }
        } else {
          if (lcp.LCP_SAMPLE_NUMBER < lcpArray[index - 1].LCP_PART_NUMBER) {
            this.pushError(row.PRM_MNEMONIC, DataFrameAnalyseAttributes.LOCATION_PARTS, "Sample Number should be in sequential order");
          }
        }
        if (lcp.LCP_SAMPLE_NUMBER < 0) {
          this.pushError(row.PRM_MNEMONIC, DataFrameAnalyseAttributes.LOCATION_PARTS, "Sample Number should not be less than 0");
        }
        if (!Number.isInteger(lcp.LCP_SAMPLE_NUMBER)) {
          this.pushError(row.PRM_MNEMONIC, DataFrameAnalyseAttributes.LOCATION_PARTS, "Sample Number should be an integer");
        }
        if (hasOwnPropertyCheck(lcp, "LCP_PART_NUMBER")) {
          if (isNaN(lcp.LCP_PART_NUMBER)) {
            this.pushError(row.PRM_MNEMONIC, DataFrameAnalyseAttributes.LOCATION_PARTS, "Component Number should be numeric type");
          }
          if (lcp.LCP_PART_NUMBER < 0) {
            this.pushError(row.PRM_MNEMONIC, DataFrameAnalyseAttributes.LOCATION_PARTS, "Component Number should not be less than 0");
          }
          if (!Number.isInteger(lcp.LCP_PART_NUMBER)) {
            this.pushError(row.PRM_MNEMONIC, DataFrameAnalyseAttributes.LOCATION_PARTS, "Component Number should be an integer");
          }
          const maxfound = lcpArray.some((el, idx) => el.LCP_SAMPLE_NUMBER === lcp.LCP_SAMPLE_NUMBER && el.LCP_PART_NUMBER === lcp.LCP_PART_NUMBER && index != idx);
          if (maxfound) {
            this.pushError(row.PRM_MNEMONIC, DataFrameAnalyseAttributes.LOCATION_PARTS, "Component Number should be unique per Sample Number");
          }
        } else {
          this.pushError(row.PRM_MNEMONIC, DataFrameAnalyseAttributes.LOCATION_PARTS, "Invalid Component Number");
        }
      } else {
        this.pushError(row.PRM_MNEMONIC, DataFrameAnalyseAttributes.LOCATION_PARTS, "Invalid Sample Number");
      }
      if (hasOwnPropertyCheck(lcp, "LCP_WORD_NB")) {
        if (!Number.isInteger(lcp.LCP_WORD_NB)) {
          this.pushError(row.PRM_MNEMONIC, DataFrameAnalyseAttributes.LOCATION_PARTS, "Sample Word Number should be an integer");
        }
        if (lcp.LCP_WORD_NB < 0) {
          this.pushError(row.PRM_MNEMONIC, DataFrameAnalyseAttributes.LOCATION_PARTS, "Sample Word Number should not be less than 0");
        }
        if (lcp.LCP_WORD_NB > this.wordsPerSecond) {
          this.pushError(row.PRM_MNEMONIC, DataFrameAnalyseAttributes.LOCATION_PARTS, "Sample Word Number should not be greater than words per second");
        }
      } else {
        this.pushError(row.PRM_MNEMONIC, DataFrameAnalyseAttributes.LOCATION_PARTS, "Invalid Sample Word Number");
      }
      if (hasOwnPropertyCheck(lcp, "LCP_BIT_SOURCE_LSB")) {
        if (!Number.isInteger(lcp.LCP_BIT_SOURCE_LSB)) {
          this.pushError(row.PRM_MNEMONIC, DataFrameAnalyseAttributes.LOCATION_PARTS, "Sample Starting Bit should be an integer");
        }
        if (lcp.LCP_BIT_SOURCE_LSB < 0) {
          this.pushError(row.PRM_MNEMONIC, DataFrameAnalyseAttributes.LOCATION_PARTS, "Sample Starting Bit should not be less than 0");
        }
        if (lcp.LCP_BIT_SOURCE_LSB > 12) {
          this.pushError(row.PRM_MNEMONIC, DataFrameAnalyseAttributes.LOCATION_PARTS, "Sample Starting Bit should not be greater than 12");
        }
      } else {
        this.pushError(row.PRM_MNEMONIC, DataFrameAnalyseAttributes.LOCATION_PARTS, "Invalid Sample Starting Bit");
      }
      if (hasOwnPropertyCheck(lcp, "LCP_BIT_LENGTH")) {
        if (!Number.isInteger(lcp.LCP_BIT_LENGTH)) {
          this.pushError(row.PRM_MNEMONIC, DataFrameAnalyseAttributes.LOCATION_PARTS, "Sample Bit Length should be an integer");
        }
        if (lcp.LCP_BIT_LENGTH < 0) {
          this.pushError(row.PRM_MNEMONIC, DataFrameAnalyseAttributes.LOCATION_PARTS, "Sample Bit Length should not be less than 0");
        }
        if (lcp.LCP_BIT_LENGTH > 12) {
          this.pushError(row.PRM_MNEMONIC, DataFrameAnalyseAttributes.LOCATION_PARTS, "Sample Bit Length should be greater than 12");
        }
        if (row.FMT_ID === StorageFormatEnum.ISO && row.FMT_ID_DISPLAY === DisplayFormatEnum.ASCII) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          if (!(lcp.LCP_BIT_LENGTH == 8) || !(lcp.LCP_BIT_LENGTH == 7)) {
            this.pushError(row.PRM_MNEMONIC, DataFrameAnalyseAttributes.LOCATION_PARTS, "Sample Bit Length should be 7 or 8");
          }
        }
        if (row.FMT_ID === StorageFormatEnum.BCD) {
          const sampleArr = [];
          let bitLen: number;


          lcpArray.forEach((lcp: Lcp) => {
            sampleArr.push(lcp.LCP_SAMPLE_NUMBER);
          });
          
          const duplicateValues = sampleArr.filter((item, index, arr) => arr.indexOf(item) !== arr.lastIndexOf(item) && arr.indexOf(item) === index);

          if (duplicateValues.length == 0) {
            bitLen = lcpArray[0].LCP_BIT_LENGTH;

              if (index != 0 && lcp.LCP_BIT_LENGTH != bitLen) {
                this.pushError(row.PRM_MNEMONIC, DataFrameAnalyseAttributes.LOCATION_PARTS, "Sample Bit Length should be same per Sample Number.");
              }
          } else if (duplicateValues.length > 0) {
            duplicateValues.forEach((element: number) => {
              if (lcpArray[0].LCP_SAMPLE_NUMBER == element) {
                if (lcpArray[0].LCP_BIT_LENGTH != bitLen) {
                  this.pushError(row.PRM_MNEMONIC,DataFrameAnalyseAttributes.LOCATION_PARTS,"Bit Length should be equal to BCD configuration value.");
                }
              }
            });
          }
        }
      } else {
        this.pushError(row.PRM_MNEMONIC, DataFrameAnalyseAttributes.LOCATION_PARTS, "Invalid Bit Length");
      }
      if (hasOwnPropertyCheck(lcp, "LCP_MODULO")) {
        if (!Number.isInteger(lcp.LCP_MODULO)) {
          this.pushError(row.PRM_MNEMONIC,DataFrameAnalyseAttributes.LOCATION_PARTS, "Sample Frame Number of Superframe should be an integer");
        }
        if (lcp.LCP_MODULO < 0) {
          this.pushError(row.PRM_MNEMONIC,DataFrameAnalyseAttributes.LOCATION_PARTS, "Sample Frame Number of Superframe should not be less than 0");
        }
        if (lcp.LCP_MODULO > 16) {
          this.pushError(row.PRM_MNEMONIC,DataFrameAnalyseAttributes.LOCATION_PARTS, "Sample Frame Number of Superframe should not be greater than 16");
        }
      } else {
        this.pushError(row.PRM_MNEMONIC, DataFrameAnalyseAttributes.LOCATION_PARTS, "Invalid Starting Bit");
      }

      const found = this.componentType.some(type => type.toLowerCase() === lcp.LCP_TYPE?.toLowerCase());
      if (!found) {
        this.pushError(row.PRM_MNEMONIC, DataFrameAnalyseAttributes.LOCATION_PARTS, "Invalid Component Type");
      }
    });
    this.parameterErrors= this.getUniqueErrors(this.parameterErrors);
  }

  private rawDataTable(row: AcquiredParams): void {
    const rawDataArray = row.EUL;
      rawDataArray.map((value) => {
          if (isNaN(value.EUL_RAW_DATA_VALUE)) {
            this.pushError(row.PRM_MNEMONIC, DataFrameAnalyseAttributes.DISCRETE, "Raw Data value should be numeric type");
          }
          if (!Number.isInteger(value.EUL_RAW_DATA_VALUE)) {
            this.pushError(row.PRM_MNEMONIC, DataFrameAnalyseAttributes.DISCRETE, "Raw Data value should be an integer");
          }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore   // Value is number, additional check
        if (value.EUL_RAW_DATA_VALUE === "") {
          this.pushError(row.PRM_MNEMONIC, DataFrameAnalyseAttributes.DISCRETE, "Invalid Raw Data value");
          }
          if (isNaN(value.EUL_EU_VALUE)) {
            this.pushError(row.PRM_MNEMONIC, DataFrameAnalyseAttributes.DISCRETE, "EU value should be numeric type");
          }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore  // Value is number, additional check
          if (value.EUL_EU_VALUE === "") {
            this.pushError(row.PRM_MNEMONIC, DataFrameAnalyseAttributes.DISCRETE, "Invalid EU value");
          }
        }
      )
  }

  private discreteTable(row: AcquiredParams): void {
    const discreteArray = row.TXD;
      discreteArray.map((value, index) => {
          if (!Number.isInteger(value.TXD_RAW_DATA_VALUE)) {
            this.pushError(row.PRM_MNEMONIC, DataFrameAnalyseAttributes.DISCRETE, "Raw Value should be an integer");
          }
          if (value.TXD_RAW_DATA_VALUE < 0) {
            this.pushError(row.PRM_MNEMONIC, DataFrameAnalyseAttributes.DISCRETE, "Raw Value should not be less than 0");
          }
          if (value.TXD_DESCRIPTION === "") {
            this.pushError(row.PRM_MNEMONIC, DataFrameAnalyseAttributes.DISCRETE, "Invalid Discrete Text");
          }
        }
      )
  }

  // Remove duplicate errors
  private getUniqueErrors = (errors: any[]): any[] => {
    const uniqueErrors = new Set<string>();
    return errors.filter(error => {
      const errorString = `${error.Mnemonic}-${error.Attribute}-${error.Error}`;
      if (!uniqueErrors.has(errorString)) {
        uniqueErrors.add(errorString);
        return true;
      }
      return false;
    });
  };
  
}
