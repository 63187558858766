<app-data-frame-analyse [isLoading]="isLoading"
                        [ParamerterErrors]="parameterErrors"
                        [errorMessages]="errorMessages"
                        [dfErrors]="dfErrors"
                        [dataFrameAnalyseValue]="Components.DataFrameAnalyse767"
                        [mapViewPath]="Components.MapView767.path"
                        [listViewPath]="Components.ListView767.path"
                        [editPath]="Components.DataFrames767.path"
                        [dfPath]="Components.DataFrame767.path"
                        >
</app-data-frame-analyse>
