import { Component, OnInit } from "@angular/core";
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from "@angular/router";
import { ConfirmationService, MessageService } from "primeng/api";
import { BreadcrumbService } from "src/app/common/services/breadcrumb.service";
import { AcmsReportMapModel } from "src/app/features/configuration/models/acmsReportMap";
import { AcmsReportMapService } from "src/app/features/configuration/services/acms-report-map.service";
import { FileSizeFormatPipe } from "src/app/platform/pipes/file-size-format.pipe";
import { Organization } from "src/app/features/administration/models/organization";
import { acmsReportMapDetailsInput } from "../../input-model/acms-report-map-input";
import { BaseDetailsComponent } from "../../base/base-details-component";
import { permissions } from "../../../../integration/configuration.permissions";
import { SecurityUserService } from "src/app/security/services/security-user.service";
import { Components } from '../../../../integration/configuration.components';

@Component({
  selector: 'app-acms-report-map-details',
  templateUrl: '../../base/add.component.html',
  providers: [{
    provide: acmsReportMapDetailsInput,
    useFactory: (acmsReportMapService: AcmsReportMapService) => new acmsReportMapDetailsInput(acmsReportMapService)
    ,
    deps: [AcmsReportMapService]
  }

  ]


})
export class AcmsReportMapDetailsComponent extends BaseDetailsComponent<AcmsReportMapModel, Organization> implements OnInit {

  constructor(
    private route: ActivatedRoute,
    router: Router,
    private location: Location,
    messageService: MessageService,
    confirmationService: ConfirmationService,
    breadcrumbService: BreadcrumbService,
    fileSizeFormatter: FileSizeFormatPipe,
    acmsReportInput: acmsReportMapDetailsInput,
    private realUserService: SecurityUserService,
  ) {
    super(messageService, confirmationService, router, breadcrumbService, fileSizeFormatter, acmsReportInput);
  }

  ngOnInit(): void {
    this.userCanAdd = this.realUserService.userHasPermission(permissions.acms_report.create);
    this.userCanModify = this.realUserService.userHasPermission(permissions.acms_report.manage);

    //Manage Acms report Section
    if (this.route.snapshot.queryParamMap.get('a')) {

      if (!this.userCanModify) {
        this.messageService.add({
          key: 'message',
          severity: 'error',
          summary: 'Unauthorized',
          detail: 'User is not authorized to add an ACMS Report Map.'
        });

        // Wait a few seconds for user to see the error, and send them back to where they came from.
        setTimeout(() => {
          this.location.back();
        }, 3000);
      }

      this.breadcrumbService.setItems(
        this.route,
        [
          { label: Components.AcmsReportMap.label, routerLink: Components.AcmsReportMap.path },
          { label: `Manage ACMS Report Map` }
        ]
      );
      this.load_Record(+this.route.snapshot.queryParamMap.get('a'))

    }
    else {// Add New Acms report Section

      if (this.userCanAdd) {
        this.breadcrumbService.setItems(
          this.route,
          [
            { label: Components.AcmsReportMap.label, routerLink: Components.AcmsReportMap.path },
            { label: `New ACMS Report Map` }
          ]
        );
        this.load_Record(-1);
      }
      else {
        this.messageService.add({
          key: 'message',
          severity: 'error',
          summary: 'Unauthorized',
          detail: 'User is not authorized to add an ACMS Report Map.'
        });

        // Wait a few seconds for user to see the error, and send them back to where they came from.
        setTimeout(() => {
          this.location.back();
        }, 3000);
      }
    }
  }
}
