import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/common/services/breadcrumb.service';
import { EnRouteWaypointService } from 'src/app/features/administration/services/enroutewaypoint.service';
import { ApiType, NavigationInformationBaseViewComponent } from '../navigation-information-base/navigation-information-base-view.component';
import { Components } from 'src/app/features/administration/integration/administration.components';


@Component({
  selector: 'app-en-route-waypoints-details',
  templateUrl: './en-route-waypoints-details.component.html',
})

export class EnRouteWaypointsDetailsComponent extends NavigationInformationBaseViewComponent implements OnInit {
  pageTitle = Components.EnRouteWaypointsDetails.label;
  selected_date: any;
  waypoint_identifier: string;
  short_icao: string;
  file_dates: any;
  procedureBreadcrumb: any;

  constructor(
    private enRouteService: EnRouteWaypointService,
    messageService: MessageService,
    confirmationService: ConfirmationService,
    router: Router,
    route: ActivatedRoute,
    breadcrumbService: BreadcrumbService) {
    super(messageService, confirmationService, router, breadcrumbService, route);
    this.short_icao = route.snapshot.queryParamMap.get('a');
    const dte = route.snapshot.queryParamMap.get('b');
    const file_dates = route.snapshot.queryParamMap.get('c');
    this.waypoint_identifier = route.snapshot.queryParamMap.get('e');
    this.procedureBreadcrumb = JSON.parse(route.snapshot.queryParamMap.get('f'));


    this.selected_date = { "dte": dte }
    this.file_dates = JSON.parse(file_dates);
    this.setBreadcrumb();
    this.getEnrouteWaypointDetail(dte, this.short_icao, this.waypoint_identifier)
  }

  ngOnInit(): void {
    this.setBreadcrumb();
  }

  getEnrouteWaypointDetail(dte, short_icao, waypoint_identifier) {
    const arg: ApiType = { request: this.enRouteService.getEnRouteWaypoint(dte, short_icao, waypoint_identifier), api_name: 'ndb en-route waypoints', callback: this.callback.bind(this) };
    this.callAPI(arg);
  }

  callback(data) {
    this.data = data;
  }

  override setBreadcrumb() {
    const trimRunwayIdentifier = (runwayIdentifier: string): string => {
      if (runwayIdentifier) {
        return runwayIdentifier.replace(/^RW/, '');
      }
      return '-';
    };

    if (this.procedureBreadcrumb) {
      super.setBreadcrumb(
        [
          {
            label: Components.AirportList.label,
            routerLink: Components.AirportList.path,
            queryParams: { a: this.selected_date["dte"] }
          },
          {
            label: Components.RunwayList.label + ` (${this.procedureBreadcrumb["display_icao_iata"]})`,
            routerLink: Components.RunwayList.path,
            queryParams: {
              a: this.procedureBreadcrumb["icao"],
              b: this.procedureBreadcrumb["display_icao_iata"],
              c: this.selected_date["dte"],
              d: JSON.stringify(this.file_dates)
            }
          },
          {
            label: Components.ProceduresList.label + ` (${trimRunwayIdentifier(this.procedureBreadcrumb["transition_identifier"])})`,
            routerLink: Components.ProceduresList.path,
            queryParams: {
              a: this.procedureBreadcrumb["icao"],
              b: this.procedureBreadcrumb["display_icao_iata"],
              c: this.selected_date["dte"],
              d: JSON.stringify(this.file_dates),
              e: this.procedureBreadcrumb["transition_identifier"]
            }
          },
          {
            label: Components.ProcedureDetails.label + ` (${this.procedureBreadcrumb["sid_star_approach_identifier"] || "-"})`,
            routerLink: Components.ProcedureDetails.path,
            queryParams: {
              a: this.procedureBreadcrumb["icao"],
              b: this.procedureBreadcrumb["display_icao_iata"],
              c: this.selected_date["dte"],
              d: JSON.stringify(this.file_dates),
              e: this.procedureBreadcrumb["transition_identifier"],
              f: this.procedureBreadcrumb["procedure_id"],
              g: this.procedureBreadcrumb["sid_star_approach_identifier"]
            }
          },
          {
            label: Components.EnRouteWaypointsDetails.label + ` (${this.waypoint_identifier || "-"})`
          }
        ]);
    }
    else {
      super.setBreadcrumb([
        {
          label: Components.EnRouteWaypointsList.label,
          routerLink: Components.EnRouteWaypointsList.path,
          queryParams: { a: this.selected_date["dte"] }
        },
        {
          label: Components.EnRouteWaypointsDetails.label + ` (${this.waypoint_identifier || "-"})`
        }
      ]);
    }
  }

  override onChange() {
    super.onChange([Components.EnRouteWaypointsDetails.path], {
      queryParams: {
        a: this.waypoint_identifier,
        b: this.short_icao,
        c: this.selected_date["dte"],
        d: JSON.stringify(this.file_dates),
        e: JSON.stringify(this.procedureBreadcrumb)
      }
    });
  }
}
