import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FlightDataRedactions } from 'src/app/features/datadelivery/datadelivery/models/FlightDataRedactions';

@Component({
  selector: 'app-data-redaction-table',
  templateUrl: './data-redaction-table.component.html',
  styleUrls: ['./data-redaction-table.component.scss'],
})
export class DataRedactionTableComponent {
  cols: any[] = [
    {
      field: 'name',
      header: 'Name',
    },
    {
      field: 'description',
      header: 'Description',
    },
    {
      field: 'dataframename',
      header: 'Data Frame',
    },
    {
      field: 'ziptype',
      header: 'Conversion Type',
    },
    {
      field: 'textualoutput',
      header: 'Textual Output',
    },
    {
      field: 'clone',
      header: 'Action',
    },
    {
      field: 'Delete',
      header: 'Action',
    },
  ];
  @Input() flightdataredactions: FlightDataRedactions[];
  @Input() defaultTableRows: number;
  @Input() currentPageReport: string;
  @Input() pageTitle: string;
  @Input() canAdd: boolean;
  @Input() canEdit: boolean;
  @Input() canDelete: boolean;
  @Input() readonly = false;
  @Input() selectedCloneData: FlightDataRedactions[] = [];
  @Output() onRowClicked = new EventEmitter<any>();
  @Output() cloneRedaction = new EventEmitter<any>();
  @Output() deleteDataRedaction = new EventEmitter<any>();

  handleClickedEdit(dataredaction: any, event) {
    this.onRowClicked.emit({ dataredaction, event });
  }

  handleCloneRedaction(dataredaction: any, event) {
    event.stopPropagation();
    this.cloneRedaction.emit(dataredaction);
  }

  handleDeleteDataRedaction(dataredaction: any, event) {
    this.deleteDataRedaction.emit({ dataredaction, event });
  }

  preventRowClick(event) {
    event.preventDefault();
    event.stopPropagation();
  }
}
