export const permissions = {
    acms_report: {
        "create": "configuration.acms_report_maps.create",
        "manage": "configuration.acms_report_maps.manage",
        "delete": "configuration.acms_report_maps.delete",
        "view": "configuration.acms_report_maps.view"
    },
    configuration_roles: {
        "create": "configuration.configuration_roles.create",
        "manage": "configuration.configuration_roles.manage",
        "delete": "configuration.configuration_roles.delete",
        "view": "configuration.configuration_roles.view"
    },
    configuration_users: {
        "manage": "configuration.configuration_users.manage",
        "view": "configuration.configuration_users.view"
    },
    dataframes: {
        create: "configuration.data_frames.create",
        manage: "configuration.data_frames.manage",
        delete: "configuration.data_frames.delete",
        view: "configuration.data_frames.view",
        download: "configuration.data_frames.download",
        manage_details: "configuration.data_frames.manage_details",
        data_frame_view:"configuration.data_frame_details.view",
        data_frame_manage:"configuration.data_frame_details.manage"

    },
    recorders: {
        create: "configuration.recorders.create",
        manage: "configuration.recorders.manage",
        delete: "configuration.recorders.delete",
        view: "configuration.recorders.view"
    },
    aircraft_recorders: {
        create: "configuration.aircraft_recorders.create",
        manage: "configuration.aircraft_recorders.manage",
        delete: "configuration.aircraft_recorders.delete",
        view: "configuration.aircraft_recorders.view"
    },
    regexes: {
        create: "configuration.recorder_regexes.create",
        manage: "configuration.recorder_regexes.manage",
        delete: "configuration.recorder_regexes.delete",
        view: "configuration.recorder_regexes.view"
    },
    sar_maps: {
        create: "configuration.sar_maps.create",
        manage: "configuration.sar_maps.manage",
        delete: "configuration.sar_maps.delete",
        view: "configuration.sar_maps.view"
    }
}