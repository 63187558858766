<p-blockUI [blocked]="loadingCount > 0">
  <tdy-spinner style="position:absolute;left:50%;top:50%;z-index: 10000"></tdy-spinner>
</p-blockUI>
<p-confirmDialog></p-confirmDialog>

<div *ngIf="user !== null" class="col-12 card p-3">
  <form #userForm="ngForm" (ngSubmit)="onSubmit()" novalidate>
    <h5 class="m-1 mt-2 mb-3 flex align-items-center justify-content-start">{{pageTitle}}</h5>

    <div class="grid">
      <div class="col-2">
        <div id="avatarContainer"
          class=" flex justify-content-center border-round-md  align-items-center avatarWrapper">
          <img *ngIf="avatarToShow" id="avatarPreview" [src]="avatarToShow" alt="User Avatar" />
          <button *ngIf="avatarToShow !== null" [disabled]="IsFieldDisabled()" id="avatarDeleteButton"
            (click)="onLogoRemove()" class="p-button-rounded" pButton pRipple
            style="position: absolute; background-color: rgba(0, 0, 0, 0.75);" icon="pi pi-times"
            pTooltip="Delete avatar" type="button">
          </button>
          <p-fileUpload *ngIf="!avatarToShow" [disabled]="IsFieldDisabled()" #avatarUpload id="avatarUpload"
            name="avatarUpload" mode="basic" [auto]="true" pTooltip="Upload Avatar" accept=".jpg,.png,.jpeg"
            [maxFileSize]="maxAvatarFileSize" (onSelect)="onAvatarSelect($event)" [ngStyle]="{'position': 'absolute'}"
            [style]="{'background-color': 'rgba(0, 0, 0, 0.75)'}" [styleClass]="'p-button-rounded'">
          </p-fileUpload>
        </div>
        <div class="p-text-center p-mt-4" style="margin-top: 10px;">
          <h5 class="flex align-items-center justify-content-center">{{pageSubTitle}}</h5>
        </div>
      </div>
      <div class="col-10">
        <div class="card pt-0 p-3">
          <p-tabView id="tabView  ">
            <p-tabPanel header="Properties" id="propertiesPanel" leftIcon="pi pi-book">
              <input id="userID" name="userID" type="hidden" [(ngModel)]="user.user_id"
                [disabled]="isReadOnly || user.AD_user" />
              <div class="p-grid pt-2">
                <div class="">

                  <h6 class="mt-2">User Information</h6>
                  <div class="-ml-2">
                    <div class="col-6">
                      <span class="p-float-label">
                        <input #firstName="ngModel" [(ngModel)]="user.first_name" id="firstName" name="firstName"
                          maxlength="75" type="text" pInputText style="width: 100%" [textContent]="user.first_name"
                          required [disabled]="IsFieldDisabled()" pattern=".*\S.*" />
                        <label>First Name</label>
                      </span>
                      <div *ngIf="(firstName.touched || firstName.dirty)">
                        <div *ngIf="firstName.hasError('required')" class="tdy-required">First name is required</div>
                        <div *ngIf="firstName.hasError('pattern')" class="tdy-required">First name cannot be blank</div>
                      </div>
                    </div>
                    <div class="col-6">
                      <span class="p-float-label">
                        <input #lastName="ngModel" [(ngModel)]="user.last_name" id="lastName" maxlength="75"
                          name="lastName" pInputText [textContent]="user.last_name" style="width: 100%" type="text"
                          required [disabled]="IsFieldDisabled()" pattern=".*\S.*" />

                        <label for="lastName">Last Name</label>
                      </span>
                      <div *ngIf="(lastName.touched || lastName.dirty)">
                        <div *ngIf="lastName.hasError('required')" class="tdy-required">Last name is required</div>
                        <div *ngIf="lastName.hasError('pattern')" class="tdy-required">Last name cannot be blank</div>
                      </div>
                    </div>
                    <div class="col-6">
                      <span class="p-float-label">
                        <input #displayName="ngModel" [(ngModel)]="user.display_name" id="displayName" maxlength="100"
                          name="displayName" pInputText [textContent]="user.display_name" style="width: 100%" required
                          type="text" [disabled]="IsFieldDisabled()" pattern=".*\S.*" />
                        <label for="displayName">Display Name</label>
                      </span>
                      <div *ngIf="(displayName.touched || displayName.dirty)">
                        <div *ngIf="displayName.hasError('required')" class="tdy-required">Display name is required
                        </div>
                        <div *ngIf="displayName.hasError('pattern')" class="tdy-required">Display name cannot be blank
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <span class="p-float-label">
                        <input #email="ngModel" [(ngModel)]="user.user_email" id="email" maxlength="100" name="email"
                          pInputText [textContent]="user.user_email" style="width: 100%" type="email"
                          [disabled]="user.user_id != -1 || user.AD_user" required
                          pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}" />
                        <label for="email">Email</label>
                      </span>
                      <div *ngIf="(email.touched || email.dirty)">
                        <div *ngIf="email.hasError('required')" class="tdy-required">Email is required</div>
                        <div *ngIf="email.hasError('pattern')" class="tdy-required">Email is Invalid</div>
                      </div>
                    </div>
                    <div class="col-6">
                      <span class="p-float-label">
                        <p-dropdown #business="ngModel" [options]="availOrgsOptions" [(ngModel)]="user.business_code"
                          placeholder="Select Business" [disabled]="isBusinessExists" id="business" name="business"
                          (onChange)="onBusinessSelection()" [style]="dropdownStyle"></p-dropdown>
                        <label for="business">Select Business</label>
                      </span>
                      <div *ngIf="(business.touched || business.dirty)">
                        <div *ngIf="business.hasError('required')" class="tdy-required">Business is required</div>
                      </div>
                    </div>
                    <div class="flex">
                      <div class="col-2" *ngIf="user.status">
                        <span class="float">
                          <label>Status: <label style="font-weight: bold;">{{user.status ??
                              'PROVISIONED'}}</label></label>
                        </span>
                      </div>
                      <div class="col-1">
                        <span class="float">
                          <p-checkbox #userAdmin="ngModel" [(ngModel)]="user.user_admin" label="Admin"
                            pTooltip="Admin User" [binary]="true"
                            [disabled]="(!canEdit && this.user.user_id > 0) || (!canAdd && this.user.user_id < 0)"
                            name="user_admin"></p-checkbox>
                        </span>
                      </div>
                      <div class="col-2">
                        <span class="float">
                          <p-checkbox #ADuser="ngModel" [(ngModel)]="user.AD_user" inputId="label"
                            pTooltip="Active Directory User" [binary]="true" [disabled]="true"
                            name="AD_user"></p-checkbox>
                          <label style="margin: 7px;" for="label">Active Directory</label>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </p-tabPanel>
            <p-tabPanel *ngIf="isLoggedInAdmin && user.business_code" header="Organizations" id="userOrgPanel" leftIcon="pi pi pi-building"
              >
              <div class="field col-12">
                <div class="col-6 p-1">
                  <div class=" row " style="margin-bottom: 0px;">
                    <div class="custom-panel -ml-2 custom-not-avail-apps">
                      <h6>Your Selected Business</h6>
                      <ul class="my-list">
                        <li>{{user.business_name}}</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <h6>Additional Organizations</h6>
                <p-pickList name="name" id="name" [source]="additionalAvailOrgs"
                  [disabled]="(!canEdit && (this.user.user_id > 0 || loggedIn)) || (!canAdd && this.user.user_id == -1)"
                  [target]="userAssignedOrgs" filterBy="name" sourceHeader="Available Organizations"
                  targetHeader="Selected Organizations" [showSourceControls]="false" [showTargetControls]="false"
                  (onMoveToTarget)="onOrgChange()" (onMoveToSource)="onOrgChange()" (onMoveAllToSource)="onOrgChange()"
                  (onMoveAllToTarget)="onOrgChange()" [dragdrop]="true" [responsive]="true"
                  [sourceStyle]="{ height: '30rem' }" [targetStyle]="{ height: '30rem' }" breakpoint="1400px">
                  <ng-template let-org pTemplate="org">
                    <ng-container *ngIf="toUserOrg(org); let item" [class.disabled]="true"
                      style="pointer-events: none; cursor: default;">
                      <div>
                        <div>{{org.name}}</div>
                      </div>
                    </ng-container>
                  </ng-template>
                </p-pickList>
              </div>
            </p-tabPanel>
            <!--Subscription is hidden rightnow -->
            <p-tabPanel *ngIf="false" header="Subscriptions" id="Subscriptions" leftIcon="pi pi-bell">

              <div class="p-grid pt-3">


                <div class="grid justify-content-between ">
                  <div class="col-5">
                    <div datakey="" id="" sortmode="single" sortfield="" class="p-element">
                      <div id="pn_id_54" class="p-datatable p-component">
                        <div class="p-datatable-wrapper">

                          <!-- comment from faraz 8 august 2024
                          this table is just for demo purose and its non functional, please replace it witha working component. thanks -->
                          <table role="table" class="p-datatable-table" style="width: 100%;     border-spacing: 0px;">
                            <thead role="rowgroup" class="p-datatable-thead">
                              <tr class="ng-star-inserted">
                                <th class="text-center" style="width: 10%;">
                                  <span ptooltip="Check all" class="p-element">
                                    <div class="p-checkbox p-component">
                                      <div class="p-hidden-accessible"><input type="checkbox" tabindex="0"
                                          aria-label="All items unselected"></div>
                                      <div class="p-checkbox-box"></div>
                                    </div>
                                  </span>
                                </th>
                                <th class="p-element p-sortable-column p-highlight" tabindex="0" role="columnheader"
                                  aria-sort="ascending">
                                  <div class="flex justify-content-between align-items-center"> Available Notifications
                                    <span field="" class="p-element">
                                      <i class="p-sortable-column-icon pi pi-fw pi-sort-amount-up-alt"></i>
                                    </span><span hideonclear="true" display="menu" field="" type="text"
                                      class="p-element ml-auto ng-tns-c2614594025-50">
                                      <div class="p-column-filter ng-tns-c2614594025-50 p-column-filter-menu">
                                        <button type="button" aria-haspopup="true"
                                          class="p-column-filter-menu-button p-link ng-tns-c2614594025-50"
                                          aria-label="Show Filter Menu" aria-expanded="false">
                                          <span class="pi pi-filter-icon pi-filter ng-tns-c65-90"></span>
                                        </button>
                                      </div>
                                    </span>
                                  </div>
                                </th>
                              </tr>
                            </thead>
                            <tbody role="rowgroup" class="p-element p-datatable-tbody">
                              <tr class="p-element p-selectable-row" data-p-highlight="false"
                                data-p-selectable-row="true">
                                <td style="width: 10%; text-align: center; vertical-align: center;"><span
                                    class="p-element">
                                    <div class="p-checkbox p-component">
                                      <div class="p-hidden-accessible"><input type="checkbox" tabindex="0"
                                          aria-label="Row Unselected"></div>
                                      <div class="p-checkbox-box p-component"></div>
                                    </div>
                                  </span></td>
                                <td style="word-wrap: break-word; width: 90%;"> Notification title </td>
                              </tr>
                              <tr class="p-element p-selectable-row" data-p-highlight="false"
                                data-p-selectable-row="true">
                                <td style="width: 10%; text-align: center; vertical-align: center;"><span
                                    class="p-element">
                                    <div class="p-checkbox p-component">
                                      <div class="p-hidden-accessible"><input type="checkbox" tabindex="0"
                                          aria-label="Row Unselected"></div>
                                      <div class="p-checkbox-box p-component"></div>
                                    </div>
                                  </span></td>
                                <td style="word-wrap: break-word; width: 90%;"> Notification title
                                </td>
                              </tr>

                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-1 align-self-center text-center">
                    <div class="ng-star-inserted"><span ptooltip="Move checked items to selected" tooltipposition="left"
                        class="p-element"><button icon="pi pi-arrow-right" id="moveToSelectedButton" pbutton=""
                          pripple="" type="button"
                          class="p-element p-ripple mb-3 p-button p-component p-button-icon-only"><span
                            class="p-button-icon pi pi-arrow-right" aria-hidden="true"></span><span class="p-ink"
                            style="height: 39px; width: 39px; top: -7.61111px; left: -9.125px;"></span></button></span>
                    </div>
                    <div class="ng-star-inserted"><span ptooltip="Move checked items to available"
                        tooltipposition="right" class="p-element"><button icon="pi pi-arrow-left"
                          id="moveToAvailableButton" pbutton="" pripple="" type="button"
                          class="p-element p-ripple p-button p-component p-button-icon-only" disabled=""><span
                            class="p-button-icon pi pi-arrow-left" aria-hidden="true"></span><span class="p-ink"
                            style="height: 39px; width: 39px; top: -2.61111px; left: 6.20135px;"></span></button></span>
                    </div>
                  </div>
                  <div class="col-5">
                    <div datakey="" id="dtAssignedEventTypes" sortmode="single" sortfield="" class="p-element">
                      <div id="pn_id_57" class="p-datatable p-component">
                        <div class="p-datatable-wrapper">
                          <table role="table" id="pn_id_57-table" class="p-datatable-table"
                            style="width: 100%;     border-spacing: 0px;">
                            <thead role="rowgroup" class="p-datatable-thead">
                              <tr class="ng-star-inserted">
                                <th class="text-center" style="width: 10%;">
                                  <span ptooltip="Check all" class="p-element">
                                    <div class="p-checkbox p-component">
                                      <div class="p-hidden-accessible"><input type="checkbox" tabindex="0"
                                          aria-label="All items unselected"></div>
                                      <div class="p-checkbox-box"></div>
                                    </div>
                                  </span>
                                </th>
                                <th class="p-element p-sortable-column p-highlight" tabindex="0" role="columnheader"
                                  aria-sort="ascending">
                                  <div class="flex justify-content-between align-items-center"> Assigned Notifications
                                    <span field="" class="p-element">
                                      <i class="p-sortable-column-icon pi pi-fw pi-sort-amount-up-alt"></i>
                                    </span><span hideonclear="true" display="menu" field="" type="text"
                                      class="p-element ml-auto ng-tns-c2614594025-51">
                                      <div class="p-column-filter ng-tns-c2614594025-51 p-column-filter-menu">

                                      </div>
                                    </span>
                                  </div>
                                </th>
                                <th style=" width: 5%;text-align: right;">
                                  <span class="pi pi-filter-icon pi-filter ng-tns-c65-90"></span>

                                </th>
                              </tr>
                            </thead>
                            <tbody role="rowgroup" class="p-element p-datatable-tbody">
                              <tr class="p-element p-selectable-row" data-p-highlight="false"
                                data-p-selectable-row="true">
                                <td style="width: 10%; text-align: center; vertical-align: center;"><span
                                    class="p-element">
                                    <div class="p-checkbox p-component">
                                      <div class="p-hidden-accessible"><input type="checkbox" tabindex="0"
                                          aria-label="Row Unselected"></div>
                                      <div class="p-checkbox-box p-component"></div>
                                    </div>
                                  </span></td>
                                <td style="word-wrap: break-word;"><span> Notification title </span></td>
                                <td style="word-wrap: break-word; width: 20%;"><span><span
                                      ptooltip="Check to also receive e-mail"
                                      class="p-element ng-untouched ng-pristine ng-valid">

                                    </span></span></td>
                              </tr>
                              <tr class="p-element p-selectable-row" data-p-highlight="false"
                                data-p-selectable-row="true">
                                <td style="width: 10%; text-align: center; vertical-align: center;"><span
                                    class="p-element">
                                    <div class="p-checkbox p-component">
                                      <div class="p-hidden-accessible"><input type="checkbox" tabindex="0"
                                          aria-label="Row Unselected"></div>
                                      <div class="p-checkbox-box p-component"></div>
                                    </div>
                                  </span></td>
                                <td style="word-wrap: break-word;"><span> Notification title </span></td>
                                <td style="word-wrap: break-word; width: 20%;"><span><span
                                      ptooltip="Check to also receive e-mail"
                                      class="p-element ng-untouched ng-pristine ng-valid">

                                    </span></span></td>
                              </tr>








                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


              </div>


            </p-tabPanel>

          </p-tabView>
        </div>
        <div class="flex justify-content-end">
          <button (click)="onCancel()" *ngIf="canView && !loggedIn" id="cancelButton" name="cancelButton" type="button"
            class="p-button-text p-mr-2" pButton pRipple icon="pi pi-times" label="Cancel"></button>
          <button (click)="onReset()" *ngIf="canEdit || canCustomize"
            [disabled]="!(userForm.dirty || orgsChanged || avatarChanged)" id="resetButton" name="resetButton"
            type="button" class="p-button-text p-mr-2" pButton pRipple icon="pi pi-undo" label="Reset"></button>
          <button id="saveButton" name="saveButton" type="submit" *ngIf="canEdit || canCustomize"
            [disabled]="checkValidationsOnSave(userForm)" pButton pRipple icon="pi pi-check" label="Save"></button>
        </div>
      </div>
    </div>
  </form>
</div>
