<div class="card">
  <div>
    <div class="table-header mb-3">
      <h6 class="title">ACMS Label Information</h6>
    </div>
    <p-table [value]="tableData" [responsive]="true" class="frame_details_table">
      <ng-template pTemplate="header">
        <tr>
          <th *ngFor="let col of columns" class="text-center">{{ col.header }}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-row>
        <tr>
          <td *ngFor="let col of columns" class="text-center"> {{ row[col.field] }}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
