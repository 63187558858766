import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService, SelectItem } from 'primeng/api';
import { BreadcrumbService } from 'src/app/common/services/breadcrumb.service';
import { CompanyService } from 'src/app/features/administration/services/company.service';
import { Action, BaseViewComponent } from 'src/app/common/components/base-view.component';
import { concatMap, forkJoin, of, takeUntil } from 'rxjs';
import { Organization, OrganizationType } from 'src/app/features/administration/models/organization';
import { OrganizationService, Status } from 'src/app/features/administration/services/organization.service';
import { ApplicationService } from 'src/app/features/administration/services/application.service';
import { Application } from 'src/app/features/administration/models/application';
import { OrganizationApp } from 'src/app/features/administration/models/organization';
import { SettingsService } from 'src/app/common/services/settings.service';
import { Setting, SettingPair } from 'src/app/common/models/setting';
import { OrgFormChanges } from 'src/app/features/administration/components/organization-details/organization-details.component';
import { SecurityUserService } from 'src/app/security/services/security-user.service';
import { permissions } from 'src/app/security/models/permissions';
import { Components } from '../../integration/administration.components';
import { CommonService } from 'src/app/common/services/common.service';
import { Aircraft, AircraftApplication, AircraftPair, AppsAssignedToAircraft } from '../../models/aircraft';
import { AircraftGroup } from '../../models/aircraftGroup';
import { AircraftService } from '../../services/aircraft.service';
import { AircraftGroupService } from '../../services/aircraft-group.service';
import { SortUtil } from 'src/app/platform/util/sortUtil';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-company-details',
  templateUrl: './company-details.component.html'
})
export class CompanyDetailsComponent extends BaseViewComponent implements OnInit {
  // For Company
  company: Organization;
  settings: Setting[];
  orgType: OrganizationType = OrganizationType.Company;

  // For Applications
  availableApplications: Application[];
  assignedApplications: OrganizationApp[] = [];
  applicationChanged = false;

  // For Aircraft Applications
  companyAircraft: AircraftPair[] = [];
  selectedAircraftApps: AircraftApplication[];
  companyAircraftApps: AircraftApplication[] = [];
  aircraft: Aircraft[];
  aircraftGroupsOptions: SelectItem[];
  aircraftGroups: AircraftGroup[];
  selectedAircraftGroup: number;
  allAircraftAppsSelected = false;
  unchangedApplicationsPayload: any[] = [];
  loggedIn = false;

  // user permissions
  codeIsReadOnly = true;
  canView = false;
  canAdd = false;
  canEdit = false;
  companyDetailsView = false;
  companyDetailsEdit = false;

  messageLabel = 'Company';

  constructor(private companyService: CompanyService,
    private securityUserService: SecurityUserService,
    private settingsService: SettingsService,
    private applicationService: ApplicationService,
    private organizationService: OrganizationService,
    private route: ActivatedRoute,
    private aircraftService: AircraftService,
    private commonService: CommonService,
    private aircraftGroupService: AircraftGroupService,
    messageService: MessageService,
    confirmationService: ConfirmationService,
    router: Router,
    breadcrumbService: BreadcrumbService) {
    super(messageService, confirmationService, router, breadcrumbService);

    // Note: Service won't update breadcrumb if caller already did so.
    this.breadcrumbService.setItems(route, [
      { label: Components.CompanyDetails.label }  // No routerLink value for current page
    ]);

    // Refresh the same page when querry params changed in url. Specially when selecting current company.
    this.route.queryParams.subscribe((params) => {
      if (params['loggedIn']) {
        this.loggedIn = true;
        this.ngOnInit();
      }
    });
  }

  ngOnInit(): void {
    this.canView = this.securityUserService.userHasPermission(permissions.admin.companies.view);
    this.canAdd = this.securityUserService.userHasPermission(permissions.admin.companies.create);
    this.canEdit = this.securityUserService.userHasPermission(permissions.admin.companies.manage);
    this.companyDetailsView = this.securityUserService.userHasPermission(permissions.admin.company_details.view);
    this.companyDetailsEdit = this.securityUserService.userHasPermission(permissions.admin.company_details.manage);

    const queryParamExists = !!this.route.snapshot.queryParamMap.get('c'); // c = company_id

    if (!queryParamExists && this.canAdd) {
      this.canEdit = true;
      this.addCompany();
      this.codeIsReadOnly = false;
    }
    else if (queryParamExists && this.canEdit || queryParamExists && this.canView) {
      this.loadCompany(false, +this.route.snapshot.queryParamMap.get('c'));
    }
    else if (this.companyDetailsView) {
      // load current company...
      this.loadCompany(true);
    }
  }

  getPageTitle(): string {
    return this.isNew ? "New Company" : Components.CompanyDetails.label;
  }

  addCompany() {
    // Update page title and breadcrumb with New...
    this.loadingCount++;
    this.updateBreadcrumb('New Company');
    forkJoin([this.commonService.listApplication(),
    this.settingsService.getDefaultSettings(OrganizationType.Company)])
      .pipe(
        takeUntil(this.ngUnsubscribe)).subscribe({
          next: ([apps, settings]) => {
            this.availableApplications = apps;
            this.settings = settings ?? [];

            // Create the company
            this.isNew = true;
            this.company = new Organization();
            this.company.id = -1;
          },
          error: error => {
            this.showErrorMsg(error, Action.Add, `${this.messageLabel}`);
            this.loadingCount--;
          },
          complete: () => {
            this.loadingCount--;
          }
        });

  }

  loadCompany(currentCompany: boolean, companyId?: number) {
    this.isNew = false;
    this.loadingCount++;

    // Check if Airline have any aircraft.
    const companyAircraftObservable$ = currentCompany ? this.commonService.listAircraftFilters(environment.encryptedApplicationName.administration) : this.aircraftService.listAircraftFiltersByOrgID(companyId,environment.encryptedApplicationName.administration);

    companyAircraftObservable$.pipe(
      takeUntil(this.ngUnsubscribe),
      concatMap((aircraft) => {
        this.companyAircraft = aircraft;
        if (this.companyAircraft.length > 0) {
          return forkJoin([currentCompany ? this.companyService.getCurrentCompany() : this.companyService.getCompany(companyId),
          currentCompany ? this.settingsService.getSettings(OrganizationType.Company) : this.settingsService.getSettingsByOrg(OrganizationType.Airline, companyId),
          currentCompany ? this.applicationService.getCurrentAssignedOrgApps(OrganizationType.Airline) : this.applicationService.getAssignedOrgApps(companyId, OrganizationType.Company),
          this.commonService.listApplication(),
          currentCompany ? this.commonService.listAircraftGroupFilters() : this.aircraftGroupService.listAircraftGroupFiltersByOrgID(companyId),
          currentCompany ? this.applicationService.getCurrentCompanyAircraftApps() : this.applicationService.getCompanyAircraftApps(companyId)
          ])
        } else {
          return forkJoin([currentCompany ? this.companyService.getCurrentCompany() : this.companyService.getCompany(companyId),
          currentCompany ? this.settingsService.getSettings(OrganizationType.Company) : this.settingsService.getSettingsByOrg(OrganizationType.Airline, companyId),
          currentCompany ? this.applicationService.getCurrentAssignedOrgApps(OrganizationType.Airline) : this.applicationService.getAssignedOrgApps(companyId, OrganizationType.Company),
          this.commonService.listApplication(),
          ])
        }
      }
      )).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
        next: ([company, settings, assignedApplications, allApplication, aircraftGroups, aircraft]) => {
          this.loadCompanyData(company, settings, assignedApplications, allApplication, aircraftGroups, aircraft)
        }, error: error => {
          this.showErrorMsg(error, Action.Get, `${this.messageLabel}`, currentCompany ? `${companyId}` : '');
          this.loadingCount--;
        }, complete: () => {
          this.loadingCount--;
        }
      });
  }


  loadCompanyData(company: Organization, settings: Setting[], assignedApps: OrganizationApp[], allApplication: Application[], aircraftGroups: AircraftGroup[], aircraftApps: AppsAssignedToAircraft[]) {
    this.company = company;
    this.unchangedApplicationsPayload = assignedApps.map(
      (app) => {
        return {
          application_id: app.application_id,
          number_of_users: app.number_of_users,
          number_of_aircrafts: app.number_of_aircrafts
        }
      }
    )
    this.assignedApplications = assignedApps ?? [];
    // Filter available apps
    this.availableApplications = allApplication;
    if (this.assignedApplications?.length > 0) {
      this.availableApplications = allApplication.filter(a => assignedApps.findIndex(s => s.application_id == a.application_id) == -1) ?? [];
    }

    if (this.companyAircraft.length > 0) {
      // Merge applications with each aircraft for gird view...
      this.companyAircraftApps = this.companyAircraft.map(
        (ac) => {
          return {
            aircraft: ac,
            availApplications: this.assignedApplications.filter(app => new Date(app.expiry_date) > new Date()) ?? [],
            selectedApplications: this.assignedApplications.filter((app) =>
              app.application_id === (aircraftApps.find((acResponse) =>
                acResponse.aircraft_id === ac.aircraft_id)?.applications.find((ap) =>
                  ap.application_id === app.application_id))?.application_id)
          }
        }
      );

      this.aircraftGroupsOptions = this.populateDropdownItems<AircraftGroup>(aircraftGroups, 'aircraftgroup_name', 'aircraftgroup_id');
      this.aircraftGroups = aircraftGroups;
    }

    this.settings = settings ?? [];
    this.updateBreadcrumb(Components.CompanyDetails.label + ' (' + this.company.code + ')');
  }

  populateDropdownItems<T>(items: T[], labelProp: string, valueProp: string, addAllOption = true): SelectItem[] {
    const options = [];
    // Some error checking
    if (!items || items.length == 0) {
      return options;
    }

    const keys = Object.keys(items[0]);
    if (!keys.includes(labelProp) || !keys.includes(valueProp)) {
      return options;
    }

    items.forEach(i => {
      options.push({ label: i[labelProp], value: i[valueProp] });
    });

    SortUtil.sortByLabel(options);

    if (addAllOption) {
      options.splice(0, 0, { label: 'All', value: null });   // Insert empty item as first item
    }
    return options;
  }

  onCancel() {
    //
    this.router.navigate([Components.Company.path]).then();
  }

  onReset() {
    //
    this.ngOnInit();
  }

  onAppSourceChanged() {
    // Merge applications with each aircraft for gird view...
    this.companyAircraftApps = this.companyAircraft.map(
      (aircraft) => {
        return {
          aircraft: aircraft,
          availApplications: this.assignedApplications,
        }
      }
    );
  }

  onAircraftAppsChanged(updatedAircraftApps: AircraftApplication[]) {
    this.companyAircraftApps = updatedAircraftApps;
  }

  onSubmit(event: OrgFormChanges) {
    this.loadingCount++;
    const queryParamExists = !!this.route.snapshot.queryParamMap.get('c'); // c = company_id


    const assignedApps = this.assignedApplications.map((app) => {
      return {
        application_id: app.application_id,
        expiry_date: app.expiry_date,
        number_of_users: app.number_of_users,
        number_of_aircrafts: app.number_of_aircrafts
      }
    })
    // Map settings to setting pair
    const settingPairs: SettingPair[] = [];
    this.settings.forEach(s => settingPairs.push({ settingdefinition_id: s.settingdefinition_id, setting_value: s.setting_value }));

    if (this.canAdd && !queryParamExists) {
      const aircraftAppsPost = this.companyAircraftApps.map(
        (aircraft) => {
          return {
            aircraft_id: aircraft.aircraft.aircraft_id,
            selectedApplications: aircraft.selectedApplications
          }
        });
      const addCompanyPayload = {
        id: this.company.id,
        code: this.company.code,
        name: this.company.name,
        web_logo: this.company.web_logo,
        mobile_logo: this.company.mobile_logo,
        company_settings: settingPairs
      }

      // make a call to save
      this.companyService.addCompany(addCompanyPayload)
        .pipe(concatMap(id => {
          this.company.id = id;
          const sources = [
            event.apps === true ? this.applicationService.assignAppsToOrganization(this.company.id, assignedApps, OrganizationType.Company) : of(null),
            event.aircraftApps === true ? this.applicationService.updateCompanyAircraftApps(aircraftAppsPost) : of(null)
          ];
          return forkJoin(sources);
        }), takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: () => {
            this.updateBreadcrumb(Components.CompanyDetails.label + ' (' + this.company.code + ')');
            this.router.navigate([], {
              relativeTo: this.route,
              queryParams: {
                c: this.company.id
              },
              queryParamsHandling: 'merge'
            }).then();
          },
          error: err => {
            this.showErrorMsg(err, Action.Add, `${this.messageLabel}`);
            this.loadingCount--;
          },
          complete: () => {
            this.isNew = false;
            this.codeIsReadOnly = true;
            this.organizationService.statusChange({ status: Status.Success, message: `Company ${this.company.name} added` });
            this.loadingCount--;
          }
        });
    } else if (this.canEdit && queryParamExists) {
      const aircraftAppsPost = this.companyAircraftApps.map(
        (aircraft) => {
          return {
            aircraft_id: aircraft.aircraft.aircraft_id,
            selectedApplications: aircraft.selectedApplications
          }
        });
      const updateCompanyPayload = {
        id: this.company.id, name: this.company.name, web_logo: this.company.web_logo, mobile_logo: this.company.mobile_logo,
      }
      this.companyService.updateCompany(updateCompanyPayload)
        .pipe(concatMap(() => {
          const sources = [
            event.apps === true ? this.applicationService.updateOrganizationApps(this.company.id, assignedApps, OrganizationType.Company) : of(null),
            event.settings === true || this.isNew ? this.settingsService.updateSetting(settingPairs, OrganizationType.Company, this.company.id) : of(null),
            event.aircraftApps === true ? this.applicationService.updateCompanyAircraftApps(aircraftAppsPost) : of(null)
          ];
          return forkJoin(sources);
        }), takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: () => {
            this.updateBreadcrumb(Components.Company.label + ' (' + this.company.code + ')');
            this.router.navigate([], {
              relativeTo: this.route,
              queryParams: {
                c: this.company.id
              },
              queryParamsHandling: 'merge'
            }).then();
          },
          error: err => {
            this.showErrorMsg(err, Action.Update, `${this.messageLabel}`, `${this.company.name}`);
            this.loadingCount--;
          },
          complete: () => {
            this.isNew = false;
            this.organizationService.statusChange({ status: Status.Success, message: `Company ${this.company.name} updated` });
            this.loadingCount--;
            this.ngOnInit();
          }
        });
    }
    else {
      const currentCompanyPayload = {
        id: this.company.id,
        name: this.company.name,
        web_logo: this.company.web_logo,
        mobile_logo: this.company.mobile_logo
      }
      this.companyService.updateCurrentCompany(currentCompanyPayload)
        .pipe(concatMap(() => {
          //this.company.id = id;
          const sources = [
            event.settings === true || this.isNew ? this.settingsService.updateCurrentOrgSetting(settingPairs, OrganizationType.Company) : of(null)
          ]
          return forkJoin(sources)
        }), takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: () => {
            this.updateBreadcrumb(Components.Company.label + ' (' + this.company.code + ')');
            this.router.navigate([], {
              relativeTo: this.route,
              queryParams: {
                c: this.company.id
              },
              queryParamsHandling: 'merge'
            }).then();
          },
          error: err => {
            this.showErrorMsg(err, Action.Update, `${this.messageLabel}`, `${this.company.name}`);
            this.loadingCount--;
          },
          complete: () => {
            this.isNew = false;
            this.organizationService.statusChange({ status: Status.Success, message: `Company ${this.company.name} updated` });
            this.loadingCount--;
            this.ngOnInit();
          }
        });
    }
  }
}
