<p-blockUI [blocked]="loadingCount > 0">
  <tdy-spinner style="position:absolute;left:50%;top:50%;z-index: 10000"></tdy-spinner>
</p-blockUI>
<div class="card" *ngIf="loadingCount==0">
  <div class="card-header m-2">
    <h5>{{pageTitle}}</h5>
    <p-dropdown [options]="file_dates" [(ngModel)]="selected_date" optionLabel="dte" [filter]="true"
      (onChange)="onChange()" tooltip="Filter by AIRAC date" filterBy="dte" [showClear]="false"
      placeholder="Select a date">
      <ng-template pTemplate="selectedItem">
        <div *ngIf="selected_date">
          <div>{{selected_date.dte}}</div>
        </div>
      </ng-template>
      <ng-template let-filedate pTemplate="item">
        <div class="country-item">
          <div>{{filedate.dte}}</div>
        </div>
      </ng-template>
    </p-dropdown>
  </div>

  <div class="card">
    <div class=" grid">
      <div class="lg:col-12 p-0 m-0">
        <div class="custom-panel m-2 p-4 mb-0">
          <h6>Details</h6>
          <div class="grid" *ngIf="data">
            <div class="col"> <span>Runway Identifier: </span></div>
            <div class="col"> {{data.trimmed_runway_identifier}} </div>
            <div class="col"> <span>Runway Description: </span></div>
            <div class="col"> {{data.runway_description || "-"}} </div>
            <div class="col"> <span>Cycle date: </span></div>
            <div class="col"> {{data.cycle_date || "-"}} </div>
          </div>
          <div class="grid" *ngIf="data">
            <div class="col"> <span>Runway Surface: </span> </div>
            <div class="col"> {{data.c_runway_surface || "-"}} </div>
            <div class="col"> <span>Runway Use: </span> </div>
            <div class="col"> {{data.c_runway_use || "-"}} </div>
            <div class="col"></div>
            <div class="col"></div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="card">
    <div class=" grid">
      <div class="lg:col-12 p-0 m-0">
        <div class="custom-panel m-2 p-4 mb-0">
          <h6>Glideslope</h6>
          <div class="grid" *ngIf="data">
            <div class="col"><span>Glideslope Latitude: </span></div>
            <div class="col">{{data.pi_glide_slope_latitude !== null ? (data.pi_glide_slope_latitude | number:'1.8-8') :
              '-' }} </div>
            <div class="col"><span>Glideslope Longitude: </span></div>
            <div class="col">{{data.pi_glide_slope_longitude !== null ? (data.pi_glide_slope_longitude | number:'1.8-8')
              : '-'}} </div>
            <div class="col"><span>Glideslope Position: </span></div>
            <div class="col">{{data.pi_glide_slope_position || "-" }} </div>
          </div>
          <div class="grid" *ngIf="data">
            <div class="col"><span>Glideslope Angle: </span></div>
            <div class="col">{{data.pi_glide_slope_angle || "-"}} </div>
            <div class="col"><span>Glideslope Height at Landing Threshold: </span></div>
            <div class="col">{{data.pi_glide_slope_height_at_landing_threshold ||
              "-"}}{{data.pi_glide_slope_height_at_landing_threshold ? ' ft' : ''}} </div>
            <div class="col"><span>Glideslope Elevation: </span></div>
            <div class="col">{{data.pi_glide_slope_elevation || "-"}}{{data.pi_glide_slope_elevation ? ' ft' : ''}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="card">
    <div class=" grid">
      <div class="lg:col-12 p-0 m-0">
        <div class="custom-panel m-2 p-4 mb-0">
          <h6>Localizer</h6>
          <div class="grid" *ngIf="data">
            <div class="col"><span>ILS Category: </span></div>
            <div class="col"> {{data.pi_ils_category || "-"}} </div>
            <div class="col"><span>Localizer Frequency: </span></div>
            <div class="col">{{data.pi_localizer_frequency || "-"}} </div>
            <div class="col"><span>Localizer Latitude: </span></div>
            <div class="col">{{data.pi_localizer_latitude !== null ? (data.pi_localizer_latitude | number:'1.8-8') :
              '-'}} </div>
          </div>
          <div class="grid" *ngIf="data">
            <div class="col"><span>Localizer Longitude: </span></div>
            <div class="col"> {{data.pi_localizer_longitude !== null ? (data.pi_localizer_longitude | number:'1.8-8') :
              '-'}} </div>
            <div class="col"><span>Localizer Bearing: </span></div>
            <div class="col">{{data.pi_localizer_bearing || "-"}} </div>
            <div class="col"><span>Localizer Position: </span></div>
            <div class="col">{{data.pi_localizer_position || "-"}} </div>
          </div>
          <div class="grid" *ngIf="data">
            <div class="col"><span>Localizer Position Reference: </span></div>
            <div class="col">{{data.pi_localizer_position_reference || "-"}}</div>
            <div class="col"><span>Localizer Width: </span></div>
            <div class="col">{{data.pi_localizer_width || "-"}}{{data.pi_localizer_width ? ' ft' : ''}} </div>
            <div class="col"><span>Station Declination: </span></div>
            <div class="col">{{data.pi_station_declination || "-"}} </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="card">
    <div class=" grid">
      <div class="lg:col-12 p-0 m-0">
        <div class="custom-panel m-2 p-4 mb-0">
          <h6>Locator</h6>
          <div class="grid" *ngIf="data">
            <div class="col"><span>Locator Latitude: </span></div>
            <div class="col">{{data.pm_locator_latitude !== null ? (data.pm_locator_latitude | number:'1.8-8') : '-'}}
            </div>
            <div class="col"><span>Locator Longitude: </span></div>
            <div class="col">{{data.pm_locator_longitude !== null ? (data.pm_locator_longitude | number:'1.8-8') : '-'}}
            </div>
            <div class="col"><span>Locator Class: </span></div>
            <div class="col">{{data.pm_locator_class || "-"}} </div>
          </div>
          <div class="grid" *ngIf="data">
            <div class="col"><span>Locator Facility Characteristics: </span></div>
            <div class="col">{{data.pm_locator_facility_characteristics || "-"}} </div>
            <div class="col"><span>Locator Identifier: </span></div>
            <div class="col">{{data.pm_locator_identifier || "-"}} </div>
            <div class="col"><span>Magnetic Variation: </span></div>
            <div class="col">{{data.pm_magnetic_variation || "-"}} </div>
          </div>
          <div class="grid" *ngIf="data">
            <div class="col"><span>Locator Frequency: </span></div>
            <div class="col">{{data.pm_locator_frequency || "-"}} </div>
            <div class="col"></div>
            <div class="col"></div>
            <div class="col"></div>
            <div class="col"></div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="card">
    <div class=" grid">
      <div class="lg:col-12 p-0 m-0">
        <div class="custom-panel m-2 p-4 mb-0">
          <h6>Markers</h6>
          <div class="grid" *ngIf="data">
            <div class="col"><span>Marker Type: </span></div>
            <div class="col"> {{data.pm_marker_type || "-"}} </div>
            <div class="col"><span>Marker Latitude: </span></div>
            <div class="col">{{data.pm_marker_latitude !== null ? (data.pm_marker_latitude | number:'1.8-8') : '-'}}
            </div>
            <div class="col"><span>Marker Longitude: </span></div>
            <div class="col">{{data.pm_marker_longitude !== null ? (data.pm_marker_longitude | number:'1.8-8') : '-'}}
            </div>
          </div>
          <div class="grid" *ngIf="data">
            <div class="col"><span>Minor Axis Bearing: </span></div>
            <div class="col">{{data.pm_minor_axis_bearing || "-"}}</div>
            <div class="col"></div>
            <div class="col"></div>
            <div class="col"></div>
            <div class="col"></div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="card">
    <div class=" grid">
      <div class="lg:col-12 p-0 m-0">
        <div class="custom-panel m-2 p-4 mb-0">
          <h6>Measurements</h6>
          <div class="grid" *ngIf="data">
            <div class="col"><span>Runway Gradient: </span> </div>
            <div class="col">{{data.runway_gradient || "-"}} </div>
            <div class="col">TORA Day:</div>
            <div class="col">{{data.c_tora_day || "-"}}<span *ngIf="data.c_lda_day"> ft</span></div>
            <div class="col">TORA Night:</div>
            <div class="col">{{data.c_tora_night || "-"}}<span *ngIf="data.c_lda_day"> ft</span></div>
          </div>

          <div class="grid">
            <div class="col"><span>Runway Width: </span></div>
            <div class="col">{{data.runway_width || "-"}}{{data.runway_width ? ' ft' : ''}}</div>
            <div class="col">TODA Day:</div>
            <div class="col">{{data.c_toda_day || "-"}}<span *ngIf="data.c_lda_day"> ft</span></div>
            <div class="col">TODA Night:</div>
            <div class="col">{{data.c_toda_night || "-"}}<span *ngIf="data.c_lda_day"> ft</span></div>
          </div>

          <div class="grid">
            <div class="col"><span>Runway Length: </span> </div>
            <div class="col">{{data.runway_length || "-"}}{{data.runway_length ? ' ft' : ''}} </div>
            <div class="col">ASDA Day:</div>
            <div class="col">{{data.c_asda_day || "-"}}<span *ngIf="data.c_lda_day"> ft</span></div>
            <div class="col">ASDA Night:</div>
            <div class="col">{{data.c_asda_night || "-"}}<span *ngIf="data.c_lda_day"> ft</span></div>
          </div>

          <div class="grid">
            <div class="col"><span>Stopway: </span></div>
            <div class="col">{{data.stopway || "-"}}{{data.stopway ? ' ft' : ''}} </div>
            <div class="col">LDA Day:</div>
            <div class="col">{{data.c_lda_day || "-"}}<span *ngIf="data.c_lda_day"> ft</span> </div>
            <div class="col">LDA Night:</div>
            <div class="col">{{data.c_lda_night || "-"}}<span *ngIf="data.c_lda_day"> ft</span></div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="card">
    <div class=" grid">
      <div class="lg:col-12 p-0 m-0">
        <div class="custom-panel m-2 p-4 mb-0">
          <h6>Position</h6>
          <div class="grid" *ngIf="data">
            <div class="col"><span>Runway Magnetic Bearing: </span></div>
            <div class="col">{{data.runway_magnetic_bearing || "-"}} </div>
            <div class="col"><span>Runway Latitude: </span></div>
            <div class="col">{{(data.runway_latitude || "-") | number:'1.8-8'}} </div>
            <div class="col"><span>Runway Longitude: </span></div>
            <div class="col">{{data.runway_longitude || "-" | number:'1.8-8'}} </div>
          </div>
          <div class="grid" *ngIf="data">
            <div class="col"> <span>Traffic Pattern: </span></div>
            <div class="col">{{data.c_traffic_pattern || "-"}} </div>
            <div class="col"></div>
            <div class="col"></div>
            <div class="col"></div>
            <div class="col"></div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="card">
    <div class=" grid">
      <div class="lg:col-12 p-0 m-0">
        <div class="custom-panel m-2 p-4 mb-0">
          <h6>Threshold</h6>
          <div class="grid" *ngIf="data">
            <div class="col"><span>Landing Threshold Elevation: </span></div>
            <div class="col">{{(data.landing_threshold_elevation || "-")|
              removeLeadingZeros}}{{(data.landing_threshold_elevation ? ' ft' : '') | removeLeadingZeros}}</div>
            <div class="col"><span>Displaced Threshold Distance: </span></div>
            <div class="col">{{data.displaced_threshold_distance || "-"}}{{data.displaced_threshold_distance ? ' ft' :
              ''}}</div>
            <div class="col"><span>Threshold Crossing Height: </span></div>
            <div class="col">{{data.threshold_crossing_height || "-"}}{{data.threshold_crossing_height ? ' ft' : ''}}
            </div>
          </div>
          <div class="grid" *ngIf="data">
            <div class="col"><span>TCH Value Indicator: </span></div>
            <div class="col">{{data.tch_value_indicator || "-"}}{{data.tch_value_indicator ? ' ft' : ''}}</div>
            <div class="col"></div>
            <div class="col"></div>
            <div class="col"></div>
            <div class="col"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="8">No runway found.</td>
    </tr>
  </ng-template>
</div>
