import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { switchMap } from 'rxjs';
import { BreadcrumbService } from 'src/app/common/services/breadcrumb.service';
import { EnRouteVHFNavaidService } from 'src/app/features/administration/services/enroute-vhf-navaid.service';
import { ApiType, NavigationInformationBaseViewComponent } from '../navigation-information-base/navigation-information-base-view.component';
import { Components } from 'src/app/features/administration/integration/administration.components';
import { Action } from 'src/app/common/components/base-view.component';

@Component({
  selector: 'app-en-route-vhf-navaids',
  templateUrl: './enroute-vhf-navaids.component.html'

})
export class EnrouteVHFNavaidsComponent extends NavigationInformationBaseViewComponent implements OnInit {
  pageTitle = Components.EnRouteVHFNavaidsList.label;
  selected_date: any;
  file_dates = [];

  constructor(
    private enRouteVHFService: EnRouteVHFNavaidService,
    messageService: MessageService,
    confirmationService: ConfirmationService,
    router: Router,
    route: ActivatedRoute,
    breadcrumbService: BreadcrumbService
  ) {

    super(messageService, confirmationService, router, breadcrumbService, route)
    this.setBreadcrumb([{ label: Components.EnRouteVHFNavaidsList.label }]);
    this.selected_date = route.snapshot.queryParamMap.get('a');
  }

  ngOnInit(): void {
    this.setBreadcrumb([{ label: Components.EnRouteVHFNavaidsDetails.label }]);
    this.loadingCount++;
    this.enRouteVHFService.getDatesOfFiles()
      .pipe(
        switchMap(dte => {
          let lst = dte.map(this.dateToMDY).sort(this.comparefn);
          lst = lst.map(x => {
            x = this.dateToDMY(x);
            this.file_dates.push({ "dte": x });
            return x;
          })
          let selected_dte = "";
          if (!this.selected_date)
            selected_dte = lst[lst.length - 1];
          else
            selected_dte = this.selected_date;

          this.selected_date = { "dte": selected_dte };
          return this.enRouteVHFService.getEnrouteVHFNavaids(selected_dte);
        })
      ).subscribe(
        {
          next: data => this.data = data,
          error: err => {
            this.showErrorMsg(err, Action.Get, `en-route vhf waypoints`);
            this.loadingCount--;
          },
          complete: () => this.loadingCount--
        }
      )

  }
  getEnrouteVHFNavaidList(data) {
    const arg: ApiType = {
      request: this.enRouteVHFService.getEnrouteVHFNavaids(data),
      api_name: 'enroute vhf navaids',
      callback: this.callback.bind(this)
    }
    this.callAPI(arg);

  }
  callback(data) {

    this.data = data;
  }
  onRowSelect($event) {

    const { icao_code, dbpn_nbd_identifier } = $event.data;

    this.router.navigate(
      [Components.EnRouteVHFNavaidsDetails.path], {
      queryParams: {
        a: icao_code,
        b: dbpn_nbd_identifier,
        c: this.selected_date["dte"],
        d: JSON.stringify(this.file_dates)
      }
    }
    )
  }

  override onChange(): void {
    super.onChange([Components.EnRouteVHFNavaidsDetails.path], {
      queryParams: { a: this.selected_date["dte"] }
    });
  }
}
