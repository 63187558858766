import { Directive, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService, ConfirmationService, Message } from 'primeng/api';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { Action, BaseViewComponent } from 'src/app/common/components/base-view.component';
import { FeatureItem } from 'src/app/common/models/feature-item.model';
import { BreadcrumbService } from 'src/app/common/services/breadcrumb.service';
import { Components } from 'src/app/features/configuration/integration/configuration.components';
import { permissions } from 'src/app/features/configuration/integration/configuration.permissions';
import { DataFrameService } from 'src/app/features/configuration/services/data-frame.service';
import { TransferdataService } from 'src/app/features/configuration/services/transferdata.service';
import { SecurityUserService } from 'src/app/security/services/security-user.service';
import { getProp } from '../shared/utils';
import { ListViewColumnType } from '../shared/helpers/data-frame.enums';

@Directive()
export abstract class ListView extends BaseViewComponent implements OnInit {
  routeLinkPath: string;
  frameId: number;
  versionId: number;
  showMessage = false;
  messageLabel: string;
  mnemonicLabel = 'mnemonic';
  dfPropId: string;
  deletedMnemonics: any[] = [];
  messages!: Message[];
  dataFrame: any;
  columns: Array<any>;

  dataFrameMapView: FeatureItem;
  dataFrameDetails: FeatureItem;
  dataFrameAdd: FeatureItem;
  dataFrameEdit: FeatureItem;
  dataFrameClone: FeatureItem;
  dataFrameAnalyse: FeatureItem;

  constructor(
    messageService: MessageService,
    confirmationService: ConfirmationService,
    router: Router,
    breadcrumbService: BreadcrumbService,
    protected route: ActivatedRoute,
    protected appService: TransferdataService,
    protected dataFrameService: DataFrameService,
    protected realUserService: SecurityUserService
  ) {
    super(messageService, confirmationService, router, breadcrumbService);
  }

  ngOnInit() {
    this.setBreadCrumbs();
    this.userCanManage = this.realUserService.userHasPermission(permissions.dataframes.data_frame_manage);
    this.userCanView = this.realUserService.userHasPermission(permissions.dataframes.data_frame_view);
    this.getDataFrame(this.versionId);

    if (this.userCanManage) {
      this.columns.push(...[
        {
          field: 'clone_Button',
          header: '',
          colType: ListViewColumnType.button,
          cssClass: '',
          icon: 'pi pi-clone',
          toolTip: 'Clone...',
          onClickAction: (data) => this.onClone(getProp(data, this.dfPropId)),
        },
        {
          field: 'showDlg_Button',
          header: '',
          colType: ListViewColumnType.button,
          cssClass: 'p-button-danger',
          icon: 'pi pi-trash',
          toolTip: 'Delete...',
          onClickAction: this.showDeleteDialog.bind(this),
        },
      ]);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  abstract getDataFrame(versionId: number);

  setBreadCrumbs() {
    if (this.getQueryParam('frame')) {
      this.breadcrumbService.setItems(this.route, [
        {
          label: Components.Dataframe.label,
          routerLink: Components.Dataframe.path,
          queryParams: {
            a: this.getQueryParam('frame'),
            Name: this.getQueryParam('Name'),
          },
        },
        {
          label: `Versions (${this.getQueryParam('Name')})`,
          routerLink: Components.DataframeVersions.path,
          queryParams: {
            a: this.getQueryParam('frame'),
            Name: this.getQueryParam('Name'),
          },
        },
        {
          label: this.getQueryParam('Name') + ` (Version ${this.getQueryParam('version_index')})`,
          routerLink: this.routeLinkPath,
        },
      ]);
      this.frameId = parseInt(this.getQueryParam('frame'));
      this.versionId = parseInt(this.getQueryParam('version'));
    }
  }

  navigateToDFDetails() {
    this.navigateToPath(this.dataFrameDetails.path, {
      screen_Name: this.getQueryParam('Framedetails'),
    });
  }

  onAddDataFrame() {
    this.navigateToPath(this.dataFrameAdd.path, { 'add': 1 });
  }

  navigateToMapView() {
    this.navigateToPath(this.dataFrameMapView.path, {});
  }

  navigateToAnalyseDF() {
    this.navigateToPath(this.dataFrameAnalyse.path, {
      editName: this.dataFrameAnalyse.label,
    });
  }

  onEdit(editName: string) {
    this.navigateToPath(this.dataFrameEdit.path, { editName: editName });
  }

  onClone(editName: string) {
    this.navigateToPath(this.dataFrameClone.path, { editName: editName, 'clone': 1 });
  }

  navigateToPath(path: string, extraQueryParams: any) {
    const queryParams = {
      Name: this.getQueryParam('Name'),
      frame: this.getQueryParam('frame'),
      version: this.getQueryParam('version'),
      version_index: this.getQueryParam('version_index'),
    };
    this.router.navigate([path], {
      queryParams: { ...queryParams, ...extraQueryParams },
      state: {},
    });
  }

  onCancel() {
    this.showMessage = false;
    this.appService.clearSessionStorage();
    this.router
      .navigate([Components.DataframeVersions.path], {
        queryParams: {
          a: this.getQueryParam('frame'),
          Name: this.getQueryParam('Name'),
        },
      })
      .then();
  }

  resetDataFrame() {
    this.showMessage = false;
    this.appService.clearSessionStorage();
    this.getDataFrame(this.versionId);
  }

  showDeleteDialog(row: any) {
    this.confirmationService.confirm({
      message: `Are you sure that you want to delete ${getProp(row, this.dfPropId)} Mnemonic?`,
      accept: () => {
        this.showSuccessMsg(Action.Delete, `${this.mnemonicLabel}`, `${getProp(row, this.dfPropId)}`);
        this.deleteRecord(row);
      },
    });
  }

  abstract deleteRecord(deleteRow: any);
  abstract onMnemonicDelete();
  abstract updatedFrameDetails();
  abstract updatedParameters();
  abstract onDPUpdates();

  deleteMnemonic(deleteItem: any) {
    this.loadingCount++;
    this.deletedMnemonics.push(deleteItem);
    sessionStorage.setItem('DeletedMnemonics', JSON.stringify(this.deletedMnemonics));
    this.onMnemonicDelete();
    this.messages = [
      {
        severity: 'warn',
        summary: '',
        detail: 'Data Frame Editor changes is not yet saved. click to save',
      },
    ];
    this.showMessage = true;
    this.loadingCount--;
  }

  updateDataFrame(frameId: number, versionId: number, response: string) {
    this.loadingCount++;
    this.dataFrameService
      .updateDataFrame(frameId, response)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res) => {
          this.showSuccessMsg(
            Action.Update,
            `${this.messageLabel}`,
            `${JSON.stringify(res.message).replace(/"/g, '')}`
          );
          this.appService.clearSessionStorage();
          this.router
            .navigate([Components.DataframeVersions.path], {
              queryParams: {
                a: this.getQueryParam('frame'),
                Name: this.getQueryParam('Name'),
              },
            })
            .then();
        },
        error: (error) => {
          this.showErrorMsg(error, Action.Update, `${this.messageLabel}`);
          this.showMessage = false;
          this.loadingCount--;
        },
        complete: () => {
          this.showMessage = false;
          this.loadingCount--;
        },
      });
  }

  updatedMnemonics() {
    this.checkFrameDetailsUpdate();
    this.checkParametersUpdate();
    this.checkMnemonicDeleteUpdate();
    this.onDPUpdates();
  }

  checkFrameDetailsUpdate() {
    const updatedframe = JSON.parse(sessionStorage.getItem('FrameDetails'));
    if (updatedframe != (undefined || null)) {
      this.messages = [
        {
          severity: 'warn',
          summary: '',
          detail: 'Data Frame Editor changes is not yet saved. click to save',
        },
      ];
      this.showMessage = true;
      this.updatedFrameDetails();
    }
  }

  checkParametersUpdate() {
    const updatedRow = JSON.parse(sessionStorage.getItem('Mnemonics'));
    if (updatedRow != (undefined || null)) {
      this.messages = [
        {
          severity: 'warn',
          summary: '',
          detail: 'Data Frame Editor changes is not yet saved. click to save',
        },
      ];
      this.showMessage = true;
      this.updatedParameters();
    }
  }

  checkMnemonicDeleteUpdate() {
    const deleteRow = JSON.parse(sessionStorage.getItem('DeletedMnemonics'));
    if (deleteRow != (undefined || null)) {
      this.messages = [
        {
          severity: 'warn',
          summary: '',
          detail: 'Data Frame Editor changes is not yet saved. click to save',
        },
      ];
      this.showMessage = true;
      this.onMnemonicDelete();
    }
  }

  saveDataFrame() {
    this.showMessage = false;
    this.updateDataFrame(this.frameId, this.versionId, this.dataFrame);
  }

  getQueryParam(queryParam: string): string {
    return this.route.snapshot.queryParamMap.get(queryParam);
  }
}
