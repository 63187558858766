<div class="col-12">
  <p-table
    id="tl"
    sortField="name"
    #tl
    [value]="SARredactions"
    (onRowSelect)="onRowSelect($event)"
    selectionMode="single"
    [showCurrentPageReport]="true"
    [paginator]="SARredactions.length > defaultTableRows"
    [rowsPerPageOptions]="[20, 50, 100, 200]"
    [rows]="defaultTableRows"
    currentPageReportTemplate="{{ currentPageReport + ' ' + pageTitle }}"
    [globalFilterFields]="[
      'name',
      'description',
      'conversiontype_name'
    ]"
    dataKey="dataredaction"
  >
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let col of cols" [pSortableColumn]="col.field">
          <div
            class="flex justify-content-start align-items-center"
            *ngIf="col.header != 'Action'"
          >
            {{ col.header }}
            <p-sortIcon [field]="col.field"></p-sortIcon>
            <p-columnFilter
              [showAddButton]="false"
              [showOperator]="false"
              class="ml-0"
              display="menu"
              [field]="col.field"
              type="text"
              matchMode="contains"
            ></p-columnFilter>
          </div>
          <div *ngIf="col.header == 'Action'"></div>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-sar>
      <tr [pSelectableRow]="sar" class="p-selectable-row">
        <td>{{ sar.name }}</td>
        <td>{{ sar.description }}</td>
        <td>{{ sar.conversiontype_name }}</td>
        <td style="width: 0;">
          <div class="flex align-items-center justify-content-end">
            <button
              id="cloneButton"
              name="cloneButton"
              type="button"
              class="p-button-rounded p-button-text mr-2"
              pButton
              icon="pi pi-clone"
              pRipple
              pTooltip="Clone..."
              *ngIf="canAdd"
              (click)="cloneSAR(sar)"
            ></button>
          </div>
        </td>
        <td style="width: 0;">
          <div class="flex align-items-center justify-content-end">
            <button
              id="deleteButton"
              name="deleteButton"
              type="button"
              *ngIf="canDelete"
              class="p-button-rounded p-button-text p-button-danger"
              pButton
              pRipple
              icon="pi pi-trash"
              pTooltip="Delete..."
              (click)="deleteSAR(sar)"
            ></button>
          </div>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td [attr.colspan]="7">No SAR Redactions Found.</td>
      </tr>
    </ng-template>
  </p-table>
</div>
