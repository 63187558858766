import { Component, OnInit, Pipe, PipeTransform } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/common/services/breadcrumb.service';
import { RunwayService } from 'src/app/features/administration/services/runway.service';
import { ApiType, NavigationInformationBaseViewComponent } from '../navigation-information-base/navigation-information-base-view.component';
import { Components } from 'src/app/features/administration/integration/administration.components';

//Custom Pipe to remove the leading zeros
@Pipe({
  name: 'removeLeadingZeros'
})
export class RemoveLeadingZerosPipe implements PipeTransform {
  transform(value: string): string {
    if (typeof value !== 'string') {
      return value;
    }

    return value.replace(/^0+/, '');
  }
}

@Component({
  selector: 'app-runway-view',
  templateUrl: './runway-details.component.html',
  providers: [RemoveLeadingZerosPipe]
})

export class RunwayDetailsComponent extends NavigationInformationBaseViewComponent implements OnInit {
  pageTitle = Components.RunwayDetails.label;
  selected_date: any;
  file_dates: any;
  display_icao_iata: string;
  icao: string;
  runway_identifier: string;
  procedureBreadcrumb: any;

  constructor(
    private runwayService: RunwayService,
    messageService: MessageService,
    confirmationService: ConfirmationService,
    router: Router,
    route: ActivatedRoute,
    breadcrumbService: BreadcrumbService) {
    super(messageService, confirmationService, router, breadcrumbService, route)
    this.icao = route.snapshot.queryParamMap.get('a');
    this.display_icao_iata = route.snapshot.queryParamMap.get('b');
    const dte = route.snapshot.queryParamMap.get('c');
    const file_dates = route.snapshot.queryParamMap.get('d');
    this.runway_identifier = route.snapshot.queryParamMap.get('e')
    this.procedureBreadcrumb = JSON.parse(route.snapshot.queryParamMap.get('f'));

    this.selected_date = { "dte": dte };
    this.file_dates = JSON.parse(file_dates);
    this.getRunway(dte, this.icao, this.runway_identifier);
    this.setBreadcrumb();
  }

  ngOnInit(): void {
    this.setBreadcrumb()
  }

  override onChange() {
    super.onChange([Components.RunwayDetails.path], {
      queryParams: {
        a: this.icao,
        b: this.display_icao_iata,
        c: this.selected_date["dte"],
        d: JSON.stringify(this.file_dates),
        e: this.runway_identifier,
        f: JSON.stringify(this.procedureBreadcrumb)
      }
    });
  }

  getRunway(dte, icao, runway_identifier) {
    const arg: ApiType = {
      request: this.runwayService.getRunway(dte, icao, runway_identifier),
      api_name: 'Runway',
      callback: this.callback
    }
    this.callAPI(arg)
  }

  callback = (data) => this.data = data;


  trimRunwayIdentifier(runwayIdentifier: string): string {
    if (runwayIdentifier) {
      return runwayIdentifier.replace(/^RW/, '');
    }
    return '-';
  }


  override setBreadcrumb() {
    const trimRunwayIdentifier = (runwayIdentifier: string): string => {
      if (runwayIdentifier) {
        return runwayIdentifier.replace(/^RW/, '');
      }
      return '-';
    };

    if (this.procedureBreadcrumb) {
      super.setBreadcrumb(
        [
          {
            label: Components.AirportList.label,
            routerLink: Components.AirportList.path,
            queryParams: { a: this.selected_date["dte"] }
          },
          {
            label: Components.RunwayList.label + ` (${this.display_icao_iata})`,
            routerLink: Components.RunwayList.path,
            queryParams: {
              a: this.icao,
              b: this.display_icao_iata,
              c: this.selected_date["dte"],
              d: JSON.stringify(this.file_dates)
            }
          },
          {
            label: Components.ProceduresList.label + ` (${trimRunwayIdentifier(this.procedureBreadcrumb["transition_identifier"])})`,
            routerLink: Components.ProceduresList.path,
            queryParams: {
              a: this.icao,
              b: this.display_icao_iata,
              c: this.selected_date["dte"],
              d: JSON.stringify(this.file_dates),
              e: this.procedureBreadcrumb["transition_identifier"]
            }
          },
          {
            label: Components.ProcedureDetails.label + ` (${this.procedureBreadcrumb["sid_star_approach_identifier"] || "-"})`,
            routerLink: Components.ProcedureDetails.path,
            queryParams: {
              a: this.icao,
              b: this.display_icao_iata,
              c: this.selected_date["dte"],
              d: JSON.stringify(this.file_dates),
              e: this.procedureBreadcrumb["transition_identifier"],
              f: this.procedureBreadcrumb["procedure_id"],
              g: this.procedureBreadcrumb["sid_star_approach_identifier"]
            }
          },
          {
            label: Components.RunwayDetails.label + ` (${trimRunwayIdentifier(this.runway_identifier)})`
          }
        ]);
    }
    else {
      super.setBreadcrumb(
        [
          {
            label: Components.AirportList.label,
            routerLink: Components.AirportList.path,
            queryParams: { a: this.selected_date["dte"] }

          },
          {
            label: Components.RunwayList.label + ` (${this.display_icao_iata})`,
            routerLink: Components.RunwayList.path,
            queryParams: {
              a: this.icao,
              b: this.display_icao_iata,
              c: this.selected_date["dte"],
              d: JSON.stringify(this.file_dates)
            }
          },
          {
            label: Components.RunwayDetails.label + ` (${trimRunwayIdentifier(this.runway_identifier)})`
          }
        ]
      );
    }
  }
}
