import { Component, OnInit, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { forkJoin, takeUntil } from 'rxjs';
import {
  Action,
  BaseViewComponent,
} from 'src/app/common/components/base-view.component';
import { BreadcrumbService } from 'src/app/common/services/breadcrumb.service';
import { CommonService } from 'src/app/common/services/common.service';
import {
  ACMS,
  ACMSType,
  FileType,
  GetFileType,
  ResponseACMS,
  fileType,
  getRegex,
  jsonValues,
} from 'src/app/features/datadelivery/datadelivery/models/ACMS';
import { EndpointACMSRedactionService } from '../../../services/acmsRedaction.service';
import { Components } from '../../../integration/data-delivery-insights.components';

@Component({
  selector: 'app-acmsreport-data-details',
  templateUrl: './acmsreport-data-details.component.html',
  styleUrls: ['./acmsreport-data-details.component.css'],
})
export class AcmsreportDataDetailsComponent
  extends BaseViewComponent
  implements OnInit {
  public pageTitle = Components.ViewACMSReportRedactionsDataDelivery.label;
  public acmsreport!: ACMS;
  public acmsReportlist: ACMSType[] = [];
  public acmsType: ACMSType[] = [];
  public fileType: fileType[] = [];
  public jsonregex: getRegex;
  public isReadonly = true;
  public uniqueError = false;
  public avialableAcms: ACMSType[] = [];
  public selectedMapIDs: string[] = [];
  public picklistChange = false;
  public isButtonDisabled = true;
  public jsonParameters: jsonValues;
  public showButtons = true;
  public FileType = FileType;

  private getSelectedAcms: object;
  public acmsreports: ACMS[] = [];
  private selectedAcms: ACMSType[] = [];
  private editselectedMapIDs: string[] = [];
  private initialRecordersCount: number;
  private saveButtonDisabled: boolean;
  private selectAcmsValue: number;
  private responseACMS: ResponseACMS;
  private isClone: number;
  private messageLabel = 'ACMS Report Redaction';
  private newLabel = 'Add ACMS Report Redaction';

  constructor(
    router: Router,
    private route: ActivatedRoute,
    breadcrumbService: BreadcrumbService,
    confirmationService: ConfirmationService,
    messageService: MessageService,
    private endpointAcmsredaction: EndpointACMSRedactionService,
    private commonService: CommonService,
    private ngZone: NgZone
  ) {
    super(messageService, confirmationService, router, breadcrumbService);
    this.getBreadCrums();
  }

  intialVal() {
    this.pageTitle = this.newLabel;
    this.updateBreadcrumb(this.pageTitle);
    this.acmsreport = new ACMS();
    this.jsonParameters = new jsonValues();
    this.jsonregex = new getRegex();
    this.acmsreport.redaction_id = -1;
    this.isNew = true;
  }

  getBreadCrums() {
    this.breadcrumbService.setItems(this.route, [
      {
        label: Components.ViewACMSReportRedactionsDataDelivery.label,
        routerLink: Components.ViewACMSReportRedactionsDataDelivery.path,
      },
      {
        label:
          Components.ViewACMSReportRedactionsDataDelivery.label +
          ` (${this.route.snapshot.queryParamMap.get('Name')})`,
      },
    ]);
  }

  ngOnInit() {
    this.getBreadCrums();
    const redactionIdParam =
      this.route.snapshot.queryParamMap.get('redactionId');
    const redactionId = parseInt(redactionIdParam, 10);
    {
      if (redactionId) {
        this.handleRedactionId(redactionId);
      } else {
        this.handleNoRedactionId();
      }
    }

    this.fileType = GetFileType;
    this.jsonregex = new getRegex();
  }

  handleRedactionId(redactionId: number) {
    this.isNew = false;
    this.loadAcmsDetails(redactionId, false);
  }

  handleNoRedactionId() {
    this.loadAcmsMap();
    this.intialVal();
  }
  onCancel() {
    this.ngZone.run(() => {
      this.router.navigate([Components.ACMSReportRedactionsDataDelivery.path]);
    });
  }

  loadAcmsMap() {
    this.loadingCount++;
    this.saveButtonDisabled = true;
    forkJoin({
      avialableAcmsMap: this.commonService.getAcmsReportMapData(),
    })
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (results) => {
          //sorting to show in alphabetical order
          results.avialableAcmsMap.forEach((ele) => {
            this.acmsType.push({
              acms_id: ele.acmsreportmap_id,
              acms_name: ele.name,
            });
          });
        },
        error: (error) => {
          this.showErrorMsg(`${error}`, Action.Get, `${this.messageLabel}`);
          this.loadingCount--;
        },
        complete: () => {
          this.loadingCount--;
        },
      });
  }

  loadAcmsConfigDetails(mapId?: number) {
    this.startLoading();
    this.fetchAcmsConfigDetails(mapId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (results) => this.processAcmsConfigDetails(results),
        error: (error) => this.handleError(error),
        complete: () => this.completeLoading(),
      });
  }

  startLoading() {
    this.loadingCount++;
    this.saveButtonDisabled = true;
    this.acmsReportlist = [];
    this.avialableAcms = [];
  }

  fetchAcmsConfigDetails(mapId?: number) {
    return forkJoin({
      avialableAcms: this.commonService.getACMSMapReports(mapId),
    });
  }

  processAcmsConfigDetails(results: any) {
    // Sorting to show in alphabetical order
    results.avialableAcms.forEach((ele) => {
      this.acmsReportlist.push({ acms_name: ele });
    });
    this.acmsReportlist.forEach((ele) => {
      this.selectedAcms.push({ acms_name: ele.acms_name });
    });

    this.avialableAcms = [];

    this.selectedAcms.forEach((ele) => {
      this.selectedMapIDs.push(ele.acms_name);
    });
    this.filterAcmsMethods();

    this.updateInitialsRecordersCount();
    this.initialRecordersCount = this.selectedAcms.length;
    this.selectedMapIDs = [];
    this.acmsReportlist.sort((a, b) => {
      return a.acms_name.localeCompare(b.acms_name);
    });
  }

  updateInitialsRecordersCount() {
    this.acmsReportlist
      ? (this.initialRecordersCount = this.acmsReportlist.length)
      : (this.initialRecordersCount = 0);
  }

  filterAcmsMethods() {
    this.avialableAcms = this.avialableAcms.filter(
      (p) => this.selectedAcms.findIndex((i) => i.acms_id == p.acms_id) == -1
    );
  }

  handleError(error: any) {
    this.showErrorMsg(`${error}`, Action.Get, `${this.messageLabel}`);
    this.loadingCount--;
  }

  completeLoading() {
    this.loadingCount--;
  }

  loadAcmsDetails(redaction_id: number, clone: boolean) {
    this.loadingCount++;
    forkJoin({
      getacmsdetails: this.endpointAcmsredaction.getACMSRedaction_by_id(redaction_id),
      avialableAcmsMap: this.commonService.getAcmsReportMapData(),
    })
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (results) => {
          results.avialableAcmsMap.forEach((ele) => {
            this.acmsType.push({
              acms_id: ele.acmsreportmap_id,
              acms_name: ele.name,
            });
          });
          this.acmsreport = results.getacmsdetails;
          this.jsonParameters = JSON.parse(
            this.acmsreport.defaultparametervaluesconfig
          );
          if (this.jsonParameters.filetype_id == FileType.Teledyne) {
            const getMapJson = JSON.parse(this.acmsreport.mapjson);
            this.editselectedMapIDs = JSON.parse(getMapJson.acmsreports);
            this.jsonParameters = JSON.parse(
              this.acmsreport.defaultparametervaluesconfig
            );
            this.onEditloadAcmsDetails(this.acmsreport.acmsreportmap_id);
          } else {
            const getMapJson = JSON.parse(this.acmsreport.mapjson);
            this.jsonregex.regex = getMapJson.regex;
          }
          if (clone) {
            this.acmsreport.name = '';
          }
        },
        error: (error) => {
          this.showErrorMsg(`${error}`, Action.Get, `${this.messageLabel}s`);
          this.loadingCount--;
        },
        complete: () => {
          this.loadingCount--;
        },
      });
  }

  onEditloadAcmsDetails(mapId?: number) {
    this.loadingCount++;
    this.saveButtonDisabled = true;
    forkJoin({
      avialableAcms: this.commonService.getACMSMapReports(mapId),
    })
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (results) => {
          //acmsReportlist source
          this.avialableAcms = [];
          results.avialableAcms.forEach((ele) => {
            if (this.editselectedMapIDs.find((a) => a == ele)) {
              this.avialableAcms.push({ acms_name: ele });
              this.selectedAcms.push({ acms_name: ele });
            }
            this.acmsReportlist.push({ acms_name: ele });
          });

          this.selectedAcms.forEach((ele) => {
            this.selectedMapIDs.push(ele.acms_name);
          });

          //Filter the source Regex List
          if (this.acmsReportlist && this.avialableAcms) {
            this.acmsReportlist = this.acmsReportlist.filter(
              (p) =>
                this.selectedAcms.findIndex(
                  (i) => i.acms_name == p.acms_name
                ) == -1
            );
          }

          this.updateInitialsRecordersCount();
          this.initialRecordersCount = this.selectedAcms.length;
          this.sortAcmsReports();
        },
        error: (error) => {
          this.showErrorMsg(`${error}`, Action.Get, `${this.messageLabel}s`);
          this.loadingCount--;
        },
        complete: () => {
          this.loadingCount--;
        },
      });
  }

  sortAcmsReports() {
    this.acmsReportlist.sort((a, b) => {
      return a.acms_name.localeCompare(b.acms_name);
    });
    this.avialableAcms.sort((a, b) => {
      return a.acms_name.localeCompare(b.acms_name);
    });
  }
}
