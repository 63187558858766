<p-blockUI [blocked]="loadingCount > 0">
  <tdy-spinner style="position:absolute;left:50%;top:50%;z-index: 10000"></tdy-spinner>
</p-blockUI>
<p-confirmDialog header="Confirm Deletion" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<div class="col-12 card p-3">
  <div class="card-header">
    <h5>{{pageTitle}}</h5>
    <div class="p-toolbar-group-end">
      <button pButton pRipple label="Add" id="addButton" *ngIf="canAdd" icon="pi pi-plus" (click)="openNew()"></button>
    </div>
  </div>
  <p-table id="tl" #tl [value]="ddsAlerts" (onRowSelect)="onRowSelect($event)" selectionMode="single"
    [globalFilterFields]="['name', 'alert_type', 'time_to_look_back','rate']" sortField="name" dataKey="dataredaction"
    [showCurrentPageReport]="true" [paginator]="ddsAlerts.length > defaultTableRows" [rowsPerPageOptions]="[20,50,100,200]" [rows]="defaultTableRows" currentPageReportTemplate="{{currentPageReport + ' ' + pageTitle}}" 
    >
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th pSortableColumn="name">
          <div class="flex justify-content-start align-items-center">
          Name 
          <p-sortIcon field="name"></p-sortIcon>
          <p-columnFilter [showAddButton]="false" [showOperator]="false" class="ml-0"
                              display="menu" field="name" matchMode="contains"  type="text"></p-columnFilter>
          </div>
        </th>
        <th pSortableColumn="alert_type">
          <div class="flex justify-content-start align-items-center">
            Type of Alert 
            <p-sortIcon field="alert_type"></p-sortIcon>
            <p-columnFilter [showAddButton]="false" [showOperator]="false" class="ml-0"
                                display="menu" field="alert_type" matchMode="contains"  type="text"></p-columnFilter>
          </div>
        </th>
        <th pSortableColumn="time_to_look_back">
          <div class="flex justify-content-start align-items-center">
            Time to look back
          <p-sortIcon field="time_to_look_back"></p-sortIcon>
          <p-columnFilter [showAddButton]="false" [showOperator]="false" class="ml-0"
                              display="menu" field="time_to_look_back"  type="numeric"></p-columnFilter>
          </div>
        </th>
        <th pSortableColumn="rate">
          <div class="flex justify-content-start align-items-center">
          Rate
          <p-sortIcon field="rate"></p-sortIcon>
          <p-columnFilter [showAddButton]="false" [showOperator]="false" class="ml-0"
                              display="menu" field="rate"   type="numeric"></p-columnFilter>
         </div>
        </th>
        <th>
          <div class="flex justify-content-between align-items-center">
            
          </div>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-ddsAlert>
      <tr [pSelectableRow]="ddsAlert" class="p-selectable-row">
        <td>{{ ddsAlert.name }}</td>
        <td>{{ ddsAlert.alert_type }}</td>
        <td>{{ ddsAlert.time_to_look_back }}</td>
        <td>{{ ddsAlert.rate }}</td>
        <td>
          <div class="flex align-items-center justify-contents-end">
            <button id="deleteButton" name="deleteButton" type="button" *ngIf="canDelete"
              class="p-button-rounded p-button-text p-button-danger" pButton pRipple icon="pi pi-trash"
              pTooltip="Delete..."  (click)="deleteAlert(ddsAlert)"></button>
          </div>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td [attr.colspan]="6">No Data Delivery Alerts Found.</td>
      </tr>
    </ng-template>
  </p-table>
  <div *ngIf="ddsAlerts.length > 0 && defaultTableRows >= ddsAlerts.length" style="text-align: center;">
    <p class="row-paging">Showing 1 to
      {{ddsAlerts.length}} of {{ddsAlerts.length}} {{pageTitle}}</p>
  </div>
</div>
