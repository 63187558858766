import {
  AWS_Region,
  getEncryptType,
} from '../../datadelivery/models/ConfigEndPoints';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { Organization } from 'src/app/features/administration/models/organization';
import {
  EncryptType,
  EndPoints,
} from 'src/app/features/datadelivery/datadelivery/models/ConfigEndPoints';

@Component({
  selector: 'app-endpoints-form',
  templateUrl: './endpoints-form.component.html',
  styleUrls: ['./endpoints-form.component.scss'],
})
export class EndpointsFormComponent implements OnInit {
  @Input() canAdd: boolean;
  @Input() canDelete: boolean;
  @Input() canEdit: boolean;
  @Input() isNew = false;
  @Input() isReadonly = true;
  @Input() endpoint: EndPoints;
  @Input() jsonParameters: any;
  @Input() transferType: any[] = [];
  @Input() awsregion: AWS_Region[] = [];
  @Input() organizationList: Organization[] = [];

  @Output() submitForm = new EventEmitter<EndPoints>();
  @Output() cancelForm = new EventEmitter<void>();
  @Output() resetForm = new EventEmitter<void>();

  public uniqueError = false;
  public encrypttype: EncryptType[] = [];
  private selectedTypeId: number;

  @ViewChild('endpointForm')
  private endpointForm!: NgForm;

  constructor() {
    // Intentionally empty
  }

  ngOnInit(): void {
    this.encrypttype = this.sortEncrypt(getEncryptType);
  }

  onSubmit() {
    this.submitForm.emit(this.endpoint);
    this.endpointForm.resetForm(this.endpointForm.value);
  }

  onCancel() {
    this.cancelForm.emit();
  }

  onReset() {
    this.endpointForm.resetForm();
    this.resetForm.emit();
    this.ngOnInit();
  }

  valueChange(e: any) {
    this.selectedTypeId = e.value;
    this.endpoint.transfertype_id = this.selectedTypeId;
  }

  sortEncrypt(encryptionOptions: EncryptType[]) {
    return [...encryptionOptions].sort((a, b) => {
      const name = a.name.toLowerCase(),
        cname = b.name.toLowerCase();
      if (name < cname) {
        return -1;
      }
      if (name > cname) {
        return 1;
      }
      return 0;
    });
  }
}
