import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from '../../../../common/services/base.service';
import { catchError, map } from 'rxjs/operators';
import { environment } from "src/environments/environment";
import { EnhancedPackages, ResponseModelEnhancedPackages } from "../models/EnhancedPackage";
@Injectable({
    providedIn: 'root'
})
export class EnhancedPackagesService extends BaseService {

    constructor(
        private http: HttpClient) {
        super();
        this.baseUrl = environment.apiUrl.datadelivery;
    }

    getClientPackages(): Observable<EnhancedPackages[]> {
        const url = this.baseUrl + "enhancedpackages";
        return this.http.get<EnhancedPackages[]>(url, { headers: this.httpGetHeadersForJSON() })
            .pipe(map(enhancedpackage => enhancedpackage.map(r => {
                r.primary = Boolean(r.primary);
                r.secondary = Boolean(r.secondary);
                return r;
            })), catchError(this.httpHandleError)
            );
    }

    getClientPackage_by_id(package_id: number): Observable<EnhancedPackages> {
        const url = this.baseUrl + "enhancedpackages" + `/${package_id}`;
        return this.http.get<EnhancedPackages>(url, { headers: this.httpGetHeadersForJSON() })
            .pipe(catchError(this.httpHandleError)
            );
    }

    addClientPackage(clientpackages: ResponseModelEnhancedPackages) {
        const url = this.baseUrl + 'enhancedpackages';
        return this.http.post<number>(url, clientpackages, { headers: this.httpGetHeadersForJSON() })
            .pipe(
                catchError(this.httpHandleError)
            )
    }

    updateClientPackage(clientpackages: ResponseModelEnhancedPackages) {
        const url = this.baseUrl + 'enhancedpackages';
        return this.http.put<number>(url, clientpackages, { headers: this.httpGetHeadersForJSON() })
            .pipe(
                catchError(this.httpHandleError)
            )
    }

    deleteClientPackage(package_id: number) {
        const url = this.baseUrl + 'enhancedpackages/' + `${package_id}`;
        const options = { headers: this.httpGetHeadersForJSON() }
        return this.http.delete(url, options)
            .pipe(catchError(this.httpHandleError));
    }

}
