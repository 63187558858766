import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { combineLatest, firstValueFrom, of } from 'rxjs';
import { RouteSecurityService } from '../services/route-security.service';
import { SecurityAuthService } from '../services/security-auth.service';
import { SecurityUserService } from '../services/security-user.service';

@Injectable()
export class AuthGuard implements CanActivate {

  declare Zone: any;

  constructor(
    private router: Router,
    private authService: SecurityAuthService,
    private userService: SecurityUserService,
    private routeGuardService: RouteSecurityService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    //Due to how early this guard logic called on page load
    //we have to wait for user info to update asyncronously rather than use userService.currentUserInfo

    var latest = combineLatest([this.userService.userInfo, of(true)]);
    return firstValueFrom(latest).then(([userInfo]) => {

      // redirect to signin if user is not authenticated
      if (!userInfo.isAuthenticated) {
          this.authService.signIn(state.url).then();
          return false;
      }

      // strip parameters from the url
      let routeUrl = state.url;
      if(!state.url.startsWith('/users-administration') && !state.url.startsWith('/roles-administration') )
         routeUrl = this.getUrlWithoutParams(state.url);
      // get required access permission for the route
      const permission = this.routeGuardService.getPermissionForRoute(routeUrl);
      if (permission === undefined) {
        //route is either unprotected or isn't defined in MenuService
        return true;
      }

      // check against permission for the route
      if (!this.userService.userHasPermission(permission)) {
        this.router.navigateByUrl("/access-denied");
        return false;
      }

      return true;
    });
  }

  getUrlWithoutParams(url: string){
    let urlTree = this.router.parseUrl(url);
    urlTree.queryParams = {};
    urlTree.fragment = null;
    return urlTree.toString();
 }
}
