import { Component, NgZone, OnInit } from '@angular/core';
import { EndPoints } from '../../models/ConfigEndPoints';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Router, ActivatedRoute } from '@angular/router';
import { Components } from '../../integration/datadelivery.components';
import { BreadcrumbService } from '../../../../../common/services/breadcrumb.service';
import { Action, BaseViewComponent } from 'src/app/common/components/base-view.component';
import { SecurityUserService } from 'src/app/security/services/security-user.service';
import { DataDeliveryEndPointService } from '../../services/endpoint.service';
import {
  Observable,
  Subject,
  catchError,
  of,
  startWith,
  switchMap,
  takeUntil,
} from 'rxjs';
import { permissions } from '../../integration/datadelivery.permissions';

@Component({
  selector: 'app-endpoints',
  templateUrl: './endpoints.component.html',
  styleUrls: ['./endpoints.component.scss'],
})
export class EndpointsComponent extends BaseViewComponent implements OnInit {
  pageTitle = Components.Endpoints.label;
  public canAdd: boolean;
  public canDelete: boolean;
  public canEdit: boolean;
  endpoints$: Observable<EndPoints[]>;
  private refreshEndpoints$ = new Subject<void>();
  messageLabel = 'Endpoint';

  constructor(
    router: Router,
    private route: ActivatedRoute,
    breadcrumbService: BreadcrumbService,
    confirmationService: ConfirmationService,
    messageService: MessageService,
    private dataDeliveryEndPoints: DataDeliveryEndPointService,
    private realUserService: SecurityUserService,
    private ngZone: NgZone
  ) {
    super(messageService, confirmationService, router, breadcrumbService);
    this.getBreadCrums();
  }

  getBreadCrums() {
    this.breadcrumbService.setItems(this.route, [
      {
        label: Components.Endpoints.label,
        routerLink: Components.Endpoints.path,
      },
    ]);
  }

  ngOnInit(): void {
    this.getBreadCrums();
    this.canAdd = this.realUserService.userHasPermission(
      permissions.endpoint.create
    );
    this.canDelete = this.realUserService.userHasPermission(
      permissions.endpoint.delete
    );
    this.canEdit = this.realUserService.userHasPermission(
      permissions.endpoint.manage
    );
    this.loadEndpoint();
  }

  loadEndpoint() {
    this.endpoints$ = this.refreshEndpoints$.pipe(
      startWith([]),
      switchMap(() => this.dataDeliveryEndPoints.getEndPoints()),
      catchError((err) => {
        this.showErrorMsg(`${err}`, Action.Get, `${this.messageLabel}s`);
        return of([]);
      })
    );
  }

  refreshEndpoints() {
    this.refreshEndpoints$.next();
  }

  deleteEndPoint(endpoint: EndPoints) {
    if (!this.canDelete) {
      this.showErrorMsg(
        'User is not authorized to delete Endpoint.',
        Action.Delete,
        `${this.messageLabel}`
      );
    } else {
      this.confirmationService.confirm({
        message: 'Are you sure that you want to delete the selected Endpoint?',
        accept: () => {
          this.dataDeliveryEndPoints
            .deleteEndpoint(endpoint.endpoint_id)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
              next: () => {
                this.showSuccessMsg(
                  Action.Delete,
                  `${this.messageLabel}`,
                  `${endpoint.name}`
                );
                // Refresh the list of Endpoint
                this.refreshEndpoints();
              },
              error: (error) => {
                this.showErrorMsg(
                  `${error}`,
                  Action.Delete,
                  `${this.messageLabel}s`
                );
              },
            });
        },
      });
    }
  }

  openNew() {
    if (!this.canAdd) {
      this.showErrorMsg(
        'User is not authorized to add an Endpoint.',
        Action.Add,
        `${this.messageLabel}`
      );
    } else {
      this.ngZone.run(() => {
        this.router.navigate([Components.AddEndpoints.path]);
      });
    }
  }

  onRowSelect(event) {
    this.ngZone.run(() => {
      this.router.navigate([Components.AddEndpoints.path], {
        state: { endptdata: event },
        queryParams: {
          endpointId: event.endpoint_id,
          name: event.name,
        },
      });
    });
  }
}
