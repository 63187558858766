import { Component, ViewChild, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DataDeliveryAlerts, Datadelivery_alert_type, ResponseDDSAlerts, ClientAuthentication, AlertTypes } from '../../models/DatadeliveryAlerts';
import { Router, ActivatedRoute } from '@angular/router';
import { Components } from '../../integration/datadelivery.components';
import { BreadcrumbService } from '../../../../../common/services/breadcrumb.service';
import { Action, BaseViewComponent } from 'src/app/common/components/base-view.component';
import { DataDeliveryAlertService } from '../../services/DataDeliveryAlert.service';
import { forkJoin, takeUntil } from 'rxjs';
import { CommonService } from 'src/app/common/services/common.service';
import { SecurityUserService } from 'src/app/security/services/security-user.service';
import { permissions } from '../../integration/datadelivery.permissions';
import { ClientApplicationService } from '../../services/clientApplication.service';
@Component({
  selector: 'app-datadeliveryalert-details',
  templateUrl: './datadeliveryalert-details.component.html',
  styleUrls: ['./datadeliveryalert-details.component.css']
})
export class DataDeliveryAlertDetailsComponent extends BaseViewComponent implements OnInit {
  @ViewChild('DataDeliveryAlertForm') DataDeliveryAlertForm!: NgForm
  pageTitle = Components.DataDeliveryAlertDetails.label;
  public canAdd: boolean;
  public canDelete: boolean;
  public canEdit: boolean;
  public modalDialog?: boolean;
  public submitted = false;
  isDataFrame = false;
  uniqueError = false;
  showButtons = true;
  today = new Date();
  todayAsStr = `${this.today.getFullYear()}-${this.today.getMonth()}-${this.today.getDate()}`;
  cols: any[] = [];
  ddsalerts: DataDeliveryAlerts[] = [];
  ddsalert!: DataDeliveryAlerts;
  isButtonDisabled = true;
  initialRecordersCount: number;
  saveButtonDisabled: boolean;
  isReadonly = true;
  responsealerts: ResponseDDSAlerts;
  alerttype: Datadelivery_alert_type[] = [];
  messageLabel = "data delivery alerting";
  min_timelookback: number;
  max_timelookback: number;
  lblTime: string;
  selectedClientAuthIDs: number[] = [];
  picklistChange = false;
  pickClientAuthlistChange = false;
  isClientAuthButtonDisabled = true;
  availableClient: ClientAuthentication[] = [];
  selectedClient: ClientAuthentication[] = [];
  initialClientCount: number;
  intialVal() {
    this.pageTitle = "New Data Delivery Alert";
    this.updateBreadcrumb(this.pageTitle);
    this.ddsalert = new DataDeliveryAlerts;
    this.ddsalert.alert_id = -1;
    this.isNew = true;
  }

  constructor(router: Router,
    private route: ActivatedRoute,
    breadcrumbService: BreadcrumbService, confirmationService: ConfirmationService,
    messageService: MessageService,
    private dataDeliveryAlertService: DataDeliveryAlertService, private commonService: CommonService,
    private realUserService: SecurityUserService, private clientApplicationService: ClientApplicationService) {
    super(messageService, confirmationService, router, breadcrumbService);
    this.getBreadCrums();

  }
  getBreadCrums() {
    this.breadcrumbService.setItems(this.route, [{ label: Components.DataDeliveryAlert.label, routerLink: Components.DataDeliveryAlert.path }, {
      label: Components.DataDeliveryAlertDetails.label + ` (${this.route.snapshot.queryParamMap.get('name')})`
    }]);
  }
  ngOnInit(): void {
    this.getBreadCrums();
    this.canAdd = this.realUserService.userHasPermission(permissions.datadelivery_recording_alert.create);
    this.canDelete = this.realUserService.userHasPermission(permissions.datadelivery_recording_alert.delete);
    this.canEdit = this.realUserService.userHasPermission(permissions.datadelivery_recording_alert.manage);
    const alertId = +this.route.snapshot.queryParamMap.get('alertId');
    if (alertId) {
      this.isNew = false;
      this.loadddsAlertDetails(alertId);
      !this.canEdit ? (this.isReadonly = true) : (this.isReadonly = false);
      !this.canEdit ? (this.showButtons = false) : (this.showButtons = true);
    } else {
      !this.canAdd ? this.isReadonly = true : this.isReadonly = false;
      !this.canAdd ? this.showButtons = false : this.showButtons = true;
      this.intialVal();
      this.loadAlertDetails();
    }
  }

  toclientauths(clientauth: ClientAuthentication): ClientAuthentication {
    return clientauth;
  }

  loadddsAlertDetails(alertId: number) {
    this.loadingCount++;
    forkJoin({
      alertType: this.dataDeliveryAlertService.getAlertType(),
      getalertdetails: this.dataDeliveryAlertService.getAlerts_by_id(alertId),
      avaliableClientAuth: this.clientApplicationService.getClientApplications()
    }).pipe(
      takeUntil(this.ngUnsubscribe)).subscribe({
        next: results => {
          this.alerttype = results.alertType;
          this.ddsalert = results.getalertdetails;
          this.ddsalert.enable_flag = this.ddsalert.enable_flag == true ? true : false;
          this.availableClient = [];
          this.setTime(this.ddsalert.alert_type_id);
          this.ddsalert.clientauths.forEach(ele => {
            this.selectedClient.push({ client_auth_id: ele.client_auth_id, clientname: ele.clientname })
          });
          results.avaliableClientAuth.forEach(ele => {
            this.availableClient.push({ client_auth_id: ele.id, clientname: ele.Name })
          });
          this.selectedClient.forEach(ele => {
            this.selectedClientAuthIDs.push(ele.client_auth_id)
          })
          if (this.availableClient && this.selectedClient) {
            this.availableClient = this.availableClient.filter(p => this.selectedClient.findIndex(i => i.client_auth_id == p.client_auth_id) == -1)
          }
          this.ddsalert.clientauths ? this.initialClientCount = this.ddsalert.clientauths.length : this.initialClientCount = 0;
          this.initialClientCount = this.selectedClient.length;
          this.availableClient.sort((a, b) => { return a.clientname.localeCompare(b.clientname) });
          this.alerttype.sort((a, b) => { return a.alert_type.localeCompare(b.alert_type) });
        }, error: error => {
          this.showErrorMsg(`${error}`, Action.Get, `${this.messageLabel}s`);
          this.loadingCount--;
        }, complete: () => {
          this.loadingCount--;
        }
      })
  }

  loadAlertDetails() {
    this.loadingCount++;
    this.ddsalert.clientauths = [];
    forkJoin({
      alertType: this.dataDeliveryAlertService.getAlertType(),
      clientAuth: this.clientApplicationService.getClientApplications()
    }).pipe(
      takeUntil(this.ngUnsubscribe)).subscribe({
        next: results => {
          this.availableClient = [];
          //sorting to show in alphabetical order
          this.alerttype = results.alertType;
          results.clientAuth.forEach(ele => {
            this.availableClient.push({ client_auth_id: ele.id, clientname: ele.Name })
          })
          this.availableClient.sort((a, b) => { return a.clientname.localeCompare(b.clientname) });
          this.alerttype.sort((a, b) => { return a.alert_type.localeCompare(b.alert_type) });
        },
        error: error => {
          this.showErrorMsg(`${error}`, Action.Get, `${this.messageLabel}s`);
          this.loadingCount--;
        },
        complete: () => {
          this.loadingCount--;
        }
      });
  }

  onAlertTypeChange(event) {
    this.setTime(event.value);
  }

  setTime(param: number) {
    if (param === AlertTypes.ProcessingFailures) {
      this.lblTime = "Minute(s)"
      this.min_timelookback = 1;
      this.max_timelookback = 1440;
    } else {
      this.lblTime = "Hour(s)"
      this.min_timelookback = 1;
      this.max_timelookback = 168;
    }
  }


  onCancel() {
    if (this.DataDeliveryAlertForm.dirty) {
      this.router.navigate([Components.DataDeliveryAlert.path]);
    } else {
      this.router.navigate([Components.DataDeliveryAlert.path]);
    }
  }

  onReset() {
    this.DataDeliveryAlertForm.resetForm();
    this.availableClient = [];
    this.selectedClientAuthIDs = [];
    this.ngOnInit();
    this.isButtonDisabled = true;
  }

  onMoveToTarget(event) {
    event.items.forEach(ele => {
      this.selectedClientAuthIDs.push(ele.client_auth_id);
    });
    this.isButtonDisabled = false;
    this.selectedClientAuthIDs = [...new Set(this.selectedClientAuthIDs)]; // remove the duplicate entries
    this.picklistChange = true;
  }


  onPicklistChange(event) {
    this.picklistChange = true;
    this.isButtonDisabled = false;
  }

  onMoveAllToSource(event) {
    event.items.forEach((ele) => {
      const index = this.selectedClientAuthIDs.indexOf(ele.client_auth_id);
      if (index > -1) {
        this.selectedClientAuthIDs.splice(index, 1);
      }
    });
    this.picklistChange = true;
    this.isButtonDisabled = false;
  }

  onMoveAllToTarget(event) {
    this.picklistChange = true;
    this.isButtonDisabled = false;
    event.items.forEach(ele => {
      this.selectedClientAuthIDs.push(ele.client_auth_id);
    });
  }

  onMoveToSource(event) {
    this.picklistChange = true;
    this.isButtonDisabled = false;
    event.items.forEach(ele => {
      const index = this.selectedClientAuthIDs.indexOf(ele.client_auth_id);
      if (index > -1) {
        this.selectedClientAuthIDs.splice(index, 1);
      }
    });
  }



  OnSubmit() {
    this.loadingCount++;
    this.submitted = true;
    this.responsealerts = {
      alert_id: this.ddsalert.alert_id,
      alert_type_id: this.ddsalert.alert_type_id,
      name: this.ddsalert.name,
      enable_flag: this.ddsalert.enable_flag == undefined ? false : this.ddsalert.enable_flag,
      organization_id: 0,
      rate: this.ddsalert.rate,
      time_to_look_back: this.ddsalert.time_to_look_back,
      client_auth_id: this.selectedClientAuthIDs
    }
    if (this.isNew) {
      this.dataDeliveryAlertService.addRecordingAlert(this.responsealerts).pipe(
        takeUntil(this.ngUnsubscribe)).subscribe({
          next: result => {
            this.ddsalert.alert_id = result;
            // Update query param
            this.router.navigate([], {
              relativeTo: this.route,
              queryParams: {
                redactionId: this.ddsalert.alert_id,
                name: this.ddsalert.name
              },
              queryParamsHandling: 'merge'
            }).then();
            this.updateBreadcrumb(Components.DataDeliveryAlertDetails.label + ' (' + this.ddsalert.name + ')');
            this.picklistChange = false;
          },
          error: err => {
            this.showErrorMsg(`${err}`, Action.Add, `${this.messageLabel}s`);
            this.submitted = true;
            this.loadingCount--;
            this.DataDeliveryAlertForm.resetForm(this.DataDeliveryAlertForm.value);
          },
          complete: () => {
            this.showSuccessMsg(Action.Add, `${this.messageLabel}`, `${this.ddsalert.name}`);
            this.isNew = false;
            this.DataDeliveryAlertForm.resetForm(this.DataDeliveryAlertForm.value);
            this.loadingCount--;
          }
        });
    } else {
      this.dataDeliveryAlertService.updateRecordingAlert(this.responsealerts).pipe(
        takeUntil(this.ngUnsubscribe)).subscribe({
          next: () => {
            this.updateBreadcrumb(Components.DataDeliveryAlertDetails.label + ' (' + this.ddsalert.name + ')');
            this.picklistChange = false;
          },
          error: err => {
            this.showErrorMsg(`${err}`, Action.Update, `${this.messageLabel}s`);
            this.loadingCount--;
          },
          complete: () => {
            this.showSuccessMsg(Action.Update, `${this.messageLabel}`, `${this.ddsalert.name}`);
            this.loadingCount--;
          }
        });
    }
  }
}
