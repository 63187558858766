import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbService } from 'src/app/common/services/breadcrumb.service';
import { MessageService, ConfirmationService } from 'primeng/api';
import { RecorderService } from 'src/app/features/configuration/services/recorders.service';
import { RecorderConfigModel, RecorderFinalModel, RecorderType } from 'src/app/features/configuration/models/recorderConfig';
import { Action, BaseViewComponent } from 'src/app/common/components/base-view.component';
import { NgForm } from '@angular/forms';
import { forkJoin, takeUntil } from 'rxjs';
import { RecorderRegexService } from 'src/app/features/configuration/services/recorder-regex.service';
import { RegexPair } from 'src/app/features/configuration/models/recorderRegex';
import { RecorderFormat, SubframeSize, DataPacking, MiniFrame, RecorderBlockMode, Regex } from 'src/app/features/configuration/models/recorderConfig';
import { SecurityUserService } from 'src/app/security/services/security-user.service';
import { permissions } from '../../integration/configuration.permissions';
import { Components } from '../../integration/configuration.components';

@Component({
  selector: 'app-recorder-config-details',
  templateUrl: './recorder-details.component.html',
  styleUrls: ['./recorder-details.component.scss']
})
export class RecorderDetailsComponent
  extends BaseViewComponent
  implements OnInit {
  recorderConfigModel: RecorderConfigModel;
  finalRecorderConfigModel: RecorderFinalModel;
  recorderType: RecorderType[] = [];
  format: RecorderFormat[] = [];
  subFrameSize: SubframeSize[] = [];
  dataPacking: DataPacking[] = [];
  miniFrames: MiniFrame[] = [];
  saveButtonDisabled: boolean;
  blockMode: RecorderBlockMode[] = [];
  showBlockMode: boolean;
  pageTitle = Components.RecordersDetails.label;
  availableRegexes: RegexPair[];
  selectedRegexes: RegexPair[] = [];
  selectedRegexIDs: number[] = [];
  recorderID: number;
  initialRecordersCount: number;
  isButtonDisabled = true;
  picklistChange = false;
  showDataPacking = false;
  isReadonly = true;
  showButtons = true;
  messageLabel = "recorder";

  public canAdd: boolean;
  public canDelete: boolean;
  public canEdit: boolean;

  @ViewChild('recorderConfigForm') recorderConfigForm: NgForm;
  constructor(
    private recordeRegexService: RecorderRegexService,
    router: Router,
    private route: ActivatedRoute,
    private realUserService: SecurityUserService,
    messageService: MessageService,
    breadcrumbService: BreadcrumbService,
    confirmationService: ConfirmationService,
    private recorderService: RecorderService
  ) {
    super(messageService, confirmationService, router, breadcrumbService);
    this.breadcrumbService.setItems(route, [
      {
        label: Components.Recorders.label,
        routerLink: Components.Recorders.path,
      },
      //dynamically populate the Breadcrum with Recorder's name in the bracket
      { label: (this.route.snapshot.queryParamMap.get('name')) ? Components.RecordersDetails.label + ` (${this.route.snapshot.queryParamMap.get('name')})` : Components.RecordersDetails.label },
    ]);
  }

  ngOnInit(): void {
    this.showBlockMode = false;
    this.canAdd = this.realUserService.userHasPermission(permissions.recorders.create);
    this.canDelete = this.realUserService.userHasPermission(permissions.recorders.delete);
    this.canEdit = this.realUserService.userHasPermission(permissions.recorders.manage);
    const recorderID = +this.route.snapshot.queryParamMap.get('recorderID'); //converting string to number
    if (recorderID) {
      !this.canEdit ? this.isReadonly = true : this.isReadonly = false;
      !this.canEdit ? this.showButtons = false : this.showButtons = true;

      // this.loadRecorderConfigDetails(recorderID);

      setTimeout(() => {
        this.loadRecorderConfigDetails(recorderID);
      }, 900);
    } else {
      !this.canAdd ? this.isReadonly = true : this.isReadonly = false;
      !this.canAdd ? this.showButtons = false : this.showButtons = true;
      this.addRecorders();
    }

  }

  addRecorders() {//when adding a new recorder
    this.loadingCount++;
    this.updateBreadcrumb('New Recorder'); //update the bread crumb with the title New
    this.pageTitle = 'New Recorder';
    this.isNew = true;
    this.recorderConfigModel = new RecorderConfigModel();
    this.recorderConfigModel.recorder_id = -1;
    this.recorderConfigModel.regexes = [];
    forkJoin({
      availableRegex: this.recordeRegexService.listRecorderRegexes(),
      recorderTypes: this.recorderService.getRecorderTypes(),
      format: this.recorderService.getRecorderFormat(),
      subFrameSize: this.recorderService.getSubFrameSize(),
      miniframes: this.recorderService.getMiniFrames(),
      datapacking: this.recorderService.getRecorderDataPacking(),
      recoderBlockMode: this.recorderService.getBlockMode(),
    }).pipe(
      takeUntil(this.ngUnsubscribe)).subscribe({
        next: results => {
          this.recorderType = results.recorderTypes.sort((a, b) => a.type.localeCompare(b.type));
          this.format = results.format.sort((a, b) => a.name.localeCompare(b.name));
          this.subFrameSize = results.subFrameSize.sort((a, b) => parseInt(a.name, 10) - parseInt(b.name, 10));  // have to parseInt as the values returned from the API are string instead of number.;
          this.miniFrames = results.miniframes;
          this.dataPacking = results.datapacking.sort((a, b) => a.datapacking_type.localeCompare(b.datapacking_type));
          this.blockMode = results.recoderBlockMode.sort((a, b) => a.blockmode_name.localeCompare(b.blockmode_name));
          this.availableRegexes = []
          results.availableRegex.forEach(ele => {
            this.availableRegexes.push({ regex_id: ele.regex_id, regex_name: ele.name })

          });
          this.availableRegexes.sort((a, b) => a.regex_name.localeCompare(b.regex_name)); //sort the regex array in ascending order
          this.initialRecordersCount = 0;
        },
        error: error => {
          this.showErrorMsg(`${error}`, Action.Get, `${this.messageLabel}`);
          this.loadingCount--;
        },
        complete: () => {
          this.loadingCount--;
        }
      }
      );
  }

  loadRecorderConfigDetails(recorderID: number) {//if modifying the existing recorder
    !this.canEdit ? this.isReadonly = true : this.isReadonly = false;
    this.loadingCount++;
    this.isNew = false;
    this.saveButtonDisabled = true;
    forkJoin({
      recorderTypes: this.recorderService.getRecorderTypes(),
      format: this.recorderService.getRecorderFormat(),
      subFrameSize: this.recorderService.getSubFrameSize(),
      miniframes: this.recorderService.getMiniFrames(),
      datapacking: this.recorderService.getRecorderDataPacking(),
      availableRegex: this.recordeRegexService.listRecorderRegexes(),
      recoderBlockMode: this.recorderService.getBlockMode(),
      recorderDetails: this.recorderService.getRecorderDetails(recorderID)
    }).pipe(
      takeUntil(this.ngUnsubscribe)).subscribe({
        next: results => {
          //sorting to show in alphabetical order
          this.recorderType = results.recorderTypes.sort((a, b) => a.type.localeCompare(b.type));
          this.format = results.format.sort((a, b) => a.name.localeCompare(b.name));
          this.subFrameSize = results.subFrameSize.sort((a, b) => parseInt(a.name, 10) - parseInt(b.name, 10));  // have to parseInt as the values returned from the API are string instead of number.
          this.miniFrames = results.miniframes.sort();
          this.dataPacking = results.datapacking.sort((a, b) => a.datapacking_type.localeCompare(b.datapacking_type));
          this.recorderConfigModel = results.recorderDetails;
          this.blockMode = results.recoderBlockMode.sort((a, b) => a.blockmode_name.localeCompare(b.blockmode_name));
          this.recorderConfigModel.regexes.forEach(ele => {
            this.selectedRegexes.push({ regex_id: ele.regex_id, regex_name: ele.regex_name })
          });

          this.recorderConfigModel.regexes.sort((a, b) => a.regex_name.localeCompare(b.regex_name)) //sorting the regexes in the ascending order

          this.availableRegexes = [];
          const temp_data = []
          results.availableRegex.forEach(ele => {
            temp_data.push({ regex_id: ele.regex_id, regex_name: ele.name })
          });

          this.selectedRegexes.forEach(ele => {
            this.selectedRegexIDs.push(ele.regex_id)
          });


          const selectedRegexIds = new Set(this.selectedRegexes.map(item => item.regex_id));
          const itemsNotInSelectedRegexes = temp_data.filter(item => !selectedRegexIds.has(item.regex_id));
          this.availableRegexes = itemsNotInSelectedRegexes
          this.availableRegexes.sort((a, b) => a.regex_name.localeCompare(b.regex_name)); //sort the regex array

          this.showBlockMode = this.recorderConfigModel.blockmode_id !== null && this.recorderConfigModel.blockmode_id !== undefined;
          this.showDataPacking = this.recorderConfigModel.datapacking_id !== null && this.recorderConfigModel.datapacking_id !== undefined && this.recorderConfigModel.recordingstandard_id === 19; //TDY NoTags should get datapacking displayed
          this.recorderConfigModel.regexes ? this.initialRecordersCount = this.recorderConfigModel.regexes.length : this.initialRecordersCount = 0;
          this.initialRecordersCount = this.selectedRegexes.length;
        },
        error: error => {
          this.showErrorMsg(`${error}`, Action.Get, `${this.messageLabel}`);
          this.loadingCount--;
        },
        complete: () => {
          this.loadingCount--;
        }
      }
      );
  }

  onChangeRecorderType(event) {
    const tempRecodertype = this.recorderType.find(ele => ele.recordertype_id == event.value);
    if (tempRecodertype !== null) {
      this.showBlockMode = tempRecodertype.type === 'L3 FA1000';
      this.showDataPacking = tempRecodertype.type === 'TDY NoTags';
    }
    else {
      this.showBlockMode = false;
      this.showDataPacking = false;
    }
  }

  onChangeFormat(event) {
    const tempFormat = this.format.find(ele => ele.recorderformat_id == event.value);
  }

  onReset() {
    this.picklistChange = false;
    this.selectedRegexes = [];
    this.selectedRegexIDs = [];
    this.ngOnInit();
    this.recorderConfigForm.resetForm();
    this.isButtonDisabled = true;
  }

  toRecorderRegexes(regexes: Regex): Regex {
    return regexes;
  }

  onMoveToTarget(event) {
    event.items.forEach(ele => {
      this.selectedRegexIDs.push(ele.regex_id);
    });
    this.isButtonDisabled = false;
    this.selectedRegexIDs = [...new Set(this.selectedRegexIDs)]; // remove the duplicate entries
    this.selectedRegexIDs.length > 0 ? this.isButtonDisabled = false : this.isButtonDisabled = true
    this.picklistChange = true;
  }

  onPicklistChange() {
    this.picklistChange = true;
    this.isButtonDisabled = false;
  }

  onMoveAllToSource(event) {
    event.items.forEach((ele) => {
      const index = this.selectedRegexIDs.indexOf(ele.regex_id);
      if (index > -1) {
        this.selectedRegexIDs.splice(index, 1);
      }
    });
    this.picklistChange = true;
    this.selectedRegexIDs.length > 0 ? this.isButtonDisabled = false : this.isButtonDisabled = true
  }

  onMoveAllToTarget(event) {
    this.picklistChange = true;
    this.isButtonDisabled = false;
    event.items.forEach(ele => {
      this.selectedRegexIDs.push(ele.regex_id);
    });

    this.selectedRegexIDs.length > 0 ? this.isButtonDisabled = false : this.isButtonDisabled = true

  }

  onMoveToSource(event) {
    this.picklistChange = true;
    this.isButtonDisabled = false;

    event.items.forEach(ele => {
      const index = this.selectedRegexIDs.indexOf(ele.regex_id);
      if (index > -1) {
        this.selectedRegexIDs.splice(index, 1);
      }
    });
    this.selectedRegexIDs.length > 0 ? this.isButtonDisabled = false : this.isButtonDisabled = true
  }

  onCancel() {
    if (this.recorderConfigForm.dirty) {
      this.confirmCancel(Components.Recorders.path);
    }
    else {
      this.router.navigate([Components.Recorders.path]).then();
    }
  }

  onSubmit() {
    this.loadingCount++;
    const finalObject = {
      recordertype_id: this.recorderConfigModel.recordertype_id,
      datapacking_id: this.recorderConfigModel.datapacking_id,
      miniframe_id: this.recorderConfigModel.miniframe_id == undefined ? this.recorderConfigModel.miniframe_id = null : this.recorderConfigModel.miniframe_id,
      subframesize_id: this.recorderConfigModel.subframesize_id,
      blockmode_id: this.recorderConfigModel.blockmode_id == undefined ? this.recorderConfigModel.blockmode_id = null : this.recorderConfigModel.blockmode_id,
      recorderformat_id: this.recorderConfigModel.recorderformat_id,
      name: this.recorderConfigModel.name,
      regex_id: [...new Set(this.selectedRegexIDs)],
      recorder_id: this.recorderConfigModel.recorder_id,
      data_level: this.recorderConfigModel.data_level
    }

    if (this.isNew) {
      this.recorderService.addNewRecorder(finalObject).pipe(
        takeUntil(this.ngUnsubscribe)).subscribe({
          next: result => {
            this.recorderConfigModel.recorder_id = result;
            // Update query param
            this.router.navigate([], {
              relativeTo: this.route,
              queryParams: {
                recorderID: this.recorderConfigModel.recorder_id
              },
              queryParamsHandling: 'merge'
            }).then();

            this.updateBreadcrumb(Components.RecordersDetails.label + ' (' + this.recorderConfigModel.name + ')');

          },
          error: err => {
            this.showErrorMsg(`${err}`, Action.Add, `${this.messageLabel}`, `${this.recorderConfigModel.name}`);
            this.loadingCount--;
            this.isButtonDisabled = true;
            this.saveButtonDisabled = true;
          },
          complete: () => {
            this.showSuccessMsg(Action.Add, `${this.messageLabel}`, `${this.recorderConfigModel.name}`);
            this.isNew = false;
            this.pageTitle = Components.RecordersDetails.label;

            this.isButtonDisabled = true;
            this.recorderConfigModel.regexes.length = null;
            this.saveButtonDisabled = true;
            this.loadRecorderConfigDetails(this.recorderConfigModel.recorder_id)
            this.loadingCount--;
            this.recorderConfigForm.resetForm(this.recorderConfigForm.value);
            this.recorderConfigForm.resetForm(this.recorderConfigForm.value);
          }
        });
    } else {
      this.recorderService.updateRecorder(finalObject, this.recorderConfigModel.recorder_id).pipe(
        takeUntil(this.ngUnsubscribe)).subscribe({
          next: () => {
            this.updateBreadcrumb(Components.RecordersDetails.label + ' (' + this.recorderConfigModel.name + ')');
          },
          error: err => {
            this.showErrorMsg(`${err}`, Action.Update, `${this.messageLabel}`, `${this.recorderConfigModel.name}`);
            this.loadingCount--;
            this.isButtonDisabled = true;
            this.saveButtonDisabled = true;
          },
          complete: () => {
            // this.loadRecorderConfigDetails(this.recorderConfigModel.recorder_id)
            // this.ngOnInit()
            this.picklistChange = false;
            this.showSuccessMsg(Action.Update, `${this.messageLabel}`, `${this.recorderConfigModel.name}`);
            this.loadingCount--;
            this.isButtonDisabled = true;
            this.recorderConfigForm.resetForm(this.recorderConfigForm.value);
            // this.ngOnInit();

          }
        });
    }
  }
}
