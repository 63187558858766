import { Component } from '@angular/core';
import { Root } from 'src/app/features/configuration/models/dataframe717-767';
import { Router, ActivatedRoute } from '@angular/router';
import { DataFrameService } from 'src/app/features/configuration/services/data-frame.service';
import { BreadcrumbService } from 'src/app/common/services/breadcrumb.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Components } from '../../../integration/configuration.components';
import { SecurityUserService } from 'src/app/security/services/security-user.service';
import { FrameDetails } from '../../dataframe-acms/dataframe/frame-details/frame-details.component';
import { DFDetailsInputCategory717, FormType } from '../../dataframe-acms/dataframe/shared/enums/formEnums';
import { InputConfiguration } from '../../dataframe-acms/dataframe/shared/models/form.models';
import { getProp } from '../../dataframe-acms/dataframe/shared/utils';
import { ValidationHelper } from '../../dataframe-acms/dataframe/shared/helpers/validation.helper';

@Component({
  selector: 'app-framedetails717',
  templateUrl: './framedetails717.component.html',
  styleUrls: ['./framedetails717.component.css'],
})
export class Framedetails717Component extends FrameDetails {
  frameDetailsForm: FormGroup;
  formConfiguration: { [key: string]: Array<InputConfiguration> };
  subFrameNum = [1, 2, 3, 4];
  miniFrame = [
    { label: '0', value: 0 }, { label: '1', value: 1 }, { label: '2', value: 2 }, { label: '4', value: 4 }, { label: '8', value: 8 },
  ];
  noOfFramesPerSuperFrame = [
    { label: '0', value: 0 }, { label: '16', value: 16 }, { label: '32', value: 32 }, { label: '64', value: 64 }, { label: '128', value: 128 },
  ];
  incrementalRecordingType = [
    { label: '0', value: 0 }, { label: '1', value: 1 }
  ];
  superFrameType = [{ label: '0-15', value: 0 }, { label: '1-16', value: 1 }];
  jsonValue!: Root;
  ErrorMessage = [];
  FRAkey = [];

  readonly DEFAULT_NO_FRAMES_PER_SUPERFRAME = 16;
  readonly DEFAULT_INCREMENTAL_RECORDING_TYPE = 0;
  readonly DEFAULT_FRA_SUPERFRAME_TYPE = 0;

  readonly MAX_SUM_STARTING_BIT_AND_BIT_LENGTH = 13;


  inputCategoryList = Object.values(DFDetailsInputCategory717);
  FormType = FormType;

  constructor(
    router: Router,
    breadcrumbService: BreadcrumbService,
    confirmationService: ConfirmationService,
    messageService: MessageService,
    route: ActivatedRoute,
    realUserService: SecurityUserService,
    dataframeservice: DataFrameService
  ) {
    super(messageService, confirmationService, router, route, breadcrumbService, realUserService, dataframeservice);
    this.messageLabel = 'Data Frame 717';
    this.dataFrameMapView = Components.MapView717;
    this.dataFrameListView = Components.ListView717;
  }

  setForm() {
    this.setFormConfiguration();
    const allConfigurations = Object.keys(this.formConfiguration).reduce(
      (acc, currentKey) => [...acc, ...this.formConfiguration[currentKey]],
      []
    );
    const group = {};
    allConfigurations.forEach((controlConf) => {
      group[controlConf.valuePath] = new FormControl({ value: getProp(this.order, 'FRA.' + controlConf.valuePath), disabled: this.userCanManage ? null : '' }, controlConf.validators);
    });
    this.frameDetailsForm = new FormGroup(group);
  }

  processDataFrame(response: any) {
    this.FRAkey = [];
    if (response != undefined || response != null || response != '') {
      const updatedframe = JSON.parse(sessionStorage.getItem('FrameDetails'));
      this.order = (updatedframe != undefined || updatedframe != null) ? updatedframe : response;
      for (const [key] of Object.entries(this.order.FRA)) {
        this.FRAkey.push(key);
      }
      this.handleMissingProps();
      this.validateJSON(this.order);
      this.setForm();
    }
  }

  handleMissingProps() {
    this.order.FRA.FRA_NB_FRAMES_PER_SUPERFRAME = this.order.FRA.FRA_NB_FRAMES_PER_SUPERFRAME ?? this.DEFAULT_NO_FRAMES_PER_SUPERFRAME;
    this.order.FRA.FRA_INCR_RECORDING_TYPE = this.order.FRA.FRA_INCR_RECORDING_TYPE ?? this.DEFAULT_INCREMENTAL_RECORDING_TYPE;
    this.order.FRA.FRA_SUPERFRAME_TYPE = this.order.FRA.FRA_SUPERFRAME_TYPE ?? this.DEFAULT_FRA_SUPERFRAME_TYPE;
  }

  validateJSON(order) {
    this.ErrorMessage = [];
    for (const [key, value] of Object.entries(order.FRA)) {
      if (key != 'AcquiredParams' && key != 'FRA_WORD_LENGTH') {
        if ((this.FRAkey.includes(key) && key == 'FRA_NAME') || key == 'FRA_DESCRIPTION') {
          if (!this.isString(value)) {
            const obj = { key: key };
            this.ErrorMessage.push(obj);
          }
        } else {
          if (!this.isNumber(value)) {
            const obj = { key: key };
            this.ErrorMessage.push(obj);
          } else {
            if (!this.isInteger(value)) {
              const obj = { key: key };
              this.ErrorMessage.push(obj);
            }
          }
        }
      }
    }
  }

  onCheckJSON(event, key) {
    if (event != '' && event != null && event != undefined) {
      const getvalue = event;
      if ((this.FRAkey.includes(key) && key == 'FRA_NAME') || key == 'FRA_DESCRIPTION') {
        const isString = typeof getvalue === 'string' ? true : false;
        if (isString) {
          const indexOfObject = this.ErrorMessage.findIndex((object) => {
            return object.key == key;
          });
          if (indexOfObject != -1) {
            this.ErrorMessage.splice(indexOfObject, 1);
          }
        }
      } else {
        if (this.FRAkey.includes(key)) {
          const isTypeNumber = typeof getvalue === 'number' ? true : false;
          if (isTypeNumber) {
            const indexOfObject = this.ErrorMessage.findIndex((object) => {
              return object.key == key;
            });
            if (indexOfObject != -1) {
              this.ErrorMessage.splice(indexOfObject, 1);
            }
            const isInteger = Number.isInteger(getvalue) ? true : false;
            if (isInteger) {
              const indexOfObject = this.ErrorMessage.findIndex((object) => {
                return object.key == key;
              });
              if (indexOfObject != -1) {
                this.ErrorMessage.splice(indexOfObject, 1);
              }
            }
          }
        }
      }
    }
  }

  setFrameName(event) {
    this.order.FRA.FRA_SFC_LOC_F = event.value;
    this.onCheckJSON(event.value, 'FRA_SFC_LOC_F');
  }

  setSubFrame(event) {
    this.order.FRA.FRA_DFC_LOC_F = event.value;
    this.onCheckJSON(event.value, 'FRA_DFC_LOC_F');
  }

  OnSubmit() {
    this.order.FRA = this.frameDetailsForm.getRawValue();
    this.order.FRA.FRA_NAME = this.order.FRA.FRA_NAME.toUpperCase();
    sessionStorage.setItem('FrameDetails', JSON.stringify(this.order));
    this.backToScreen();
  }

  formReset() {
    this.ErrorMessage, (this.FRAkey = []);
    this.frameDetailsForm.reset();
    this.getDataFrame(this.versionId);
  }

  onWordPerSecondChange() {
    // we refresh all dependent control validators
    this.onDataFrameWordNumberChange();
    this.onSubframeWordChange();
  }

  onDataFrameWordNumberChange() {
    this.frameDetailsForm.controls['FRA_DFC_LOC_W'].setValidators(this.dataFrameWordNumberValidators);
    this.frameDetailsForm.controls['FRA_DFC_LOC_W'].updateValueAndValidity();
  }

  onDFCStartingBitChange() {
    this.frameDetailsForm.controls['FRA_DFC_BIT_LENGTH'].setValidators(this.bitDFCLengthValidators);
    this.frameDetailsForm.controls['FRA_DFC_BIT_LENGTH'].updateValueAndValidity();
  }

  onSFCStartingBitChange() {
    this.frameDetailsForm.controls['FRA_SFC_BIT_LENGTH'].setValidators(this.bitSFCLengthValidators);
    this.frameDetailsForm.controls['FRA_SFC_BIT_LENGTH'].updateValueAndValidity();
  }

  onSubframeWordChange() {
    this.frameDetailsForm.controls['FRA_SFC_LOC_W'].setValidators(this.subframeWordNumberValidators);
    this.frameDetailsForm.controls['FRA_SFC_LOC_W'].updateValueAndValidity();
  }

  get dataFrameWordNumberValidators() {
    return [Validators.required, Validators.min(1), Validators.max(this.frameDetailsForm?.controls['FRA_SUBFRAME_WORD_NB']?.value ?? this.order.FRA.FRA_SUBFRAME_WORD_NB), ValidationHelper.validateInteger(), ValidationHelper.validateNumber()];
  }

  get bitDFCLengthValidators() {
    return [Validators.required, Validators.min(1), Validators.max(12), ValidationHelper.validateInteger(), ValidationHelper.validateNumber(),
    ValidationHelper.validateSumLte(this.frameDetailsForm?.getRawValue() ?? this.order.FRA, 'FRA_DFC_BIT_SOURCE_LSB', 'FRA_DFC_BIT_LENGTH', 'Starting Bit', 'Bit Length', this.MAX_SUM_STARTING_BIT_AND_BIT_LENGTH)];
  }

  get bitSFCLengthValidators() {
    return [Validators.required, Validators.min(1), ValidationHelper.validateInteger(), ValidationHelper.validateNumber(),
    ValidationHelper.validateSumLte(this.frameDetailsForm?.getRawValue() ?? this.order.FRA, 'FRA_SFC_BIT_SOURCE_LSB', 'FRA_SFC_BIT_LENGTH', 'Starting Bit', 'Bit Length', this.MAX_SUM_STARTING_BIT_AND_BIT_LENGTH)];
  }

  get subframeWordNumberValidators() {
    return [Validators.required, Validators.min(1), Validators.max(this.frameDetailsForm?.controls['FRA_SUBFRAME_WORD_NB']?.value ?? this.order.FRA.FRA_SUBFRAME_WORD_NB), ValidationHelper.validateInteger(), ValidationHelper.validateNumber()];
  }

  setFormConfiguration() {
    const isDisabled = this.userCanManage ? null : ''; // this is for setting simple disabled attribute without value
    this.formConfiguration = {
      [DFDetailsInputCategory717.General.toString()]: [
        {
          category: DFDetailsInputCategory717.General,
          formType: FormType.Input,
          type: 'text',
          name: 'FRA_NAME',
          label: 'Data Frame Name',
          valuePath: 'FRA_NAME',
          style: { width: '100%', 'text-transform': 'uppercase' },
          extraAttributes: { disabled: isDisabled, pInputText: '' },
          patternError: ' should be alphanumeric.',
          validators: [Validators.required, Validators.pattern('^[a-zA-Z0-9_-]+$'), ValidationHelper.validateString()],
          onInput: () => this.onCheckJSON(this.order.FRA.FRA_NAME, 'FRA_NAME'),
        },
        {
          category: DFDetailsInputCategory717.General,
          formType: FormType.Input,
          type: 'number',
          name: 'wpSeconds',
          label: 'Words Per Second',
          valuePath: 'FRA_SUBFRAME_WORD_NB',
          style: { width: '100%' },
          extraAttributes: { pKeyFilter: 'int', disabled: isDisabled, required: '', pInputText: '', type: 'number' },
          validators: [Validators.required, Validators.min(1), ValidationHelper.validateInteger(), ValidationHelper.validateNumber()],
          onInput: () => { this.onCheckJSON(this.order.FRA.FRA_SUBFRAME_WORD_NB, 'FRA_SUBFRAME_WORD_NB'); this.onWordPerSecondChange(); },
        },
        {
          category: DFDetailsInputCategory717.General,
          formType: FormType.Input,
          type: 'text',
          name: 'FRA_DESCRIPTION',
          label: 'Data Frame Description',
          valuePath: 'FRA_DESCRIPTION',
          style: { width: '100%' },
          class: 'lg:col-6',
          extraAttributes: { pInputText: '', maxlength: 50, disabled: isDisabled },
          validators: [Validators.required, ValidationHelper.validateString()],
          onInput: () => this.onCheckJSON(this.order.FRA.FRA_DESCRIPTION, 'FRA_DESCRIPTION'),
        },
      ],
      [DFDetailsInputCategory717.DFCounter.toString()]: [
        {
          category: DFDetailsInputCategory717.DFCounter,
          formType: FormType.Dropdown,
          name: 'sub_frame_num',
          label: 'Data Frame Subframe Number',
          valuePath: 'FRA_DFC_LOC_F',
          style: { width: '100%' },
          options: this.subFrameNum,
          disabled: !this.userCanManage, // for p-dropdown => this needs to be specified as a property, not in extraAttributes
          required: true,
          validators: [Validators.required, ValidationHelper.validateInteger(), ValidationHelper.validateNumber()],
        },
        {
          category: DFDetailsInputCategory717.DFCounter,
          formType: FormType.Input,
          type: 'number',
          name: 'dfc_starting_bit',
          label: 'Starting Bit',
          valuePath: 'FRA_DFC_BIT_SOURCE_LSB',
          style: { width: '100%' },
          extraAttributes: { pInputText: '', pKeyFilter: 'int', disabled: isDisabled },
          validators: [Validators.required, Validators.min(1), Validators.max(12), ValidationHelper.validateInteger(), ValidationHelper.validateNumber()],
          onInput: () => { this.onCheckJSON(this.order.FRA.FRA_DFC_BIT_SOURCE_LSB, 'FRA_DFC_BIT_SOURCE_LSB'); this.onDFCStartingBitChange() },
        },
        {
          category: DFDetailsInputCategory717.DFCounter,
          formType: FormType.Input,
          type: 'number',
          name: 'dfc_word_number',
          label: 'Data Frame Word Number',
          valuePath: 'FRA_DFC_LOC_W',
          style: { width: '100%' },
          extraAttributes: { pInputText: '', pKeyFilter: 'int', disabled: isDisabled, },
          validators: this.dataFrameWordNumberValidators,
          onInput: () => { this.onCheckJSON(this.order.FRA.FRA_DFC_LOC_W, 'FRA_DFC_LOC_W'); this.onDataFrameWordNumberChange(); },
        },
        {
          category: DFDetailsInputCategory717.DFCounter,
          formType: FormType.Input,
          type: 'number',
          name: 'bit_length',
          label: 'Bit Length',
          valuePath: 'FRA_DFC_BIT_LENGTH',
          style: { width: '100%' },
          extraAttributes: { pInputText: '', pKeyFilter: 'int', disabled: isDisabled },
          validators: this.bitDFCLengthValidators,
          onInput: () => { this.onCheckJSON(this.order.FRA.FRA_DFC_BIT_LENGTH, 'FRA_DFC_BIT_LENGTH'); this.onDFCStartingBitChange(); },
        },
      ],
      [DFDetailsInputCategory717.SFCounter.toString()]: [
        {
          category: DFDetailsInputCategory717.SFCounter,
          formType: FormType.Dropdown,
          name: 'subframenum',
          label: 'Subframe Location',
          valuePath: 'FRA_SFC_LOC_F',
          style: { width: '100%' },
          options: this.subFrameNum,
          disabled: !this.userCanManage, // for p-dropdown => this needs to be specified as a property, not in extraAttributes
          required: true,
          validators: [Validators.required, ValidationHelper.validateInteger(), ValidationHelper.validateNumber()],
        },
        {
          category: DFDetailsInputCategory717.SFCounter,
          formType: FormType.Input,
          type: 'number',
          name: 'sfcstarting_bit',
          label: 'Starting Bit',
          valuePath: 'FRA_SFC_BIT_SOURCE_LSB',
          style: { width: '100%' },
          extraAttributes: { pInputText: '', pKeyFilter: 'int', disabled: isDisabled },
          validators: [Validators.required, Validators.min(1), Validators.max(12), ValidationHelper.validateInteger(), ValidationHelper.validateNumber()],
          onInput: () => { this.onCheckJSON(this.order.FRA.FRA_SFC_BIT_SOURCE_LSB, 'FRA_SFC_BIT_SOURCE_LSB'); this.onSFCStartingBitChange(); },
        },
        {
          category: DFDetailsInputCategory717.SFCounter,
          formType: FormType.Input,
          type: 'number',
          name: 'sfcword_num',
          label: 'Subframe Word Number',
          valuePath: 'FRA_SFC_LOC_W',
          style: { width: '100%' },
          extraAttributes: { pInputText: '', pKeyFilter: 'int', disabled: isDisabled, },
          validators: this.subframeWordNumberValidators,
          onInput: () => { this.onCheckJSON(this.order.FRA.FRA_SFC_LOC_W, 'FRA_SFC_LOC_W'); this.onSubframeWordChange(); },
        },
        {
          category: DFDetailsInputCategory717.SFCounter,
          formType: FormType.Input,
          type: 'number',
          name: 'sfcbit_length',
          label: 'Bit Length',
          valuePath: 'FRA_SFC_BIT_LENGTH',
          style: { width: '100%' },
          extraAttributes: { pInputText: '', pKeyFilter: 'int', disabled: isDisabled },
          validators: this.bitSFCLengthValidators,
          onInput: () => { this.onCheckJSON(this.order.FRA.FRA_SFC_BIT_LENGTH, 'FRA_SFC_BIT_LENGTH'); this.onSFCStartingBitChange(); },
        },
      ],
      [DFDetailsInputCategory717.DFType.toString()]: [
        {
          category: DFDetailsInputCategory717.DFType,
          formType: FormType.Dropdown,
          name: 'mini_frame',
          label: 'Number of Mini-frames per Subframe',
          valuePath: 'FRA_NB_MINIFRAMES_IN_SUBFRAME',
          style: { width: '100%' },
          options: this.miniFrame,
          disabled: !this.userCanManage, // for p-dropdown => this needs to be specified as a property, not in extraAttributes
          required: true,
          validators: [Validators.required,],
        },

        {
          category: DFDetailsInputCategory717.DFType,
          formType: FormType.Dropdown,
          name: 'nb_frame_per_superframe',
          label: 'Number of Frames per Subframe',
          valuePath: 'FRA_NB_FRAMES_PER_SUPERFRAME',
          style: { width: '100%' },
          options: this.noOfFramesPerSuperFrame,
          disabled: !this.userCanManage, // for p-dropdown => this needs to be specified as a property, not in extraAttributes
          required: true,
          validators: [Validators.required,]
        },

        {
          category: DFDetailsInputCategory717.DFType,
          formType: FormType.Dropdown,
          type: 'number',
          name: 'incr_recording_type',
          label: 'Incremental Recording Type',
          valuePath: 'FRA_INCR_RECORDING_TYPE',
          style: { width: '100%' },
          options: this.incrementalRecordingType,
          extraAttributes: { pInputText: '', pKeyFilter: 'int' },
          disabled: !this.userCanManage, // for p-dropdown => this needs to be specified as a property, not in extraAttributes
          validators: [Validators.required, ValidationHelper.validateInteger(), ValidationHelper.validateNumber()],
          onInput: () => this.onCheckJSON(this.order.FRA.FRA_INCR_RECORDING_TYPE, 'FRA_INCR_RECORDING_TYPE'),
        },

        {
          category: DFDetailsInputCategory717.DFType,
          formType: FormType.Input,
          type: 'number',
          name: 'word_length',
          label: 'Word Length',
          valuePath: 'FRA_WORD_LENGTH',
          style: { width: '100%' },
          extraAttributes: { pInputText: '', pKeyFilter: 'int', disabled: isDisabled },
          validators: [Validators.required, Validators.min(1), ValidationHelper.validateInteger(), ValidationHelper.validateNumber()],
          onInput: () => this.onCheckJSON(this.order.FRA.FRA_WORD_LENGTH, 'FRA_WORD_LENGTH'),
        },

        {
          category: DFDetailsInputCategory717.DFType,
          formType: FormType.Dropdown,
          name: 'superframe_type',
          label: 'Superframe Type',
          valuePath: 'FRA_SUPERFRAME_TYPE',
          style: { width: '100%' },
          options: this.superFrameType,
          disabled: !this.userCanManage, // for p-dropdown => this needs to be specified as a property, not in extraAttributes
          required: true,
          validators: [Validators.required, ValidationHelper.validateInteger(), ValidationHelper.validateNumber()],
        },
      ],
    };
  }
}
