<app-spinner [isLoading]="isLoading"></app-spinner>
<div class="col-12 card p-3 mapViewTable">
    <app-list-view-header [pageTitle]="pageTitle"
        [showMessage]="showMessage"
        [messages]="messages"
        [userCanManage]="userCanManage"
        [isMapView]="true"
        (onNavigateToDFDetails)="toDataFrameDetails()"
        (onNavigateToListView)="toListView()"
        (onNavigateToAnalyseDF)="toAnalyseDataFrame()"
        (onAddDataFrame)="onAddDataFrame()">
    </app-list-view-header>
    <p-table [value]="mapView" [filterDelay]="0" [tableStyle]="{'width':'100%'}" selectionMode="single" #dtable
        [totalRecords]="mapView.length" [showCurrentPageReport]="true" [paginator]="mapView.length > defaultTableRows"
        [rowsPerPageOptions]="rowsPerPageOptions" [rows]="defaultTableRows"
        currentPageReportTemplate="{{currentPageReport + ' ' + pageTitle}}"
        class="mapview-data-table">
        <ng-template pTemplate="header" let-columns pTemplate="header">
            <tr>
                <th>
                    <div class="flex justify-content-start align-items-center">
                        Word
                    </div>
                </th>
                <th>
                    <div class="flex justify-content-start align-items-center">
                        SF
                    </div>
                </th>
                <th *ngFor="let col of colHeads">
                    <div class="flex justify-content-start align-items-center">
                        {{ col.header }}
                    </div>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-data let-columns="columns">
            <tr>
                <td *ngIf="data.rowIndex === 1" class="word_column" rowspan="4">{{data.word}}</td>
                <td>{{data.sf}}</td>
                <td [pTooltip]="data.name" (click)="onCellClick(data.name)"
                    [attr.rowspan]="data.subFrame == 'ALL' ? 4 : 1"
                    *ngIf="data.length==defaultBitLen && data.subFrame=='ALL' && data.rowIndex === 1"
                    [attr.colspan]="data.length"
                    style="text-align:center;" [ngClass]="getCellClass(data.word,data.length)">
                    {{data.name}}
                </td>
                <td [pTooltip]="data.name" (click)="onCellClick(data.name)"
                    *ngIf="data.length==defaultBitLen && data.subFrame!='ALL' && doesSubFrameExist(data.subFrame,data.sf)"
                    [attr.colspan]="data.length" style="text-align:center;" class="rowColor">
                    {{data.name}}
                </td>
                <ng-container *ngIf="data.length!= defaultBitLen && (data.subFrame === 'ALL' && data.rowIndex === 1)">
                    {{getClear()}}
                    <td style="text-align:center;"
                        [ngClass]="getFillColor(col.length,col.word,data.cols,col.colIndex,col.sf,data.sf,data.word, data.isConflicted)"
                        *ngFor="let col of data.cols" style="text-align:left;"
                        [attr.rowspan]="col.sf != 'ALL' ? 1 : col.sf == 'ALL' ? 4 : col.rowspan">
                        <div [pTooltip]="col.word"
                            *ngIf="col.sf!='ALL' && doesSubFrameExist(col.sf,data.sf)"
                            style="cursor: pointer;"
                            (click)="onCellClick(col.word)">
                            {{getColVal(col.word)}}
                        </div>
                        <div [pTooltip]="col.word"
                            *ngIf="col.sf=='ALL'"
                            style="cursor: pointer;"
                            (click)="onCellClick(col.word)">
                            {{getColVal(col.word)}}
                        </div>
                    </td>
                </ng-container>
                <ng-container *ngIf="data.length!= defaultBitLen && (data.subFrame.includes(data.rowIndex))">
                    {{getClear()}}
                    <ng-container
                    *ngFor="let col of data.cols"
                    >
                        <td style="text-align:center;"
                            [ngClass]="getFillColor(col.length,col.word,data.cols,col.colIndex,col.sf,data.sf,data.word,data.isConflicted)"
                            style="text-align:left;"
                            *ngIf="(col.sf !== 'ALL' && doesSubFrameExist(col.sf,data.sf)) || (col.sf === 'ALL' && data.rowIndex === 1)"
                            [attr.rowspan]="col.sf != 'ALL' ? 1 : col.sf == 'ALL' ? 4 : col.rowspan">
                            <div [pTooltip]="col.word"
                                *ngIf="col.sf!='ALL' && doesSubFrameExist(col.sf,data.sf)"
                                style="cursor: pointer;"
                                (click)="onCellClick(col.word)">
                                {{getColVal(col.word)}}
                            </div>
                            <div [pTooltip]="col.word"
                                *ngIf="col.sf=='ALL'"
                                style="cursor: pointer;"
                                (click)="onCellClick(col.word)">
                                {{getColVal(col.word)}}
                            </div>
                        </td>
                    </ng-container>

                </ng-container>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td [attr.colspan]="3">
                    {{noDataFoundLabel}}
                </td>
            </tr>
        </ng-template>
    </p-table>
    <div *ngIf="mapView.length > 0 && defaultTableRows >= mapView.length" style="text-align: center;">
        <p class="row-paging">Showing 1 to
            {{mapView.length}} of {{mapView.length}} {{pageTitle}}</p>
    </div>
    <app-data-frame-footer-action
        [saveDisabled]="!showMessage"
        [resetDisabled]="!showMessage"
        [userCanManage]="userCanManage"
        (onCancel)="onCancel()"
        (onReset)="resetDataFrame()"
        (onSave)="saveDataFrame()">
    </app-data-frame-footer-action>
</div>
