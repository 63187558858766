import { HttpClient} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from "src/environments/environment";
import { FlightDataRedactionService } from '../../datadelivery/services/flightDataRedaction.service';

@Injectable({
  providedIn: 'root'
})
export class EndpointFlightDataRedactionService extends FlightDataRedactionService {

  constructor(
    public override http: HttpClient) {
    super(http);
    this.baseUrl = environment.apiUrl.dataDeliveryInsights;
  }
}
