import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import {
  DataFrameType,
  FilePathType, RedactionType, AvailableDataFrame,
  REDACTION_TYPES
} from '../../models/FlightDataRedactions';
import { GetErrorMessage } from 'src/app/features/sendtoteledyne/models/SendToTdyFlightRedaction';
import { ConfirmationService, MessageService } from 'primeng/api';
import {
  FormGroup,
  NgForm,
} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Components } from '../../integration/datadelivery.components';
import { BreadcrumbService } from '../../../../../common/services/breadcrumb.service';
import { Action, BaseViewComponent } from 'src/app/common/components/base-view.component';
import { DataFrameService } from '../../../../configuration/services/data-frame.service';
import { FlightDataRedactionService } from '../../services/flightDataRedaction.service';
import { forkJoin, takeUntil } from 'rxjs';
import { FileUpload } from "primeng/fileupload";
import { CommonService } from 'src/app/common/services/common.service';
import { Components as ConfigComponents } from '../../../../configuration/integration/configuration.components';
import { SecurityUserService } from 'src/app/security/services/security-user.service';
import { permissions } from '../../integration/datadelivery.permissions';
import { EnhancedRedactionService } from '../../services/enhancedRedaction.service';
import { EnhancedRedactions, ResponseEnhancedRedaction } from '../../models/EnhancedRedaction';
import { Message } from 'primeng/api';
@Component({
  selector: 'app-enhancedredaction-details',
  templateUrl: './enhancedredaction-details.component.html',
  styleUrls: ['./enhancedredaction-details.component.css'],
})
export class EnhancedRedactionAddComponent extends BaseViewComponent implements OnInit {
  @ViewChild('dataredactionForm') dataredactionForm!: NgForm;
  @ViewChild('ContentUpload') content: FileUpload;
  @ViewChild('Name') NameControl: ElementRef;
  pageTitle = Components.AddEnhancedRedaction.label;
  public canAdd: boolean;
  public canDelete: boolean;
  public canEdit: boolean;
  public modalDialog?: boolean;
  public submitted?: boolean;
  isReadOnly: boolean = true;
  showButtons: boolean = true;
  maxFileSize: number;
  picklistChange = false;
  isDataFrame = false;
  isButtonDisabled = true;
  today = new Date();
  cols: any[] = [];
  flightdataredactions: EnhancedRedactions[] = [];
  flightdataredaction!: EnhancedRedactions;
  redactionType: RedactionType[] = [];
  availableDataFrame: AvailableDataFrame[] = [];
  availableDataFramelist: AvailableDataFrame[] = [];
  dataframe: DataFrameType[] = [];
  selecteduploadDataframes: DataFrameType[] = [];
  uploadfilepath!: FilePathType;
  files: any = '';
  empty = false;
  displayError = false;
  redactionForm!: FormGroup;
  allnames: any[] = [];
  uploadedFiles: any[] = [];
  uniqueError = false;
  names: any[] = [];
  checked!: boolean;
  selectedDataframes: AvailableDataFrame[] = [];
  selectedDataframesIDs: string[] = [];
  editselectedDataframesIDs: string[] = [];
  isReadonly = true;
  isReadonlyredaction = false;
  selectedType: any;
  selectedDataframeId: number;
  selectedRedactionTypeId: number;
  dropdownChanged = false;
  saveButtonDisabled: boolean;
  initialRecordersCount: number;
  flightdataredactionData: any;
  responseFlightDataRedaction: ResponseEnhancedRedaction;
  fileButtonEnabled: boolean = false
  acceptedFiles: string = '.csv';
  filename: string;
  FileToSave: any;
  arr: string[] = [];
  editarr: string[] = [];
  fileString: string;
  isValid = true;
  isFileValid = true;
  messageLabel = "enhanced data redaction";
  newLabel = "New Enhanced Data Redaction";
  messages!: Message[];
  showMessage = false;
  constructor(router: Router,
    private route: ActivatedRoute,
    breadcrumbService: BreadcrumbService, confirmationService: ConfirmationService,
    messageService: MessageService, private dataFrameService: DataFrameService, private flightdataredactionservice: FlightDataRedactionService
    , private commonservice: CommonService, private realUserService: SecurityUserService) {
    super(messageService, confirmationService, router, breadcrumbService);
    this.getBreadCrums();
  }

  getBreadCrums() {
    this.breadcrumbService.setItems(this.route, [{ label: Components.EnhancedRedaction.label, routerLink: Components.EnhancedRedaction.path }, {
      label: Components.AddEnhancedRedaction.label + ` (${this.route.snapshot.queryParamMap.get('name')})`
    }])
  }

  ngOnInit(): void {
    this.canAdd = this.realUserService.userHasPermission(permissions.datadelivery_enhanced_redaction.create);
    this.canEdit = this.realUserService.userHasPermission(permissions.datadelivery_enhanced_redaction.manage);
    this.canDelete = this.realUserService.userHasPermission(permissions.datadelivery_enhanced_redaction.delete);
    this.fileButtonEnabled = true;
    this.uploadfilepath = new FilePathType();
    this.flightdataredaction = new EnhancedRedactions();
    this.getBreadCrums();
    const redactionId = +this.route.snapshot.queryParamMap.get('redactionId');
    const isClone = !!this.route.snapshot.queryParamMap.get('clone');
    if (redactionId) {
      if (isClone) {
        this.isNew = true;
        !this.canAdd ? this.isReadonly = true : this.isReadonly = false;
        !this.canAdd ? this.showButtons = false : this.showButtons = true;
        this.pageTitle = 'Clone Enhanced Data Redaction';
        this.updateBreadcrumb(`${this.pageTitle} (${this.route.snapshot.queryParamMap.get('name')})`);
      } else {
        this.isNew = false;
        !this.canEdit ? this.isReadonly = true : this.isReadonly = false;
        !this.canEdit ? this.showButtons = false : this.showButtons = true;
      }
      this.loadFlightDataRedactionDetails(redactionId, isClone);
    } else {
      !this.canAdd ? this.isReadonly = true : this.isReadonly = false;
      !this.canAdd ? this.showButtons = false : this.showButtons = true;
      this.intialVal();
      this.loadDataFrames();
    }

  }

  intialVal() {
    this.isNew = true;
    this.pageTitle = this.newLabel;
    this.updateBreadcrumb(this.pageTitle);
    this.flightdataredaction = new EnhancedRedactions();
    this.flightdataredaction.redaction_id = -1;
    this.flightdataredaction.textualoutput = false;
    this.availableDataFrame = [];
    this.availableDataFramelist = [];
    this.uploadfilepath = new FilePathType();
  }

  onDataFramChange(e: any) {
    this.dropdownChanged = true;
    this.selectedDataframeId = e.value;
    this.loadDataRedactionDetails(this.selectedDataframeId);
    if (this.selecteduploadDataframes.length > 0) {
      this.selectedDataframesIDs = [];
      this.availableDataFrame = [];
      this.availableDataFramelist.forEach(ele => {
        if (this.selecteduploadDataframes.find(a => a.dataframe_type == ele.dataframe_type)) {
          this.availableDataFrame.push({ dataframe_type: ele.dataframe_type });
        }
      });

      this.selecteduploadDataframes.forEach(ele => {
        this.selectedDataframesIDs.push(ele.dataframe_type)
      });

      if (this.availableDataFramelist && this.availableDataFrame) {
        this.availableDataFramelist = this.availableDataFramelist.filter(p => this.selecteduploadDataframes.findIndex(i => i.dataframe_type == p.dataframe_type) == -1)
      }

      this.availableDataFramelist ? this.initialRecordersCount = this.availableDataFramelist.length : this.initialRecordersCount = 0;
      this.initialRecordersCount = this.selecteduploadDataframes.length;
    }
  }

  loadFlightDataRedactionDetails(redaction_id: number, clone: boolean) {
    this.loadingCount++;
    forkJoin({
      getflightredactiondetails: this.flightdataredactionservice.getFligtRedaction_by_id(redaction_id),
      dataframe: this.commonservice.getDataFrameData(),
      redactionType: this.flightdataredactionservice.getRedactionType(),
    }).pipe(
      takeUntil(this.ngUnsubscribe)).subscribe({
        next: results => {
          results.dataframe.forEach(ele => {
            this.dataframe.push({ dataframe_id: ele.dataframe_id, dataframe_type: ele.name })
          });
          this.redactionType = results.redactionType.filter(a => (a.redactiontype_id != REDACTION_TYPES.RAW));
          this.flightdataredaction = results.getflightredactiondetails;
          let getDataframes = JSON.parse(this.flightdataredaction.mapjson)
          this.editselectedDataframesIDs = getDataframes.selected_values;
          this.selectedRedactionTypeId = this.flightdataredaction.redactiontype_id;
          this.selectedDataframeId = this.flightdataredaction.dataframe_id;
          this.flightdataredaction.textualoutput = this.flightdataredaction.textualoutput == true ? true : false;
          if (this.selectedRedactionTypeId == REDACTION_TYPES.RAW) { this.isReadonlyredaction = true; }
          this.onEditloadDataframeDetails(this.flightdataredaction.dataframe_id);
          if (clone) {
            this.flightdataredaction.name = "";
          }
          this.empty = true;
        }, error: error => {
          this.showErrorMsg(`${error}`, Action.Get, `${this.messageLabel}s`);
          this.loadingCount--;
        }, complete: () => {
          this.loadingCount--;
        }
      })
  }

  onEditloadDataframeDetails(dataframeId?: number) {//if modifying the existing SAR
    this.loadingCount++;
    this.saveButtonDisabled = true;
    forkJoin({
      availableredaction: this.dataFrameService.DataFrameByFrameId(dataframeId)
    }).pipe(
      takeUntil(this.ngUnsubscribe)).subscribe({
        next: results => {
          this.availableDataFrame = [];
          //sorting to show in alphabetical order
          results.availableredaction.forEach(ele => {
            if (this.editselectedDataframesIDs.find(a => a == ele)) {
              this.availableDataFrame.push({ dataframe_type: ele });
              this.selectedDataframes.push({ dataframe_type: ele });
            }
            this.availableDataFramelist.push({ dataframe_type: ele })
          });

          this.selectedDataframes.forEach(ele => {
            this.selectedDataframesIDs.push(ele.dataframe_type)
          });

          this.editarr = this.editselectedDataframesIDs.filter(p => this.selectedDataframes.findIndex(i => i.dataframe_type == p) == -1)

          if (this.availableDataFramelist && this.availableDataFrame) {
            this.availableDataFramelist = this.availableDataFramelist.filter(p => this.selectedDataframes.findIndex(i => i.dataframe_type == p.dataframe_type) == -1)
          }

          this.availableDataFramelist ? this.initialRecordersCount = this.availableDataFramelist.length : this.initialRecordersCount = 0;
          this.initialRecordersCount = this.selectedDataframes.length;
          this.availableDataFramelist.sort((a, b) => { return a.dataframe_type.localeCompare(b.dataframe_type) });
          this.availableDataFrame.sort((a, b) => { return a.dataframe_type.localeCompare(b.dataframe_type) });
        },
        error: error => {
          this.showErrorMsg(`${error}`, Action.Get, `${this.messageLabel}s`);
          this.loadingCount--;
        },
        complete: () => {
          this.loadingCount--;
        }
      }
      );
  }

  loadDataFrames() {
    this.loadingCount++;
    forkJoin({
      dataframe: this.commonservice.getDataFrameData(),
      redactionType: this.flightdataredactionservice.getRedactionType(),
    }).pipe(
      takeUntil(this.ngUnsubscribe)).subscribe({
        next: results => {

          results.dataframe.forEach(ele => {
            this.dataframe.push({ dataframe_id: ele.dataframe_id, dataframe_type: ele.name })
          });
          this.redactionType = results.redactionType.filter(a => (a.redactiontype_id != REDACTION_TYPES.RAW));
        },
        error: error => {
          this.showErrorMsg(`${error}`, Action.Get, `${this.messageLabel}s`);
          this.loadingCount--;
        },
        complete: () => {
          this.loadingCount--;
        }
      });
  }

  loadDataRedactionDetails(frameId: number) {//if modifying the existing flight
    this.loadingCount++;
    this.saveButtonDisabled = true;
    this.availableDataFramelist = [];
    this.availableDataFrame = [];
    forkJoin({
      availableDataFrame: this.dataFrameService.DataFrameByFrameId(frameId)
    }).pipe(
      takeUntil(this.ngUnsubscribe)).subscribe({
        next: results => {
          //sorting to show in alphabetical order
          results.availableDataFrame.forEach(ele => {
            this.availableDataFramelist.push({ dataframe_type: ele })

          });
          this.availableDataFramelist.forEach(ele => {
            this.selectedDataframes.push({ dataframe_type: ele.dataframe_type })
          });

          this.availableDataFrame = [];

          this.selectedDataframes.forEach(ele => {
            this.selectedDataframesIDs.push(ele.dataframe_type)
          });

          //Filter the source Dataframe List
          if (this.availableDataFrame && this.selectedDataframes) {
            this.availableDataFrame = this.availableDataFrame.filter(p => this.selectedDataframes.findIndex(i => i.dataframe_type == p.dataframe_type) == -1)
          }

          this.availableDataFramelist ? this.initialRecordersCount = this.availableDataFramelist.length : this.initialRecordersCount = 0;
          this.initialRecordersCount = this.selectedDataframes.length;
          this.availableDataFramelist.sort((a, b) => { return a.dataframe_type.localeCompare(b.dataframe_type) });
          this.selectedDataframesIDs = [];
          this.empty = true;
        },
        error: error => {
          this.showErrorMsg(`${error}`, Action.Get, `${this.messageLabel}s`);
          this.loadingCount--;
        },
        complete: () => {
          this.loadingCount--;
        }
      }
      );
  }


  onChangeRedaction(e: any) {
    this.isReadonlyredaction = false;
    this.flightdataredaction.textualoutput = false;
    this.selectedRedactionTypeId = e.value;
    if (e.value == REDACTION_TYPES.RAW) { this.isReadonlyredaction = true; }
  }

  valuechange(event) {
    this.fileButtonEnabled = event.target.value !== '';
    this.filename = event.target.value;
    this.FileToSave = "";
    this.uploadfilepath["filename"] = null;
    this.content.clear();
    this.submitted = true;
  }

  onSubmit() {
    this.loadingCount++;
    let selectedValues = null;
    let getValue = { "selected_values": selectedValues == null ? this.selectedDataframesIDs : selectedValues }
    this.responseFlightDataRedaction = {
      redaction_id: this.flightdataredaction.redaction_id,
      redaction_name: this.flightdataredaction.name,
      redaction_description: this.flightdataredaction.description,
      redaction_textualoutput: this.flightdataredaction.textualoutput == null ? false : this.flightdataredaction.textualoutput,
      redaction_mapjson: JSON.stringify(getValue),
      redaction_defaultparametervaluesconfig: '',
      redaction_dataframe_id: this.selectedDataframeId,
      redaction_redactiontype_id: this.selectedRedactionTypeId,
      organization_id: 0,
      enhanced: true
    }
    if (this.isNew) {
      this.flightdataredactionservice.addFlightRedaction(this.responseFlightDataRedaction).pipe(
        takeUntil(this.ngUnsubscribe)).subscribe({
          next: result => {
            this.flightdataredaction.redaction_id = result;
            // Update query param
            this.router.navigate([], {
              relativeTo: this.route,
              queryParams: {
                redactionId: this.flightdataredaction.redaction_id,
                name: this.flightdataredaction.name
              },
              queryParamsHandling: 'merge'
            }).then();

            this.updateBreadcrumb(Components.AddEnhancedRedaction.label + ' (' + this.flightdataredaction.name + ')');
            this.pageTitle = Components.AddEnhancedRedaction.label;
            this.picklistChange = false;
          },
          error: err => {
            this.showErrorMsg(`${err}`, Action.Add, `${this.messageLabel}s`);
            this.loadingCount--;
            this.isButtonDisabled = true;
            this.saveButtonDisabled = true;
            this.showMessage = false;
          },
          complete: () => {
            this.showSuccessMsg(Action.Add, `${this.messageLabel}`, `${this.flightdataredaction.name}`);
            this.isNew = false;
            this.loadingCount--;
            this.isButtonDisabled = true;
            this.saveButtonDisabled = true;
            this.showMessage = false;
            this.dataredactionForm.resetForm(this.dataredactionForm.value);
            this.ngOnInit;
          }
        });
    } else {
      this.flightdataredactionservice.updateFlightRedaction(this.responseFlightDataRedaction).pipe(
        takeUntil(this.ngUnsubscribe)).subscribe({
          next: () => {
            this.updateBreadcrumb(Components.AddEnhancedRedaction.label + ' (' + this.flightdataredaction.name + ')');
            this.picklistChange = false;
          },
          error: err => {
            this.showErrorMsg(`${err}`, Action.Update, `${this.messageLabel}s`);
            this.loadingCount--;
            this.isButtonDisabled = true;
            this.saveButtonDisabled = true;
            this.showMessage = false;
          },
          complete: () => {
            this.showSuccessMsg(Action.Update, `${this.messageLabel}`, `${this.flightdataredaction.name}`);
            this.loadingCount--;
            this.isButtonDisabled = true;
            this.showMessage = false;
            this.dataredactionForm.resetForm(this.dataredactionForm.value);
            this.ngOnInit;
          }
        });
    }
  }

  onReset() {
    this.onFileRemove();
    this.uploadfilepath["filename"] = "";
    this.content.clear();
    this.dataredactionForm.resetForm();
    this.picklistChange = false;
    this.selectedDataframes = [];
    this.selectedDataframesIDs = [];
    this.availableDataFramelist = [];
    this.editarr = [];
    this.isReadonlyredaction = false;
    this.ngOnInit();
    this.isButtonDisabled = true;
    this.isFileValid = true;
    this.empty = false;
    this.selecteduploadDataframes = [];
    this.showMessage = false;
  }

  filesname() {
    for (let name of this.uploadedFiles) {
      this.allnames = name.name;
    }
  }


  onFileRemove() {
    this.arr = [];
    this.submitted = true;
    this.uploadfilepath["filename"] = null;
    this.fileButtonEnabled = this.uploadfilepath["filename"] != "";
    this.isValid = true;
    this.isFileValid = true;
    this.showMessage = false;
    this.uploadedParamaterValues();
  }

  uploadedParamaterValues() {
    if (this.selecteduploadDataframes.length > 0) {
      this.selectedDataframesIDs = [];
      this.availableDataFrame = this.availableDataFrame.filter(p => this.selecteduploadDataframes.findIndex(i => i.dataframe_type == p.dataframe_type) == -1)
      this.availableDataFrame.forEach(ele => {
        this.selectedDataframesIDs.push(ele.dataframe_type)
      });
      this.selecteduploadDataframes.forEach(ele => {
        if (this.availableDataFramelist.find(a => a.dataframe_type == ele.dataframe_type)) {
        } else { this.availableDataFramelist.push({ dataframe_type: ele.dataframe_type }) }
      });
      this.availableDataFramelist.sort((a, b) => { return a.dataframe_type.localeCompare(b.dataframe_type) });
      this.selecteduploadDataframes = [];
    }
  }

  onUpload(event) {
    if (this.fileValidation(event)) {
      this.submitted = true;
    }
  }

  fileValidation(event) {
    const fileType = event.files[0].name.split('.').pop();
    const file = event.files[0];
    this.isFileValid = false;
    if (!this.acceptedFiles.includes(`.${fileType.toLowerCase()}`)) {
      this.showErrorMsg(`${event.files[0].name} is not the correct type . Only ${this.acceptedFiles} types are supported.`, Action.Upload, `${this.messageLabel}s`);
      this.isValid = false;
      this.removefile();
      this.isFileValid = true;
    }
    else {
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onload = () => {
        this.fileString = reader.result as string;
        this.arr = this.fileString.split(/[\r\n]+/);

        //Get uploaded files matched values
        let getMatch = this.availableDataFramelist.filter(a => this.arr.includes(a.dataframe_type));
        const findDuplicateVal = this.arr.filter(
          (item, index) => this.arr.indexOf(item) != index
        );

        const getUnmatches = this.arr.filter(a => !getMatch.find(item => item.dataframe_type == a));
        this.selecteduploadDataframes = [];
        //sorting to show in alphabetical order
        this.availableDataFramelist.forEach(ele => {
          if (getMatch.find(a => a.dataframe_type == ele.dataframe_type)) {
            {
              this.availableDataFrame.push({ dataframe_type: ele.dataframe_type });
              this.selecteduploadDataframes.push({ dataframe_type: ele.dataframe_type });
            }
          }
        });

        this.selecteduploadDataframes.forEach(ele => {
          this.selectedDataframesIDs.push(ele.dataframe_type)
        });

        if (this.availableDataFramelist && this.availableDataFrame) {
          this.availableDataFramelist = this.availableDataFramelist.filter(p => this.selecteduploadDataframes.findIndex(i => i.dataframe_type == p.dataframe_type) == -1)
        }

        this.availableDataFramelist ? this.initialRecordersCount = this.availableDataFramelist.length : this.initialRecordersCount = 0;
        this.initialRecordersCount = this.selecteduploadDataframes.length;


        if (findDuplicateVal.length > 1) {
          this.isValid = false;
          this.removefile();
          this.messages = this.flightdataredactionservice.getErrorMessage(GetErrorMessage.Duplicates + this.checkChar(findDuplicateVal))
          this.showMessage = true;
        } else if (getMatch.length < 1) {
          this.isValid = false;
          this.removefile();
          this.messages = this.flightdataredactionservice.getErrorMessage(GetErrorMessage.Matching + getMatch.map(a => a.dataframe_type).join(',').slice(0, -1))
          this.showMessage = true;
        } else if (getUnmatches.length > 1) {
          this.isValid = false;
          this.removefile();
          this.messages = this.flightdataredactionservice.getErrorMessage(GetErrorMessage.Selected + this.checkChar(getUnmatches))
          this.showMessage = true;
        } else {
          this.isValid = true;
        }
        if (getMatch.length > 0) { this.isValid = true } else { this.isValid = false; }
      };
      this.isFileValid = false;
    }
    return this.isValid;
  }

  checkChar(param: string[]) {
    let getValue = param.join(",");
    if (getValue.charAt(getValue.length - 1) === ",") {
      getValue = getValue.slice(0, -1)
    }
    return getValue;
  }

  LoadFileToSave(event): boolean {
    const fileType = event.files[0].name.split('.').pop();
    this.filename = event.files[0].name;

    const file = event.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.fileString = reader.result as string;

      this.FileToSave = reader.result;
      this.arr = this.fileString.split(/[\r\n]+/);
      this.uploadfilepath["fileContent"] = this.FileToSave;
      this.uploadfilepath["filename"] = this.filename;
    }
    return true;
  }


  onMoveToTarget(event) {
    event.items.forEach(ele => {
      this.selectedDataframesIDs.push(ele.dataframe_type);
    });
    this.isButtonDisabled = false;
    this.selectedDataframesIDs = [...new Set(this.selectedDataframesIDs)]; // remove the duplicate entries
    this.picklistChange = true;
  }

  onPicklistChange(event) {
    this.picklistChange = true;
    this.isButtonDisabled = false;
  }

  onMoveAllToSource(event) {
    event.items.forEach((ele) => {
      const index = this.selectedDataframesIDs.indexOf(ele.dataframe_type);
      if (index > -1) {
        this.selectedDataframesIDs.splice(index, 1);
      }
    });
    this.picklistChange = true;
    this.isButtonDisabled = false;
  }

  onMoveAllToTarget(event) {
    this.picklistChange = true;
    this.isButtonDisabled = false;
    event.items.forEach(ele => {
      this.selectedDataframesIDs.push(ele.dataframe_type);
    });
  }

  onMoveToSource(event) {
    this.picklistChange = true;
    this.isButtonDisabled = false;
    event.items.forEach(ele => {
      const index = this.selectedDataframesIDs.indexOf(ele.dataframe_type);
      if (index > -1) {
        this.selectedDataframesIDs.splice(index, 1);
      }
    });
  }

  onCancel() {
    if (this.dataredactionForm.dirty) {
      this.router.navigate([Components.EnhancedRedaction.path]);
    } else {
      this.router.navigate([Components.EnhancedRedaction.path]).then();
    }
  }



  removefile() {
    this.uploadfilepath.filename = '';
    this.isValid = true;
  }
}
