import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { FileUpload } from 'primeng/fileupload';
import { BreadcrumbService } from 'src/app/common/services/breadcrumb.service';
import { sarMapsVersions } from 'src/app/features/configuration/models/sarMaps';
import { Action, BaseViewComponent } from 'src/app/common/components/base-view.component';
import { SARMapsService } from 'src/app/features/configuration/services/sar-map.service';
import { takeUntil } from 'rxjs';
import { Buffer } from 'buffer';
import { permissions } from '../../../integration/configuration.permissions';
import { SecurityUserService } from 'src/app/security/services/security-user.service';
import { Components } from '../../../integration/configuration.components';
import { Location } from '@angular/common';

@Component({
  selector: 'app-sar-map-add',
  templateUrl: './sar-map-add.component.html',
})
export class SarMapAddComponent extends BaseViewComponent implements OnInit {
  pageTitle = Components.SarMapsAdd.label;
  fileButtonEnabled = false;
  isSaveDisabled = true;
  allowedFileType= ".txt";
  recordFile: File = null;
  record: sarMapsVersions;
  fileName: string;
  FileToSave: any;
  public canAdd: boolean;
  public canDelete: boolean;
  public canEdit: boolean;

  @ViewChild('contentUpload') content: FileUpload;
  @ViewChild('addForm') addForm: NgForm;
  @ViewChild('Name') NameControl: ElementRef;
  messageLabel = "sar-map"

  constructor(
    private route: ActivatedRoute,
    private sarMapsService: SARMapsService,
    breadcrumbService: BreadcrumbService,
    router: Router,
    confirmationService: ConfirmationService,
    messageService: MessageService,
    private realUserService: SecurityUserService,
    private location: Location,
  ) {
    super(
      messageService,
      confirmationService,
      router,
      breadcrumbService
    );
    this.breadcrumbService.setItems(this.route, [
      { label: Components.SarMaps.label, routerLink: Components.SarMaps.path },
      {
        label: Components.SarMapsAdd.label,
        routerLink: Components.SarMapsAdd.path,
      },
    ]);
  }

  ngOnInit(): void {
    this.record = new sarMapsVersions();

    this.canAdd = this.realUserService.userHasPermission(permissions.sar_maps.create);
    this.canDelete = this.realUserService.userHasPermission(permissions.sar_maps.delete);
    this.canEdit = this.realUserService.userHasPermission(permissions.sar_maps.manage);

    if (!this.canAdd) {
      this.showErrorMsg('User is not authorized to add SAR Maps.', Action.Add, `${this.messageLabel}`);
      // Wait a few seconds for user to see the error, and send them back to where they came from.
      setTimeout(() => {
        this.location.back();
      }, 3000);
    }

  }

  valuechange(event) {
    this.fileButtonEnabled = event.target.value !== '';
    this.fileName = event.target.value;
    this.FileToSave = "";
    // this.record["version"] = null;
    this.content.clear();
    this.isSaveDisabled = true;
  }

  onFileRemove() {
    this.isSaveDisabled = true;
    this.record["version"] = null;
    this.fileButtonEnabled = this.record["name"] != "";
  }

  onFileSelect(event) {
    this.LoadFileToSave(event);
  }

  LoadFileToSave(event) {
    this.loadingCount++
    const allowedfileType = ['.TXT']
    const fileType = event.files[0].name.split('.').pop();
    const file = event.files[0];
    const reader = new FileReader();

    reader.readAsDataURL(file);

    if (!allowedfileType.includes(`.${fileType.toUpperCase()}`)) {
      this.messageService.add({
        key: 'message',
        severity: 'error',
        summary: 'Incorrect File Type',
        detail: `${event.files[0].name} is not the correct type . Only ${this.allowedFileType} types are supported.`,
      });
      this.loadingCount--;
      return true;
    }

    reader.onload = () => {
      this.FileToSave = reader.result;

      if (fileType.toLowerCase() === 'txt') {
        const content = Buffer.from(this.FileToSave.replace('data:text/plain;base64,', ' ')
          , 'base64')
          .toString('ascii');
      }
    };
    this.loadingCount--;
    this.isSaveDisabled = false;
  }

  onSubmit() {
    this.loadingCount++;

    this.sarMapsService.addSarMap(this.record, this.FileToSave).pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: () => {
          this.showSuccessMsg(Action.Add, `${this.messageLabel}`, `${this.record.name}`);
          this.isSaveDisabled = true;
        },
        error: err => {
          this.showErrorMsg(`${err}`, Action.Add, `${this.messageLabel}`);
          this.loadingCount--;
        },
        complete: () => {
          this.loadingCount--
          this.updateBreadcrumb(Components.SarMapsAdd.label)
          this.onReset();
        }
      })
  }

  onCancel() {
    if (!this.isSaveDisabled) {
      this.confirmationService.confirm({
        message: `Are you sure you want to cancel adding a new SAR Map?`,
        header: 'Cancel?',
        rejectButtonStyleClass: 'p-button-text',
        accept: () => {
          this.router.navigate([Components.SarMaps.path]).then();
        }
      });
    }
    else {
      this.router.navigate([Components.SarMaps.path]).then();
    }
  }

  onReset() {
    this.FileToSave = "";
    this.record["name"] = ""
    this.content.clear();
    this.isSaveDisabled = true;
  }
}
