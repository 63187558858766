import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from '../../../../common/services/base.service';
import { catchError } from 'rxjs/operators';
import { environment } from "src/environments/environment";
import { RecordingModel, ResponseRecording, RecordingIdModel } from "../models/recordingClientLogModel";
@Injectable({
  providedIn: 'root'
})

export class RecordingClientLogService extends BaseService {

  constructor(
    private http: HttpClient) {
    super();
    this.baseUrl = environment.apiUrl.datadelivery;
  }

  getRecordingStatusbyClientIds(responseRecording: ResponseRecording): Observable<RecordingModel[]> {
    const url = this.baseUrl + "recording-status-client-log";
    return this.http.post<RecordingModel[]>(url, responseRecording, { headers: this.httpGetHeadersForJSON() })
      .pipe(catchError(this.httpHandleError)
      );
  }

  getRecordingLogByRecordingId(recording_id: number): Observable<RecordingIdModel[]> {
    const url = this.baseUrl + "recording-status-client-log" + `/${recording_id}`;
    return this.http.get<RecordingIdModel[]>(url, { headers: this.httpGetHeadersForJSON() })
      .pipe(catchError(this.httpHandleError)
      );
  }
}
