import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import {
  ConversionType,
  RedactionType,
  SAR,
  SARMap,
  jsonValues,
} from 'src/app/features/datadelivery/datadelivery/models/SARRedaction';

@Component({
  selector: 'app-sar-redaction-details',
  templateUrl: './sar-redaction-details.component.html',
  styleUrls: ['./sar-redaction-details.component.css'],
})
export class SARRedactionDetailsComponent {
  @Input() canAdd: boolean;
  @Input() canDelete: boolean;
  @Input() canEdit: boolean;
  @Input() isNew = false;
  @Input() isReadonly = true;
  @Input() SARredaction: SAR;
  @Input() convtype: ConversionType[] = [];
  @Input() redactiontype: RedactionType[] = [];
  @Input() sarmap: SARMap[] = [];
  @Input() jsonParameters: jsonValues;
  @Input() showButtons = true;
  @Input() selectedSarMapIDs: string[];
  @Input() sarMapList: SARMap[];
  @Input() isButtonDisabled = true;
  @Input() selectedSarMap: SARMap[] = [];
  @Input() avialableSarMap: SARMap[] = [];
  @Input() picklistChange = false;

  @Output() conversionChange = new EventEmitter<any>();
  @Output() sarMapChange = new EventEmitter<any>();
  @Output() moveToTarget = new EventEmitter<any>();
  @Output() moveToSource = new EventEmitter<any>();
  @Output() moveAllToSource = new EventEmitter<any>();
  @Output() moveAllToTarget = new EventEmitter<any>();

  @Output() submitForm = new EventEmitter<void>();
  @Output() cancelForm = new EventEmitter<void>();
  @Output() resetForm = new EventEmitter<void>();

  @ViewChild('SARFormData')
  private SARFormData!: NgForm;

  constructor() {
    // Intentionally empty
  }

  onSarMapChange(event) {
    this.sarMapChange.emit(event);
  }

  onConverionChange(event) {
    this.conversionChange.emit(event);
  }

  onMoveToTarget(event) {
    this.moveToTarget.emit(event);
  }

  onMoveAllToSource(event) {
    this.moveAllToSource.emit(event);
  }

  onMoveAllToTarget(event) {
    this.moveAllToTarget.emit(event);
  }

  onMoveToSource(event) {
    this.moveToSource.emit(event);
  }

  onCancel() {
    this.cancelForm.emit();
  }

  onReset() {
    this.SARFormData.resetForm();
    this.resetForm.emit();
  }

  onSubmit() {
    this.submitForm.emit();
  }
}
