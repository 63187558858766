<form #acmsForm="ngForm" (ngSubmit)="onSubmit()" novalidate>
  <div class="formgrid grid">
    <div class="lg:col-4">
      <span class="p-float-label">
        <input [style]="{ width: '100%' }" type="text" class="form-control" pattern=".*\S.*" pInputText id="name"
          name="name" [(ngModel)]="acmsreport.name" [disabled]="isReadonly" #name="ngModel" required maxlength="50" />
        <label for="name">Name</label>
      </span>
      <div *ngIf="uniqueError">
        <small class="p-invalid p-error">Name Already Exists</small>
      </div>
      <div class="tdy-required" *ngIf="(name.touched || name.dirty) && name.hasError('required')">
        Name is required.
      </div>
      <div class="tdy-required" *ngIf="name.hasError('pattern')">
        Name cannot be left blank.
      </div>
    </div>

    <div class="lg:col-4">
      <span class="p-float-label">
        <input [style]="{ width: '100%' }" [disabled]="isReadonly" #description="ngModel" pattern=".*\S.*"
          maxlength="255" type="text" id="description" name="description" pInputText
          [(ngModel)]="acmsreport.description" />
        <label for="description">Description</label>
        <div class="tdy-required" *ngIf="description.hasError('pattern')">
          Description cannot be left blank.
        </div>
      </span>
    </div>
    <div class="lg:col-4">
      <span class="p-float-label">
        <p-dropdown [disabled]="isReadonly" autoWidth="false" #reportType="ngModel" [style]="{ width: '100%' }"
          [autoDisplayFirst]="false" optionLabel="file_type" optionValue="file_id" name="file_type" id="file_type"
          [options]="fileType" [required]="true" (onChange)="onAirlineChange($event)"
          [(ngModel)]="jsonParameters.filetype_id"></p-dropdown>
        <label for="file_id">Report Type</label>
        <div class="tdy-required" *ngIf="
            (reportType.touched || reportType.dirty) && reportType.hasError('required')
          ">
          Report Type is required.
        </div>
      </span>
    </div>

    <div *ngIf="jsonParameters.filetype_id === FileType.AircraftReport" class="formgrid grid lg:col-12">

      <!--Name Field-->
      <!-- hide this field because this is common component and might used it in endpointdatdelivery-> acmsreport-data-details.component-->
      <div class="lg:col-4" [hidden]="true">
        <span class="p-float-label">
          <input [disabled]="isReadonly" [style]="{ width: '100%' }" #regexName="ngModel" type="text" id="regexName"
            name="regexName" pInputText [(ngModel)]="jsonregex.name" />
          <label for="description">Regex name</label>
        </span>

      </div>
      <!--Regex Field-->
      <!-- hide this field because this is common component and might used it in endpointdatdelivery-> acmsreport-data-details.component-->
      <div class="lg:col-4" [hidden]="true">
        <span class="p-float-label">
          <input [disabled]="isReadonly" pattern=".*\S.*" [style]="{ width: '100%' }" #regexValue="ngModel" type="text"
            id="regex" name="regex" pInputText [(ngModel)]="jsonregex.regex" />
          <label for="description">Regex</label>
        </span>
      </div>
      <!-- Add button -->
      <div class="card" style="width:100%">
        <div class="flex justify-content-end">
          <button pButton pRipple label="Add" type="button" icon="pi pi-plus" class="mb-2"
            (click)="onAddRegex()"></button>
        </div>

        <!-- table -->
        <div class="lg:col-12">
          <p-table [value]="_regexlist" [style]="{ width: '100%' }" [responsive]="true">
            <ng-template pTemplate="header">
              <tr>
                <th>
                  <div class="flex justify-content-start align-items-center">Regex Name</div>
                </th>
                <th>
                  <div class="flex justify-content-start align-items-center"> Regex </div>
                </th>
                <th style="text-align: center;">Action</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-i="rowIndex">
              <tr>
                <td style="width:40%">
                  <input [id]="'regexName' + _regexlist[i].id" [name]="'regexName' + _regexlist[i].id"
                    #regexName="ngModel" [(ngModel)]="_regexlist[i].name" pInputText [style]="{'width': '100%'}" required />
                </td>
                <td style="width:40%">
                  <input [(ngModel)]="_regexlist[i].regex" [id]="'regex' + _regexlist[i].id"
                    [name]="'regex' + _regexlist[i].id" #regex="ngModel" pInputText [style]="{'width': '100%'}" required/>
                </td>
                <td style="width:20%; text-align: center;">
                  <button pButton pRipple type="button" icon="pi pi-trash" class="p-button-danger"
                    (click)="onDeleteRegex(_regexlist[i].id)"></button>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>

    </div>



    <div *ngIf="
        jsonParameters.filetype_id === FileType.Teledyne && acmsType.length > 0
      " class="lg:col-4">
      <span class="p-float-label">
        <p-dropdown [disabled]="isReadonly" autoWidth="false" [style]="{ width: '100%' }" optionLabel="acms_name"
          optionValue="acms_id" [options]="acmsType" #acms_report_map="ngModel" [autoDisplayFirst]="false"
          id="acms_report_map" name="acms_report_map" [required]="true" (onChange)="onChange($event)"
          [(ngModel)]="acmsreport.acmsreportmap_id"></p-dropdown>
        <label for="acms_report_map">ACMS Report Map</label>
      </span>
      <div class="tdy-required" *ngIf="
          (acms_report_map.touched || acms_report_map.dirty) &&
          acms_report_map.hasError('required')
        ">
        ACMS Report Map is required.
      </div>
    </div>
  </div>

  <div *ngIf="jsonParameters.filetype_id === FileType.Teledyne" class="mt-5">
    <h6>ACMS Report Selection</h6>
    <div class="field">
      <p-pickList [source]="acmsReportlist" [target]="avialableAcms" id="acms_id"
        sourceHeader="Available ACMS Report(s)" targetHeader="Selected ACMS Report(s)" [showSourceControls]="false"
        [showTargetControls]="false" [responsive]="true" [sourceStyle]="{ height: '200px' }"
        (onMoveToTarget)="onMoveToTarget($event)" (onMoveToSource)="onMoveToSource($event)"
        (onMoveAllToSource)="onMoveAllToSource($event)" (onMoveAllToTarget)="onMoveAllToTarget($event)"
        [disabled]="isReadonly" [targetStyle]="{ height: '200px' }">
        <ng-template let-acms pTemplate="item">
          <ng-container>
            <div>
              <div>{{ acms.acms_name }}</div>
            </div>
          </ng-container>
        </ng-template>
      </p-pickList>
      <div class="tdy-required" *ngIf="
          isNew
            ? selectedMapIDs.length < 1
            : selectedMapIDs.length < 1 && selectedMapIDs.length != null
        ">
        At least 1 ACMS Report is required.
      </div>
    </div>
  </div>
  <div class="flex justify-content-end mt-3">
    <span class="p-input-icon-left">
      <button pButton pRipple label="Cancel" type="button" icon="pi pi-times" class="p-button-text"
        (click)="onCancel()"></button>
    </span>
    <span class="p-input-icon-left p-button-text" style="float: right">
      <button *ngIf="showButtons" pButton pRipple label="Reset" type="button"
        [disabled]="!acmsForm.dirty && isButtonDisabled" icon="pi pi-refresh" class="p-button-text"
        (click)="onReset()"></button>
    </span>
    <span class="p-input-icon-left ml-3" style="float: right">
      <button pButton pRipple label="Save" *ngIf="showButtons" icon="pi pi-check" type="submit" [disabled]="
          isNew
            ? !acmsForm.valid ||
              (jsonParameters.filetype_id === FileType.Teledyne
                ? selectedMapIDs?.length < 1
                : regexList?.length < 1)
            : !((acmsForm.dirty || picklistChange || regexlistchanged) && acmsForm.valid) ||
              (jsonParameters.filetype_id === FileType.Teledyne
                ? selectedMapIDs.length < 1
                : regexList?.length < 1)
        " icon="pi pi-check"></button>
    </span>
  </div>
</form>
