import { Component, NgZone, OnInit } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Components } from '../../integration/datadelivery.components';
import { BreadcrumbService } from '../../../../../common/services/breadcrumb.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Action, BaseViewComponent } from 'src/app/common/components/base-view.component';
import { ClientApplication, ResponseClientStatus } from '../../models/clientApplication';
import { ClientApplicationService } from '../../services/clientApplication.service';
import { takeUntil } from 'rxjs';
import { SecurityUserService } from 'src/app/security/services/security-user.service';
import { permissions } from '../../integration/datadelivery.permissions';

@Component({
  selector: 'app-clientapplication',
  templateUrl: './clientapplication.component.html',
  styleUrls: ['./clientapplication.component.css'],
})
export class ClientapplicationComponent
  extends BaseViewComponent
  implements OnInit {
  pageTitle = Components.ClientApplication.label;
  public canAdd: boolean;
  public canDelete: boolean;
  public canEdit: boolean;
  public canActivate: boolean;
  public canDeactivate: boolean;
  responseClientStatus: ResponseClientStatus;
  deleteDialog = false;
  deleteRow: any;
  clientauths: ClientApplication[] = [];
  messageLabel = 'Client Authentication';
  copyClicked = false;

  constructor(
    router: Router,
    private route: ActivatedRoute,
    breadcrumbService: BreadcrumbService,
    confirmationService: ConfirmationService,
    messageService: MessageService,
    private clientApplicationService: ClientApplicationService,
    private realUserService: SecurityUserService,
    private ngZone: NgZone
  ) {
    super(messageService, confirmationService, router, breadcrumbService);
    this.getBreadCrums();
  }

  getBreadCrums() {
    this.breadcrumbService.setItems(this.route, [
      { label: Components.ClientApplication.label },
    ]);
  }

  ngOnInit(): void {
    this.getBreadCrums();
    this.canAdd = this.realUserService.userHasPermission(
      permissions.datadelivery_client_authentication.create
    );
    this.canDelete = this.realUserService.userHasPermission(
      permissions.datadelivery_client_authentication.delete
    );
    this.canEdit = this.realUserService.userHasPermission(
      permissions.datadelivery_client_authentication.manage
    );
    this.canActivate = this.realUserService.userHasPermission(
      permissions.datadelivery_client_authentication.activate
    );
    this.canDeactivate = this.realUserService.userHasPermission(
      permissions.datadelivery_client_authentication.deactivate
    );
    this.loadClientApp();
  }

  loadClientApp() {
    this.loadingCount++;
    this.clientApplicationService
      .getClientApplications()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (data) => {
          this.clientauths = data;
        },
        error: (err) => {
          this.showErrorMsg(`${err}`, Action.Get, `${this.messageLabel}s`);
          this.loadingCount--;
        },
        complete: () => this.loadingCount--,
      });
  }
  showDialog(row: any) {
    this.deleteRow = row;
    this.deleteDialog = true;
  }
  hideDialog() {
    this.deleteRow = null;
    this.deleteDialog = false;
  }

  openNew() {
    if (!this.canAdd) {
      this.showErrorMsg(
        'User is not authorized to add a Client Authentication.',
        Action.Add,
        `${this.messageLabel}`
      );
    } else {
      this.ngZone.run(() => {
        this.router.navigate([Components.AddClientApplication.path]).then();
      });
    }
  }

  statusUpdate(clientAuth: ClientApplication) {
    this.responseClientStatus = {
      client_auth_id: clientAuth.id,
    };

    if (clientAuth.IsActive) {
      this.handleDeactivation(clientAuth);
    } else {
      this.handleActivation(clientAuth);
    }
  }

  handleDeactivation(clientAuth: ClientApplication) {
    if (!this.canDeactivate) {
      this.showErrorMsg(
        'User is not authorized to deactivate a Client Authentication.',
        Action.Update,
        this.messageLabel
      );
    } else {
      this.confirmDeactivation(clientAuth);
    }
  }

  handleActivation(clientAuth: ClientApplication) {
    if (!this.canActivate) {
      this.showErrorMsg(
        'User is not authorized to activate a Client Authentication.',
        Action.Update,
        this.messageLabel
      );
    } else {
      this.confirmActivation(clientAuth);
    }
  }

  confirmDeactivation(clientAuth: ClientApplication) {
    this.confirmationService.confirm({
      header: 'Confirm Deactivate',
      message:
        'Are you sure that you want to deactivate the selected Client Authentication?',
      accept: () => {
        this.deactivateClient(clientAuth);
      },
    });
  }

  confirmActivation(clientAuth: ClientApplication) {
    this.confirmationService.confirm({
      header: 'Confirm Activate',
      message:
        'Are you sure that you want to activate the selected Client Authentication?',
      accept: () => {
        this.activateClient(clientAuth);
      },
    });
  }

  deactivateClient(clientAuth: ClientApplication) {
    this.clientApplicationService
      .updateDeActiveStatus(this.responseClientStatus)
      .subscribe({
        next: () => this.handleSuccess(clientAuth, Action.Update),
        error: (error) => this.handleError(error, Action.Delete),
      });
  }

  activateClient(clientAuth: ClientApplication) {
    this.clientApplicationService
      .updateActiveStatus(this.responseClientStatus)
      .subscribe({
        next: () => this.handleSuccess(clientAuth, Action.Update),
        error: (error) => this.handleError(error, Action.Delete),
      });
  }

  handleSuccess(clientAuth: ClientApplication, action: Action) {
    this.showSuccessMsg(action, this.messageLabel, clientAuth.Name);
    this.loadClientApp(); // Refresh the list of client Application
  }

  handleError(error: any, action: Action) {
    this.showErrorMsg(`${error}`, action, `${this.messageLabel}s`);
  }

  generateClientSecret(clientAuth: ClientApplication) {
    this.responseClientStatus = {
      client_auth_id: clientAuth.id,
    };

    if (!this.canEdit) {
      this.showErrorMsg(
        'User is not authorized to update a Client Authentication.',
        Action.Update,
        this.messageLabel
      );
    } else {
      this.confirmGeneration(clientAuth);
    }
  }

  confirmGeneration(clientAuth: ClientApplication) {
    this.confirmationService.confirm({
      header: 'Generate Client ID/Secret',
      message: 'Are you sure that you want to generate Client Authentication?',
      accept: () => {
        this.generateClient(clientAuth);
      },
    });
  }

  generateClient(clientAuth: ClientApplication) {
    this.clientApplicationService
      .generateClientApplication(this.responseClientStatus)
      .subscribe({
        next: () => {
          this.loadClientApp(); // Refresh the list of client Application
        },
        error: (error) => {
          this.handleError(error, Action.Update);
        },
        complete: () => {
          this.handleSuccess(clientAuth, Action.Update);
        },
      });
  }

  deleteClientAuth(clientAuth: ClientApplication) {
    this.responseClientStatus = {
      client_auth_id: clientAuth.id,
    };

    if (!this.canDelete) {
      this.showErrorMsg(
        'User is not authorized to delete a Client Authentication.',
        Action.Delete,
        this.messageLabel
      );
    } else {
      this.confirmDeletion(clientAuth);
    }
  }

  confirmDeletion(clientAuth: ClientApplication) {
    this.confirmationService.confirm({
      header: 'Confirm Deletion',
      message:
        'Are you sure that you want to delete the selected Client Authentication?',
      accept: () => {
        this.deleteClient(clientAuth);
      },
    });
  }

  deleteClient(clientAuth: ClientApplication) {
    this.clientApplicationService
      .deleteClientApplication(this.responseClientStatus)
      .subscribe({
        next: () => {
          this.handleSuccess(clientAuth, Action.Delete);
        },
        error: (error) => {
          this.handleError(error, Action.Delete);
        },
      });
  }

  onRowSelect(event) {
    this.ngZone.run(() => {
      this.router.navigate([Components.AddClientApplication.path], {
        queryParams: {
          auth_id: event.data.auth_id,
          name: event.data.Name,
        },
      })
        .then();
    });
  }

  copyClientId(data: ClientApplication) {
    navigator.clipboard.writeText(data.ClientId);
  }

  copyClientSecret(data: ClientApplication) {
    navigator.clipboard.writeText(data.ClientSecret);
  }
}
