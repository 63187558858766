import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { BaseService } from 'src/app/common/services/base.service';
import { AircraftRecorderModel, UpdateAircraftRecorderModel } from 'src/app/features/configuration/models/aircraftRecorder';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AircraftRecorderService extends BaseService {

  constructor(private http: HttpClient) { super(); }
  aircraftRecodersURL = environment.apiUrl.config + 'aircraft-recorder';

  public getSelectedAircraftRecorder(aircraftRecorderID: number) {
    const url = this.aircraftRecodersURL + `/${aircraftRecorderID}`;
    const data = this.http.get<AircraftRecorderModel>(url, { headers: this.httpGetHeadersForJSON() })
      .pipe(catchError(this.httpHandleError));
    return data;
  }

  public listAircraftRecorders(): Observable<AircraftRecorderModel[]> { // get all the aircraftRecorders
    return this.http.get<AircraftRecorderModel[]>(this.aircraftRecodersURL).pipe(catchError(this.httpHandleError));
  }

  public listAircraftRecordersbyAircraftGroup(aircraftGroupdID: number): Observable<AircraftRecorderModel[]> { // get all the aircraftRecorders
    const url = environment.apiUrl.config + 'aircraft-recorders-by-aircraftgroup' + `/${aircraftGroupdID}`;
    return this.http.get<AircraftRecorderModel[]>(url, { headers: this.httpGetHeadersForJSON() });
  }

  public deleteAircraftRecorder(aircraftRecorderID: number) {
    const url = this.aircraftRecodersURL + `/${aircraftRecorderID}`;
    const data = this.http.delete(url, { headers: this.httpGetHeadersForJSON() })
      .pipe(catchError(this.httpHandleError));
    return data;
  }

  public updateSingleAircraftRecorder(aircraftRecorder: UpdateAircraftRecorderModel, recorderID: number) { // for updating single Aircraft Recorder
    return this.http.put(this.aircraftRecodersURL + `/${recorderID}`, aircraftRecorder, { headers: this.httpGetHeadersForJSON() })
      .pipe(catchError(this.httpHandleError)
      );
  }

  public updateMultipleAircraftRecorders(aircraftRecorder: UpdateAircraftRecorderModel) { //for updating multiple Aircraft Recorders
    return this.http.put(this.aircraftRecodersURL, aircraftRecorder, { headers: this.httpGetHeadersForJSON() })
      .pipe(catchError(this.httpHandleError)
      );
  }
}
