import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import {
  FormGroup,
  NgForm,
} from '@angular/forms';
import { Components } from '../../integration/datadelivery.components';
import { BreadcrumbService } from '../../../../../common/services/breadcrumb.service';
import { Action, BaseViewComponent } from 'src/app/common/components/base-view.component';
import { Router, ActivatedRoute } from '@angular/router';
import { forkJoin, takeUntil } from 'rxjs';
import { UserService } from 'src/app/features/administration/services/user.service';
import { SecurityUserService } from 'src/app/security/services/security-user.service';
import { permissions } from '../../integration/datadelivery.permissions';
import { applicationRoleData } from '../../../../administration/models/application';
import { UserRecordingSubscriptionService } from '../../services/userRecordingSubscription.service';
import { UsersRecordingNotifications } from '../../models/userRecordingSubscriptionModel';
@Component({
  selector: 'app-userclientrecording-alerts',
  templateUrl: './userclientrecording-alerts.component.html',
  styleUrls: ['./userclientrecording-alerts.component.css'],
})
export class UserRecordingNotificationsComponent extends BaseViewComponent implements OnInit {
  @ViewChild('userForm') userForm!: NgForm;
  pageTitle = Components.UserRecordingNotifications.label;
  messageLabel = "User";
  public canAdd: boolean;
  public canDelete: boolean;
  public canEdit: boolean;
  public modalDialog?: boolean;
  public submitted?: boolean;
  endPoint = "";
  applicationId: number;
  users: UsersRecordingNotifications[];
  constructor(router: Router,
    private route: ActivatedRoute,
    breadcrumbService: BreadcrumbService, confirmationService: ConfirmationService,
    messageService: MessageService,
    private realUserService: SecurityUserService, private userRecordingSubscriptionService: UserRecordingSubscriptionService) {
    super(messageService, confirmationService, router, breadcrumbService);
    this.getBreadCrums();
  }

  getBreadCrums() {
    this.breadcrumbService.setItems(this.route, [
      { label: Components.UserRecordingNotifications.label }
    ]);
  }

  ngOnInit(): void {
    this.getBreadCrums();
    this.route.queryParams.subscribe(q => {
      this.users = []
      const appName = 'Datadelivery';
      if (appName && Object.keys(applicationRoleData).includes(appName)) {
        this.applicationId = +applicationRoleData[appName].id;

        // Set the permissions
        const permissions = applicationRoleData[appName].users.permissions;
        this.canEdit = this.realUserService.userHasPermission(permissions.manage);

        // Get the endpoint
        this.endPoint = applicationRoleData[appName].endPoint;

        // Load the roles
        this.loadApplicationUsers(this.applicationId);
      }
    });
  }

  toUser(user: UsersRecordingNotifications): UsersRecordingNotifications {
    return user;
  }

  loadApplicationUsers(appId: number) {
    this.loadingCount++;
    forkJoin({
      datadeliveryusers: this.userRecordingSubscriptionService.getRecordingUsers(appId),
    }).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: results => {
        this.users = results.datadeliveryusers;

      }, error: err => {
        this.showErrorMsg(`${err}`, Action.Get, `${this.messageLabel}`);
        this.loadingCount--;
      }, complete: () => {
        this.loadingCount--;
      }
    });
  }

  onRowSelect(event) {
    this.router.navigate([Components.UserRecordingSubscription.path], {
      queryParams: { userId: event.data.user_id, name: event.data.display_name }
    }).then();
  }


}
