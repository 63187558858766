import { Component, Input, OnInit } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, NavigationEnd } from '@angular/router';
import { BaseComponent } from 'src/app/common/components/base.component';
import { MainComponent } from 'src/app/platform/components/main/main.component';
import { AppComponent } from 'src/app/app.component';
import { SecurityUserService } from 'src/app/security/services/security-user.service';
import { PostOrgSwitchBehavior, SecurityAuthService } from 'src/app/security/services/security-auth.service';
import { Components as AdministrationComponents } from 'src/app/features/administration/integration/administration.components';
import { permissions } from 'src/app/security/models/permissions';
import { ThemeService } from '../../services/theme.service';
import { SECURITY_CONSTANTS } from 'src/app/security/models/security-constants';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-top-menu',
  templateUrl: './top-menu.component.html',
  animations: [
    trigger('topbarActionPanelAnimation', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scaleY(0.8)' }),
        animate('.12s cubic-bezier(0, 0, 0.2, 1)', style({ opacity: 1, transform: '*' })),
      ]),
      transition(':leave', [
        animate('.1s linear', style({ opacity: 0 }))
      ])
    ])
  ]
})
export class TopMenuComponent extends BaseComponent implements OnInit {
  @Input() postSwitchBehavior: PostOrgSwitchBehavior = PostOrgSwitchBehavior.Reload;
  @Input() showFavoriteSelector = true;
  
  userProfileTitle = AdministrationComponents.UserDetails.label;
  currentAirlines = AdministrationComponents.AirlineDetails.label;
  userRole = '';
  icaoCode = '';
  userAvatar: any;
  userAvatarLoaded = false;
  notifCount: number;
  orgType: number;
  activeOrgID: number;
  canViewOrg: boolean;
  userName: string;
  activeOrg?: string;
  availableOrgs: string[] = [];
  
  // Theme options per application
  themeOptions: { name: string; file: string; }[];

  currentApplication: string = 'administration';

  

  private defaultOrgStorageKey: string;

  constructor(public app: AppComponent,
    public appMain: MainComponent,
    private securityUserService: SecurityUserService,
    private _authService: SecurityAuthService,
    private domSanitizer: DomSanitizer,
    private themeService: ThemeService,
    private router: Router) {
    super();
    this.themeOptions = this.themeService.getThemeOptions(this.currentApplication);
    this.themeService.initializeAppTheme(this.currentApplication);
  }

  ngOnInit() {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      this.currentApplication = this.themeService.getCurrentApplication(this.router.url);
      this.themeOptions = this.themeService.getThemeOptions(this.currentApplication);
    this.themeService.initializeAppTheme(this.currentApplication);
    });

    this.securityUserService.userInfo.subscribe(userInfo => {
      const noneEntry = userInfo.activeOrg === SECURITY_CONSTANTS.no_org_scope ? [SECURITY_CONSTANTS.no_org_scope] : [];
      this.defaultOrgStorageKey = userInfo.email + SECURITY_CONSTANTS.favorite_org_local_storage_key_suffix;
      const orderedOrgs = userInfo?.orgs?.sort((a, b) => (a > b ? 1 : -1)) ?? []
      this.availableOrgs = [...noneEntry, ...orderedOrgs];
      this.activeOrg = userInfo.activeOrg;
      this.activeOrgID = userInfo.activeOrgId;
      this.userName = userInfo.name;
      this.orgType = userInfo.orgTypeId;

      if (this.orgType == 1) {
        this.canViewOrg = this.securityUserService.userHasPermission(permissions.admin.airline_details.view)
      } else if (this.orgType == 2 || this.orgType == 3) {
        this.canViewOrg = this.securityUserService.userHasPermission(permissions.admin.company_details.view)
      }
    });

  }

  changeTheme(theme: string) {
    this.themeService.changeTheme(this.currentApplication, theme);
  }

  public onOrgChange(org : string) {
    if (org !== this.activeOrg && this.availableOrgs.includes(org)) {
      localStorage.setItem(this.defaultOrgStorageKey, org);
      this._authService.switchOrg(org, this.postSwitchBehavior);
    }
  }

  createUserAvatarFromBlob(image: any) {
    this.userAvatar = ' ';
    const blob = new Blob([image], { type: 'image/png' });
    const reader = new FileReader();

    reader.addEventListener('load', () => {
      const c = '' + reader.result;
      this.userAvatar = this.domSanitizer.bypassSecurityTrustUrl(c);
    }, false);

    if (image && image.size > 0) {
      this.userAvatarLoaded = true;
      reader.readAsDataURL(blob);
    } else {
      this.userAvatarLoaded = false;
      this.userAvatar = '';
    }
  }

  /**
   * Event handler for when a user selects their user profile
   */
  onClickUserProfile() {
    this.router.navigate([AdministrationComponents.UserDetails.path], {
      queryParams: {
        loggedIn: true
      }
    }).then();
  }

  isThemeSelected(themeFile: string): boolean {
    return this.themeService.selectedThemes[this.currentApplication] === themeFile;
  }

  onClickCurrentDetails() {
    if (this.orgType == 1 && this.canViewOrg) { // Check if the type is Airline
      this.router.navigate([AdministrationComponents.AirlineDetails.path], {
        queryParams: {
          a: this.activeOrgID, //passing the Airline ID
          loggedIn: true
        }
      }).then();
    } else if ((this.orgType == 2 || this.orgType == 3) && this.canViewOrg) { // Check if the type is Company, orgType=2 is Teledyne...
      this.router.navigate([AdministrationComponents.CompanyDetails.path], {
        queryParams: {
          c: this.activeOrgID, //passing the Company ID
          loggedIn: true
        }
      }).then();
    }
  }


  onLogoutClick() {
    this._authService.signOut();
  }

}
