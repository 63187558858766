import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/common/services/breadcrumb.service';
import { Action, BaseViewComponent } from 'src/app/common/components/base-view.component';
import { RecorderRegexService } from 'src/app/features/configuration/services/recorder-regex.service';
import { takeUntil } from 'rxjs';
import { Location } from '@angular/common';
import { RecorderRegexModel } from 'src/app/features/configuration/models/recorderRegex';
import { SecurityUserService } from 'src/app/security/services/security-user.service';
import { permissions } from '../../integration/configuration.permissions';
import { Components } from '../../integration/configuration.components';

@Component({
  selector: 'app-recorder-regex',
  templateUrl: './recorder-regex.component.html',
})
export class RecorderRegexComponent
  extends BaseViewComponent
  implements OnInit {
  pageTitle = Components.RecorderRegexes.label;
  recorderRegexes: RecorderRegexModel[];
  public canAdd: boolean;
  public canDelete: boolean;
  public canEdit: boolean;
  public canView: boolean;
  messageLabel = "Recoder Regex";

  constructor(
    private recorderRegexService: RecorderRegexService,

    messageService: MessageService,
    confirmationService: ConfirmationService,
    router: Router,
    breadcrumbService: BreadcrumbService,
    private route: ActivatedRoute,
    private location: Location,
    private realUserService: SecurityUserService
  ) {
    super(

      messageService,
      confirmationService,
      router,
      breadcrumbService
    );
    // Note: Service won't update breadcrumb if caller already did so.
    this.breadcrumbService.setItems(route, [
      {
        label: Components.RecorderRegexes.label,
        routerLink: Components.RecorderRegexes.path,
      },
    ]);
  }

  ngOnInit(): void {
    this.canAdd = this.realUserService.userHasPermission(
      permissions.regexes.create
    );
    this.canDelete = this.realUserService.userHasPermission(
      permissions.regexes.delete
    );
    this.canEdit = this.realUserService.userHasPermission(
      permissions.regexes.manage
    );
    if (!this.canView) {
      this.loadRecorderRegexes();
    }
  }

  loadRecorderRegexes() {
    this.loadingCount++;
    this.recorderRegexService
      .listRecorderRegexes()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (data) => {
          this.recorderRegexes = data;
        },
        error: (err) => {
          this.showErrorMsg(`${err}`, Action.Get, `list of ${this.messageLabel}`);
          this.loadingCount--;
        },
        complete: () => this.loadingCount--,
      });
  }

  onDeleteRecorderRegex(recorderRegex: RecorderRegexModel) {
    if (this.userCanDelete) {
      this.showErrorMsg("User is not authorized to delete a Recorder Regex.", Action.Delete, `${this.messageLabel}`, `${recorderRegex.name}`);
    } else {
      this.confirmationService.confirm({
        message:
          'Are you sure that you want to delete the selected Recorder Regex?',
        accept: () => {
          this.recorderRegexService
            .deleteRecorderRegex(recorderRegex.regex_id)
            .subscribe({
              next: () => {
                this.showSuccessMsg(Action.Delete, `${this.messageLabel}`, `${recorderRegex.name}`)
                // Refresh the list of Recorder Regexes
                this.loadRecorderRegexes();
              },
              error: error => {
                this.showErrorMsg(`${error}`, Action.Delete, `${this.messageLabel}`, `${recorderRegex.name}`);
              },
            });
        },
      });
    }
  }

  onClickAdd() {
    if (!this.canAdd) {
      this.showErrorMsg("User is not authorized to add a Recorder Regex.", Action.Add, `${this.messageLabel}`);
    } else {
      this.router.navigate([Components.RecorderRegexDetails.path]).then();
    }
  }

  onRowSelect(event) {
    this.router
      .navigate([Components.RecorderRegexDetails.path], {
        queryParams: {
          recorderRegexID: event.data.regex_id,
          recorderRegexName: event.data.name,
        },
      })
      .then();
  }
}
