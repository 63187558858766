import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from '../../../../common/services/base.service';
import { catchError } from 'rxjs/operators';
import { environment } from "src/environments/environment";
import { FlightDataRedactions, ResponseFlightDataRedaction, RedactionType } from "../models/FlightDataRedactions";
import { ZipType } from '../models/ClientPackage';
import { Message } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class FlightDataRedactionService extends BaseService {
  messages!: Message[];

  constructor(
    public http: HttpClient) {
    super();
    this.baseUrl = environment.apiUrl.datadelivery;
  }

  getRedactionType(): Observable<RedactionType[]> {
    const url = this.baseUrl + "redactiontype";
    return this.http.get<RedactionType[]>(url, { headers: this.httpGetHeadersForJSON() })
      .pipe(catchError(this.httpHandleError)
      );
  }

  getZipTypes(): Observable<ZipType[]> {
    const url = this.baseUrl + "datadelivery-ziptypes";
    return this.http.get<ZipType[]>(url, { headers: this.httpGetHeadersForJSON() })
      .pipe(catchError(this.httpHandleError)
      );
  }

  getFligtRedactions(): Observable<FlightDataRedactions[]> {
    const url = this.baseUrl + "flightdataredaction";
    return this.http.get<FlightDataRedactions[]>(url, { headers: this.httpGetHeadersForJSON() })
      .pipe(catchError(this.httpHandleError)
      );
  }

  getFligtRedaction_by_id(redaction_id: number): Observable<FlightDataRedactions> {
    const url = this.baseUrl + "flightdataredaction" + `/${redaction_id}`;
    return this.http.get<FlightDataRedactions>(url, { headers: this.httpGetHeadersForJSON() })
      .pipe(catchError(this.httpHandleError)
      );
  }

  addFlightRedaction(flightredaction: ResponseFlightDataRedaction) {
    const url = this.baseUrl + 'flightdataredaction';
    return this.http.post<number>(url, flightredaction, { headers: this.httpGetHeadersForJSON() })
      .pipe(
        catchError(this.httpHandleError)
      )
  }

  updateFlightRedaction(flightredaction: ResponseFlightDataRedaction) {
    const url = this.baseUrl + 'flightdataredaction';
    return this.http.put<number>(url, flightredaction, { headers: this.httpGetHeadersForJSON() })
      .pipe(
        catchError(this.httpHandleError)
      )
  }

  public deleteFlightRedaction(redaction_id: number) {
    const url = this.baseUrl + 'flightdataredaction/' + `${redaction_id}`;
    const options = { headers: this.httpGetHeadersForJSON() }
    return this.http.delete(url, options)
      .pipe(catchError(this.httpHandleError));
  }

  clonetodds(flightredaction: ResponseFlightDataRedaction[]) {
    const url = this.baseUrl + 'sendredactionfrmtdytodds';
    return this.http.post<number>(url, flightredaction, { headers: this.httpGetHeadersForJSON() })
      .pipe(
        catchError(this.httpHandleError)
      )
  }

  public getErrorMessage(message: string) {
    this.messages = [{ severity: 'warn', summary: '', detail: message }];
    return this.messages;
  }
}
