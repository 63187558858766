<p-blockUI [blocked]="loadingCount > 0">
  <tdy-spinner style="position:absolute;left:50%;top:50%;z-index: 10000"></tdy-spinner>
</p-blockUI>
<div class="card" *ngIf="loadingCount==0">
  <div class="card-header m-2">
    <h5>{{pageTitle}}</h5>
    <p-dropdown [options]="file_dates" [(ngModel)]="selected_date" optionLabel="dte" [filter]="true"
      (onChange)="onChange()" tooltip="Filter by AIRAC date" filterBy="dte" [showClear]="false"
      placeholder="Select a date">
      <ng-template pTemplate="selectedItem">
        <div *ngIf="selected_date">
          <div>{{selected_date.dte}}</div>
        </div>
      </ng-template>
      <ng-template let-filedate pTemplate="item">
        <div class="country-item">
          <div>{{filedate.dte}}</div>
        </div>
      </ng-template>
    </p-dropdown>
  </div>


  <div class="card mb-0">


    <div class=" grid">
      <div class="lg:col-6 p-0 m-0">
        <div class="custom-panel  m-2 p-4 mb-4">
          <h6>Details</h6>
          <div class="grid m-0 p-2 mb-2 row-highlights" *ngIf="data">
            <div class="col"><span>Airport ICAO Identifier: </span></div>
            <div class="col">{{data.airport_icao_identifier || "-"}}</div>
            <div class="col"><span>Cycle Date: </span></div>
            <div class="col">{{data.cycle_date || "-"}}</div>
          </div>
          <div class="grid m-0 p-2 mb-2 row-highlights" *ngIf="data">
            <div class="col"><span>Station Declination: </span></div>
            <div class="col">{{data.station_declination || "-"}}</div>
            <div class="col"><span>Frequency Protection: </span></div>
            <div class="col">{{data.frequency_protection || "-"}}</div>
          </div>
        </div>
      </div>

      <div class="lg:col-6 p-0 m-0">
        <div class="custom-panel   m-2 p-4 mb-4">
          <h6>DME</h6>
          <div class="grid m-0 p-2 mb-2 row-highlights" *ngIf="data">
            <div class="col"><span>DME Identifier: </span></div>
            <div class="col">{{data.dme_ident || "-"}}</div>
            <div class="col"><span>DME Latitude: </span></div>
            <div class="col">{{ data.dme_latitude !== null ? (data.dme_latitude | number:'1.8-8') : '-' }}</div>
          </div>
          <div class="grid m-0 p-2 mb-2 row-highlights" *ngIf="data">
            <div class="col"><span>Elevation: </span></div>
            <div class="col">{{data.dme_elevation || "-"}}</div>
            <div class="col"><span>DME Longitude: </span></div>
            <div class="col">{{ data.dme_longitude !== null ? (data.dme_longitude | number:'1.8-8') : '-' }}</div>
          </div>
          <div class="grid m-0 p-2 mb-2 row-highlights" *ngIf="data">
            <div class="col"><span>ILS Bias: </span></div>
            <div class="col">{{data.ils_dme_bias || "-"}}</div>
            <div class="col"></div>
            <div class="col"></div>
          </div>
        </div>

      </div>
    </div>

  </div>
  <div class="grid">


    <div class=" lg:col-6">
      <div class="custom-panel   m-2 p-4 mb-4">
        <h6>VOR</h6>
        <div class="grid m-0 p-2 mb-2 row-highlights" *ngIf="data">
          <div class="col"><span>VOR Identifier: </span></div>
          <div class="col">{{data.vor_identifier || "-"}}</div>
          <div class="col"><span>VOR Latitude: </span></div>
          <div class="col">{{ data.vor_latitude !== null ? (data.vor_latitude | number:'1.8-8') : '-' }}</div>
        </div>
        <div class="grid m-0 p-2 mb-2 row-highlights" *ngIf="data">
          <div class="col"><span>Name: </span></div>
          <div class="col">{{data.vor_name || "-"}}</div>
          <div class="col"><span>VOR Longitude: </span></div>
          <div class="col">{{ data.vor_longitude !== null ? (data.vor_longitude | number:'1.8-8') : '-' }}</div>
        </div>
        <div class="grid m-0 p-2 mb-2 row-highlights" *ngIf="data">
          <div class="col"><span>Frequency: </span></div>
          <div class="col">{{data.frequency || "-"}}</div>
          <div class="col"><span>Navaid Class: </span></div>
          <div class="col">{{data.navaid_class || "-"}}</div>
        </div>
      </div>

    </div>


  </div>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="5">No terminal VHF navaid found.</td>
    </tr>
  </ng-template>
</div>
