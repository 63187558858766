export class FlightDataRedactions {
  redaction_id?: number;
  name: string;
  description: string;
  textualoutput: boolean;
  mapjson?: string;
  defaultparametervaluesconfig?: string;
  dataframe_id: number;
  redactiontype_id: number;
  organization_id: number;
  ziptype: string;
  dataframename: string;
  receiver_organization_id?: string;
}

export interface ResponseFlightDataRedaction {
  redaction_id: number;
  redaction_name: string;
  redaction_description?: string;
  redaction_textualoutput?: boolean;
  redaction_mapjson?: string;
  redaction_defaultparametervaluesconfig: string;
  redaction_dataframe_id: number;
  redaction_redactiontype_id: number;
  organization_id: number;
  enhanced: boolean;
  receiver_organization_id?: string;
}

export interface ConvertType {
  conversion_id: number;
  conversion_type: string;
}
export interface DataFrameType {
  dataframe_id?: number;
  dataframe_type: string;
}
export class FilePathType {
  filename: string;
  fileContent: string;
}

export interface RedactionType {
  redactiontype_id: number;
  name: string;
}

export interface AvailableDataFrame {
  dataframe_type: string;
}

export const REDACTION_TYPES = {
  RAW: 1,
  CSV: 2,
  PARQUET: 3,
};

export const mappedRedactionTypes = Object.entries(REDACTION_TYPES).map(([name, id]) => ({ name, redactiontype_id: id }))
