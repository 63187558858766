import { AsyncValidatorFn, ValidatorFn } from '@angular/forms';

export class FormInputBase<T> {
  value: T | undefined;
  key: string;
  label: string;
  required: boolean;
  order: number;
  controlType: string;
  type: string;
  optionsEnhanced: { key: string; value: string; }[];
  options: any[];
  additionalDataVal;
  optionLabel: string;
  optionValue: string;
  validators: ValidatorFn[] | null;
  asyncValidators: AsyncValidatorFn[] | null;
  readonly: boolean;
  hintLabel: string;
  iconClass: string;
  placeholder: string;
  errorMessages: any | undefined;
  block: string;
  colSize: number | undefined;
  colStart: boolean | undefined;
  chipOptions: any[] | undefined;
  inputType: string;
  disabled: boolean | undefined;
  class: string | undefined;
  pattern: string | undefined;
  maxLength: number;
  minLength: number;
  max: number;
  min: number;
  keyUpEvent: string;
  newRow: boolean;
  boolValue: boolean;
  rowSize: number;
  constructor(
    options: {
      value?: T;
      key?: string;
      label?: string;
      required?: boolean;
      order?: number;
      controlType?: string;
      type?: string;
      options?: any[];
      optionsEnhanced?: { key: string; value: string }[];
      additionalDataVal?: string;
      optionLabel?: string;
      optionValue?: string;
      chipOptions?: any[];
      class?: string;
      validators?: ValidatorFn[] | null;
      asyncValidator?: AsyncValidatorFn[] | null;
      readonly?: boolean;
      hintLabel?: string;
      iconClass?: string;
      placeholder?: string;
      minErrorMessage?: string;
      errorMessages?: any;
      block?: string;
      rowStart?: boolean;
      rowEnd?: boolean;
      colSize?: number | undefined;
      colStart?: boolean;
      disabled?: boolean | undefined;
      pattern?: string;
      inputType?: string;
      maxLength?: number | undefined;
      minLength?: number | undefined;
      max?: number | undefined;
      min?: number | undefined;
      keyUpEvent?: string;
      boolValue?: boolean;
      newRow?: boolean;
      rowSize?: number | undefined;
    } = {},
  ) {
    this.value = options.value;
    this.key = options.key || '';
    this.label = options.label || '';
    this.required = !!options.required;
    this.order = options.order === undefined ? 1 : options.order;
    this.controlType = options.controlType || '';
    this.type = options.type || '';
    this.options = options.options || [];
    this.optionsEnhanced = options.optionsEnhanced || [];
    this.additionalDataVal = options.additionalDataVal;
    this.optionLabel = options.optionLabel;
    this.optionValue = options.optionValue;
    this.validators = options.validators || [];
    this.asyncValidators = options.asyncValidator || [];
    this.readonly = !!options.readonly;
    this.hintLabel = options.hintLabel || '';
    this.iconClass = options.iconClass || '';
    this.placeholder = options.placeholder || '';
    this.errorMessages = options.errorMessages || {};
    this.block = options.block || '';
    this.colSize = options.colSize || undefined;
    this.colStart = options.colStart;
    this.inputType = options.inputType;
    this.chipOptions = options.chipOptions;
    this.class = options.class;
    this.pattern = options.pattern;
    this.maxLength = options.maxLength;
    this.minLength = options.minLength;
    this.max = options.max;
    this.min = options.min;
    this.disabled = options.disabled;
    this.keyUpEvent = options.keyUpEvent;
    this.boolValue = options.boolValue;
    this.newRow = options.newRow;
    this.rowSize = options.rowSize;
  }
}
