<p-blockUI [blocked]="loadingCount > 0">
  <tdy-spinner style="position:absolute;left:50%;top:50%;z-index: 10000"></tdy-spinner>
</p-blockUI>
<div class="col-12 card p-3">
  <div class="card-header m-2">
    <h5>{{pageTitle}}</h5>
  </div>
  <div *ngIf="clientauth" class="col-12 card">
    <form #addclientauthForm="ngForm" (ngSubmit)="onSubmit()" novalidate>
      <div class="mt-2">
        <div class="formgrid grid">
          <div class="field col-12 md:col-6 lg:col-4">
            <span class="p-float-label">
              <input type="text" id="Name" maxlength="50" [disabled]="isReadonly" name="Name" pattern=".*\S.*"
                pInputText required #Name="ngModel" [(ngModel)]="clientauth.Name" [style]="{ width: '100%' }" />
              <label for="Name">Name</label>
            </span>
            <div class="tdy-required" *ngIf="( Name.dirty  || Name.touched) && Name.errors?.['required']">
              Name is required
            </div>
            <div class="tdy-required" *ngIf="Name.hasError('pattern')">
              Name cannot be left blank.
            </div>
          </div>
          <div class="field col-12 md:col-6 lg:col-4">
            <span class="p-float-label">
              <input type="number" id="client_id" name="client_id" pInputText required #client_id="ngModel"
                [(ngModel)]="clientauth.ClientId" [style]="{ width: '100%' }" [disabled]="true" />
              <label for="client_id">Client ID</label>
            </span>

          </div>
          <div class="field col-12 md:col-6 lg:col-4">
            <span class="p-float-label">
              <input type="text" id="client_secret" name="client_secret" pInputText required #client_secret="ngModel"
                [(ngModel)]="clientauth.ClientSecret" [style]="{ width: '100%' }" [disabled]="true" />
              <label for="Name">Client Secret</label>
            </span>
          </div>
        </div>
      </div>
      <div class="flex justify-content-end">
        <span class="p-input-icon-left">
          <button pButton label="Cancel" class="p-button-text" icon="pi pi-times" type="button"
            (click)="onCancel()"></button>
        </span>
        <span class="p-input-icon-left">
          <button pButton type="button" *ngIf="showButtons" [disabled]="!addclientauthForm.dirty" label="Reset"
            class="p-button-text" icon="pi pi-replay" (click)="onReset()"></button>
        </span>
        <span class="p-input-icon-left pl-2">
          <button pButton *ngIf="showButtons" [disabled]="(addclientauthForm.invalid)" pRipple label="Generate"
            type="submit" icon="pi pi-check"></button>
        </span>
      </div>
    </form>
  </div>
</div>
