<p-blockUI [blocked]="loadingCount > 0">
    <tdy-spinner style="position:absolute;left:50%;top:50%;z-index: 10000"></tdy-spinner>
</p-blockUI>
<div class="card" *ngIf="loadingCount == 0">
    <div class="card-header m-2">
        <h5>{{pageTitle}}</h5>
        <p-dropdown [options]="file_dates" [(ngModel)]="selected_date" optionLabel="dte" [filter]="true"
            (onChange)="onChange()" tooltip="Filter by AIRAC date" filterBy="dte" [showClear]="false"
            placeholder="Select a date">
            <ng-template pTemplate="selectedItem">
                <div *ngIf="selected_date">

                    <div>{{selected_date.dte}}</div>
                </div>
            </ng-template>
            <ng-template let-filedate pTemplate="item">
                <div class="country-item">

                    <div>{{filedate.dte}}</div>
                </div>
            </ng-template>
        </p-dropdown>
    </div>
    <div class="col-12 terminal_waypoint_table">
        <p-table #dt_waypoints [value]="data" dataKey="customer_area_code" dataKey="waypoint_identifier"
            [showCurrentPageReport]="true" [paginator]="data.length > defaultTableRows"
            [rowsPerPageOptions]="[20,50,100,200]" [rows]="defaultTableRows"
            currentPageReportTemplate="{{currentPageReport + ' ' + pageTitle}}" styleClass="" selectionMode="single"
            [(selection)]="selected_terminalwaypoints" (onRowSelect)="onRowSelect()">
            <ng-template pTemplate="caption">
                <!-- <div class="flex flex-row-reverse">

            </div> -->
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="waypoint_identifier" [ngStyle]="{'width':'10%'}">
                        <div class="p-d-flex p-jc-between p-ai-center">
                            Waypoint Identifier
                            <p-sortIcon field="waypoint_identifier"></p-sortIcon>
                            <p-columnFilter type="text" field="waypoint_identifier" display="menu"
                                [showOperator]="false" [showAddButton]="false"></p-columnFilter>
                        </div>

                    </th>
                    <th pSortableColumn="waypoint_name_description" [ngStyle]="{'width':'10%'}">
                        <div class="p-d-flex p-jc-between p-ai-center">
                            Name
                            <p-sortIcon field="waypoint_name_description"></p-sortIcon>
                            <p-columnFilter type="text" field="waypoint_name_description" display="menu"
                                [showOperator]="false" [showAddButton]="false"></p-columnFilter>
                        </div>
                    </th>
                    <th pSortableColumn="waypoint_usage" [ngStyle]="{'width':'10%'}">
                        <div class="p-d-flex p-jc-between p-ai-center">
                            Usage
                            <p-sortIcon field="waypoint_usage"></p-sortIcon>
                            <p-columnFilter type="text" field="waypoint_usage" display="menu" [showOperator]="false"
                                [showAddButton]="false"></p-columnFilter>
                        </div>
                    </th>
                    <th pSortableColumn="waypoint_type" [ngStyle]="{'width':'10%'}">
                        <div class="p-d-flex p-jc-between p-ai-center">
                            Type
                            <p-sortIcon field="waypoint_type"></p-sortIcon>
                            <p-columnFilter type="text" field="waypoint_type" display="menu" [showOperator]="false"
                                [showAddButton]="false"></p-columnFilter>
                        </div>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-terminalwaypoint>
                <tr [pSelectableRow]="terminalwaypoint">
                    <td>
                        {{terminalwaypoint.waypoint_identifier || "-"}}
                    </td>
                    <td>
                        {{terminalwaypoint.waypoint_name_description || "-"}}
                    </td>
                    <td>
                        {{terminalwaypoint.waypoint_usage || "-"}}
                    </td>
                    <td>
                        {{terminalwaypoint.waypoint_type || "-"}}
                    </td>


                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="4">No terminal waypoints found.</td>
                </tr>
            </ng-template>
        </p-table>

        <div *ngIf="data.length > 0 && defaultTableRows >= data.length" style="text-align: center;">
            <p class="row-paging">Showing 1 to
                {{data.length}} of {{data.length}} {{pageTitle}}</p>
        </div>
    </div>
</div>
