<p-blockUI [blocked]="loadingCount > 0">
    <tdy-spinner style="position:absolute;left:50%;top:50%;z-index: 10000"></tdy-spinner>
</p-blockUI>
<p-confirmDialog></p-confirmDialog>

<div class="col-12 card p-3">
    <form #addForm="ngForm" (ngSubmit)="onSubmit();" novalidate>
        <div class="card-header m-2">
            <h5>{{pageTitle}}</h5>
        </div>
        <div class="col-12   ">
            <div class=" ">
                <div class="mb-0">
                    <h6>Upload Data Frame</h6>

                    <div class="col-6 p-0">
                        <p-fileUpload #contentUpload (onRemove)="onFileRemove()" (onSelect)="onFileSelect($event)"
                            [showCancelButton]=false [showUploadButton]=false [fileLimit]="1" [multiple]="false"
                            [accept]="allowedFileType" chooseLabel="File" class="col-12" id="FileUpload" mode="advanced"
                            name="FileUpload" url="">
                            <ng-template pTemplate="content">
                                <div class="p-text-center">
                                    <div *ngIf="!isFileSelected">
                                        No file uploaded.
                                    </div>
                                </div>
                            </ng-template>
                        </p-fileUpload>
                    </div>
                </div>
            </div>
        </div>

        <div class="field">
            <div class="card mb-4 flex-1">
                <div>
                    <div class="mb-1">
                        <p-pickList [source]="availableParameters" [target]="selectedParameters" [responsive]="true"
                            sourceHeader="Available Parameters" targetHeader="Selected Parameters"
                            filterBy="missingParamsList" [showSourceControls]="false" [showTargetControls]="false"
                            [sourceStyle]="{'height':'200px'}" (onMoveToTarget)="onMoveToTarget()"
                            (onMoveToSource)="onMoveToSource()" [targetStyle]="{'height':'200px'}"
                            (onMoveAllToSource)="onMoveToSource()" (onMoveAllToTarget)="onMoveToTarget()">
                            <ng-template let-missingParamsList pTemplate="item">
                                <div>
                                    <div>{{missingParamsList}} </div>
                                </div>
                            </ng-template>
                        </p-pickList>
                        <div *ngIf="selectedParameters.length<1 && availableParameters.length>0" class="tdy-required">
                            At least 1 Parameter is required.
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="flex justify-content-end">
            <span class="p-input-icon-left">
                <button (click)="onCancel()" class="p-button-text" icon="pi pi-times" id="cancelButton" label="Cancel"
                    pButton pRipple type="button"></button>
            </span>
            <span class="p-input-icon-left">
                <button (click)="onReset()" class="p-button-text" icon="pi pi-undo" id="resetButton" label="Reset"
                    pButton pRipple [disabled]="!isFileSelected" type="button"></button>
            </span>
            <span class="p-input-icon-left pl-2">
                <button [disabled]="isSaveDisabled " icon="pi pi-check" id="okButton" label="Save" pButton pRipple
                    type="submit"></button>
            </span>
        </div>
    </form>
</div>
