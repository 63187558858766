import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from '../../../../common/services/base.service';
import { catchError } from 'rxjs/operators';
import { environment } from "src/environments/environment";
import { DataDeliveryAlerts, Datadelivery_alert_type, ResponseDDSAlerts } from "../models/DatadeliveryAlerts";
@Injectable({
  providedIn: 'root'
})
export class DataDeliveryAlertService extends BaseService {

  constructor(
    private http: HttpClient) {
    super();
    this.baseUrl = environment.apiUrl.datadelivery;
  }

  getAlertType(): Observable<Datadelivery_alert_type[]> {
    const url = this.baseUrl + "datadelivery-alerttypes";
    return this.http.get<Datadelivery_alert_type[]>(url, { headers: this.httpGetHeadersForJSON() })
      .pipe(catchError(this.httpHandleError)
      );
  }

  getRecordingAlerts(): Observable<DataDeliveryAlerts[]> {
    const url = this.baseUrl + "datadelivery-alert";
    return this.http.get<DataDeliveryAlerts[]>(url, { headers: this.httpGetHeadersForJSON() })
      .pipe(catchError(this.httpHandleError)
      );
  }

  getAlerts_by_id(alert_id: number): Observable<DataDeliveryAlerts> {
    const url = this.baseUrl + "datadelivery-alert" + `/${alert_id}`;
    return this.http.get<DataDeliveryAlerts>(url, { headers: this.httpGetHeadersForJSON() })
      .pipe(catchError(this.httpHandleError)
      );
  }

  addRecordingAlert(responseAlerts: ResponseDDSAlerts) {
    const url = this.baseUrl + 'datadelivery-alert';
    return this.http.post<number>(url, responseAlerts, { headers: this.httpGetHeadersForJSON() })
      .pipe(
        catchError(this.httpHandleError)
      )
  }

  updateRecordingAlert(responseAlerts: ResponseDDSAlerts) {
    const url = this.baseUrl + 'datadelivery-alert';
    return this.http.put<number>(url, responseAlerts, { headers: this.httpGetHeadersForJSON() })
      .pipe(
        catchError(this.httpHandleError)
      )
  }

  public deleteRecordingAlert(alert_id: number) {
    const url = this.baseUrl + 'datadelivery-alert/' + `${alert_id}`;
    const options = { headers: this.httpGetHeadersForJSON() }
    return this.http.delete(url, options)
      .pipe(catchError(this.httpHandleError));
  }

}
