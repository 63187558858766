export class AircraftRecorderModel {
  acmsreportmap_id: number;
  acmsreportmap_name: string;
  acmsreportmap_version: number;
  acmsreportmapversion_id: number;
  aircraft_registration: string;
  aircraftmodel: string;
  aircraftmodel_id: number;
  aircraftrecorder_id: number;
  dataframe1_id: number;
  dataframe1_name: string;
  dataframe1_version: number;
  dataframe2_id: number;
  dataframe2_name: string;
  dataframe2_version: number;
  dataframeversion1_id: number;
  dataframeversion2_id: number;
  enginetype_id: number;
  enginetype_name: string;
  recorder1_id: number;
  recorder1_name: string;
  recorder2_id: number;
  recorder2_name: string;
  recordingstandard1_id: number;
  recordingstandard2_id: number;
  recordingstandard1_name: string;
  recordingstandard2_name: string;
  sarmap_id: number;
  sarmap_name: string;
  sarmap_version: number;
  sarmapversion_id: number;
}

export class AircraftRecorderDetails {
  dataframe_id: number;
  dataframeversion_id: number;
  recorder_id: number;
  recordingstandard_id: number;
  // recordersList:{label:string, value:string}[]
  // dataFramesList:{label:string, value:string}[]
  // dataFrameVersonsList:{label:string, value:string}[]
}

export class UpdateAircraftRecorderModel { // new final data object
  aircraftrecorder_id: number;
  sarmap_id: number;
  sarmapversion_id: number;
  acmsreportmap_id: number;
  acmsreportmapversion_id: number;
  recorders: RecorderData[];
}

export class RecorderData {
  recorder_id: number;
  dataframe_id: number;
  dataframeversion_id: number;
}
