<p-blockUI [blocked]="loadingCount > 0">
  <tdy-spinner style="position: absolute; left: 50%; top: 50%; z-index: 10000"></tdy-spinner>
</p-blockUI>
<div class="col-12 card p-3">
  <div class="card-header m-2">
    <h5>{{ pageTitle }}</h5>
  </div>

  <div *ngIf="clientpackage" class="col-12 card">
    <app-clientpackage-form [aircraftGroups]="aircraftGroups" [availableAircraft]="availableAircraft"
      [availableEndPoints]="availableEndPoints" [clientpackage]="clientpackage" [conversionType]="conversionType"
      [redactionType]="redactionType" [isNew]="isNew" [isReadonly]="isReadonly" [isRedaction]="isRedaction"
      [pickListChange]="pickListChange" [selectedAircraftGroup]="selectedAircraftGroup"
      [selectedEndPointsIDs]="selectedEndPointsIDs" [selectedRedactionId]="selectedRedactionId"
      [selectedTargetAircraftGroup]="selectedTargetAircraftGroup" [showButtons]="showButtons"
      [targetAircraft]="targetAircraft" [zipType]="zipType"
      (aircraftMoveAllToSource)="onAircraftMoveAllToSource($event)"
      (aircraftMoveAllToTarget)="onAircraftMoveAllToTarget($event)"
      (aircraftMoveToSource)="onAircraftMoveToSource($event)" (aircraftMoveToTarget)="onAircraftMoveToTarget($event)"
      (cancelForm)="onCancel()" (changeAircraftGroup)="onChangeAircraftGroup($event)"
      (changeTargetAircraftGroup)="onChangeTargetAircraftGroup($event)"
      (conversionTypeChange)="onConversionType($event)" (moveAllToSource)="onMoveAllToSource($event)"
      (moveAllToTarget)="onMoveAllToTarget($event)" (moveToSource)="onMoveToSource($event)"
      (moveToTarget)="onMoveToTarget($event)" (redactionTypeChange)="onRedactionType($event)" (resetForm)="onReset()"
      (submitForm)="submitForm($event)" (zipSelectionChange)="onZipSelection($event)"></app-clientpackage-form>
  </div>
</div>
<p-overlayPanel #panel [showCloseIcon]="true" [style]="{ width: '500px' }">
  <ng-template pTemplate="content">
    <div>
      <h5>Wildcards</h5>
    </div>
    <li class="ml-3 mb-2"><span>Aircraft Registration (Reg)</span></li>
    <li class="ml-3 mb-2"><span>Airline ICAO (AirlineICAO)</span></li>
    <li class="ml-3 mb-2"><span>Airline IATA (AirlineIATA)</span></li>
    <li class="ml-3 mb-2"><span>Current Year: %yyyy%</span></li>
    <li class="ml-3 mb-2"><span>Current Month: %MM% </span></li>
    <li class="ml-3 mb-2"><span>Current Day('dd')</span></li>
    <li class="ml-3 mb-2"><span>Current Hour('hh')</span></li>
    <li class="ml-3 mb-2"><span>Current Minute('mm')</span></li>
    <li class="ml-3 mb-2"><span>Current Second('ss')</span></li>
    <li class="ml-3 mb-2"><span>Package Name (Package)</span></li>
    <li class="ml-3 mb-2"><span>Source File Name(Source)</span></li>
  </ng-template>
</p-overlayPanel>
