import { FeatureRoot } from 'src/app/common/models/feature-item.model';
import { Components } from './data-delivery-insights.components';
import { permissions } from './data-delivery-insights.permissions';

export const dataDeliveryInsightsMenu: FeatureRoot = {
  label: 'Data Delivery Insights',
  icon: 'hub',
  user_management: {
    feature_name: 'Endpointdatadelivery',
    user_permission: permissions.datadeliveryinsights_users.view,
    roles_permission: permissions.datadeliveryinsights_roles.view,
  },
  items: [
    {
      label: 'Data Delivery Insights',
      icon: 'hub',
      items: [
        Components.DataDeliveryInsights,
        Components.DataRedactions,
        Components.ACMSReportRedactionsDataDelivery,
        Components.SARRedactionsDataDelivery,
        Components.ClientpackagesDataDelivery,
      ],
    },
  ],
};
