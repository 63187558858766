import { FeatureRoot } from "src/app/common/models/feature-item.model";
import { Components } from "./administration.components";
import { permissions } from "./administration.permissions";

export const administrationMenu: FeatureRoot = {
    label: "Administration",
    icon: "edit_document",
    user_management: {
        feature_name: "Administration",
        user_permission: permissions.administration_users.view,
        roles_permission: permissions.administration_roles.view
    },
    items: [
        {
            label: "Organization",
            icon: "dashboard_customize",
            items: [
                Components.Airlines,
                Components.Company,
                Components.Users
            ]
        },
        {
            label: "Fleet",
            icon: "flight_takeoff",
            items: [
                Components.Airframer,
                Components.AircraftModels,
                Components.EngineTypes,
                Components.AircraftInterior,
                Components.Aircraft,
                Components.AircraftGroup
            ]
        }
        // Might need this in the future
        // {
        //     label: "Navigation Information",
        //     icon: "assistant_navigation",
        //     items: [
        //         Components.AirportList,
        //     ]
        // }
    ]
}
