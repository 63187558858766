import { Component, OnInit } from "@angular/core";
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from "@angular/router";
import { ConfirmationService, Message, MessageService } from "primeng/api";
import { BreadcrumbService } from "src/app/common/services/breadcrumb.service";
import { Organization } from "src/app/features/administration/models/organization";
import { DataFrameModel } from "src/app/features/configuration/models/dataframe";
import { FileSizeFormatPipe } from "src/app/platform/pipes/file-size-format.pipe";
import { BaseDetailsComponent } from "../../base/base-details-component";
import { dataframeDetailsInput } from "../../input-model/dataframe-input";
import { DataFrameService } from "src/app/features/configuration/services/data-frame.service";
import { takeUntil } from "rxjs";
import { HttpErrorResponse } from "@angular/common/http";
import { permissions } from "../../../../integration/configuration.permissions";
import { SecurityUserService } from "src/app/security/services/security-user.service";
import { Components } from '../../../../integration/configuration.components';
import { Action } from 'src/app/common/components/base-view.component';

@Component({
  selector: 'app-data-frame-details',
  templateUrl: '../../base/add.component.html',
  providers: [{
    provide: dataframeDetailsInput,
    useFactory: (dataFrameService: DataFrameService) => new dataframeDetailsInput(dataFrameService)
    ,
    deps: [DataFrameService]
  }]


})
export class DataFrameDetailsComponent extends BaseDetailsComponent<DataFrameModel, Organization> implements OnInit {
  //  dataframeId : number;
  dataframeName: string;
  // override cloneErrMsg : Message[]=[{severity:'error', summary:'Error', detail:'Message Content'}];
  override cloneErrMsg: Message[] = []
  constructor(
    private route: ActivatedRoute,
    router: Router,
    private location: Location,
    messageService: MessageService,
    confirmationService: ConfirmationService,
    breadcrumbService: BreadcrumbService,
    fileSizeFormatter: FileSizeFormatPipe,
    private dataframeInput: dataframeDetailsInput,
    private dataframeService: DataFrameService,
    private realUserService: SecurityUserService
  ) {
    super(messageService, confirmationService, router, breadcrumbService, fileSizeFormatter, dataframeInput);


  }
  ngOnInit(): void {
    this.userCanAdd = this.realUserService.userHasPermission(permissions.dataframes.create);
    this.userCanModify = this.realUserService.userHasPermission(permissions.dataframes.manage);
    // go to clone section
    if (this.route.snapshot.queryParamMap.get('c')) {
      if (!this.userCanAdd) {
        this.messageService.add({
          key: 'message',
          severity: 'error',
          summary: 'Unauthorized',
          detail: 'User is not authorized to clone a Data Frame.'
        });
        setTimeout(() => {
          this.location.back();
        }, 3000);
      }


      this.isClone = true;
      this.pageTitle = "";

      const a = this.route.snapshot.queryParamMap.get('a');
      this.loadingCount++;

      this.dataframeInput.getRecord(a).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
        next: res => {
          //this.dataframeId = res['model']['dataframe_id'];
          this.dataframeName = res['model']['name'];
          this.breadcrumbService.setItems(
            this.route,
            [{ label: Components.Dataframe.label, routerLink: Components.Dataframe.path },
            { label: `Versions (${this.dataframeName})`, routerLink: Components.DataframeVersions.path, queryParams: { "a": a, "Name": this.dataframeName } },
            { label: `Clone Data Frame Version (${this.route.snapshot.queryParamMap.get('v')})` }]);

        },
        error: err => {
          this.showErrorMsg(`${err}`, Action.Get, `Data Frame`, `${a}`);
          this.loadingCount--;
        },
        complete: () => { this.loadingCount--; }

      }

      )

      /*
       this.breadcrumbService.setItems(
       this.route,
       [{label : Components.Dataframe.label , routerLink: Components.Dataframe.path  },
        {label : `Versions (${this.dataframeName})`, routerLink: Components.DataframeVersions.path, queryParams:{"a": a, "Name": this.dataframeName }},
        {label : `Clone Data Frame Version (${this.route.snapshot.queryParamMap.get('v')})`}       ]);*/

    }
    // go to manage section
    else if (this.route.snapshot.queryParamMap.get('a')) {
      if (!this.userCanModify) {
        this.messageService.add({
          key: 'message',
          severity: 'error',
          summary: 'Unauthorized',
          detail: 'User is not authorized to manage a Data Frame.'
        });
        setTimeout(() => {
          this.location.back();
        }, 3000);
      }


      this.breadcrumbService.setItems(
        this.route,
        [{ label: Components.Dataframe.label, routerLink: Components.Dataframe.path },
        { label: `Manage Data Frame` }
        ]);
      this.load_Record(+this.route.snapshot.queryParamMap.get('a'))
    }
    else {
      // go to add section
      if (this.userCanAdd) {
        this.breadcrumbService.setItems(
          this.route,
          [{ label: Components.Dataframe.label, routerLink: Components.Dataframe.path },
          { label: `New Data Frame` }

          ]);
        this.load_Record(-1);
      }
      else {
        this.messageService.add({
          key: 'message',
          severity: 'error',
          summary: 'Unauthorized',
          detail: 'User is not authorized to add a Data Frame.'
        });

        // Wait a few seconds for user to see the error, and send them back to where they came from.
        setTimeout(() => {
          this.location.back();
        }, 3000);
      }
    }
  }


  override onCancelClone(): void {
    this.dataframeName = this.dataframeName.replace('(', '').replace(')', '');
    this.router.navigate([Components.DataframeVersions.path], {
      queryParams: {
        a: this.route.snapshot.queryParamMap.get('a'),
        "Name": this.dataframeName
      }
    }).then();
  }

  override executeClone(): void {


    this.loadingCount++;
    this.dataframeService.cloneDataFrame(+this.route.snapshot.queryParamMap.get('c'), this.newCloneName).pipe(
      takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: () => {
          this.showSuccessMsg(Action.Add, `Data frame <${this.newCloneName}>`);
        },
        error: (err: HttpErrorResponse) => {
          this.cloneErrMsg = [{ severity: 'error', summary: `Unable to clone : ${err}`, detail: err.error }];
          this.loadingCount--;
        },
        complete: () => {
          this.loadingCount--;
          this.cloneErrMsg = [];
        }
      }
      )



  }

}
