import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/common/services/breadcrumb.service';
import { TerminalVHFNavaidService } from 'src/app/features/administration/services/terminal-vhf-navaid.service';
import { ApiType, NavigationInformationBaseViewComponent } from '../navigation-information-base/navigation-information-base-view.component';
import { VHFNavaid } from "src/app/features/administration/models/VHFNavaid";
import { Components } from 'src/app/features/administration/integration/administration.components';

@Component({
  selector: 'app-terminal-vhf-navaids',
  templateUrl: './terminal-vhf-navaids.component.html'

})
export class TerminalVHFNavaidsComponent extends NavigationInformationBaseViewComponent implements OnInit {
  pageTitle = Components.TerminalVHFNavaidsList.label;
  file_dates: any;
  selected_date: any;
  airport_icao_identifier: string;
  airport_identifier: string;
  terminalVHFNavaids: VHFNavaid[];
  selected_terminalVHFNavaid: VHFNavaid;

  constructor(
    private terminalVHFService: TerminalVHFNavaidService,
    messageService: MessageService,
    confirmationService: ConfirmationService,
    router: Router,
    route: ActivatedRoute,
    breadcrumbService: BreadcrumbService
  ) {
    super(messageService, confirmationService, router, breadcrumbService, route)
    this.airport_icao_identifier = route.snapshot.queryParamMap.get('a');
    this.airport_identifier = route.snapshot.queryParamMap.get('b');
    const dte = route.snapshot.queryParamMap.get('c');
    const file_dates = route.snapshot.queryParamMap.get('d');
    this.selected_date = { "dte": dte };
    this.file_dates = JSON.parse(file_dates);

    this.getTerminalVHFNavaidList(dte, this.airport_icao_identifier);
    this.setBreadcrumb(this.airport_identifier);
  }

  ngOnInit(): void {
    this.setBreadcrumb(this.airport_identifier)
  }

  getTerminalVHFNavaidList(data, airport_icao_identifier) {
    const arg: ApiType = {
      request: this.terminalVHFService.getTerminalVHFNavaids(data, airport_icao_identifier),
      api_name: 'terminal vhf navaids',
      callback: this.callback.bind(this)
    }
    this.callAPI(arg);
  }

  callback(data) {
    this.data = data;
  }

  override setBreadcrumb(airport_identifier) {
    super.setBreadcrumb(
      [
        {
          label: Components.AirportList.label,
          routerLink: Components.AirportList.path,
          queryParams: { a: this.selected_date["dte"] }
        },
        {
          label: Components.TerminalVHFNavaidsList.label + ` (${airport_identifier})`
        }
      ]);
  }

  onRowSelect() {
    const vor_identifier = this.selected_terminalVHFNavaid["vor_identifier"];
    this.router.navigate(
      [Components.TerminalVHFNavaidsDetails.path], {
      queryParams: {
        a: this.airport_icao_identifier,
        b: this.airport_identifier,
        c: this.selected_date["dte"],
        d: JSON.stringify(this.file_dates),
        e: vor_identifier
      }
    }
    )
  }

  override onChange(): void {
    super.onChange([Components.TerminalVHFNavaidsList.path],
      {
        queryParams: {
          a: this.airport_icao_identifier,
          b: this.airport_identifier,
          c: this.selected_date["dte"],
          d: JSON.stringify(this.file_dates)
        }
      });
  }
}
