import { FeatureRoot } from 'src/app/common/models/feature-item.model';
import { Components } from './datadelivery.components';
import { permissions } from './datadelivery.permissions';

export const datadeliveryMenu: FeatureRoot = {
  label: 'Data Delivery',
  icon: 'unarchive',
  user_management: {
    feature_name: 'Datadelivery',
    user_permission: permissions.datadelivery_users.view,
    roles_permission: permissions.datadelivery_roles.view,
  },
  items: [
    {
      label: 'Data Delivery',
      icon: 'unarchive',
      items: [
        Components.Endpoints,
        Components.DataRedactions,
        Components.ACMSReportRedactions,
        Components.SARRedactions,
        Components.ClientPackages,
        Components.ClientApplication,
        //Components.RecordingClientLog
        //Components.EnhancedRedaction,
        //Components.EnhancedPackages
      ],
    },
    //Hiding this for now
    // {
    //   label: 'Alerting',
    //   icon: 'notifications',
    //   items: [
    //     Components.DataDeliveryAlert,
    //     Components.UserRecordingNotifications
    //   ],
    // },
  ],
};
