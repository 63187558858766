import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: '[compare-result]',
  template: `
    <ng-container>

        <ng-template [ngIf]="!isDataFrame">
          <div class="col-12">
              <textarea [rows]="10" [(ngModel)]="comparisonResult"  pInputTextarea  readonly  style="resize:none;width:100%"></textarea>
          </div>
        </ng-template>
        <ng-template  [ngIf]="isDataFrame">
          <div class="grid">
            <p-panel header="Data Frame Changes" [toggleable]="false" class="col">
              <textarea id="textarea1" [rows]="10" [(ngModel)]="comparisonResult_data_frame_changes"  pInputTextarea  readonly  style="resize:none;width:100%"></textarea>
            </p-panel>
            <p-panel header="Parameters Added" [toggleable]="false" class="col">
               <textarea id="textarea2" [rows]="10" [(ngModel)]="comparisonResult_parameters_added"  pInputTextarea  readonly  style="resize:none;width:100%"></textarea>
            </p-panel>
            <p-panel header="Parameters Changed" [toggleable]="false" class="col">
               <textarea id="textarea3" [rows]="10" [(ngModel)]="comparisonResult_parameters_changed"  pInputTextarea  readonly  style="resize:none;width:100%"></textarea>
            </p-panel>
            <p-panel header="Parameters Removed" [toggleable]="false" class="col">
               <textarea id="textarea4" [rows]="10" [(ngModel)]="comparisonResult_parameters_removed"  pInputTextarea  readonly  style="resize:none;width:100%"></textarea>
            </p-panel>
          </div>
        </ng-template>
    </ng-container>
    `
})
export class CompareResultComponent implements OnInit {
  private _result: any;

  @Input('modelName') model: string;
  @Input() set result(value: any) {
    this._result = value;
    this.setValues();
  }
  comparisonResult: string;

  comparisonResult_data_frame_changes: string;
  comparisonResult_parameters_added: string;
  comparisonResult_parameters_changed: string;
  comparisonResult_parameters_removed: string;
  isDataFrame: boolean;

  ngOnInit() {

    this.isDataFrame = (this.model == "dataframe");
    this.setValues();
  }

  setValues() {
    if (typeof (this._result) == "object") {
      if (!this.isDataFrame && Array.isArray(this._result)) {
        this.comparisonResult = this._result.join('\n');
      } else {
        this.comparisonResult = this._result;

        if (this.comparisonResult["Data_frame_changes"].length != 0)
          this.comparisonResult_data_frame_changes = this.comparisonResult["Data_frame_changes"].join('\n');
        else
          this.comparisonResult_data_frame_changes = "No Changes."

        if (this.comparisonResult["Parameters_added"].length != 0)
          this.comparisonResult_parameters_added = this.comparisonResult["Parameters_added"].join('\n');
        else
          this.comparisonResult_parameters_added = "No Changes."

        if (Object.keys(this.comparisonResult["Parameters_changed"]).length != 0)
          this.comparisonResult_parameters_changed = this.DisplayParameterChanged(this.comparisonResult["Parameters_changed"]);
        else
          this.comparisonResult_parameters_changed = "No Changes."

        if (this.comparisonResult["Parameters_removed"].length != 0)
          this.comparisonResult_parameters_removed = this.comparisonResult["Parameters_removed"].join('\n');
        else
          this.comparisonResult_parameters_removed = "No Changes."
      }

    } else {
      if (!this.isDataFrame) {
        this.comparisonResult = "No difference found."
      }
    }
  }

  DisplayParameterChanged(list): string {
    const sub_result = [];
    (<any>window).lst = list
    //list.map(x=> sub_result.push(x.join('\n')));
    Object.keys(list).map(x => {
      sub_result.push(x)
      list[x].map(y => sub_result.push(y))
      sub_result.push('')
    })
    return sub_result.join('\n');
  }
}
