<p-blockUI [blocked]="loadingCount > 0">
  <tdy-spinner style="position:absolute;left:50%;top:50%;z-index: 10000"></tdy-spinner>
</p-blockUI>
<p-confirmDialog header="Confirm Deletion" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<div class="col-12 card">
  <div class="card-header m-2">
    <h5>{{pageTitle}}</h5>
    <div class="p-toolbar-group-end">
      <span>
        <button pButton pRipple label="Add" *ngIf="canAdd" id="addButton" icon="pi pi-plus" 
          (click)="openNew()"></button>
      </span>
     
    </div>
  </div>
  <p-table id="dt" [filterDelay]="0"
     #dataredaction sortField="name" [value]="flightdataredactions" [globalFilterFields]="[
      'name',
      'description',
      'dataframe',
      'ziptype',
      'textualoutput'
    ]"
    [showCurrentPageReport]="true" [paginator]="flightdataredactions.length > defaultTableRows" [rowsPerPageOptions]="[20,50,100,200]" [rows]="defaultTableRows" currentPageReportTemplate="{{currentPageReport + ' ' + pageTitle}}" 
    [(selection)]="selectedCloneData" (onRowSelect)="onRowSelect($event)" selectionMode="single" dataKey="redaction_id">
    <ng-template pTemplate="header" let-columns>
      <tr>
        
        <th *ngFor="let col of cols" [pSortableColumn]="col.field">
          <div class="flex justify-content-start align-items-center" *ngIf="col.header != 'Action'">
            {{ col.header }}
            <p-sortIcon [field]="col.field"></p-sortIcon>
            <p-columnFilter [showAddButton]="false" [showOperator]="false" class="ml-0" display="menu"
              [field]="col.field" type="text" matchMode="contains"></p-columnFilter>
          </div>
          <div *ngIf="col.header == 'Action'">
            
          </div>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-dataredaction>
      <tr [pSelectableRow]="dataredaction" class="p-selectable-row">
        
        <td>{{dataredaction.name}}</td>
        <td>{{dataredaction.description}}</td>
        <td>{{dataredaction.ziptype}}</td>
        <td>{{dataredaction.textualoutput}}</td>
        <td>
          <div class="flex align-items-center justify-content-end">
            <button id="cloneButton" name="cloneButton" type="button" class="p-button-rounded p-button-text mr-2"
              pButton icon="pi pi-clone" pRipple pTooltip="Clone..." *ngIf="canAdd"
              (click)="cloneredaction(dataredaction)"></button>
          </div>
        </td>
        <td>
          <div class="flex align-items-center justify-content-end">
            <button id="deleteButton" name="deleteButton" type="button" *ngIf="canDelete"
              class="p-button-rounded p-button-text p-button-danger" pButton pRipple icon="pi pi-trash"
              pTooltip="Delete..." (click)="deleteDataRedaction(dataredaction)"></button>
          </div>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td [attr.colspan]="7">No Enhanced Data Redactions Found.</td>
      </tr>
    </ng-template>
  </p-table>
  <div *ngIf="flightdataredactions.length > 0 && defaultTableRows >= flightdataredactions.length" style="text-align: center;">
    <p class="row-paging">Showing 1 to
      {{flightdataredactions.length}} of {{flightdataredactions.length}} {{pageTitle}}</p>
  </div>
</div>