import { Component, OnInit } from '@angular/core';
import { map } from 'rxjs';
import { SECURITY_CONSTANTS } from '../models/security-constants';
import { PostOrgSwitchBehavior, SecurityAuthService } from '../services/security-auth.service';
import { SecurityUserService } from '../services/security-user.service';

@Component({
  selector: 'app-org-login',
  template: ``,
  styles: [`
    .hide {
      visibility: hidden;
    }
  `]
})

export class OrgloginComponent implements OnInit {

  public postSwitchBehavior: PostOrgSwitchBehavior = PostOrgSwitchBehavior.Redirect
  public showSelector = false;

  constructor(
    private _authService: SecurityAuthService,
    private _userService: SecurityUserService
  ) { }

  public ngOnInit(): void {
    const subscription = this._userService.userInfo.subscribe(userInfo => {
      const orgs = userInfo.orgs;

      if (!!orgs && orgs.length === 1) {
        this._authService.switchOrg(orgs[0], this.postSwitchBehavior);
        setTimeout(() => subscription.unsubscribe(), 0);
        return;
      }

      const defaultOrgStorageKey = userInfo.email + SECURITY_CONSTANTS.favorite_org_local_storage_key_suffix;
      const savedOrg = localStorage.getItem(defaultOrgStorageKey);

      if (!!orgs) {
        if (!orgs.includes(savedOrg)) {
          localStorage.setItem(defaultOrgStorageKey, userInfo.defaultOrg);
          this._authService.switchOrg(userInfo.defaultOrg, this.postSwitchBehavior);
          setTimeout(() => subscription.unsubscribe(), 0);
          return;
        } else {
          this._authService.switchOrg(savedOrg, this.postSwitchBehavior);
          setTimeout(() => subscription.unsubscribe(), 0);
          return;
        }
        
      }

    });
  }
}
