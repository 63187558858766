import { Component, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { forkJoin, takeUntil } from 'rxjs';

import { Action, BaseViewComponent } from 'src/app/common/components/base-view.component';
import { SecurityUserService } from 'src/app/security/services/security-user.service';
import { BreadcrumbService } from '../../../../../common/services/breadcrumb.service';
import { Components } from '../../integration/datadelivery.components';
import { permissions } from '../../integration/datadelivery.permissions';
import { ACMS, FileType } from '../../models/ACMS';
import { ACMSRedactionService } from '../../services/acmsRedaction.service';
@Component({
  selector: 'app-acmsreport',
  templateUrl: './acmsreport.component.html',
  styleUrls: ['./acmsreport.component.css'],
})
export class AcmsreportComponent extends BaseViewComponent implements OnInit {
  public pageTitle = Components.ACMSReportRedactions.label;
  public canAdd: boolean;
  public canDelete: boolean;
  public cols: any[] = [];
  public acmsreports: ACMS[] = [];
  private canEdit: boolean;
  private messageLabel = 'ACMS Report Redaction';

  constructor(
    router: Router,
    breadcrumbService: BreadcrumbService,
    confirmationService: ConfirmationService,
    messageService: MessageService,
    private route: ActivatedRoute,
    private acmsRedactionService: ACMSRedactionService,
    private realUserService: SecurityUserService,
    private ngZone: NgZone
  ) {
    super(messageService, confirmationService, router, breadcrumbService);
    this.getBreadCrums();
  }

  ngOnInit() {
    this.getBreadCrums();
    this.canAdd = this.realUserService.userHasPermission(
      permissions.datadelivery_acms_redaction.create
    );
    this.canDelete = this.realUserService.userHasPermission(
      permissions.datadelivery_acms_redaction.delete
    );
    this.canEdit = this.realUserService.userHasPermission(
      permissions.datadelivery_acms_redaction.manage
    );
    this.loadACMSData();
  }

  openNew() {
    if (!this.canAdd) {
      this.showErrorMsg(
        'User is not authorized to add a ACMS Report Redaction..',
        Action.Add,
        `${this.messageLabel}`
      );
    } else {
      this.ngZone.run(() => {
        this.router.navigate([Components.ACMSReportAddRedactions.path]).then();
      });
    }
  }

  onRowSelect(event) {
    this.ngZone.run(() => {
      this.router.navigate([Components.ACMSReportAddRedactions.path], {
        queryParams: {
          redactionId: event.data.redaction_id,
          Name: event.data.name,
        },
      })
        .then();
    });
  }

  deleteACMS(acms: ACMS) {
    if (!this.canDelete) {
      this.showErrorMsg(
        'User is not authorized to delete an ACMS Report Redaction.',
        Action.Delete,
        `${this.messageLabel}`
      );
    } else {
      this.confirmationService.confirm({
        message:
          'Are you sure that you want to delete the selected ACMS Report Redaction?',
        accept: () => {
          this.acmsRedactionService
            .deleteACMSRedaction(acms.redaction_id)
            .subscribe({
              next: () => {
                this.showSuccessMsg(
                  Action.Delete,
                  `${this.messageLabel}`,
                  `${acms?.name}`
                );
                // Refresh the list of ACMS models
                this.loadACMSData();
              },
              error: (error) => {
                this.showErrorMsg(
                  `Error deleting ACMS Report Redaction ${acms?.name} with error ${error}`,
                  Action.Delete,
                  `${this.messageLabel}s`
                );
              },
            });
        },
      });
    }
  }

  cloneAcms(acms: ACMS) {
    this.ngZone.run(() => {
      this.router.navigate([Components.ACMSReportAddRedactions.path], {
        queryParams: {
          redactionId: acms.redaction_id,
          Name: acms.name,
          clone: true,
        },
        queryParamsHandling: 'merge',
      })
        .then();
    });
  }

  getBreadCrums() {
    this.breadcrumbService.setItems(this.route, [
      { label: Components.ACMSReportRedactions.label },
    ]);
  }

  loadACMSData() {
    this.loadingCount++;
    forkJoin({
      acmsReports: this.acmsRedactionService.getACMSRedactions(),
    })
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (results) => {
          this.acmsreports = [];
          results.acmsReports.forEach((element) => {
            let getMapJson = '';
            const jsonRegex = JSON.parse(element.defaultparametervaluesconfig);
            if (jsonRegex.filetype_id === FileType.AircraftReport) {
              try {
                getMapJson = JSON.parse(element.mapjson)?.regex;
              } catch (e) {
              }
            }
            this.acmsreports.push({
              acmsreportmap_id: element.acmsreportmap_id,
              acmsreportname: element.acmsreportname ?? '',
              description: element.description,
              name: element.name,
              redaction_id: element.redaction_id,
              defaultparametervaluesconfig: getMapJson,
            });
          });
        },
        error: (error) => {
          this.showErrorMsg(`${error}`, Action.Get, `${this.messageLabel}s`);
          this.loadingCount--;
        },
        complete: () => {
          this.loadingCount--;
        },
      });
  }
}
