import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs';
import { AircraftRecorderService } from 'src/app/features/configuration/services/aircraftRecorder.service';
import { BreadcrumbService } from 'src/app/common/services/breadcrumb.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Action, BaseViewComponent } from 'src/app/common/components/base-view.component';
import { ConfirmationService, MessageService, SelectItem } from 'primeng/api';
import { AircraftRecorderModel } from 'src/app/features/configuration/models/aircraftRecorder';
import { SortUtil } from 'src/app/platform/util/sortUtil';
import { AircraftGroup } from 'src/app/features/administration/models/aircraftGroup';
import { AircraftGroupService } from 'src/app/features/administration/services/aircraft-group.service';
import { forkJoin } from 'rxjs';
import { SecurityUserService } from 'src/app/security/services/security-user.service';
import { permissions } from '../../integration/configuration.permissions';
import { Components } from '../../integration/configuration.components';

@Component({
  selector: 'app-aircraft-recorder',
  templateUrl: './aircraft-recorder.component.html',
})
export class AircraftRecorderComponent
  extends BaseViewComponent
  implements OnInit {
  pageTitle = Components.AircraftRecorder.label;
  AircraftRecorderModel: AircraftRecorderModel
  bulkUpdate = false;
  recorderIds: number[] = [];
  recoderNames: string[] = [];
  tableData: AircraftRecorderModel[] = [];
  selectAllCheckbox: AircraftRecorderModel[] = [];
  updateButtonDisabled = true;
  downloadButtonDisabled = true;
  selectedAircraftGroup: number;
  aircraftGroups: SelectItem[];
  public canAdd: boolean;
  public canDelete: boolean;
  public canEdit: boolean;
  public canDownload: boolean;
  messageLabel = 'Aircraft Recorder';
  public dynamicdownloadName: string;

  constructor(
    private aircraftRecorderService: AircraftRecorderService,
    private aircraftGroupService: AircraftGroupService,
    private route: ActivatedRoute,
    private realUserService: SecurityUserService,
    breadcrumbService: BreadcrumbService,
    router: Router,
    confirmationService: ConfirmationService,
    messageService: MessageService

  ) {
    super(

      messageService,
      confirmationService,
      router,
      breadcrumbService
    );
    this.breadcrumbService.setItems(route, [
      { label: Components.AircraftRecorder.label, routerLink: Components.AircraftRecorder.path },
    ]);
  }

  cols: any[];

  exportColumns: any[];

  ngOnInit(): void {


    this.loadAircraftRecorderData(); // call the API to populate the Table
    this.canAdd = this.realUserService.userHasPermission(permissions.aircraft_recorders.create);
    this.canDelete = this.realUserService.userHasPermission(permissions.aircraft_recorders.delete);
    this.canEdit = this.realUserService.userHasPermission(permissions.aircraft_recorders.manage);
    this.cols = [
      { field: 'aircraft_registration', header: 'Aircraft Registration', customExportHeader: 'aircraft_registration' },
      { field: 'aircraftmodel', header: 'Aircraft Model', customExportHeader: 'aircraftmodel' },
      { field: 'enginetype_name', header: 'Engine Type', customExportHeader: 'enginetype_name' },
      { field: 'recorder1_name', header: 'Primary Recorder', customExportHeader: 'recorder1_name' },
      { field: 'dataframe1_name', header: 'Primary DataFrame', customExportHeader: 'dataframe1_name' },
      { field: 'dataframe1_version', header: 'Primary DataFrame Version', customExportHeader: 'dataframe1_version' },
    ];
    this.dynamicdownloadName = 'Aircraft_Recorders' + '_export_' + new Date().toLocaleDateString() + '_' + new Date().toLocaleTimeString();
    this.exportColumns = this.cols.map(col => ({ title: col.header, dataKey: col.field }));

  }

  loadAircraftRecorderData() {
    this.loadingCount++;
    forkJoin([
      this.aircraftRecorderService.listAircraftRecorders(),
      this.aircraftGroupService.listAircraftGroups()
    ])
      .pipe(takeUntil(this.ngUnsubscribe)).subscribe({
        next: ([aircraftRecorders, aircraftGroups]) => {
          this.tableData = aircraftRecorders
          this.aircraftGroups = this.populateDropdownItems<AircraftGroup>(aircraftGroups, 'aircraftgroup_name', 'aircraftgroup_id');
          this.downloadButtonDisabled = this.tableData.length < 1;
        },
        error: error => {
          this.showErrorMsg(`${error}`, Action.Get, `${this.messageLabel}`);
          this.loadingCount--;
        },
        complete: () => this.loadingCount--
      });

  }

  onClickCheckBox() {
    this.updateButtonDisabled = this.selectAllCheckbox.length < 1;
  }

  onClickUpdate() {

    this.selectAllCheckbox.forEach(element => {
      this.recorderIds.push(element.aircraftrecorder_id);
      this.recoderNames.push(element.aircraft_registration);
    });

    if (this.recoderNames.length > 1) {
      this.router.navigate([Components.AircraftRecorderDetails.path], {
        queryParams: {
          data: this.recorderIds.toString(), //need to convert to string so that the entire data can be retrieved
          name: ""
        }
      }).then();
    } else {
      this.router.navigate([Components.AircraftRecorderDetails.path], {
        queryParams: {
          data: this.recorderIds.toString(), //need to convert to string so that the entire data can be retrieved
          name: this.recoderNames.join(', ')
        }
      }).then();
    }


  }

  onRowClick(event, ac_recorder_id: number, ac_registration: string) {
    // Return if the click event's target is the checkbox:
    if (event.target.ariaChecked != null && event.srcElement.className.includes('checkbox')) {
      return;
    }

    this.router.navigate([Components.AircraftRecorderDetails.path], {
      queryParams: {
        data: ac_recorder_id.toString(), //need to convert to string so that the entire data can be retrieved
        name: ac_registration
      }
    }).then();
  }

  onChangeAircraftGroup() {
    this.loadingCount++;
    if (this.selectedAircraftGroup) {
      this.aircraftRecorderService.listAircraftRecordersbyAircraftGroup(this.selectedAircraftGroup).pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: data => {
            this.selectAllCheckbox = [];
            this.tableData = data;
            this.downloadButtonDisabled = data.length < 1;
          },
          error: err => {
            this.showErrorMsg(`${err}`, Action.Get, `${this.messageLabel}`);
            this.loadingCount--;
          },
          complete: () =>
            this.loadingCount--
        });
    } else {
      this.aircraftRecorderService.listAircraftRecorders().pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: data => {
            this.tableData = data;
            this.downloadButtonDisabled = data.length < 1;
          },
          error: err => {
            this.showErrorMsg(`${err}`, Action.Get, `${this.messageLabel}`);
            this.loadingCount--;
          },
          complete: () =>
            this.loadingCount--
        });
    }
  }

  populateDropdownItems<T>(items: T[], labelProp: string, valueProp: string, addAllOption = true): SelectItem[] {
    const options = [];
    // Some error checking
    if (!items || items.length == 0) {
      return options;
    }

    const keys = Object.keys(items[0]);
    if (!keys.includes(labelProp) || !keys.includes(valueProp)) {
      return options;
    }

    items.forEach(i => {
      options.push({ label: i[labelProp], value: i[valueProp] });
    });

    SortUtil.sortByLabel(options);

    if (addAllOption) {
      options.splice(0, 0, { label: 'All', value: null });   // Insert empty item as first item
    }
    return options;
  }
}
