import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { combineLatest, switchMap } from 'rxjs';
import { BreadcrumbService } from 'src/app/common/services/breadcrumb.service';
import { OrganizationType } from 'src/app/features/administration/models/organization';
import { Setting } from 'src/app/common/models/setting';
import { EnRouteWaypointService } from 'src/app/features/administration/services/enroutewaypoint.service';
import { SettingsService } from 'src/app/common/services/settings.service';
import { ApiType, NavigationInformationBaseViewComponent } from '../navigation-information-base/navigation-information-base-view.component';
import { Components } from 'src/app/features/administration/integration/administration.components';
import { Action } from 'src/app/common/components/base-view.component';

@Component({
  selector: 'app-en-route-waypoints-list',
  templateUrl: './enroute-waypoints.component.html'

})
export class EnRouteWaypointsListComponent extends NavigationInformationBaseViewComponent implements OnInit {
  pageTitle = Components.EnRouteWaypointsList.label;
  airline_settings: Setting;
  isICAO: boolean;
  selected_date: any;
  selected_airport: any;
  file_dates = [];

  constructor(
    private enRouteService: EnRouteWaypointService,
    private settingsService: SettingsService,
    messageService: MessageService,
    confirmationService: ConfirmationService,
    router: Router,
    route: ActivatedRoute,
    breadcrumbService: BreadcrumbService) {
    super(messageService, confirmationService, router, breadcrumbService, route);
    this.setBreadcrumb([{ label: Components.EnRouteWaypointsList.label }]);
    this.selected_date = route.snapshot.queryParamMap.get('a');
  }

  ngOnInit(): void {
    this.setBreadcrumb([{ label: Components.EnRouteWaypointsList.label }])
    this.loadingCount++;
    combineLatest({
      airline_settings: this.settingsService.getSystemSetting(2, OrganizationType.Airline),
      enroutewaypoint_list: this.enRouteService.getDatesOfFiles()
        .pipe(
          switchMap(dte => {

            let lst = dte.map(this.dateToMDY).sort(this.comparefn)

            lst = lst.map(x => {
              x = this.dateToDMY(x);
              this.file_dates.push({ "dte": x });
              return x;
            })


            let selected_dte = ""
            if (!this.selected_date)
              selected_dte = lst[lst.length - 1];
            else
              selected_dte = this.selected_date;

            this.selected_date = { "dte": selected_dte }

            return this.enRouteService.getEnRouteWaypoints(selected_dte)
          })
        )
    }).subscribe(
      {
        next: data => {
          this.data = data.enroutewaypoint_list;
          this.isICAO = data.airline_settings["setting_value"] == '1' ? true : false;
        },
        error: err => {
          this.showErrorMsg(err, Action.Get, `en-route waypoints `);
          this.loadingCount--;
        },
        complete: () => this.loadingCount--
      }
    )
  }

  getEnrouteWaypointList(dte) {
    const arg: ApiType = {
      request: this.enRouteService.getEnRouteWaypoints(dte),
      api_name: ' en-route waypoints',
      callback: this.callback.bind(this)
    }
    this.callAPI(arg)
  }

  callback(data) {
    this.data = (data) ? [...data] : [];
  }

  override onChange($event): void {
    const dte = $event.value["dte"];
    this.getEnrouteWaypointList(dte);
  }

  onRowSelect($event) {

    const { waypoint_identifier, c_fir_code } = $event.data;
    this.router.navigate(
      [Components.EnRouteWaypointsDetails.path], {
      queryParams: {
        a: waypoint_identifier,
        b: c_fir_code,
        c: this.selected_date["dte"],
        d: JSON.stringify(this.file_dates)
      }
    }).then();

  }
}
