<p-blockUI [blocked]="loadingCount > 0">
	<tdy-spinner style="position:absolute;left:50%;top:50%;z-index: 10000"></tdy-spinner>
</p-blockUI>
<div class="card" *ngIf="loadingCount == 0">
	<div class="card-header m-2">
		<h5>{{pageTitle}} </h5>
		<p-dropdown [options]="file_dates" [(ngModel)]="selected_date" optionLabel="dte" [filter]="true"
			(onChange)="onChange()" tooltip="Filter by AIRAC date" filterBy="dte" [showClear]="false"
			placeholder="Select a date">
			<ng-template pTemplate="selectedItem">
				<div *ngIf="selected_date">
					<div>{{selected_date.dte}}</div>
				</div>
			</ng-template>
			<ng-template let-filedate pTemplate="item">
				<div class="country-item">
					<div>{{filedate.dte}}</div>
				</div>
			</ng-template>
		</p-dropdown>
	</div>

	<div class="col-12">
		<p-table #dt_procedures [value]="data" dataKey="airport_icao_identifier" [showCurrentPageReport]="true"
			[paginator]="data.length > defaultTableRows" [rowsPerPageOptions]="[20,50,100,200]"
			[rows]="defaultTableRows" currentPageReportTemplate="{{currentPageReport + ' ' + pageTitle}}" styleClass=""
			selectionMode="single" [(selection)]="selected_procedure" (onRowSelect)="onRowSelect()"
			sortField="airport_icao_identifier">
			<!-- <ng-template pTemplate="caption">
				<div class="flex flex-row-reverse">

				</div>
			</ng-template> -->
			<ng-template pTemplate="header">
				<tr>
					<th pSortableColumn="airport_icao_identifier" [ngStyle]="{'width':'15%'}">
						<div class="p-d-flex p-jc-between p-ai-center">
							ICAO Code
							<p-sortIcon field="airport_icao_identifier"></p-sortIcon>
							<p-columnFilter type="text" field="airport_icao_identifier" display="menu"
								[showOperator]="false" [showAddButton]="false"></p-columnFilter>
						</div>
					</th>
					<th pSortableColumn="sid_star_approach_identifier" [ngStyle]="{'width':'15%'}">
						<div class="p-d-flex p-jc-between p-ai-center">
							SID/STAR/Approach Identifier
							<p-sortIcon field="sid_star_approach_identifier"></p-sortIcon>
							<p-columnFilter type="text" field="sid_star_approach_identifier" display="menu"
								[showOperator]="false" [showAddButton]="false"></p-columnFilter>
						</div>
					</th>
					<th pSortableColumn="c_procedure_design_mag_var" [ngStyle]="{'width':'15%'}">
						<div class="p-d-flex p-jc-between p-ai-center">
							Magnetic variance
							<p-sortIcon field="c_procedure_design_mag_var"></p-sortIcon>
							<p-columnFilter type="text" field="c_procedure_design_mag_var" display="menu"
								[showOperator]="false" [showAddButton]="false"></p-columnFilter>
						</div>
					</th>
					<th pSortableColumn="c_procedure_design_mag_var_ind" [ngStyle]="{'width':'15%'}">
						<div class="p-d-flex p-jc-between p-ai-center">
							Magnetic variance indicator
							<p-sortIcon field="c_procedure_design_mag_var_int"></p-sortIcon>
							<p-columnFilter type="text" field="c_procedure_design_mag_var_int" display="menu"
								[showOperator]="false" [showAddButton]="false"></p-columnFilter>
						</div>
					</th>
					<th pSortableColumn="procedure_design_aircraft_category_or_type" [ngStyle]="{'width':'15%'}">
						<div class="p-d-flex p-jc-between p-ai-center">
							Procedure design aircraft category or type
							<p-sortIcon field="procedure_design_aircraft_category_or_type"></p-sortIcon>
							<p-columnFilter type="text" field="procedure_design_aircraft_category_or_type"
								display="menu" [showOperator]="false" [showAddButton]="false"></p-columnFilter>
						</div>
					</th>
					<th pSortableColumn="gnss_fms_indication" [ngStyle]="{'width':'15%'}">
						<div class="p-d-flex p-jc-between p-ai-center">
							GNSS/FMS indication
							<p-sortIcon field="gnss_fms_indication"></p-sortIcon>
							<p-columnFilter type="text" field="gnss_fms_indication" display="menu"
								[showOperator]="false" [showAddButton]="false"></p-columnFilter>
						</div>
					</th>
				</tr>
			</ng-template>
			<ng-template pTemplate="body" let-procedure>
				<tr [pSelectableRow]="procedure">
					<td>
						{{procedure.airport_identifier || "-"}}
					</td>
					<td>
						{{procedure.sid_star_approach_identifier || "-"}}
					</td>
					<td>
						{{procedure.c_procedure_design_mag_var || "-"}}
					</td>
					<td>
						{{procedure.c_procedure_design_mag_var_ind || "-"}}
					</td>
					<td>
						{{procedure.procedure_design_aircraft_category_or_type || "-"}}
					</td>
					<td>
						{{procedure.gnss_fms_indication || "-"}}
					</td>
				</tr>
			</ng-template>
			<ng-template pTemplate="emptymessage">
				<tr>
					<td colspan="8">No procedures found.</td>
				</tr>
			</ng-template>
		</p-table>
	</div>
	<div *ngIf="data.length > 0 && defaultTableRows >= data.length" style="text-align: center;">
		<p class="row-paging">Showing 1 to
			{{data.length}} of {{data.length}} {{pageTitle}}</p>
	</div>
</div>
