import { Component, OnInit, NgZone } from '@angular/core';
import {
  Action,
  BaseViewComponent,
} from 'src/app/common/components/base-view.component';
import { Components } from '../../../integration/data-delivery-insights.components';
import {
  AvailableDataFrame,
  DataFrameType,
  FlightDataRedactions,
  RedactionType,
} from 'src/app/features/datadelivery/datadelivery/models/FlightDataRedactions';
import { ConfirmationService, Message, MessageService } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbService } from 'src/app/common/services/breadcrumb.service';
import { DataFrameService } from 'src/app/features/configuration/services/data-frame.service';
import { EndpointFlightDataRedactionService } from '../../../services/flightDataRedaction.service';
import { CommonService } from 'src/app/common/services/common.service';
import { SecurityUserService } from 'src/app/security/services/security-user.service';
import { forkJoin, takeUntil } from 'rxjs';

const CONVERSION_TYPES = {
  'RAW 717': 1,
  'RAW 767': 5,
  DXS: 4,
  CSV: 2,
  PARQUET: 3,
};

const redactionTypes = Object.keys(CONVERSION_TYPES).map((key) => ({
  name: key,
  redactiontype_id: CONVERSION_TYPES[key],
}));

@Component({
  selector: 'app-redaction-details',
  templateUrl: './data-redaction-details.component.html',
  styleUrls: ['./data-redaction-details.component.scss'],
})
export class DataRedactionDetailsComponent
  extends BaseViewComponent
  implements OnInit {
  pageTitle = Components.DataRedactionDetails.label;
  flightdataredactions: FlightDataRedactions[] = [];
  flightdataredaction!: FlightDataRedactions;
  redactionType: RedactionType[] = redactionTypes;
  availableDataFrame: AvailableDataFrame[] = [];
  availableDataFramelist: AvailableDataFrame[] = [];
  dataframe: DataFrameType[] = [];
  uniqueError = false;
  selectedDataframes: AvailableDataFrame[] = [];
  selectedDataframesIDs: string[] = [];
  editselectedDataframesIDs: string[] = [];
  selectedDataframeId: number;
  selectedRedactionTypeId: number;
  initialRecordersCount: number;
  messageLabel = 'Flight Data Redaction';
  messages!: Message[];

  constructor(
    router: Router,
    private route: ActivatedRoute,
    breadcrumbService: BreadcrumbService,
    confirmationService: ConfirmationService,
    messageService: MessageService,
    private dataFrameService: DataFrameService,
    private endpointFlightdataredactionservice: EndpointFlightDataRedactionService,
    private commonservice: CommonService,
    private realUserService: SecurityUserService,
    private ngZone: NgZone
  ) {
    super(messageService, confirmationService, router, breadcrumbService);
  }

  getBreadCrums() {
    this.breadcrumbService.setItems(this.route, [
      {
        label: Components.DataRedactions.label,
        routerLink: Components.DataRedactions.path,
      },
      {
        label:
          Components.DataRedactionDetails.label +
          ` (${this.route.snapshot.queryParamMap.get('name')})`,
      },
    ]);
  }

  ngOnInit(): void {
    this.flightdataredaction = new FlightDataRedactions();
    this.getBreadCrums();
    const redactionId = +this.route.snapshot.queryParamMap.get('redactionId');
    this.intialVal();
    this.loadFlightDataRedactionDetails(redactionId);
  }

  intialVal() {
    this.isNew = true;
    this.updateBreadcrumb(this.pageTitle);
    this.flightdataredaction = new FlightDataRedactions();
    this.flightdataredaction.redaction_id = -1;
    this.flightdataredaction.textualoutput = false;
    this.availableDataFrame = [];
    this.availableDataFramelist = [];
  }

  loadFlightDataRedactionDetails(redaction_id: number) {
    this.incrementLoadingCount();
    this.fetchFlightDataRedactionDetails(redaction_id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (results) => this.handleFlightDataRedactionDetails(results),
        error: (error) => {
          this.showErrorMsg(`${error}`, Action.Get, `${this.messageLabel}s`);
          this.decrementLoadingCount();
        },
        complete: () => this.decrementLoadingCount(),
      });
  }

  fetchFlightDataRedactionDetails(redaction_id: number) {
    return forkJoin({
      getflightredactiondetails:
        this.endpointFlightdataredactionservice.getFligtRedaction_by_id(redaction_id),
      dataframe: this.commonservice.getDataFrameData(),
    });
  }

  handleFlightDataRedactionDetails(results: any) {
    const getDataframes = this.parseDataframes(
      results.getflightredactiondetails.mapjson
    );
    this.populateDataframes(results.dataframe);
    this.flightdataredaction = results.getflightredactiondetails;
    this.editselectedDataframesIDs = getDataframes.selected_values;
    this.selectedRedactionTypeId = this.flightdataredaction.redactiontype_id;
    this.selectedDataframeId = this.flightdataredaction.dataframe_id;
    this.flightdataredaction.textualoutput = this.isTextualOutput(
      this.flightdataredaction.textualoutput
    );
    this.onEditloadDataframeDetails(this.flightdataredaction.dataframe_id);
  }

  parseDataframes(mapjson: string) {
    return JSON.parse(mapjson);
  }

  populateDataframes(dataframes: any[]) {
    dataframes.forEach((ele) => {
      this.dataframe.push({
        dataframe_id: ele.dataframe_id,
        dataframe_type: ele.name,
      });
    });
  }

  isTextualOutput(textualoutput: boolean) {
    return textualoutput === true;
  }

  onEditloadDataframeDetails(dataframeId?: number) {
    this.loadingCount++;
    this.fetchDataFrameDetails(dataframeId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (results) => {
          this.processDataFrameDetails(results);
        },
        error: (error) => {
          this.handleError(error);
        },
        complete: () => {
          this.loadingCount--;
        },
      });
  }

  fetchDataFrameDetails(dataframeId?: number) {
    return forkJoin({
      availableredaction: this.dataFrameService.DataFrameByFrameId(dataframeId),
    });
  }

  processDataFrameDetails(results: any) {
    this.availableDataFrame = [];
    this.populateAvailableDataFrame(results.availableredaction);
    this.populateSelectedDataframes();
    this.filterAvailableDataFramelistFromSelectedDataFrames();
    this.updateInitialRecordersCountFromSelectedDataFrames();
    this.sortDataFrames();
  }

  populateAvailableDataFrame(availableredaction: any) {
    availableredaction.forEach((ele: any) => {
      if (this.editselectedDataframesIDs.find((a) => a == ele)) {
        this.availableDataFrame.push({ dataframe_type: ele });
        this.selectedDataframes.push({ dataframe_type: ele });
      }
      this.availableDataFramelist.push({ dataframe_type: ele });
    });
  }

  populateSelectedDataframes() {
    this.selectedDataframes.forEach((ele) => {
      this.selectedDataframesIDs.push(ele.dataframe_type);
    });
  }

  filterAvailableDataFramelistFromSelectedDataFrames() {
    if (this.availableDataFramelist && this.availableDataFrame) {
      this.availableDataFramelist = this.availableDataFramelist.filter(
        (p) =>
          this.selectedDataframes.findIndex(
            (i) => i.dataframe_type == p.dataframe_type
          ) == -1
      );
    }
  }

  updateInitialRecordersCountFromSelectedDataFrames() {
    this.initialRecordersCount = this.availableDataFramelist
      ? this.availableDataFramelist.length
      : 0;
    this.initialRecordersCount = this.selectedDataframes.length;
  }

  sortDataFrames() {
    this.availableDataFramelist.sort((a, b) => {
      return a.dataframe_type.localeCompare(b.dataframe_type);
    });
    this.availableDataFrame.sort((a, b) => {
      return a.dataframe_type.localeCompare(b.dataframe_type);
    });
  }

  handleError(error: any) {
    this.showErrorMsg(`${error}`, Action.Get, `${this.messageLabel}s`);
    this.loadingCount--;
  }

  incrementLoadingCount() {
    this.loadingCount++;
  }

  decrementLoadingCount() {
    this.loadingCount--;
  }

  onCancel() {
    this.ngZone.run(() => {
      this.router.navigate([Components.DataRedactions.path]);
    });
  }
}
