import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';
import {
  ACMS,
  ACMSType,
  FileType,
  fileType,
  getRegex,
  jsonValues,
} from 'src/app/features/datadelivery/datadelivery/models/ACMS';

@Component({
  selector: 'app-acmsreport-form',
  templateUrl: './acmsreport-form.component.html',
})
export class AcmsreportFormComponent implements OnInit {
  @Input() acmsreport!: ACMS;
  @Input() acmsReportlist: ACMSType[] = [];
  @Input() acmsType: ACMSType[] = [];
  @Input() fileType: fileType[] = [];


  @Input() jsonregex: getRegex = new getRegex();

  @Input() uniqueError = false;
  @Input() avialableAcms: ACMSType[] = [];
  @Input() selectedMapIDs: string[] = [];
  @Input() picklistChange = false;
  @Input() isButtonDisabled = true;
  @Input() jsonParameters: jsonValues;
  @Input() showButtons = true;
  @Input() FileType = FileType;

  @Input() canAdd: boolean;
  @Input() canDelete: boolean;
  @Input() canEdit: boolean;
  @Input() isNew = false;
  @Input() isReadonly = true;
  @Input() acmsreports: ACMS[] = [];

  @Output() moveToTarget = new EventEmitter<ACMS>();
  @Output() moveToSource = new EventEmitter<ACMS>();
  @Output() moveAllToSource = new EventEmitter<ACMS>();
  @Output() moveAllToTarget = new EventEmitter<ACMS>();
  @Output() onAirlineChangeEvent = new EventEmitter<ACMS>();
  @Output() onChangeEvent = new EventEmitter<ACMS>();

  @Output() submitForm = new EventEmitter<void>();
  @Output() cancelForm = new EventEmitter<void>();
  @Output() resetForm = new EventEmitter<void>();

  @ViewChild('acmsForm')
  public acmsForm!: NgForm;

  @ViewChild('regexName')
  regexName!: NgModel;

  @ViewChild('regexValue')
  regexValue!: NgModel;

  _regexlist : getRegex[] = [];

  regexlistchanged :boolean = false;

  @Input()
  set regexlist(value: getRegex[]) {
    this._regexlist = value;
    this._regexlist.forEach((element,index)=>{
      element.id = index +1;
    })
  }

  get regexList(){
    return this._regexlist;
  }

  constructor() {
    // Intentionally empty
  }

  ngOnInit(): void {
    // Intentionally empty
  }

  onSubmit() {
    this.submitForm.emit();

  }

  onCancel() {
    this.cancelForm.emit();
  }

  onReset() {
    this.acmsForm.resetForm();
    this.resetForm.emit();
  }

  onMoveToTarget(event: ACMS) {
    this.moveToTarget.emit(event);
  }

  onMoveToSource(event: ACMS) {
    this.moveToSource.emit(event);
  }

  onMoveAllToSource(event: ACMS) {
    this.moveAllToSource.emit(event);
  }

  onMoveAllToTarget(event: ACMS) {
    this.moveAllToTarget.emit(event);
  }

  onAirlineChange(event: ACMS) {
    this.onAirlineChangeEvent.emit(event);
  }

  onChange(event: ACMS) {
    this.onChangeEvent.emit(event);
  }

  onAddRegex(){
    let maxId = 1;

    if(this._regexlist.length !== 0)
      maxId = this._regexlist.reduce((prev, current) => (prev.id > current.id) ? prev : current).id +1;
      this._regexlist.push({id:maxId, name:"", regex:""});

    this.regexlistchanged = true;



  }
  onDeleteRegex(id){
    const index = this._regexlist.findIndex(item => item.id === id);
    this._regexlist.splice(index, 1);
    this.regexlistchanged = true;
    }
}
