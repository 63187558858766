import { Organization } from "../../administration/models/organization";

export class DataFrameModel {
    dataframe_id: number;
    dataframeversion_id: number;
    name: string;
    version: number
    airline: Organization[];
    description: string;
    cnt: number;
    isloading: boolean;
    filename: string;
    recordingstandard_id: number;
}

export class DataFrameCompareModel {
    data_frame_changes: string[];
    parameters_added: string[];
    parameters_changed: ParameterChanged[];
    parameters_removed: string[];
}

export class ParameterChanged {
    key: string;
    value: string[];
}

export class DataFrameByRecorderModel { //for Dataframes obtained by Recorder Standard ID
    dataframe_id: number;
    name: string;
}

export class DataFrameVersionByID { //for Dataframes obtained by Recorder Standard ID
    version: number;
    dataframeversion_id: number;
}

export class DataFrameVersions {
    version: number;
    dataframeversion_id: number;
}

export class CompareDataFrame {
    Data_frame_changes: string[];
    Parameters_added: string[];
    Parameters_changed: ParameterChanged[];
    Parameters_removed: string[];
}
