import { FeatureRoot } from "src/app/common/models/feature-item.model";
import { Components } from "./configuration.components";
import { permissions } from "./configuration.permissions";

export const configurationMenu: FeatureRoot = {
    label: "Aircraft Configuration",
    icon: "tune",
    user_management: {
        feature_name: "Configuration",
        user_permission: permissions.configuration_users.view,
        roles_permission: permissions.configuration_roles.view
    },
    items: [
        {
            label: "Recorder",
            icon: "keyboard_voice",
            items: [
                Components.Dataframe,
                Components.AcmsReportMap,
                Components.SarMaps,
                Components.RecorderRegexes,
                Components.Recorders,
                Components.AircraftRecorder,
            ]
        }
    ]
}