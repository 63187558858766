import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Components } from "../../../../integration/configuration.components";
import { Action, BaseViewComponent } from '../../../../../../common/components/base-view.component';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbService } from 'src/app/common/services/breadcrumb.service';
import { DataFrameByRecorderModel, DataFrameVersions, ParameterChanged } from 'src/app/features/configuration/models/dataframe';
import { DataFrameService } from 'src/app/features/configuration/services/data-frame.service';

@Component({
  selector: 'app-data-frame-compare',
  templateUrl: './data-frame-compare.component.html'
})
export class DataFrameComparisonComponent extends BaseViewComponent implements OnInit {

  // constructor(
  //   private commonService: CommonService,
  // ) { }

  constructor(
    messageService: MessageService,
    confirmationService: ConfirmationService,
    router: Router,
    breadcrumbService: BreadcrumbService,
    private route: ActivatedRoute,
    private dataFrameService: DataFrameService,
  ) {
    super(messageService, confirmationService, router, breadcrumbService);
    this.breadcrumbService.setItems(route, [
      { label: Components.Dataframe.label, routerLink: Components.Dataframe.path },
      { label: Components.DataframeCompare.label, routerLink: Components.DataframeCompare.path }
    ]);
  }

  listDataFrame1: DataFrameByRecorderModel[];
  listDataFrameVersions1: DataFrameVersions[];
  listDataFrameVersions2: DataFrameVersions[];
  listDataFrame2: DataFrameByRecorderModel[];
  selectedDataFrame1: number;
  selectedDataFrame2: number;
  selectedDataFrameVersion1: number;
  selectedDataFrameVersion2: number;
  pageTitle = Components.DataframeCompare.label;
  model: string;
  comparisonResult: any;
  messageLabel = "DataFrame Comparison";
  comparisonResult_data_frame_changes = "No Changes.";
  comparisonResult_parameters_added = "No Changes.";
  comparisonResult_parameters_changed = "No Changes.";
  comparisonResult_parameters_removed = "No Changes.";

  ngOnInit(): void {
    this.loadingCount++;

    this.breadcrumbService.setItems(this.route, [
      { label: Components.Dataframe.label, routerLink: Components.Dataframe.path },
      { label: Components.DataframeCompare.label, routerLink: Components.DataframeCompare.path }
    ]);

    this.selectedDataFrame1 = +this.route.snapshot.queryParamMap.get('dataFrameID');
    this.getDataFrameList(this.selectedDataFrame1);
    this.getDataFrameVersions(this.selectedDataFrame1, "version");
    this.loadingCount--;
  }

  // Inside your component class
  isEmptyArray(array: string[]): boolean {
    return array.every((item) => item.trim() === '');
  }

  areAllValuesEmpty(paramsChanged: ParameterChanged[]): boolean {
    return paramsChanged.every((param) => param.value.every((val) => val.trim() === ''));
  }

  onChangeDataFrame1(event) {
    this.selectedDataFrame1 = event.value
    this.getDataFrameVersions(this.selectedDataFrame1, "one");
    setTimeout(() => this.handleSimilarity(event.value, this.selectedDataFrame2, "one"), 300);
  }

  onChangeDataFrame2(event) {
    this.selectedDataFrame2 = event.value
    this.getDataFrameVersions(this.selectedDataFrame2, "two")
    setTimeout(() => this.handleSimilarity(this.selectedDataFrame1, event.value, "two"), 300)
  }

  onChangeDataFrameVersion1(event) {
    this.selectedDataFrameVersion1 = event.value;
    setTimeout(() => this.handleSimilarity(this.selectedDataFrame1, this.selectedDataFrame2, "one"), 300)
  }

  onChangeDataFrameVersion2(event) {
    this.selectedDataFrameVersion2 = event.value;
    setTimeout(() => this.handleSimilarity(this.selectedDataFrame1, this.selectedDataFrame2, "two"), 300)

  }

  handleSimilarity(dataFrame1, dataFrame2, event: string) {

    if ((dataFrame1 == dataFrame2) && event == "one") { // If DataFrame Version 1 is selected
      this.listDataFrameVersions2 = this.listDataFrameVersions2.filter(item => item.dataframeversion_id !== this.selectedDataFrameVersion1);
      this.listDataFrameVersions1 = this.listDataFrameVersions1.filter(item => item.dataframeversion_id !== this.selectedDataFrameVersion2);
    }
    else if ((dataFrame2 == dataFrame1) && event == "two") {
      this.listDataFrameVersions1 = this.listDataFrameVersions1.filter(item => item.dataframeversion_id !== this.selectedDataFrameVersion2);
      this.listDataFrameVersions2 = this.listDataFrameVersions2.filter(item => item.dataframeversion_id !== this.selectedDataFrameVersion1);
    }
    else if (((dataFrame1 != dataFrame2))) { // When the dataframes are different then we need versions for both of the Dataframes in a edge case after we tried to compare same dataframes/
      this.getDataFrameVersions(dataFrame1, "one")
      this.getDataFrameVersions(dataFrame2, "two")
    }
  }


  onClickCompare() {
    this.loadingCount++;
    this.dataFrameService.compareDataFrameVersions(this.selectedDataFrameVersion1, this.selectedDataFrameVersion2)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (data) => {
          this.comparisonResult_data_frame_changes = data["Data_frame_changes"].join('\n');
          if (this.comparisonResult_data_frame_changes.length < 1) this.comparisonResult_data_frame_changes = "No Changes."

          this.comparisonResult_parameters_added = data["Parameters_added"].join('\n');
          if (this.comparisonResult_parameters_added.length < 1) this.comparisonResult_parameters_added = "No Changes."

          this.comparisonResult_parameters_removed = data["Parameters_removed"].join('\n');
          if (this.comparisonResult_parameters_removed.length < 1) this.comparisonResult_parameters_removed = "No Changes."

          if (Object.keys(data["Parameters_changed"]).length != 0)
            this.comparisonResult_parameters_changed = this.DisplayParameterChanged(data["Parameters_changed"]);
          else
            this.comparisonResult_parameters_changed = "No Changes."
        },
        error: (err) => {
          this.showErrorMsg(err, Action.Get, `${this.messageLabel}`);
          this.loadingCount--;
        },
        complete: () => this.loadingCount--
      })
  }

  DisplayParameterChanged(list): string {
    const sub_result = [];
    (<any>window).lst = list
    Object.keys(list).map(x => {
      sub_result.push(x)
      list[x].map(y => sub_result.push(y))
      sub_result.push('')
    })

    return sub_result.join('\n');
  }

  getDataFrameList(dataframe_id) {
    this.loadingCount++;
    this.dataFrameService.getDataFrameForComparison(dataframe_id).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: (data) => {
        data.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)

        this.listDataFrame1 = data;
        this.listDataFrame2 = data;
      },
      error: (err) => {
        this.showErrorMsg(err, Action.Get, `${this.messageLabel}`);
        this.loadingCount--
      },
      complete: () => this.loadingCount--
    })
  }

  getDataFrameVersions(id: number, type: string) {
    this.loadingCount++;
    this.dataFrameService.getDataFrameById(id).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: (data) => {
        if (type == "one") {
          // this.listDataFrameVersions1 = data.map((item) => { version: item.version, dataframeversion_id: item.dataframeversion_id});
          this.listDataFrameVersions1 = data.map((item) => {
            return {
              version: item.version,
              dataframeversion_id: item.dataframeversion_id
            };
          });
        } else if (type == "two") {
          this.listDataFrameVersions2 = data.map((item) => {
            return {
              version: item.version,
              dataframeversion_id: item.dataframeversion_id
            };
          });
        } else if (type == "version") {
          this.listDataFrameVersions1 = data.map((item) => {
            return {
              version: item.version,
              dataframeversion_id: item.dataframeversion_id
            };
          });
          //get the latest version of the selected DataFrame
          const latestVersionObject = this.listDataFrameVersions1.reduce((prev, current) => {
            return current.version > prev.version ? current : prev;
          });
          this.selectedDataFrameVersion1 = latestVersionObject.dataframeversion_id;
        }

      },
      error: (err) => {
        this.showErrorMsg(err, Action.Get, `${this.messageLabel}`);
        this.loadingCount--;
      },
      complete: () => this.loadingCount--
    })
  }

}
