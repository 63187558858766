<p-blockUI [blocked]="loadingCount > 0">
  <tdy-spinner style="position:absolute;left:50%;top:50%;z-index: 10000"></tdy-spinner>
</p-blockUI>
<div class="card" *ngIf="loadingCount==0">
  <div class="card-header m-2">
    <h5>{{pageTitle}}</h5>
    <p-dropdown [options]="file_dates" [(ngModel)]="selected_date" optionLabel="dte" [filter]="true"
      (onChange)="onChange()" tooltip="Filter by AIRAC date" filterBy="dte" [showClear]="false"
      placeholder="Select a date">
      <ng-template pTemplate="selectedItem">
        <div *ngIf="selected_date">
          <div>{{selected_date.dte}}</div>
        </div>
      </ng-template>
      <ng-template let-filedate pTemplate="item">
        <div class="country-item">
          <div>{{filedate.dte}}</div>
        </div>
      </ng-template>
    </p-dropdown>
  </div>

  <div class="card">
    <h6>Country</h6>
    <div class="grid" *ngIf="data">
      <div class="col"><span>Time Zone: </span></div>
      <div class="col">{{data.time_zone || "-"}}</div>
      <div class="col"><span>Day Light Indicator: </span></div>
      <div class="col">{{data.daylight_indicator || "-"}}</div>
      <div class="col"><span>Customer Area Code: </span></div>
      <div class="col">{{data.customer_area_code || "-"}}</div>
    </div>
  </div>

  <div class="card">
    <h6>Details</h6>
    <div class="grid" *ngIf="data">
      <div class="col"><span>Airport ICAO Identifier: </span></div>
      <div class="col">{{data.airport_icao_identifier || "-"}}</div>
      <div class="col"><span>ICAO Code: </span></div>
      <div class="col">{{data.icao_code || "-"}}</div>
      <div class="col"><span>IATA Code:</span></div>
      <div class="col"><span>{{data.ata_iata_designator || "-"}}</span></div>
    </div>
    <div class="grid" *ngIf="data">
      <div class="col"><span>Airport Name: </span></div>
      <div class="col">{{data.airport_name || "-"}}</div>
      <div class="col"><span>Cycle Date: </span></div>
      <div class="col">{{data.cycle_date || "-"}}</div>
      <div class="col"><span>IFR Capability : </span></div>
      <div class="col">{{data.ifr_capability || "-"}}</div>
    </div>
    <div class="grid" *ngIf="data">
      <div class="col"><span>Public Military Indicator: </span></div>
      <div class="col">{{data.public_military_indicator || "-"}}</div>
      <div class="col"></div>
      <div class="col"></div>
      <div class="col"></div>
      <div class="col"></div>
    </div>
  </div>

  <div class="card">
    <h6>Limits</h6>
    <div class="grid" *ngIf="data">
      <div class="col"><span>Speed Limit Altitude: </span></div>
      <div class="col">{{formatDecimals(data.speed_limit_altitude, 'ft')}}</div>
      <div class="col"><span>Speed Limit: </span></div>
      <div class="col">{{formatDecimals(data.speed_limit, 'knots')}}</div>
      <div class="col"><span>Transitions Altitude: </span></div>
      <div class="col">{{formatDecimals(data.transitions_altitude, 'ft')}}</div>
    </div>
    <div class="grid" *ngIf="data">
      <div class="col"><span>Transitions Level: </span></div>
      <div class="col">{{formatDecimals(data.transition_level, 'ft')}}</div>
      <div class="col"></div>
      <div class="col"></div>
      <div class="col"></div>
      <div class="col"></div>
    </div>
  </div>


  <div class="card">
    <h6>Position</h6>
    <div class="grid" *ngIf="data">
      <div class="col"><span>Airport Reference Latitude: </span></div>
      <div class="col">{{formatDecimals(data.airport_reference_pt_latitude)}}</div>
      <div class="col"><span>Airport Reference Longitude: </span></div>
      <div class="col">{{formatDecimals(data.airport_reference_pt_longitude)}}</div>
      <div class="col"><span>Magnetic Variation: </span></div>
      <div class="col">{{formatDecimals(data.magnetic_variation)}}</div>
    </div>
    <div class="grid" *ngIf="data">
      <div class="col"><span>Airport Elevation: </span></div>
      <div class="col">{{formatDecimals(data.airport_elevation, 'ft')}}</div>
      <div class="col"><span>Magnetic True Indicator: </span></div>
      <div class="col">{{data.magnetic_true_indicator || "-"}}</div>
      <div class="col"><span>Datum Code: </span></div>
      <div class="col">{{data.datum_code || "-"}}</div>
    </div>
  </div>

  <div class="card">
    <h6>Notes</h6>
    <div class="grid" *ngIf="data">
      <div class="col"><span>Recommended Navaid: </span></div>
      <div class="col">{{data.recommended_navaid || "-"}}</div>
      <div class="col"><span>Longest Runway: </span></div>
      <div class="col">{{data.longest_runway || "-"}}</div>
      <div class="col"><span>Longest Runway Surface Code: </span></div>
      <div class="col">{{data.longest_runway_surface_code || "-"}}</div>
    </div>
  </div>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="8">No airport found.</td>
    </tr>
  </ng-template>
</div>
