import { Component, OnInit, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { forkJoin, takeUntil } from 'rxjs';
import {
  Action,
  BaseViewComponent,
} from 'src/app/common/components/base-view.component';
import { BreadcrumbService } from 'src/app/common/services/breadcrumb.service';
import { ACMS, FileType, } from 'src/app/features/datadelivery/datadelivery/models/ACMS';
import { EndpointACMSRedactionService } from '../../../services/acmsRedaction.service';
import { Components } from '../../../integration/data-delivery-insights.components';
@Component({
  selector: 'app-acmsreport-data-list.component',
  templateUrl: './acmsreport-data-list.component.html',
  styleUrls: ['./acmsreport-data-list.component.css'],
})
export class AcmsreportDataListComponent
  extends BaseViewComponent
  implements OnInit {
  public pageTitle = Components.ACMSReportRedactionsDataDelivery.label;
  public acmsreports: ACMS[] = [];
  private messageLabel = 'ACMS Report Redaction';

  constructor(
    router: Router,
    breadcrumbService: BreadcrumbService,
    confirmationService: ConfirmationService,
    messageService: MessageService,
    private route: ActivatedRoute,
    private endpointAcmsRedactionService: EndpointACMSRedactionService,
    private ngZone: NgZone
  ) {
    super(messageService, confirmationService, router, breadcrumbService);
    this.getBreadCrums();
  }

  ngOnInit() {
    this.getBreadCrums();
    this.loadACMSData();
  }

  onRowSelect(event) {
    this.ngZone.run(() => {
      this.router.navigate([Components.ViewACMSReportRedactionsDataDelivery.path], {
        queryParams: {
          redactionId: event.data.redaction_id,
          Name: event.data.name,
        },
      })
        .then();
    });
  }

  getBreadCrums() {
    this.breadcrumbService.setItems(this.route, [
      { label: Components.ACMSReportRedactionsDataDelivery.label },
    ]);
  }

  loadACMSData() {
    this.loadingCount++;
    forkJoin({
      acmsReports: this.endpointAcmsRedactionService.getACMSRedactions(),
    })
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (results) => {
          this.acmsreports = [];
          results.acmsReports.forEach((element) => {
            let getMapJson = '';
            const jsonRegex = JSON.parse(element.defaultparametervaluesconfig);
            if (jsonRegex.filetype_id == FileType.AircraftReport) {
              const getRegex = JSON.parse(element.mapjson);
              getMapJson = getRegex.regex;
            }
            this.acmsreports.push({
              acmsreportmap_id: element.acmsreportmap_id,
              acmsreportname: element.acmsreportname ?? '',
              description: element.description,
              name: element.name,
              redaction_id: element.redaction_id,
              defaultparametervaluesconfig: getMapJson,
            });
          });
        },
        error: (error) => {
          this.showErrorMsg(`${error}`, Action.Get, `${this.messageLabel}s`);
          this.loadingCount--;
        },
        complete: () => {
          this.loadingCount--;
        },
      });
  }
}
