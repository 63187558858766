<p-blockUI [blocked]="loadingCount > 0">
    <tdy-spinner style="position:absolute;left:50%;top:50%;z-index: 10000"></tdy-spinner>
</p-blockUI>
<p-confirmDialog header="Confirm Deletion" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<div class="col-12 card">
    <div class="card-header m-2">
        <h5>{{pageTitle}}</h5>
        <div>
            <button (click)="onClickAdd()" *ngIf="canAdd" icon="pi pi-plus" id="addButton" label="Add" pButton
                pRipple type="button"></button>
        </div>
    </div>
    <div class="col-12">
        <p-table [filterDelay]="0" [responsive]="true" [sortOrder]="1" [value]="sarMapsList"
         [showCurrentPageReport]="true" [paginator]="sarMapsList?.length > defaultTableRows" [rowsPerPageOptions]="[20,50,100,200]" [rows]="defaultTableRows" 
         currentPageReportTemplate="{{currentPageReport + ' ' + pageTitle}}" 
         dataKey="name" id="sarMapTable" (onRowSelect)="onRowSelect($event)" selectionMode="single" sortField="name">
            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="name">
                        <div class="flex justify-content-start align-items-center">
                            Name
                            <p-sortIcon field="name"></p-sortIcon>
                            <p-columnFilter [showAddButton]="false" [showOperator]="false" class="ml-o"
                                display="menu" field="name"  type="text" matchMode="contains"></p-columnFilter>
                        </div>
                    </th>
                   
                    <th style="width:5%" *ngIf="canAdd || canDelete"></th>
                </tr>
            </ng-template>
            <ng-template let-sarMaps pTemplate="body">
                    <tr [pSelectableRow]="sarMaps">
                        <td>
                            {{sarMaps.name}}
                        </td>

                        
                        <td *ngIf="canDelete">
                            <div class="flex justify-content-start">
                                <button id="deleteButton" name="deleteButton" type="button"
                                    class="p-button-rounded p-button-text p-button-danger" pButton pRipple
                                    icon="pi pi-trash" pTooltip="Delete..."
                                    (click)="onDeleteSarMaps(sarMaps)"></button>
                            </div>
                        </td>
                    </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="3">No SAR Maps Found.</td>
                </tr>
            </ng-template>
        </p-table>
        <div *ngIf="sarMapsList?.length > 0 && defaultTableRows >= sarMapsList.length" style="text-align: center;">
            <p class="row-paging">Showing 1 to
              {{sarMapsList.length}} of {{sarMapsList.length}} {{pageTitle}}</p>
          </div>
    </div>
</div>