import { FeatureItem, FeatureComponentsDefinition } from "src/app/common/models/feature-item.model";
import { permissions } from "./administration.permissions";
import { AircraftComponent } from "../components/aircraft/aircraft.component";
import { AircraftDetailsComponent } from "../components/aircraft-details/aircraft-details.component";
import { AircraftGroupComponent } from "../components/aircraft-group/aircraft-group.component";
import { AircraftGroupDetailsComponent } from "../components/aircraft-group-details/aircraft-group-details.component";
import { AirlinesComponent } from "../components/airlines/airlines.component";
import { AirlineDetailsComponent } from "../components/airline-details/airline-details.component";
import { AirportsComponent } from "../components/navigation-information/airports/airports.component";
import { RunwaysComponent } from "../components/navigation-information/runways/runways.component";
import { RunwayDetailsComponent } from "../components/navigation-information/runway-details/runway-details.component";
import { CompanyComponent } from "../components/company/company.component";
import { CompanyDetailsComponent } from "../components/company-details/company-details.component";
import { EngineTypesComponent } from "../components/engine-types/engine-types.component";
import { EngineTypeDetailsComponent } from "../components/engine-type-details/engine-type-details.component";
import { AirframersComponent } from "../components/airframers/airframers.component";
import { AirframerDetailsComponent } from "../components/airframer-details/airframer-details.component";
import { AircraftInteriorComponent } from "../components/aircraft-interior/aircraft-interior.component";
import { AircraftInteriorDetailsComponent } from "../components/aircraft-interior-details/aircraft-interior-details.component";
import { AircraftModelsComponent } from "../components/aircraft-models/aircraft-models.component";
import { AircraftModelDetailsComponent } from "../components/aircraft-model-details/aircraft-model-details.component";
import { UsersComponent } from "../components/users/users.component";
import { UserDetailsComponent } from "../components/user-details/user-details.component";
import { TerminalWaypointsComponent } from "../components/navigation-information/terminal-waypoints/terminal-waypoints.component";
import { TerminalWaypointsDetailsComponent } from "../components/navigation-information/terminal-waypoints-details/terminal-waypoints-details.component";
import { EnRouteWaypointsListComponent } from "../components/navigation-information/enroute-waypoints/enroute-waypoints.component";
import { EnRouteWaypointsDetailsComponent } from "../components/navigation-information/enroute-waypoints-details/en-route-waypoints-details.component";
import { TerminalNDBNavaidsComponent } from "../components/navigation-information/terminal-ndb-navaids/terminal-ndb-navaids.component";
import { TerminalNDBNavaidsDetailsComponent } from "../components/navigation-information/terminal-ndb-navaids-details/terminal-ndb-navaids-details.component";
import { EnrouteVHFNavaidsComponent } from "../components/navigation-information/enroute-vhf-navaids/enroute-vhf-navaids.component";
import { EnrouteVHFNavaidsDetailsComponent } from "../components/navigation-information/enroute-vhf-navaids-details/enroute-vhf-navaids-details.component";
import { TerminalVHFNavaidsComponent } from "../components/navigation-information/terminal-vhf-navaids/terminal-vhf-navaids.component";
import { TerminalVHFNavaidsDetailsComponent } from "../components/navigation-information/terminal-vhf-navaids-details/terminal-vhf-navaids-details.component";
import { ProceduresComponent } from "../components/navigation-information/procedures/procedures.component";
import { ProcedureDetailsComponent } from "../components/navigation-information/procedure-details/procedure-details.component";
import { AirportDetailsComponent } from "../components/navigation-information/airport-details/airport-details.component";
import { EnrouteNDBNavaidsComponent } from "../components/navigation-information/enroute-ndb-navaids/enroute-ndb-navaids.component";
import { EnrouteNDBNavaidsDetailsComponent } from "../components/navigation-information/enroute-ndb-navaids-details/enroute-ndb-navaids-details.component";
import { UserNotificationsComponent } from "src/app/common/components/user-notifications/user-notifications.component";

const ROUTE_ROOT = "administration"

export class Components extends FeatureComponentsDefinition {

    public static readonly Aircraft: FeatureItem = {
        label: "Aircraft",
        icon: "local_airport",
        permission: permissions.aircraft.view,
        path: `${ROUTE_ROOT}/aircraft`,
        component: AircraftComponent
    };

    public static readonly AircraftDetails: FeatureItem = {
        label: "Aircraft Details",
        permission: permissions.aircraft.view,
        path: `${ROUTE_ROOT}/aircraft-details`,
        component: AircraftDetailsComponent
    };

    public static readonly AircraftGroup: FeatureItem = {
        label: "Aircraft Groups",
        icon: "connecting_airports",
        permission: permissions.aircraft_groups.view,
        path: `${ROUTE_ROOT}/aircraft-group`,
        component: AircraftGroupComponent
    };

    public static readonly AircraftGroupDetails: FeatureItem = {
        label: "Aircraft Group Details",
        permission: permissions.aircraft_groups.view,
        path: `${ROUTE_ROOT}/aircraftgroup-details`,
        component: AircraftGroupDetailsComponent
    };

    public static readonly Airlines: FeatureItem = {
        label: "Airlines",
        icon: "airlines",
        permission: permissions.airlines.view,
        path: `${ROUTE_ROOT}/airlines`,
        component: AirlinesComponent
    };

    public static readonly AirlineDetails: FeatureItem = {
        label: "Airline Details",
        permission: permissions.airlines.view,
        path: `${ROUTE_ROOT}/airline-details`,
        component: AirlineDetailsComponent
    };

    public static readonly AirportList: FeatureItem = {
        label: "Airports",
        icon: "flight_takeoff",
        permission: permissions.airports.view,
        path: `${ROUTE_ROOT}/airports-list`,
        component: AirportsComponent
    };

    public static readonly AirportDetails: FeatureItem = {
        label: "Airport Details",
        permission: permissions.airports.view,
        path: `${ROUTE_ROOT}/airport-details`,
        component: AirportDetailsComponent
    };

    public static readonly RunwayList: FeatureItem = {
        label: "Runways",
        icon: "flight_takeoff",
        permission: permissions.runways.view,
        path: `${ROUTE_ROOT}/runways-list`,
        component: RunwaysComponent
    };

    public static readonly RunwayDetails: FeatureItem = {
        label: "Runway Details",
        permission: permissions.runways.view,
        path: `${ROUTE_ROOT}/runway-details`,
        component: RunwayDetailsComponent
    };

    public static readonly Company: FeatureItem = {
        label: "Companies",
        icon: "business",
        permission: permissions.companies.view,
        path: `${ROUTE_ROOT}/companies`,
        component: CompanyComponent
    };

    public static readonly CompanyDetails: FeatureItem = {
        label: "Company Details",
        permission: permissions.companies.view,
        path: `${ROUTE_ROOT}/company-details`,
        component: CompanyDetailsComponent
    };

    public static readonly EngineTypes: FeatureItem = {
        label: "Engine Types",
        icon: "bolt",
        permission: permissions.engine_types.view,
        path: `${ROUTE_ROOT}/engine-types`,
        component: EngineTypesComponent
    };

    public static readonly EngineTypeDetails: FeatureItem = {
        label: "Engine Type Details",
        permission: permissions.engine_types.view,
        path: `${ROUTE_ROOT}/engine-type-details`,
        component: EngineTypeDetailsComponent
    };

    public static readonly Airframer: FeatureItem = {
        label: "Airframers",
        icon: "warehouse",
        permission: permissions.airframers.view,
        path: `${ROUTE_ROOT}/airframers`,
        component: AirframersComponent
    };

    public static readonly AirframerDetails: FeatureItem = {
        label: "Airframer Details",
        permission: permissions.airframers.view,
        path: `${ROUTE_ROOT}/airframer-details`,
        component: AirframerDetailsComponent
    };

    public static readonly AircraftInterior: FeatureItem = {
        label: "Aircraft Interiors",
        icon: "dashboard",
        permission: permissions.aircraft_interiors.view,
        path: `${ROUTE_ROOT}/aircraft-interior`,
        component: AircraftInteriorComponent
    };

    public static readonly AircraftInteriorDetails: FeatureItem = {
        label: "Aircraft Interior Details",
        permission: permissions.aircraft_interiors.view,
        path: `${ROUTE_ROOT}/aircraft-interior-details`,
        component: AircraftInteriorDetailsComponent
    };

    public static readonly AircraftModels: FeatureItem = {
        label: "Aircraft Models",
        icon: "subtitles",
        permission: permissions.aircraft_models.view,
        path: `${ROUTE_ROOT}/aircraft-models`,
        component: AircraftModelsComponent
    };

    public static readonly AircraftModelDetails: FeatureItem = {
        label: "Aircraft Model Details",
        permission: permissions.aircraft_models.view,
        path: `${ROUTE_ROOT}/aircraft-model-details`,
        component: AircraftModelDetailsComponent
    };

    public static readonly Users: FeatureItem = {
        label: "Users",
        icon: "person_outline",
        permission: permissions.users.view,
        path: `${ROUTE_ROOT}/users`,
        component: UsersComponent
    };

    public static readonly UserDetails: FeatureItem = {
        label: "User Details",
        permission: undefined,
        path: `${ROUTE_ROOT}/user-details`,
        component: UserDetailsComponent
    };

    public static readonly TerminalWaypointList: FeatureItem = {
        label: "Terminal Waypoints",
        permission: permissions.waypoints.view,
        path: `${ROUTE_ROOT}/terminal-waypoint-list`,
        component: TerminalWaypointsComponent
    };

    public static readonly TerminalWaypointDetails: FeatureItem = {
        label: "Terminal Waypoint Details",
        permission: permissions.waypoints.view,
        path: `${ROUTE_ROOT}/terminal-waypoint-details`,
        component: TerminalWaypointsDetailsComponent
    };

    public static readonly EnRouteWaypointsList: FeatureItem = {
        label: "Enroute Waypoints",
        permission: permissions.waypoints.view,
        path: `${ROUTE_ROOT}/en-route-waypoints-list`,
        component: EnRouteWaypointsListComponent
    };

    public static readonly EnRouteWaypointsDetails: FeatureItem = {
        label: "Enroute Waypoint Details",
        permission: permissions.waypoints.view,
        path: `${ROUTE_ROOT}/en-route-waypoints-details`,
        component: EnRouteWaypointsDetailsComponent
    };

    public static readonly EnRouteNdbNavaidsList: FeatureItem = {
        label: "Enroute NDB Navaids",
        permission: permissions.ndb_navaids.view,
        path: `${ROUTE_ROOT}/en-route-ndb-navaids-list`,
        component: EnrouteNDBNavaidsComponent
    };

    public static readonly EnRouteNdbNavaidsDetails: FeatureItem = {
        label: "Enroute NDB Navaid Details",
        permission: permissions.ndb_navaids.view,
        path: `${ROUTE_ROOT}/en-route-ndb-navaids-details`,
        component: EnrouteNDBNavaidsDetailsComponent
    };

    public static readonly TerminalNDBNavaidsList: FeatureItem = {
        label: "Terminal NDB Navaids",
        permission: permissions.ndb_navaids.view,
        path: `${ROUTE_ROOT}/terminal-ndb-navaids-list`,
        component: TerminalNDBNavaidsComponent
    };

    public static readonly TerminalNDBNavaidsDetails: FeatureItem = {
        label: "Terminal NDB Navaid Details",
        permission: permissions.ndb_navaids.view,
        path: `${ROUTE_ROOT}/terminal-ndb-navaid-details`,
        component: TerminalNDBNavaidsDetailsComponent
    };

    public static readonly EnRouteVHFNavaidsList: FeatureItem = {
        label: "Enroute VHF Navaids",
        permission: permissions.vhf_navaids.view,
        path: `${ROUTE_ROOT}/en-route-vhf-navaids-list`,
        component: EnrouteVHFNavaidsComponent
    };

    public static readonly EnRouteVHFNavaidsDetails: FeatureItem = {
        label: "Enroute VHF Navaid Details",
        permission: permissions.vhf_navaids.view,
        path: `${ROUTE_ROOT}/en-route-vhf-navaids-details`,
        component: EnrouteVHFNavaidsDetailsComponent
    };

    public static readonly TerminalVHFNavaidsList: FeatureItem = {
        label: "Terminal VHF Navaids",
        permission: permissions.vhf_navaids.view,
        path: `${ROUTE_ROOT}/terminal-vhf-navaids-list`,
        component: TerminalVHFNavaidsComponent
    };

    public static readonly TerminalVHFNavaidsDetails: FeatureItem = {
        label: "Terminal VHF Navaid Details",
        permission: permissions.vhf_navaids.view,
        path: `${ROUTE_ROOT}/terminal-vhf-navaid-details`,
        component: TerminalVHFNavaidsDetailsComponent
    };

    public static readonly ProceduresList: FeatureItem = {
        label: "Procedures",
        permission: permissions.procedures.view,
        path: `${ROUTE_ROOT}/procedures-list`,
        component: ProceduresComponent
    };

    public static readonly ProcedureDetails: FeatureItem = {
        label: "Procedure Details",
        permission: permissions.procedures.view,
        path: `${ROUTE_ROOT}/procedure-details`,
        component: ProcedureDetailsComponent
    };

    //Hiding User Notifications for now!
    // public static readonly UserNotifications: FeatureItem = {
    //     label: "User Notifications",
    //     permission: undefined,
    //     path: `${ROUTE_ROOT}/user-notifications`,
    //     component: UserNotificationsComponent
    // };

    public static readonly UserProfile: FeatureItem = {
        label: "User Profile",
        permission: undefined,
        path: `${ROUTE_ROOT}/user-details`,
        component: UserDetailsComponent
    };
}
