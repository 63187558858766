import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { combineLatest, switchMap } from 'rxjs';
import { BreadcrumbService } from 'src/app/common/services/breadcrumb.service';
import { Airport } from 'src/app/features/administration/models/airport';
import { AirportService } from 'src/app/features/administration/services/airport.service';
import { SettingsService } from 'src/app/common/services/settings.service';
import { Setting } from 'src/app/common/models/setting';
import { OrganizationType } from 'src/app/features/administration/models/organization';
import { Components } from 'src/app/features/administration/integration/administration.components';
import { Action } from 'src/app/common/components/base-view.component';
import { SecurityUserService } from 'src/app/security/services/security-user.service';
import { permissions } from 'src/app/features/administration/integration/administration.permissions';
import { ApiType, NavigationInformationBaseViewComponent } from '../navigation-information-base/navigation-information-base-view.component';

@Component({
  selector: 'app-airports-list',
  templateUrl: './airports.component.html',
})

export class AirportsComponent extends NavigationInformationBaseViewComponent implements OnInit {
  pageTitle = Components.AirportList.label
  airports: Airport[]
  loading = false;
  isICAO: boolean;

  airline_settings: Setting
  selected_date: any;
  selected_airport: any;
  file_dates = [];

  public showVHFNavaids: boolean;
  public showRunways: boolean;
  public showNDBNavaids: boolean;
  public terminalWaypoints: boolean;

  constructor(
    private airportService: AirportService,
    private settingsService: SettingsService,
    private realUserService: SecurityUserService,
    messageService: MessageService,
    confirmationService: ConfirmationService,
    router: Router,
    route: ActivatedRoute,
    breadcrumbService: BreadcrumbService) {
    super(messageService, confirmationService, router, breadcrumbService, route);
    this.setBreadcrumb([{ label: Components.AirportList.label }])
    this.selected_date = route.snapshot.queryParamMap.get('a')
  }

  ngOnInit(): void {
    this.showRunways = this.realUserService.userHasPermission(permissions.runways.view);
    this.showVHFNavaids = this.realUserService.userHasPermission(permissions.vhf_navaids.view);
    this.showNDBNavaids = this.realUserService.userHasPermission(permissions.ndb_navaids.view);
    this.terminalWaypoints = this.realUserService.userHasPermission(permissions.waypoints.view);


    this.setBreadcrumb([{ label: Components.AirportList.label }])
    this.loadingCount++;
    combineLatest({
      airline_settings: this.settingsService.getSystemSetting(2, OrganizationType.Airline),
      airports_list: this.airportService.getDatesOfFiles()
        .pipe(
          switchMap(dte => {

            let lst = dte.map(this.dateToMDY).sort(this.comparefn)

            lst = lst.map(x => {
              x = this.dateToDMY(x);
              this.file_dates.push({ "dte": x });
              return x;
            })

            let selected_dte = ""
            if (!this.selected_date)
              selected_dte = lst[lst.length - 1];
            else
              selected_dte = this.selected_date;


            this.selected_date = { "dte": selected_dte }

            return this.airportService.getAirports(selected_dte)
          })
        )
    }).subscribe(
      {
        next: data => this.handleData(data),
        error: err => {
          this.showErrorMsg(err, Action.Get, `airports`);
          this.loadingCount--;
        },
        complete: () => this.loadingCount--
      }
    )
  }

  callback = (data) => {
    this.data = data;
  }

  getAirportLists(dte) {
    const arg: ApiType = { request: this.airportService.getAirports(dte), api_name: 'Airports', callback: this.callback }
    this.callAPI(arg)
  }


  override onChange($event) {
    const dte = $event.value["dte"];
    this.getAirportLists(dte)
  }

  onRowSelect() {
    const { airport_icao_identifier, ata_iata_designator } = this.selected_airport;
    const display_icao_iata = this.isICAO ? airport_icao_identifier : ata_iata_designator;
    const queryParams = {
      a: airport_icao_identifier,
      b: display_icao_iata ?? "-",
      c: this.selected_date["dte"],
      d: JSON.stringify(this.file_dates)
    };

    this.router.navigate([Components.AirportDetails.path], { queryParams }).then();
  }

  onRunwayClick(icao, iata) {
    const display_icao_iata = this.isICAO ? icao : iata;
    this.router.navigate([Components.RunwayList.path],
      {
        queryParams: {
          a: icao,
          b: display_icao_iata ?? "-",
          c: this.selected_date["dte"],
          d: JSON.stringify(this.file_dates),
        }
      }
    ).then();
  }


  onTerminalWaypointClick(icao, iata) {
    const display_icao_iata = this.isICAO ? icao : iata;
    this.router.navigate([Components.TerminalWaypointList.path],
      {
        queryParams: {
          a: icao,
          b: display_icao_iata ?? "-",
          c: this.selected_date["dte"],
          d: JSON.stringify(this.file_dates)
        }
      }
    ).then();
  }

  onTerminalNDBNavaidClick(icao, iata) {
    const display_icao_iata = (this.isICAO) ? icao : iata;
    this.router.navigate([Components.TerminalNDBNavaidsList.path],
      {
        queryParams: {
          a: icao,
          b: display_icao_iata ?? "-",
          c: this.selected_date["dte"],
          d: JSON.stringify(this.file_dates),
        }
      }).then();
  }

  onTerminalVHFNavaidClick(icao, iata) {
    const display_icao_iata = (this.isICAO) ? icao : iata;
    this.router.navigate([Components.TerminalVHFNavaidsList.path],
      {
        queryParams: {
          a: icao,
          b: display_icao_iata ?? "-",
          c: this.selected_date["dte"],
          d: JSON.stringify(this.file_dates),
        }
      }).then();
  }

  private handleData(data: any): void {
    //this.data = data.airports_list;
    if (data.airline_settings && data.airline_settings["setting_value"]) {
      const icao = data.airline_settings["setting_value"];
      if (icao === "true") {
        this.isICAO = true;
      } else if (icao === "false") {
        this.isICAO = false;
      } else if (!isNaN(parseFloat(icao)) && isFinite(icao)) {
        const num = parseInt(icao);
        this.isICAO = num === 1;
      } else {
        this.isICAO = false;
      }
    }
  }
}
