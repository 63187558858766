import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from '../../../../common/services/base.service';
import { catchError, map } from 'rxjs/operators';
import { environment } from "src/environments/environment";
import { ClientPackages, ResponseModelClientPackages, ConversionType } from "../models/ClientPackage";
@Injectable({
    providedIn: 'root'
})
export class ClientPackagesService extends BaseService {

    constructor(
        public http: HttpClient) {
        super();
        this.baseUrl = environment.apiUrl.datadelivery;
    }

    getClientPackages(): Observable<ClientPackages[]> {
        const url = this.baseUrl + "datadeliverypackages";
        return this.http.get<ClientPackages[]>(url, { headers: this.httpGetHeadersForJSON() })
            .pipe(map(ddspackage => ddspackage.map(r => {
                r.primary = Boolean(r.primary);
                r.secondary = Boolean(r.secondary);
                return r;
            })), catchError(this.httpHandleError)
            );
    }

    getClientPackage_by_id(package_id: number): Observable<ClientPackages> {
        const url = this.baseUrl + "datadeliverypackages" + `/${package_id}`;
        return this.http.get<ClientPackages>(url, { headers: this.httpGetHeadersForJSON() })
            .pipe(catchError(this.httpHandleError)
            );
    }

    addClientPackage(clientpackages: ResponseModelClientPackages) {
        const url = this.baseUrl + 'datadeliverypackages';
        return this.http.post<number>(url, clientpackages, { headers: this.httpGetHeadersForJSON() })
            .pipe(
                catchError(this.httpHandleError)
            )
    }

    updateClientPackage(clientpackages: ResponseModelClientPackages) {
        const url = this.baseUrl + 'datadeliverypackages';
        return this.http.put<number>(url, clientpackages, { headers: this.httpGetHeadersForJSON() })
            .pipe(
                catchError(this.httpHandleError)
            )
    }

    deleteClientPackage(package_id: number) {
        const url = this.baseUrl + 'datadeliverypackages/' + `${package_id}`;
        const options = { headers: this.httpGetHeadersForJSON() }
        return this.http.delete(url, options)
            .pipe(catchError(this.httpHandleError));
    }

    getConvertionTypes(): Observable<ConversionType[]> {
        const url = this.baseUrl + "conversiontypes";
        return this.http.get<ConversionType[]>(url, { headers: this.httpGetHeadersForJSON() })
            .pipe(map(res => {
                return res.sort((item1, item2) => {
                    return item1.conversiontype_id - item2.conversiontype_id;
                });
            }), catchError(this.httpHandleError));
    }

    getPredefinedRedaction(convertiontype_id: number) {
        const url = this.baseUrl + "predefinedredaction/" + `${convertiontype_id}`;
        return this.http.get<any>(url, { headers: this.httpGetHeadersForJSON() })
            .pipe(catchError(this.httpHandleError)
            );
    }

    getRedactionTypeDetails(convertiontype_id: number) {
        const url = this.baseUrl + "datadeliveryconversiontype/" + `${convertiontype_id}`;
        return this.http.get<any>(url, { headers: this.httpGetHeadersForJSON() })
            .pipe(catchError(this.httpHandleError)
            );
    }

}
