import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/common/services/breadcrumb.service';
import { AircraftInterior } from 'src/app/features/administration/models/aircraftInterior';
import { AircraftInteriorService } from 'src/app/features/administration/services/aircraft-interior.service';
import { UserService } from 'src/app/features/administration/services/user.service';
import { Action, BaseViewComponent } from 'src/app/common/components/base-view.component';
import { SecurityUserService } from 'src/app/security/services/security-user.service';
import { permissions } from 'src/app/security/models/permissions';
import { Components } from '../../integration/administration.components';

@Component({
  selector: 'app-aircraft-interior',
  templateUrl: './aircraft-interior.component.html'
})


export class AircraftInteriorComponent extends BaseViewComponent implements OnInit {

  pageTitle = Components.AircraftInterior.label;
  aircraftinterior: AircraftInterior[] = [];
  public canAdd: boolean;
  public canDelete: boolean;
  public canView: boolean;
  messageLabel = 'Aircraft Interior';

  test: ActivatedRoute;
  constructor(
    breadcrumbService: BreadcrumbService,
    messageService: MessageService,
    confirmationService: ConfirmationService,
    private securityUserService: SecurityUserService,
    userService: UserService,
    router: Router,
    private route: ActivatedRoute,
    private aircraftInteriorService: AircraftInteriorService
  ) {
    super(messageService, confirmationService, router, breadcrumbService);
    // Note: Service won't update breadcrumb if caller already did so.
    // add AircraftInterior to breadcrumbService items list ex: Home> AircraftInterior. This does not have route link
    this.breadcrumbService.setItems(this.route, [{ label: Components.AircraftInterior.label }]);

  }

  ngOnInit(): void {
    this.canView = this.securityUserService.userHasPermission(permissions.admin.aircraft_interiors.view);
    this.canAdd = this.securityUserService.userHasPermission(permissions.admin.aircraft_interiors.create);
    this.canDelete = this.securityUserService.userHasPermission(permissions.admin.aircraft_interiors.delete);

    this.breadcrumbService.setItems(this.route, [{ label: Components.AircraftInterior.label }]);

    if (this.canView) {
      this.loadAircraftInteriors();
    }
  }

  /**
   * Event handler for when the Add button is clicked. Navigates to the details page to create a new aircraft interior arrangements.
   */
  onClickAdd() {
    if (!this.canAdd) {
      this.showErrorMsg('User is not authorized to add a Aircraft Interior.', Action.Add, `${this.messageLabel}`);
    } else {
      this.router.navigate([Components.AircraftInteriorDetails.path]).then();
    }
  }

  /**
   * Event handler for when the table row is clicked. Navigates to the details page to view/update aircraft interior arrangements.
   */
  onRowSelect(event) {
    const aircraftinterior = event.data as AircraftInterior;
    this.router.navigate([Components.AircraftInteriorDetails.path], {
      queryParams: {
        aircraftInteriorID: aircraftinterior.aircraftinterior_id,
        name: event.data.name
      }
    }).then();
  }


  /**
   * Loads available aircraft interior arrangements.
   */
  loadAircraftInteriors() {
    this.loadingCount++;
    this.aircraftInteriorService.listAircraftInteriors().subscribe({
      next: results => {
        this.aircraftinterior = results;
      },
      error: error => {
        this.showErrorMsg(error, Action.Get, `${this.messageLabel}`);

        this.loadingCount--;
      },
      complete: () => {
        this.loadingCount--;
      }
    });

  }


  /**
  * Delete aircraft interior from available list
  */
  onDeleteAircraftInterior(aircraftInterior: AircraftInterior) {
    if (!this.canDelete) {
      this.showErrorMsg('User is not authorized to delete an Aircraft Interior.', Action.Delete, `${this.messageLabel}`);
    } else {
      this.confirmationService.confirm({
        message: 'Are you sure that you want to delete the selected Aircraft Interior?',
        accept: () => {
          this.loadingCount++;
          this.aircraftInteriorService.deleteAircraftInterior(aircraftInterior.aircraftinterior_id).subscribe({
            next: () => {
              this.showSuccessMsg(Action.Delete, `${this.messageLabel}`, `${aircraftInterior.aircraftinterior_deck}`);

              // Refresh the list of aircraft interiors
              this.loadAircraftInteriors();
            },
            error: error => {
              this.showErrorMsg(`${error}`, Action.Delete, `${this.messageLabel}`, `${aircraftInterior.aircraftinterior_deck}`);

              this.loadingCount--;
            },
            complete: () => this.loadingCount--
          });
        }
      });
    }
  }

  //aircraftInterior
  toAircraftInterior(aircraftInterior: AircraftInterior): AircraftInterior {
    return aircraftInterior;
  }

}
