import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from "src/environments/environment";
import { SARRedactionService } from '../../datadelivery/services/sarRedaction.service';

@Injectable({
  providedIn: 'root'
})
export class EndpointSARRedactionService extends SARRedactionService {

  constructor(
    public override http: HttpClient) {
    super(http);
    this.baseUrl = environment.apiUrl.dataDeliveryInsights;
  }

}
