import { Component, ViewChild } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { TransferdataService } from 'src/app/features/configuration/services/transferdata.service';
import {
  AcquiredParams,
  StorageFormatEnum,
  DisplayFormatEnum,
  EuTypeEnum,
} from 'src/app/features/configuration/models/dataframe717-767';
import { Router, ActivatedRoute } from '@angular/router';
import { DataFrameService } from 'src/app/features/configuration/services/data-frame.service';
import { BreadcrumbService } from 'src/app/common/services/breadcrumb.service';
import { Action } from 'src/app/common/components/base-view.component';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Components } from '../../../integration/configuration.components';
import { Table } from 'primeng/table';
import { SecurityUserService } from 'src/app/security/services/security-user.service';
import { ListView } from '../../dataframe-acms/dataframe/data-frame-listview/listview.component';
import { ListViewColumnType } from '../../dataframe-acms/dataframe/shared/helpers/data-frame.enums';

@Component({
  selector: 'app-listview717',
  templateUrl: './listview717.component.html',
  styleUrls: ['./listview717.component.css'],
})
export class Listview717Component extends ListView {
  @ViewChild(Table) dt: Table;
  pageTitle = Components.ListView717.label;
  dataparameter: AcquiredParams[] = [];
  deleteDialog = false;
  updatedMnemonic: any;
  updatedFrame: any;
  copydataparameter: AcquiredParams[] = [];
  datatablecopy = [];

  emptyMessage = 'No 717 Data Frame Found.';

  constructor(
    router: Router,
    appService: TransferdataService,
    dataframeservice: DataFrameService,
    route: ActivatedRoute,
    breadcrumbService: BreadcrumbService,
    confirmationService: ConfirmationService,
    messageService: MessageService,
    realUserService: SecurityUserService
  ) {
    super(
      messageService,
      confirmationService,
      router,
      breadcrumbService,
      route,
      appService,
      dataframeservice,
      realUserService
    );
    // Data Frame 717 - Init Data
    this.routeLinkPath = Components.ListView717.path;
    this.messageLabel = 'Data Frame 717.';
    this.dfPropId = 'PRM_MNEMONIC';
    this.dataFrameClone = Components.DataFrameDynamic717;
    this.dataFrameAnalyse = Components.DataFrameAnalyse717;
    this.dataFrameEdit = Components.DataFrameDynamic717;
    this.dataFrameMapView = Components.MapView717;
    this.dataFrameAdd = Components.DataFrameDynamic717;
    this.dataFrameDetails = Components.DataFrame717;
    this.columns = [
      { field: 'PRM_MNEMONIC', header: 'Mnemonic', colType: ListViewColumnType.default, filterType: 'text', cssClasses: 'ml-auto', extraAttributes: { matchMode: 'contains' } },
      { field: 'PRM_NAME', header: 'Name', colType: ListViewColumnType.default, filterType: 'text', extraAttributes: { matchMode: 'contains' } },
      { field: 'PRM_DESCRIPTION', header: 'Description', colType: ListViewColumnType.default, filterType: 'text', extraAttributes: { matchMode: 'contains' } },
      { field: 'FMT_ID', header: 'Storage Format', colType: ListViewColumnType.default, filterType: 'text', extraAttributes: { matchMode: 'contains' } },
      { field: 'FMT_ID_DISPLAY', header: 'Display Format', colType: ListViewColumnType.default, filterType: 'text', extraAttributes: { matchMode: 'contains' } },
      { field: 'PRM_RATE', header: 'Rate', colType: ListViewColumnType.default, type: 'numeric' },
      { field: 'PRM_UNIT', header: 'Units', colType: ListViewColumnType.default, filterType: 'text', extraAttributes: { matchMode: 'contains' } },
      { field: 'EUT_ID', header: 'EU Conversion Type', colType: ListViewColumnType.default, filterType: 'text', extraAttributes: { matchMode: 'contains' } },
    ];
  }

  getDataFrame(versionId: any) {
    this.dataparameter = [];
    this.updatedMnemonic = [];
    this.dataFrame = [];
    this.copydataparameter = [];
    this.loadingCount++;
    this.dataFrameService
      .IdentifyDataFrame(versionId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (data) => {
          const response = JSON.parse(data.content);
          const copyresponse = JSON.parse(data.content);
          this.dataFrame = copyresponse;
          for (const key in response.FRA.AcquiredParams) {
            for (const key1 in response.FRA.AcquiredParams[key]) {
              const lcpArray = response.FRA.AcquiredParams[key][key1].LCP;
              const Rate = [...new Set(lcpArray.map((item) => item.LCP_SAMPLE_NUMBER))];
              response.FRA.AcquiredParams[key][key1].PRM_RATE = Rate.length;
              const indexOfS = Object.values(StorageFormatEnum).indexOf(
                response.FRA.AcquiredParams[key][key1].FMT_ID as unknown as StorageFormatEnum
              );
              const storage = Object.keys(StorageFormatEnum)[indexOfS];
              response.FRA.AcquiredParams[key][key1].FMT_ID = storage === 'BinaryGMT' ? 'Binary GMT' : storage;
              const indexOfD = Object.values(DisplayFormatEnum).indexOf(
                response.FRA.AcquiredParams[key][key1].FMT_ID_DISPLAY as unknown as DisplayFormatEnum
              );
              const display = Object.keys(DisplayFormatEnum)[indexOfD];
              response.FRA.AcquiredParams[key][key1].FMT_ID_DISPLAY = display;

              const indexOfE = Object.values(EuTypeEnum).indexOf(
                response.FRA.AcquiredParams[key][key1].EUT_ID as unknown as EuTypeEnum
              );
              const eutype = Object.keys(EuTypeEnum)[indexOfE];
              response.FRA.AcquiredParams[key][key1].EUT_ID = eutype;

              this.dataparameter.push(response.FRA.AcquiredParams[key][key1]);
            }
          }
          this.copydataparameter = this.dataparameter;
          this.updatedMnemonics();
        },
        error: (err) => {
          this.showErrorMsg(err, Action.Get, `${this.messageLabel}`);
          this.loadingCount--;
        },
        complete: () => {
          this.loadingCount--;
        },
      });
  }

  onDPUpdates() {
    this.dataparameter.sort((a, b) => {
      const fa = a.PRM_MNEMONIC.toLowerCase(),
        fb = b.PRM_MNEMONIC.toLowerCase();
      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    });
    this.copydataparameter = this.dataparameter;
  }

  updatedParameters() {
    this.copydataparameter = [];
    const mnemonicsArray = JSON.parse(sessionStorage.getItem('Mnemonics'));
    const copymnemonicsArray = JSON.parse(sessionStorage.getItem('Mnemonics'));
    for (const key in mnemonicsArray) {
      for (const key1 in mnemonicsArray[key]) {
        for (const key2 in mnemonicsArray[key][key1]) {
          const lcpArray = mnemonicsArray[key][key1][key2].LCP;
          const Rate = [...new Set(lcpArray.map((item) => item.LCP_SAMPLE_NUMBER))];
          mnemonicsArray[key][key1][key2].PRM_RATE = Rate.length;
          const indexOfS = Object.values(StorageFormatEnum).indexOf(
            mnemonicsArray[key][key1][key2].FMT_ID as unknown as StorageFormatEnum
          );
          const storage = Object.keys(StorageFormatEnum)[indexOfS];
          mnemonicsArray[key][key1][key2].FMT_ID = storage;

          const indexOfD = Object.values(DisplayFormatEnum).indexOf(
            mnemonicsArray[key][key1][key2].FMT_ID_DISPLAY as unknown as DisplayFormatEnum
          );
          const display = Object.keys(DisplayFormatEnum)[indexOfD];
          mnemonicsArray[key][key1][key2].FMT_ID_DISPLAY = display;

          const indexOfE = Object.values(EuTypeEnum).indexOf(
            mnemonicsArray[key][key1][key2].EUT_ID as unknown as EuTypeEnum
          );
          const eutype = Object.keys(EuTypeEnum)[indexOfE];
          mnemonicsArray[key][key1][key2].EUT_ID = eutype;

          if (key1 === 'Edit') {
            this.dataparameter[key2] = mnemonicsArray[key][key1][key2];
          }
          if (key1 === 'Clone') {
            this.dataparameter.push(mnemonicsArray[key][key1][key2]);
          }
          if (key1 === 'Add') {
            this.dataparameter.push(mnemonicsArray[key][key1][key2]);
          }
        }
      }
      this.copydataparameter = this.dataparameter;
    }
    for (const key in copymnemonicsArray) {
      for (const key1 in copymnemonicsArray[key]) {
        for (const key2 in copymnemonicsArray[key][key1]) {
          const lcpArray = copymnemonicsArray[key][key1][key2].LCP;
          const Rate = [...new Set(lcpArray.map((item) => item.LCP_SAMPLE_NUMBER))];
          copymnemonicsArray[key][key1][key2].PRM_RATE = Rate.length;
          mnemonicsArray[key][key1][key2].PRM_RATE = Rate.length;

          if (key1 === 'Edit') {
            const name = copymnemonicsArray[key][key1][key2].PRM_MNEMONIC;
            this.dataFrame.FRA.AcquiredParams[key2] = {
              [name]: copymnemonicsArray[key][key1][key2],
            };
          }
          if (key1 === 'Clone') {
            const name = copymnemonicsArray[key][key1][key2].PRM_MNEMONIC;
            //  let index = parseInt(key2) + 1;
            this.dataFrame.FRA.AcquiredParams.push({
              [name]: copymnemonicsArray[key][key1][key2],
            });
          }
          if (key1 === 'Add') {
            const name = copymnemonicsArray[key][key1][key2].PRM_MNEMONIC;
            this.dataFrame.FRA.AcquiredParams.push({
              [name]: copymnemonicsArray[key][key1][key2],
            });
          }
        }
      }
    }
  }

  onMnemonicDelete() {
    this.deletedMnemonics = JSON.parse(sessionStorage.getItem('DeletedMnemonics'));
    this.deletedMnemonics.forEach((element) => {
      for (const key in this.dataFrame.FRA.AcquiredParams) {
        for (const key1 in this.dataFrame.FRA.AcquiredParams[key]) {
          if (this.dataFrame.FRA.AcquiredParams[key][key1].PRM_MNEMONIC === element) {
            this.dataFrame.FRA.AcquiredParams.splice(key, 1);
          }
        }
      }
      this.dataparameter.splice(
        this.dataparameter.findIndex((e) => e.PRM_MNEMONIC === element),
        1
      );
      this.copydataparameter = this.dataparameter;
      this.datatablecopy = Object.assign([], this.copydataparameter);
      this.datatablecopy.slice(
        this.datatablecopy.findIndex((e) => e.PRM_MNEMONIC === element),
        1
      );
      this.copydataparameter = this.datatablecopy;
      this.updatedMnemonic = JSON.parse(sessionStorage.getItem('Mnemonics'));
      for (const key in this.updatedMnemonic) {
        for (const key1 in this.updatedMnemonic[key]) {
          for (const key2 in this.updatedMnemonic[key][key1]) {
            if (this.updatedMnemonic[key][key1][key2].PRM_MNEMONIC === element) {
              this.updatedMnemonic.splice(key, 1);
            }
          }
        }
      }
    });

    sessionStorage.setItem('DeletedMnemonics', JSON.stringify(this.deletedMnemonics));
    sessionStorage.removeItem('Mnemonics');
    sessionStorage.setItem('Mnemonics', JSON.stringify(this.updatedMnemonic));
  }
  updatedFrameDetails() {
    this.showMessage = true;
    this.updatedFrame = JSON.parse(sessionStorage.getItem('FrameDetails'));
    const updatedframe = this.updatedFrame;
    this.dataFrame.FRA = this.updatedFrame.FRA;
    sessionStorage.setItem('FrameDetails', JSON.stringify(updatedframe));
  }

  deleteRecord(deleteRow: any) {
    this.deleteMnemonic(deleteRow.PRM_MNEMONIC);
  }
}
