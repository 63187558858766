import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { BaseService } from 'src/app/common/services/base.service';
import {
  DataPacking,
  MiniFrame,
  RecorderBlockMode,
  RecorderConfigModel,
  RecorderFinalModel,
  RecorderFormat,
  RecorderType,
  SubframeSize
}
  from 'src/app/features/configuration/models/recorderConfig';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class RecorderService extends BaseService {

  constructor(private http: HttpClient) {
    super();
  }
  recorderURL = environment.apiUrl.config + 'recorder';

  public getRecorders(): Observable<RecorderConfigModel[]> { // get all the recorders
    return this.http.get<RecorderConfigModel[]>(this.recorderURL, { headers: this.httpGetHeadersForJSON() }).pipe(catchError(this.httpHandleError));
  }

  public getRecorderTypes(): Observable<RecorderType[]> { // get all the recorder-types
    const url = environment.apiUrl.config + 'recorder-type';
    return this.http.get<RecorderType[]>(url, { headers: this.httpGetHeadersForJSON() }).pipe(catchError(this.httpHandleError));
  }

  public getRecorderFormat(): Observable<RecorderFormat[]> { // get all the format
    const url = environment.apiUrl.config + 'recorder-data-format';
    return this.http.get<RecorderFormat[]>(url, { headers: this.httpGetHeadersForJSON() }).pipe(catchError(this.httpHandleError));
  }

  public getSubFrameSize(): Observable<SubframeSize[]> { // get all the subframe size
    const url = environment.apiUrl.config + 'recorder-subframe-size';
    return this.http.get<SubframeSize[]>(url, { headers: this.httpGetHeadersForJSON() }).pipe(catchError(this.httpHandleError));
  }

  public getMiniFrames(): Observable<MiniFrame[]> { // get all the Mini frames
    const url = environment.apiUrl.config + 'recorder-miniframes';
    return this.http.get<MiniFrame[]>(url, { headers: this.httpGetHeadersForJSON() }).pipe(catchError(this.httpHandleError));
  }

  public getBlockMode(): Observable<RecorderBlockMode[]> { // get all the BlockModes
    const url = environment.apiUrl.config + 'recorder-blockmode';
    return this.http.get<RecorderBlockMode[]>(url, { headers: this.httpGetHeadersForJSON() }).pipe(catchError(this.httpHandleError));
  }

  public getRecorderDataPacking(): Observable<DataPacking[]> { // get all the Data packing
    const url = environment.apiUrl.config + 'recorder-datapacking';
    return this.http.get<DataPacking[]>(url, { headers: this.httpGetHeadersForJSON() }).pipe(catchError(this.httpHandleError));
  }

  public getRecorderDetails(recorderID: number) {// get details for selected recorder
    const url = this.recorderURL + `/${recorderID}`;
    return this.http.get<RecorderConfigModel>(url, { headers: this.httpGetHeadersForJSON() })
      .pipe(catchError(this.httpHandleError));
  }

  public addNewRecorder(newRecorder: RecorderFinalModel) {// create a new Recorder
    return this.http.post<number>(this.recorderURL, newRecorder, { headers: this.httpGetHeadersForJSON() })
      .pipe(catchError(this.httpHandleError)
      );
  }

  public deleteRecorder(recorderID: number) {// Delete the recorder
    const url = environment.apiUrl.config + 'recorder' + `/${recorderID}`;
    return this.http.delete(url, { headers: this.httpGetHeadersForJSON() })
      .pipe(catchError(this.httpHandleError));
  }

  public updateRecorder(newRecorder: RecorderFinalModel, recorderID: number) {
    const url = this.recorderURL + `/${recorderID}`;
    return this.http.put(url, newRecorder, { headers: this.httpGetHeadersForJSON() })
      .pipe(catchError(this.httpHandleError)
      );
  }
}
