import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { takeUntil } from 'rxjs';
import { Action } from 'src/app/common/components/base-view.component';
import { BreadcrumbService } from 'src/app/common/services/breadcrumb.service';
import { Frame, Parameter, ParameterList, getMapView767, tableparameters } from 'src/app/features/configuration/models/dataframe717-767';
import { DataFrameService } from 'src/app/features/configuration/services/data-frame.service';
import { TransferdataService } from 'src/app/features/configuration/services/transferdata.service';
import { SecurityUserService } from 'src/app/security/services/security-user.service';
import { Components } from '../../../integration/configuration.components';
import { MapViewComponent } from '../../dataframe-acms/dataframe/data-frame-mapview/mapview.component';

@Component({
  selector: 'app-mapview767',
  templateUrl: './mapview767.component.html',
  styleUrls: ['./mapview767.component.scss']
})
export class Mapview767Component extends MapViewComponent {
  colLen = 0;
  frames: Frame[] = [];
  tableParameters: tableparameters[] = [];
  maptableParameters: tableparameters[] = [];
  getMapViewVal: getMapView767[] = [];
  bitLength = 0;
  hzLabel?: string;
  selectedFrameItem: any;
  dataparameter: Parameter[] = [];
  frameIdentification: Frame[] = [];
  updatedMnemonic: any;
  first = 0;

  constructor(
    router: Router,
    breadcrumbService: BreadcrumbService,
    confirmationService: ConfirmationService,
    messageService: MessageService,
    appService: TransferdataService,
    route: ActivatedRoute,
    dataframeservice: DataFrameService,
    realUserService: SecurityUserService
  ) {
    super(
      router,
      breadcrumbService,
      confirmationService,
      messageService,
      appService,
      route,
      dataframeservice,
      realUserService
    );
    this.noDataFoundLabel = 'No 767 Data Frame MapView Found.';
    this.pageTitle = Components.MapView767.label;
    this.messageLabel = 'Data Frame 767.'
    this.mapviewComponent = Components.MapView767
    this.setBreadCrumbs(Components.ListView767.path);
  }

  getDataFrame(versionId: number) {
    this.showLoader();
    this.dataframeservice.IdentifyDataFrame(versionId)
      .pipe(takeUntil(this.ngUnsubscribe)).subscribe({
        next: (data) => {
          const response = JSON.parse(data.content);
          this.prepareListView(response);
        },
        error: (err) => {
          this.showErrorMsg(err, Action.Get, `${this.messageLabel}`);
          this.hideLoader();
        },
        complete: () => {
          this.hideLoader();
        }
      })
  }

  prepareListView(response: any) {
    this.frameIdentification = [];
    this.dataparameter = [];
    const updatedJSON = response;
    this.dataFrame = response;
    for (const key in updatedJSON.FREDFile.FRED767.Frame) {
      for (const key1 in updatedJSON.FREDFile.FRED767.Frame[key]) {
        this.frameIdentification.push(updatedJSON.FREDFile.FRED767.Frame[key][key1]);
      }
    }

    for (const key in updatedJSON.FREDFile.FRED767.Parameter767) {
      for (const key1 in updatedJSON.FREDFile.FRED767.Parameter767[key]) {
        this.dataparameter.push(updatedJSON.FREDFile.FRED767.Parameter767[key][key1]);
      }
    }
    this.updateMnemonics();
  }

  updateMnemonics() {
    const updatedframe = JSON.parse(sessionStorage.getItem('FrameDetails'));
    if (updatedframe != (undefined || null)) {
      this.showWarning()
      this.updatedFrameDetails();
    }
    const updatedRow = JSON.parse(sessionStorage.getItem('Mnemonics'));
    if (updatedRow != (undefined || null)) {
      this.showWarning()
      this.updatedParameters();
    }
    const deletedMnemonics = JSON.parse(sessionStorage.getItem('DeletedMnemonics'));
    if (deletedMnemonics != (undefined || null)) {
      this.showWarning()
      this.deleteMnemonics(deletedMnemonics);
    }

    const dataParamList = [];

    this.dataparameter.forEach((parameterItem) => {
      for (const frame of this.frameIdentification) {
        if (!frame.Parameter_List.Name_Ref) {
          frame.Parameter_List = { Name_Ref: [] } as ParameterList;
        }
        if (frame.Parameter_List.Name_Ref.includes(parameterItem.Name) === true) {
          const obj = {
            Name: parameterItem.Name,
            Mnemonic_Code: parameterItem.Mnemonic_Code,
            Comments: parameterItem.Comments,
            Units: parameterItem.Units,
            Data_Type: parameterItem.Range.Data_Type,
            Frame_ID: frame.Frame_ID,
            Frame_Title: frame.Frame_Title,
            Recording_Rate: frame.Recording_Rate,
            Resolution: parameterItem.Resolution,
            Min_Operational_Range: Object.prototype.hasOwnProperty.call(parameterItem, 'Min_Operational_Range') ? parameterItem.Operational_Range.RealRange_Start : 0,
            Max_Operational_Range: Object.prototype.hasOwnProperty.call(parameterItem, 'Max_Operational_Range') ? parameterItem.Operational_Range.RealRange_End : 0,
            Sign_Convention: parameterItem.Sign_Convention,
            Signal_Source: parameterItem.Signal_Source,
            Bit_Length: parameterItem.Number_of_Bits,
            Raw_Range_Min: parameterItem.Range.Raw_Range.IntRange_Start,
            Raw_Range_Max: parameterItem.Range.Raw_Range.IntRange_End,
            Frame_Number: frame.Parameter_List.Name_Ref.indexOf(parameterItem.Name) + 1,
            Frame: frame,
            Schema767: parameterItem,
            Frame_Rate: Object.prototype.hasOwnProperty.call(frame.Recording_Rate, 'Denominator') ? parseFloat((frame.Recording_Rate.Numerator / frame.Recording_Rate.Denominator).toFixed(2)) : frame.Recording_Rate.Numerator,

          }
          dataParamList.push(obj)
        }
      }
    })
    this.getMapViewVal.push({ dataParameter: dataParamList, frameDetails: this.frameIdentification });
    this.getMapView();
  }

  updatedFrameDetails() {
    this.frameIdentification = [];
    this.showMessage = true;
    this.updatedFrame = JSON.parse(sessionStorage.getItem('FrameDetails'));
    this.dataFrame.FREDFile.FRED767.Header = this.updatedFrame.FREDFile.FRED767.Header;
    this.dataFrame.FREDFile.FRED767.Recorder_File = this.updatedFrame.FREDFile.FRED767.Recorder_File;
    this.dataFrame.FREDFile.FRED767.Frame = this.updatedFrame.FREDFile.FRED767.Frame;
    for (const key in this.updatedFrame.FREDFile.FRED767.Frame) {
      for (const key1 in this.updatedFrame.FREDFile.FRED767.Frame[key]) {
        this.frameIdentification.push(this.updatedFrame.FREDFile.FRED767.Frame[key][key1]);
      }
    }
    sessionStorage.setItem('FrameDetails', JSON.stringify(this.updatedFrame));
  }

  updatedParameters() {
    const mnemonicsArray = JSON.parse(sessionStorage.getItem('Mnemonics'));
    for (const key in mnemonicsArray) {
      for (const key1 in mnemonicsArray[key]) {
        for (const key2 in mnemonicsArray[key][key1]) {
          const name = mnemonicsArray[key][key1][key2].Schema767.Mnemonic_Code;
          if (key1 === "Edit") {
            this.dataparameter[key2] = mnemonicsArray[key][key1][key2].Schema767;
            this.dataFrame.FREDFile.FRED767.Parameter767[key2] = { [name]: mnemonicsArray[key][key1][key2].Schema767 };
          }
          if (key1 === "Clone") {
            this.frameIdentification.filter((value) => {
              if (value.Frame_ID === mnemonicsArray[key][key1][key2].Frame.Frame_ID) {
                value.Parameter_List.Name_Ref.push(mnemonicsArray[key][key1][key2].Schema767.Name)
              }
            });
            this.dataparameter.push(mnemonicsArray[key][key1][key2].Schema767);
            this.dataFrame.FREDFile.FRED767.Frame.filter((value) => {
              if (value.Frame_ID === mnemonicsArray[key][key1][key2].Frame.Frame_ID) {
                value.Parameter_List.Name_Ref.push(mnemonicsArray[key][key1][key2].Schema767.Name)
              }
            });
            this.dataFrame.FREDFile.FRED767.Parameter767.push({ [name]: mnemonicsArray[key][key1][key2].Schema767 });
          }
          if (key1 === "Add") {
            this.frameIdentification.filter((value) => {
              if (value.Frame_ID === mnemonicsArray[key][key1][key2].Frame.Frame_ID) {
                value.Parameter_List.Name_Ref.push(mnemonicsArray[key][key1][key2].Schema767.Name)
              }
            });
            this.dataparameter.push(mnemonicsArray[key][key1][key2].Schema767);
            this.dataFrame.FREDFile.FRED767.Frame.filter((value) => {
              if (value.Frame_ID === mnemonicsArray[key][key1][key2].Frame.Frame_ID) {
                value.Parameter_List.Name_Ref.push(mnemonicsArray[key][key1][key2].Schema767.Name)
              }
            });
            this.dataFrame.FREDFile.FRED767.Parameter767.push({ [name]: mnemonicsArray[key][key1][key2].Schema767 })
          }
        }

      }
    }
  }

  prepareTableColHead() {
    return Array.from({ length: this.bitLength }, (_, i) => ({ label: `${i === 0 || i === this.bitLength - 1 ? 'Bit ' : ''}${i + 1}`, index: i + 1 }));
  }

  getMapView() {
    if (this.getMapViewVal.length > 0) {
      this.frames = this.getMapViewVal[0].frameDetails;
      this.frames = this.frames.sort(function (a, b) {
        return a.Frame_ID - b.Frame_ID;
      });
      this.tableParameters = this.getMapViewVal[0].dataParameter;
      this.tableParameters.sort((a, b) => {
        if (a.Frame_ID == null && b.Frame_ID != null) {
          return 1;
        } else if (a.Frame_ID != null && b.Frame_ID == null) {
          return -1;
        } else if (a.Frame_ID != null && b.Frame_ID != null) {
          return a.Frame_ID - b.Frame_ID;
        } else { return 0 }
      });
      const selectedFrameTitle = this.frames[0].Frame_Title
      this.selectFrame({ value: selectedFrameTitle })
    }
  }

  selectFrame(param: any) {
    this.first = 0;
    this.maptableParameters = [];
    this.selectedFrameItem = param.value;
    const selectedFrame = this.frames.filter(a => a.Frame_Title == param.value);
    if (selectedFrame[0].Recording_Rate.Numerator?.toString() != undefined) {
      this.hzLabel = selectedFrame[0].Recording_Rate.Numerator?.toString()
    }
    if (selectedFrame[0].Recording_Rate.Denominator?.toString() != undefined) {
      this.hzLabel += "/" + selectedFrame[0].Recording_Rate.Denominator?.toString()
    }
    this.hzLabel += 'Hz';
    this.tableParameters.forEach((val) => {
      if (val.Frame_Title == param.value) {
        this.maptableParameters.push(val);
      }
    })
    const bitLengthList = this.maptableParameters.map(obj => obj.Bit_Length);
    this.bitLength = Math.max(...bitLengthList);
  }

  toEditView(row: any) {
    this.navigateTo({
      path: Components.DataFrames767.path,
      query: {
        editName: row.Schema767.Mnemonic_Code,
        isMapView: 1,
      },
    })
  }

  toListView() {
    this.navigateTo({
      path: Components.ListView767.path
    })
  }

  toAnalyseDataFrame() {
    this.navigateTo({
      path: Components.DataFrameAnalyse767.path,
      query: {
        editName: Components.DataFrameAnalyse767.label,
        isMapView: 1,
      },
    })
  }

  toDataFrameDetails() {
    this.navigateTo({
      path: Components.DataFrame767.path,
      query: {
        isMapView: 1,
      },
    })
  }

  onAddDataFrame() {
    this.navigateTo({
      path: Components.DataFrames767.path,
      query: {}
    });
  }

  resetDataFrame() {
    this.dataparameter = [];
    this.frames = [];
    this.tableParameters = [];
    this.maptableParameters = [];
    this.getMapViewVal = [];
    this.dataparameter = [];
    this.frameIdentification = [];
    this.resetMapView()
  }

  deleteMnemonics(deletedMnemonics: any) {
    deletedMnemonics.forEach(element => {
      for (const key in this.dataFrame.FREDFile.FRED767.Frame) {
        for (const key1 in this.dataFrame.FREDFile.FRED767.Frame[key]) {
          this.dataFrame.FREDFile.FRED767.Frame[key][key1].Parameter_List.Name_Ref.forEach((name) => {
            if (name === element.Name) {
              const index = this.dataFrame.FREDFile.FRED767.Frame[key][key1].Parameter_List.Name_Ref.indexOf(element.Name);
              this.dataFrame.FREDFile.FRED767.Frame[key][key1].Parameter_List.Name_Ref.splice(index, 1);
            }
          }
          )
        }
      }
      for (const key in this.dataFrame.FREDFile.FRED767.Parameter767) {
        for (const key1 in this.dataFrame.FREDFile.FRED767.Parameter767[key]) {
          if (this.dataFrame.FREDFile.FRED767.Parameter767[key][key1].Mnemonic_Code === element.Mnemonic_Code) {
            this.dataFrame.FREDFile.FRED767.Parameter767.splice(key, 1);
          }
        }
      }
      this.dataparameter.splice(this.dataparameter.findIndex(e => e.Mnemonic_Code === element.Mnemonic_Code), 1);
      this.updatedMnemonic = JSON.parse(sessionStorage.getItem('Mnemonics'))
      for (const key in this.updatedMnemonic) {
        for (const key1 in this.updatedMnemonic[key]) {
          for (const key2 in this.updatedMnemonic[key][key1]) {
            if (this.updatedMnemonic[key][key1][key2].Mnemonic_Code === element.Mnemonic_Code) {
              this.updatedMnemonic.splice(key, 1);
            }
          }
        }
      }

    });
    this.setupMnemonics({
      updatedMnemonic: this.updatedMnemonic,
      deletedMnemonics
    })
  }
}
