import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Setting, SettingPair } from '../models/setting';
import { OrganizationType } from '../../features/administration/models/organization';
import { environment } from 'src/environments/environment';

@Injectable()
export class SettingsService extends BaseService {
  settingUrl = environment.apiUrl.admin;

  /**
   * Constructor.
   * @param http The HTTP client.
   */
  constructor(private http: HttpClient) {
    super();
  }

  /**
   * Get all the default settings for the organization type
   */
  getDefaultSettings(orgType: OrganizationType): Observable<Setting[]> {
    const url = this.settingUrl + this.getOrgEndPoint(orgType) + '-organization/' + orgType;
    return this.http.get<Setting[]>(url, { headers: this.httpGetHeadersForJSON() })
      .pipe(map(settings => settings.map(element => this.mapSetting(element, true))),
        catchError(this.httpHandleError)
      );
  }

  /**
   * Gets all settings for the logged in org
   * @returns The observable collection of settings.
   */
  getSettings(orgType: OrganizationType): Observable<Setting[]> {
    const url = this.settingUrl + this.getOrgEndPoint(orgType);
    return this.http.get<Setting[]>(url, { headers: this.httpGetHeadersForJSON() })
      .pipe(map(settings => settings.map(element => this.mapSetting(element))),
        catchError(this.httpHandleError)
      );
  }

  /**
   * Gets a system-wide setting (not airline-specific).
   * @param settingDefinitionId - The setting identifier.
   * @returns The observable setting.
   */
  getSystemSetting(settingDefinitionId: number, orgType: OrganizationType): Observable<Setting> {
    const url = this.settingUrl + this.getOrgEndPoint(orgType) + `/${settingDefinitionId}`;
    return this.http.get<Setting>(url, { headers: this.httpGetHeadersForJSON() })
      .pipe(
        catchError(this.httpHandleError)
      );
  }

  getSettingsByOrg(orgType: OrganizationType, orgId: number) {
    let url: string;
    if (orgType === OrganizationType.Airline) {
      url = this.settingUrl + 'setting-by-airline' + `/${orgId}`;
    } else {
      url = this.settingUrl + 'setting-by-company' + `/${orgId}`;
    }

    return this.http.get<Setting[]>(url, { headers: this.httpGetHeadersForJSON() })
      .pipe(map(settings => settings.map(element => this.mapSetting(element))),
        catchError(this.httpHandleError)
      );
  }

  /**
   * Updates the specified setting.
   * @param setting - The airline setting.
   */
  updateSetting(setting: SettingPair[], orgType: OrganizationType, id: number) {
    if (setting) {
      const url = this.settingUrl + this.getOrgEndPoint(orgType) + `/${id}`;
      return this.http.put(url, JSON.stringify(setting),
        { headers: this.httpGetHeadersForJSON() })
        .pipe(
          catchError(this.httpHandleError)
        );
    }
  }

  updateCurrentOrgSetting(setting: SettingPair[], orgType: OrganizationType) {
    let url;
    if (orgType === OrganizationType.Airline) {
      url = this.settingUrl + 'current-setting-airline';
    } else {
      url = this.settingUrl + 'current-setting-company';
    }

    if (setting) {
      return this.http.put(url, JSON.stringify(setting),
        { headers: this.httpGetHeadersForJSON() })
        .pipe(
          catchError(this.httpHandleError)
        );
    }
  }

  private getOrgEndPoint(orgType: OrganizationType): string {
    let endpoint = "";
    switch (orgType) {
      case OrganizationType.Airline:
        endpoint = 'setting-airline';
        break;
      case OrganizationType.Company:
        endpoint = 'setting-company';
        break;
    }
    return endpoint;
  }

  // Casts the settings to the appropriate type and sets the value to the default if default set to true
  private mapSetting(setting: Setting, defaultValue = false): Setting {
    if (defaultValue) {
      setting.setting_value = setting.settingdefinition_defaultvalue;
    }
    if (setting.settingdefinition_datatype == 'boolean') {
      setting.setting_value = setting.setting_value === 'true' ? true : false;
    }
    return setting;
  }
}
