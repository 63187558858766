import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-list-view-header',
  templateUrl: './list-view-header.component.html',
  styleUrls: ['./list-view-header.component.css'],
})
export class ListViewHeaderComponent {
  @Input() pageTitle;
  @Input() showMessage;
  @Input() messages;
  @Input() userCanManage;
  @Input() isMapView? = false;
  @Output() onNavigateToDFDetails = new EventEmitter();
  @Output() onNavigateToMapView = new EventEmitter();
  @Output() onNavigateToListView = new EventEmitter();
  @Output() onNavigateToAnalyseDF = new EventEmitter();
  @Output() onAddDataFrame = new EventEmitter();

  navigateToDFDetails() {
    this.onNavigateToDFDetails.emit();
  }

  navigateToMapView() {
    this.onNavigateToMapView.emit();
  }

  navigateToListView() {
    this.onNavigateToListView.emit();
  }

  navigateToAnalyseDF() {
    this.onNavigateToAnalyseDF.emit();
  }

  addDataFrame() {
    this.onAddDataFrame.emit();
  }
}
