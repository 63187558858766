<p-toast [showTransformOptions]="'translate(10%,1000px)'" [showTransitionOptions]="'2000ms ease-out'" key="message"
  position="top-right"></p-toast>
<div class="layout-topbar p-shadow-4">

  <!-- <a class="layout-menu-button collapse-menu"  >
    <i class="pi pi-bars"></i>
    </a> -->
  <div class="layout-topbar-left">

    <!-- [style]="{'width': app.menuMode === 'slim' ? '9rem' : '17rem'}" -->
    <a class="layout-topbar-logo" routerLink="/" pTooltip="Dashboard..." tooltipPosition="bottom">
      <!-- <img id="app-logo" src="assets/images/spectr_noun_002_35076.jpg" alt="ACES" style="height: 3rem"> -->
    </a>
    <a class="layout-menu-button p-shadow-6" [style]="{'left': app.menuMode === 'slim' ? '1.5rem' : '1.5rem'}"
      (click)="appMain.onMenuButtonClick($event)" pRipple [pTooltip]="appMain.getMenuButtonTooltip()">
      <!-- <i class="pi pi-chevron-right"></i> heheh -->
      <i class="material-symbols-outlined">menu_open</i>

    </a>
  </div>

  <div class="layout-topbar-right" [ngClass]="{'layout-topbar-mobile-active': appMain.mobileTopbarActive}">
    <div class="layout-topbar-actions-left">
    </div>

    <div class="layout-topbar-actions-right">
      <ul class="layout-topbar-items">
        <li class="layout-topbarright-logo">

          <!--          <a href="https://www.teledynecontrols.com" target="_blank">-->
          <!--            <img class="p-mr-2" id="tdy-logo" src="assets/layout/images/TDY-Logo-Blue.png" alt="ACES" style="height: 2.5rem">-->
          <!-- <img class=" " id="tdy-logo" src="assets/images/teledyne-logo.png" alt="" style="    height: 40px;" > -->
          <!--          </a>-->
        </li>
        <li class="layout-topbar-item"></li>
        <li class="layout-topbar-item layout-search-item">
          <a class="layout-topbar-action rounded-circle" (click)="appMain.onTopbarItemClick($event, 'search')" pRipple
            pTooltip="Search" tooltipPosition="bottom">


            <i class="material-symbols-outlined"> search</i>
          </a>
        </li>
        <li class="layout-topbar-item layout-mode-item">

          <!-- <a class="layout-topbar-action rounded-circle" (click)="appMain.onTopbarItemClick($event, 'search')" pRipple pTooltip="Dark Theme" tooltipPosition="bottom">
            <i class="pi pi-moon fs-large"></i>
          </a> -->

          <a class="layout-topbar-action flex justify-content-center align-items-center p-px-2 rounded-circle"
            (click)="appMain.onTopbarItemClick($event, 'themes')" pRipple pTooltip="Themes" tooltipPosition="bottom">

            <i class="material-symbols-outlined">
              dark_mode </i>
          </a>
          
          <!--Load themes options dynamically based on application-->
          <ul class="layout-topbar-action-panel p-shadow-6" style="min-width: 20rem;"
              [@topbarActionPanelAnimation]="'visible'" *ngIf="appMain.activeTopbarItem === 'themes'">
                <a *ngFor="let theme of themeOptions" (click)="changeTheme(theme.file)">
                  <li [ngClass]="{'active_theme': isThemeSelected(theme.file)}" class="layout-topbar-action-item">
                    <i class="material-symbols-outlined pr-2">palette</i>
                    <span>{{ theme.name }}</span>
                  </li>
                </a>
          </ul>
        </li>

        <li class="layout-topbar-item">
          <a class="layout-topbar-action flex justify-content-center align-items-center p-px-2 rounded-circle"
            (click)="appMain.onTopbarItemClick($event, 'organization')" pRipple pTooltip="Organization"
            tooltipPosition="bottom">

            <i class="material-symbols-outlined">
              grid_view
            </i>

          </a>

          <div class="layout-topbar-action-panel p-shadow-6 user-top-div" style="min-width: 20rem;"
            [@topbarActionPanelAnimation]="'visible'" *ngIf="appMain.activeTopbarItem === 'organization'">
              <div class="flex flex-wrap justify-content-start align-items-center organization_menu" style="max-width: 300px;">
                <button *ngFor="let org of availableOrgs"
                  [ngClass]="org === activeOrg ? 'items_organization active' : 'items_organization'"
                    (click)="onOrgChange(org)" style="cursor: pointer; ">
                    {{ org }}
                </button>
              </div>
          </div>
        </li>
        <!-- notifications wrapper is hidden right now -->
        <li *ngIf="false" class="layout-topbar-item notifications notifications_wrapper">
          <a class="layout-topbar-action rounded-circle" (click)="appMain.onTopbarItemClick($event, 'notifications')"
            pRipple pTooltip="Notifications" tooltipPosition="bottom">
            <span class="p-overlay-badge">
              <span id=" " class="p-badge p-component p-badge-danger p-badge-dot"></span>
              <i class="material-symbols-outlined">
                notifications_active
              </i>
              <span *ngIf="notifCount > 1" class="p-badge p-badge-dot"
                style="background-color: #FF2828;min-width: 0.5rem;height: 0.5rem;">1</span>
            </span>
          </a>


          <ul class=" layout-topbar-action-panel p-shadow-6" [@topbarActionPanelAnimation]="'visible'"
            *ngIf="appMain.activeTopbarItem === 'notifications'">
            <li class="p-3 flex justify-content-between align-items-center "> <span
                class="text-lg font-medium">Notifications</span> <span class=" text-green-600">
                <span class="  font-bold">{{notifCount}} 0</span>
                unread notifications.</span></li>
            <li class="  ">


              <div class="notification_message_wrapper p-0">
                <h4 class="day_title  text-sm font-medium ">
                  TODAY

                </h4>

                <div class="notification_message p-0">
                  <div class="notification_details new_noti">
                    <div class="header  p-0 font-medium pb-0  pt-2  flex justify-content-between align-items-center">
                      <span>Message Headline </span> <span class="text-sm font-normal"> 2h Ago</span>
                    </div>
                    <div class="desc p-0 pt-1 flex justify-content-between align-items-center">
                      <span>Text wil come here...</span>
                    </div>
                  </div>
                </div>
                <div class="notification_message  p-0">
                  <div class="notification_details new_noti">
                    <div class="header  p-0 font-medium pb-0  pt-2  flex justify-content-between align-items-center">
                      <span>Message Headline </span> <span class="text-sm font-normal"> 2h Ago</span>
                    </div>
                    <div class="desc p-0 pt-1 flex justify-content-between align-items-center">
                      <span>Long text message will come here.. its just a test notification and it will come
                        here...</span>
                    </div>
                  </div>
                </div>
                <div class="notification_message p-0">
                  <div class="notification_details">
                    <div class="header  p-0 font-medium pb-0  pt-2  flex justify-content-between align-items-center">
                      <span>Message Headline </span> <span class="text-sm font-normal"> 2h Ago</span>
                    </div>
                    <div class="desc p-0 pt-1 flex justify-content-between align-items-center">
                      <span>Text wil come here...</span>
                    </div>
                  </div>
                </div>

                <h4 class="day_title  text-sm font-medium ">
                  26 Aug 2024

                </h4>
                <div class="notification_message p-0">
                  <div class="notification_details">
                    <div class="header  p-0 font-medium pb-0  pt-2  flex justify-content-between align-items-center">
                      <span>Message Headline </span> <span class="text-sm font-normal"> 2h Ago</span>
                    </div>
                    <div class="desc p-0 pt-1 flex justify-content-between align-items-center">
                      <span>Text wil come here...</span>
                    </div>
                  </div>
                </div>
                <div class="notification_message p-0">
                  <div class="notification_details">
                    <div class="header  p-0 font-medium pb-0  pt-2  flex justify-content-between align-items-center">
                      <span>Message Headline </span> <span class="text-sm font-normal"> 2h Ago</span>
                    </div>
                    <div class="desc p-0 pt-1 flex justify-content-between align-items-center">
                      <span>Text wil come here...</span>
                    </div>
                  </div>
                </div>
                <div class="notification_message p-0">
                  <div class="notification_details">
                    <div class="header  p-0 font-medium pb-0  pt-2  flex justify-content-between align-items-center">
                      <span>Message Headline </span> <span class="text-sm font-normal"> 2h Ago</span>
                    </div>
                    <div class="desc p-0 pt-1 flex justify-content-between align-items-center">
                      <span>Text wil come here...</span>
                    </div>
                  </div>
                </div>
                <div class="notification_message p-0">
                  <div class="notification_details">
                    <div class="header  p-0 font-medium pb-0  pt-2  flex justify-content-between align-items-center">
                      <span>Message Headline </span> <span class="text-sm font-normal"> 2h Ago</span>
                    </div>
                    <div class="desc p-0 pt-1 flex justify-content-between align-items-center">
                      <span>Text wil come here...</span>
                    </div>
                  </div>
                </div>
                <div class="notification_message p-0">
                  <div class="notification_details">
                    <div class="header  p-0 font-medium pb-0  pt-2  flex justify-content-between align-items-center">
                      <span>Message Headline </span> <span class="text-sm font-normal"> 2h Ago</span>
                    </div>
                    <div class="desc p-0 pt-1 flex justify-content-between align-items-center">
                      <span>Text wil come here...</span>
                    </div>
                  </div>
                </div>
                <div class="notification_message p-0">
                  <div class="notification_details">
                    <div class="header  p-0 font-medium pb-0  pt-2  flex justify-content-between align-items-center">
                      <span>Message Headline </span> <span class="text-sm font-normal"> 2h Ago</span>
                    </div>
                    <div class="desc p-0 pt-1 flex justify-content-between align-items-center">
                      <span>Text wil come here...</span>
                    </div>
                  </div>
                </div>
                <div class="notification_message p-0">
                  <div class="notification_details">
                    <div class="header  p-0 font-medium pb-0  pt-2  flex justify-content-between align-items-center">
                      <span>Message Headline </span> <span class="text-sm font-normal"> 2h Ago</span>
                    </div>
                    <div class="desc p-0 pt-1 flex justify-content-between align-items-center">
                      <span>Text wil come here...</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="no_notification">


                <p class="noti_alert"> No New Notifications</p> 
    
  
 
              </div>

            </li>


            <li class="view_all_row  p-1 pt-1 pl-3 pb-3 ">
              <button id="viewAllNotificationsButton" label="View All" type="button"
                class=" text-blue-700 p-0 mt-3 p-button-text fs-small" pButton pRipple></button>


            </li>

          </ul>

        </li>
        <li class="layout-topbar-item">
          <a *ngIf="!userAvatarLoaded"
            class="layout-topbar-action flex justify-content-center align-items-center p-px-2 rounded-circle"
            (click)="appMain.onTopbarItemClick($event, 'profile')" pRipple pTooltip="User" tooltipPosition="bottom">

            <i class="material-symbols-outlined">
              person
            </i>
          </a>
          <a *ngIf="userAvatarLoaded"
            class="layout-topbar-action flex justify-content-center align-items-center p-px-2 rounded-circle"
            (click)="appMain.onTopbarItemClick($event, 'profile')" pTooltip="User" tooltipPosition="bottom"
            style="background-color: transparent">
            <img id="userAvatar" alt="User Avatar" [src]="userAvatar"
              style="height: 100%; width: 45px; object-fit: cover; object-position: center; border-radius: 50%" />
          </a>
          <ul class="layout-topbar-action-panel p-shadow-6 user-top-div" style="min-width: 20rem;"
            [@topbarActionPanelAnimation]="'visible'" *ngIf="appMain.activeTopbarItem === 'profile'">
            <div class="flex align-items-center text-gray user_wrapper" (click)="$event.stopPropagation()">

              <p-avatar styleClass="mr-3" size="xlarge">
                <img *ngIf="!userAvatarLoaded" src="../../../../assets/images/icoUsers.png" />

                <!-- <i *ngIf="!userAvatarLoaded"
                class="pi pi-user fs-large p-ml-3 p-mr-3"
                style="padding: 10px;"></i> -->
              </p-avatar>

              <img *ngIf="userAvatarLoaded" id="userAvatarSubmenu" alt="User Avatar" [src]="userAvatar"
                class="p-ml-3 p-mr-3"
                style="background-color: rgba(0, 0, 0, 0.1); height: 100%; width: 50px; object-fit: cover; object-position: center; border-radius: 50%" />
              <span class="flex flex-column">
                <span class="font-bold">{{userName}}</span>
                <span class="p-pt-1 fs-small">{{icaoCode}} - {{userRole}}</span>
              </span>
            </div>
            <a (click)="onClickUserProfile()">
              <li class="layout-topbar-action-item">
                <i class="material-symbols-outlined" style="padding-right: 10px;">manage_accounts</i>
                <span>{{userProfileTitle}}</span>

              </li>
            </a>
            <a (click)="onClickCurrentDetails()" *ngIf="orgType && canViewOrg">
              <li class="layout-topbar-action-item">
                <i *ngIf="orgType == 1 && canViewOrg" class="material-symbols-outlined p-mr-2 "
                  style="padding-right: 10px;">contact_mail</i>
                <i *ngIf="(orgType == 2  || orgType == 3) && canViewOrg" class="material-symbols-outlined p-mr-2 "
                  style="padding-right: 10px;"> contact_mail</i>

                <span>{{activeOrg}} Details</span>
              </li>
            </a>
            <a (click)="onLogoutClick()">
              <li class="layout-topbar-action-item">
                <i class="material-symbols-outlined " style="padding-right: 10px;">logout</i>

                <span>Logout</span>
              </li>
            </a>
          </ul>
        </li>
        <!--        <li class="layout-topbar-item">-->
        <!--          <img class="p-mr-2" id="tdy-logo2" src="assets/layout/images/TDY-Logo-White.png" alt="ACES" style="height: 4rem">-->
        <!--        </li>-->
      </ul>
    </div>
  </div>
</div>