import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from '../../../../common/services/base.service';
import { catchError } from 'rxjs/operators';
import { environment } from "src/environments/environment";
import { ClientApplication, ResponseClientApplication, ResponseClientStatus } from '../models/clientApplication';
@Injectable({
  providedIn: 'root'
})
export class ClientApplicationService extends BaseService {

  constructor(
    private http: HttpClient) {
    super();
    this.baseUrl = environment.apiUrl.datadelivery;
  }

  getClientApplications(): Observable<ClientApplication[]> {
    const url = this.baseUrl + "client-applications";
    return this.http.get<ClientApplication[]>(url, { headers: this.httpGetHeadersForJSON() })
      .pipe(catchError(this.httpHandleError)
      );
  }

  addClientApplication(clientapplication: ResponseClientApplication) {
    const url = this.baseUrl + 'generate-client-application';
    return this.http.post<number>(url, clientapplication, { headers: this.httpGetHeadersForJSON() })
      .pipe(
        catchError(this.httpHandleError)
      )
  }

  updateActiveStatus(clientapplicationstatus: ResponseClientStatus) {
    const url = this.baseUrl + 'activate-client-application';
    return this.http.put<number>(url, clientapplicationstatus, { headers: this.httpGetHeadersForJSON() })
      .pipe(
        catchError(this.httpHandleError)
      )
  }

  updateDeActiveStatus(clientapplicationstatus: ResponseClientStatus) {
    const url = this.baseUrl + 'deactivate-client-application';
    return this.http.put<number>(url, clientapplicationstatus, { headers: this.httpGetHeadersForJSON() })
      .pipe(
        catchError(this.httpHandleError)
      )
  }

  deleteClientApplication(clientapplicationstatus: ResponseClientStatus) {
    const url = this.baseUrl + 'client-applications';
    const options = { headers: this.httpGetHeadersForJSON(), body: clientapplicationstatus }
    return this.http.delete<any>(url, options)
      .pipe(catchError(this.httpHandleError));
  }

  generateClientApplication(clientapplication: ResponseClientStatus) {
    const url = this.baseUrl + 'update-client-application';
    return this.http.put<number>(url, clientapplication, { headers: this.httpGetHeadersForJSON() })
      .pipe(
        catchError(this.httpHandleError)
      )
  }

}
