<p-blockUI [blocked]="loadingCount > 0">
  <tdy-spinner style="position:absolute;left:50%;top:50%;z-index: 10000"></tdy-spinner>
</p-blockUI>
<div class="col-12 card p-3">
  <div class="card-header m-2">
    <h5>{{pageTitle}}</h5>
  </div>
  <div *ngIf="clientpackage" class="col-12 card">
    <form #clientpackageForm="ngForm" (ngSubmit)="OnSubmit()" novalidate>
      <div class="grid formgrid">
        <div class="field   lg:col-4">
          <span class="p-float-label">
            <input [disabled]="isReadonly" type="text" [style]="{ width: '100%' }" pInputText id="name" name="name"
              pattern=".*\S.*" [(ngModel)]="clientpackage.name" required #name="ngModel" maxlength="50" />
            <label for="name">Name</label>

          </span>
          <div class="tdy-required" *ngIf="(name.touched || name.dirty) && name.hasError('required')">
            Name is required.
          </div>
          <div class="tdy-required" *ngIf="name.hasError('pattern')">
            Name cannot be left blank.
          </div>
        </div>

        <div class="field   lg:col-4">
          <span class="p-float-label">
            <input [disabled]="isReadonly" pattern=".*\S.*" #description="ngModel" type="text" id="description"
              maxlength="255" name="description" [style]="{ width: '100%' }" pInputText
              [(ngModel)]="clientpackage.description" />
            <label for="description">Description</label>

          </span>
          <div class="tdy-required" *ngIf="description.hasError('pattern')">
            Description cannot be left blank.
          </div>
        </div>
        <div class="field   lg:col-4">
          <span class="p-float-label">
            <input [disabled]="isReadonly" type="text" id="filename" name="filename" [style]="{ width: '93%' }"
              pInputText [(ngModel)]="clientpackage.file_name" pattern=".*\S.*" #filename="ngModel" />
            <label for="filename">File Name</label>
            <i class="material-symbols-outlined icon_help" (click)="panel.toggle($event)">help</i>

          </span>
          <div class="tdy-required" *ngIf="filename.hasError('pattern')">
            File Name cannot be left blank.
          </div>
        </div>


        <div class="field   lg:col-4">
          <span class="p-float-label">
            <p-dropdown [disabled]="isReadonly" autoWidth="false" id="redactiontype" #redactiontype="ngModel"
              [style]="{ width: '100%' }" optionLabel="redaction_type" optionValue="redaction_id"
              [options]="this.redactionType" (onChange)="onRedactionType($event)" name="redactiontype"
              [(ngModel)]="selectedRedactionId" [autoDisplayFirst]="false" [required]="isRedaction"></p-dropdown>
            <label for="redactiontype">Redaction Type</label>

          </span>
          <div class="tdy-required"
            *ngIf="(redactiontype.touched || redactiontype.dirty) && redactiontype.hasError('required')">
            Redaction Type is required.
          </div>
        </div>


        <div class="field   lg:col-4">
          <span class="p-float-label">
            <p-dropdown [disabled]="isReadonly" autoWidth="false" [autoDisplayFirst]="false" [style]="{ width: '100%' }"
              optionLabel="ziptype" optionValue="ziptype_id" [options]="this.zipType" #zip_id="ngModel" id="zip_id"
              name="ziptype" [autoDisplayFirst]="false" [(ngModel)]="clientpackage.ziptype_id"
              [required]="true"></p-dropdown>
            <label for="ziptype">Zip Type</label>

          </span>
          <div class="tdy-required" *ngIf="(zip_id.touched || zip_id.dirty) && zip_id.hasError('required')">
            Zip Type is required.
          </div>
        </div>


        <div class="field   lg:col-4">
          <span class="p-float-label">
            <input [disabled]="isReadonly || clientpackage.ziptype_id === 1 || clientpackage.ziptype_id== undefined"
              pInputText [(ngModel)]="clientpackage.zip_password" [style]="{ width: '100%' }" autocomplete="on"
              name="pwd" type="password" id="pwd">
            <label for="pwd">Password</label>
          </span>
        </div>
        <div class="p-2 flex flex-spacebetween">
          <p-checkbox [disabled]="isReadonly" label="Primary" [(ngModel)]="clientpackage.primary" id="txtopt"
            name="txt-opt" [binary]="true" inputId="binary"></p-checkbox>
        </div>
        <div class="p-2 flex flex-spacebetween">
          <p-checkbox [disabled]="isReadonly" label="Secondary" [(ngModel)]="clientpackage.secondary" id="txtsec"
            name="txt-sec" [binary]="true" inputId="binary"></p-checkbox>
        </div>

      </div>
      <div class="mt-5">
        <h6>Endpoint Selection</h6>
        <div class="field">
          <p-pickList [source]="availableEndPoints" [target]="clientpackage.endpoints" id="endpoint_id"
            sourceHeader="Available Endpoint(s)" targetHeader="Selected Endpoint(s)" [showSourceControls]="false"
            [showTargetControls]="false" [responsive]="true" [sourceStyle]="{'height':'200px'}"
            (onMoveToTarget)="onMoveToTarget($event)" (onMoveToSource)="onMoveToSource($event)
      " (onMoveAllToSource)="onMoveAllToSource($event)" (onMoveAllToTarget)="onMoveAllToTarget($event)"
            [disabled]="isReadonly" [targetStyle]="{'height':'200px'}">
            <ng-template let-endpoint pTemplate="item">
              <ng-container *ngIf="toendpoints(endpoint)">
                <div>
                  <div>{{endpoint.endpoint_name}}</div>
                </div>
              </ng-container>
            </ng-template>
          </p-pickList>
          <div class="tdy-required"
            *ngIf="(isNew? selectedEndPointsIDs.length < 1 : selectedEndPointsIDs.length < 1 && selectedEndPointsIDs.length != null  )">
            At least 1 Endpoint is required.
          </div>
        </div>
      </div>
      <div class="mt-5">
        <h6>Aircraft Selection</h6>
        <div class="formgrid grid">
          <div class="field col-6">
            <label for="filter">Aircraft Group Filter</label>
            <span class="p-float-label">
              <p-dropdown id="aircraftGroupList" [options]="aircraftGroups" [(ngModel)]="selectedAircraftGroup"
                id="sourcegroup" name="sourcegroup" (onChange)="onChangeAircraftGroup($event)"
                tooltip="Filter by Aircraft Group" [autoDisplayFirst]="true" [showClear]="true"
                [style]="{'width':'96%'}" [dropdownIcon]="'pi pi-filter'"></p-dropdown>

            </span>
          </div>

          <div class="field col-5 ml-4">
            <label for="filter">Aircraft Group Filter</label>
            <span class="p-float-label">
              <p-dropdown id="aircraftGroupList" [options]="aircraftGroups" [(ngModel)]="selectedTargetAircraftGroup"
                id="targetgroup" name="targetgroup" (onChange)="onChangeTargetAircraftGroup($event)"
                tooltip="Filter by Aircraft Group" [autoDisplayFirst]="true" [showClear]="true"
                [style]="{'width':'115%'}" [dropdownIcon]="'pi pi-filter'"></p-dropdown>

            </span>
          </div>
        </div>
        <div class="field">
          <p-pickList #aircraft_filter [source]="availableAircraft" [target]="targetAircraft" id="aircraft_registration"
            name="aircraft_registration" sourceHeader="Available Aircraft" targetHeader="Selected Aircraft"
            [showSourceControls]="false" [showTargetControls]="false" filterBy="aircraft_registration"
            [responsive]="true" [sourceStyle]="{'height':'200px'}" (onMoveToTarget)="onAircraftMoveToTarget($event)"
            (onMoveToSource)="onAircraftMoveToSource($event)
      " (onMoveAllToSource)="onAircraftMoveAllToSource($event)" (onMoveAllToTarget)="onAircraftMoveAllToTarget($event)"
            [disabled]="isReadonly" [targetStyle]="{'height':'200px'}">
            <ng-template let-aircraft pTemplate="item">
              <ng-container>
                <div>
                  <div>{{aircraft.aircraft_registration}}</div>
                </div>
              </ng-container>
            </ng-template>
          </p-pickList>
        </div>
      </div>
      <div class="flex justify-content-end">
        <span class="p-input-icon-left">
          <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" type="button"
            (click)="onCancel()"></button>
        </span>
        <span class="p-input-icon-left p-button-text" style="float: right">
          <button pButton pRipple label="Reset" *ngIf="showButtons" type="button"
            [disabled]="!clientpackageForm.dirty && isButtonDisabled" icon="pi pi-refresh" class="p-button-text"
            (click)="onReset();aircraft_filter.resetFilter()"></button>
        </span>
        <span class="p-input-icon-left ml-3" style="float: right">
          <button pButton pRipple label="Save" *ngIf="showButtons" icon="pi pi-check" type="submit"
            [disabled]="(isNew? (!clientpackageForm.valid || selectedEndPointsIDs.length < 1) : !((clientpackageForm.dirty || picklistChange) && clientpackageForm.valid) || selectedEndPointsIDs.length < 1  )"
            icon="pi pi-check"></button>
        </span>
      </div>
    </form>
  </div>
</div>
<p-overlayPanel style="width:500px" #panel [showCloseIcon]="true" [style]="{'width':'500px'}">
  <ng-template pTemplate="content">
    <div>
      <h5>Wildcards</h5>
    </div>
    <li class="ml-3 mb-2"><span>Aircraft Registration (Reg)</span></li>
    <li class="ml-3 mb-2"><span>Airline ICAO (AirlineICAO)</span></li>
    <li class="ml-3 mb-2"><span>Airline IATA (AirlineIATA)</span></li>
    <li class="ml-3 mb-2"><span>Current Year: %yyyy%</span></li>
    <li class="ml-3 mb-2"><span>Current Month: %MM% </span></li>
    <li class="ml-3 mb-2"><span>Current Day('dd')</span></li>
    <li class="ml-3 mb-2"><span>Current Hour('hh')</span></li>
    <li class="ml-3 mb-2"><span>Current Minute('mm')</span></li>
    <li class="ml-3 mb-2"><span>Current Second('ss')</span></li>
    <li class="ml-3 mb-2"><span>Package Name (Package)</span></li>
    <li class="ml-3 mb-2"><span>Source File Name(Source)</span></li>
  </ng-template>
</p-overlayPanel>
