import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { takeUntil } from 'rxjs';
import { BreadcrumbService } from 'src/app/common/services/breadcrumb.service';
import { permissions } from 'src/app/security/models/permissions';
import { SecurityUserService } from 'src/app/security/services/security-user.service';
import { Airframer } from 'src/app/features/administration/models/airframer';
import { AirframerService } from 'src/app/features/administration/services/airframer.service';
import { Action, BaseViewComponent } from 'src/app/common/components/base-view.component';
import { Components } from '../../integration/administration.components';
@Component({
  selector: 'app-airframers',
  templateUrl: './airframers.component.html'
})
export class AirframersComponent extends BaseViewComponent implements OnInit {
  pageTitle = Components.Airframer.label;
  airframers: Airframer[] = null;
  // public userCanView:boolean;

  messageLabel = 'Airframer';

  constructor(private airframerService: AirframerService,
    private route: ActivatedRoute,
    messageService: MessageService,
    confirmationService: ConfirmationService,
    router: Router,
    private securityUserService: SecurityUserService,
    breadcrumbService: BreadcrumbService) {
    super(messageService, confirmationService, router, breadcrumbService);

    // Note: Service won't update breadcrumb if caller already did so.
    this.breadcrumbService.setItems(route, [
      { label: Components.Airframer.label }  // No routerLink value for current page
    ]);
  }

  ngOnInit(): void {

    this.userCanAdd = this.securityUserService.userHasPermission(permissions.admin.airframers.create);
    this.userCanModify = this.securityUserService.userHasPermission(permissions.admin.airframers.manage);
    this.userCanDelete = this.securityUserService.userHasPermission(permissions.admin.airframers.delete);
    this.userCanView = this.securityUserService.userHasPermission(permissions.admin.airframers.view);

    this.breadcrumbService.setItems(this.route, [{ label: Components.Airframer.label }]);
    this.loadAirframers();

  }

  loadAirframers() {
    this.loadingCount++;
    this.airframerService.listAirframers().pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: (airframers) => {
        this.airframers = airframers;
      },
      error: (err) => {
        this.showErrorMsg(err, Action.Get, `${this.messageLabel}s`);
        this.loadingCount--;
      },
      complete: () => this.loadingCount--
    })
  }

  onRowSelect(event) {
    const airframer = event.data as Airframer;
    this.router.navigate([Components.AirframerDetails.path], {
      queryParams: {
        a: airframer?.airframer_id,
        name: airframer.airframer_name
      }
    }).then();
  }

  onDeleteAirframer(airframer: Airframer) {
    if (!this.userCanDelete) {
      this.showErrorMsg('User is not authorized to delete an Airframer.', Action.Delete, `${this.messageLabel}`, `${airframer.airframer_name}`);
    } else {
      this.confirmationService.confirm({
        message: 'Are you sure that you want to delete the selected Airframer?',
        accept: () => {
          this.airframerService.deleteAirframer(airframer.airframer_id).subscribe({
            next: () => {
              this.showSuccessMsg(Action.Delete, `${this.messageLabel}`, `${airframer.airframer_name}`);
              // Refresh the list of airframers
              this.loadAirframers();
            },
            error: error => {
              this.showErrorMsg(`${error}`, Action.Delete, `${this.messageLabel}`, `${airframer.airframer_name}`);
            }
          });
        }
      });
    }
  }

  onClickAdd() {
    if (!this.userCanAdd) {
      this.showErrorMsg('User is not authorized to add an Airframer.', Action.Add, `${this.messageLabel}`);
    } else {
      this.router.navigate([Components.AirframerDetails.path]).then();
    }
  }

  toAirframer(airframer: Airframer) {
    return airframer;
  }

}
