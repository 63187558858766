import { Directive, Input, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Message, ConfirmationService, MessageService } from "primeng/api";
import { BreadcrumbService } from "src/app/common/services/breadcrumb.service";
import { Frame, IAnalyseDataFromServer } from "src/app/features/configuration/models/dataframe717-767";
import { DataFrameService } from "src/app/features/configuration/services/data-frame.service";
import { DataFrameAnalyseAttributes, DataFrameConstants } from "../../shared/constants/constants";
import { isNotEmptyString, hasOwnPropertyCheck } from "../../shared/utils";
import { DataFrameAnalyseAbstract } from "../data-frame-analyse-abstract.component";
 
@Directive()
export class Analyse767DXSDfComponent extends DataFrameAnalyseAbstract implements OnInit {
  public messages: Message[];
  public errorMessages: string[] = [];
  public bitLength = 0;
  public frameIdentification: Frame[] = []; 
  public frameType = ["Event", "Scheduled"];
  public frameNameArray: string[] = [];

  constructor(router: Router,
              dataFrameService: DataFrameService,
              route: ActivatedRoute,
              breadcrumbService: BreadcrumbService,
              confirmationService: ConfirmationService,
              messageService: MessageService
  ) {
    super(router, route, breadcrumbService, dataFrameService, messageService, confirmationService );
  }

  public processReceivedData(data: IAnalyseDataFromServer): void {
    this.dataParameter = [];
    this.frameIdentification = [];
    this.frameNameArray = [];
    this.dataFrame = [];
    const response = (() => {
      try {
        return JSON.parse(data.content)
      }catch(e){
        return ''
      }
    })();
    this.dataFrame = response;
    const [fredType, parameterType] = this.getFREDType();

    if (isNotEmptyString(response)) {
      if (isNotEmptyString(response.FREDFile)) {
        if (isNotEmptyString(response.FREDFile[fredType])) {
          if (isNotEmptyString(response.FREDFile[fredType].Frame)) {
            for (const key in response.FREDFile[fredType].Frame) {
              for (const key1 in response.FREDFile[fredType].Frame[key]) {
                this.frameIdentification.push(response.FREDFile[fredType].Frame[key][key1]);
              }
            }
            this.frameIdentification.forEach((frame) => {
              frame.Parameter_List.Name_Ref.forEach((name) => {
                this.frameNameArray.push(name);
              })
            })
            if (isNotEmptyString(response.FREDFile[fredType][parameterType])) {
              for (const key in response.FREDFile[fredType][parameterType]) {
                for (const key1 in response.FREDFile[fredType][parameterType][key]) {
                  this.dataParameter.push(response.FREDFile[fredType][parameterType][key][key1]);
                  this.dataParameter.sort((a, b) => {
                    const fa = a.Mnemonic_Code.toLowerCase(),
                      fb = b.Mnemonic_Code.toLowerCase();
                    if (fa < fb) {
                      return -1;
                    }
                    if (fa > fb) {
                      return 1;
                    }
                    return 0;
                  });
                }
              }
              this.generateErrorMessages();
            } else {
              this.errorMessages.push("No Parameter found")
            }
          } else {
            this.errorMessages.push("No Frame found")
          }
        } else {
          this.errorMessages.push("No FRED found")
        }
      } else {
        this.errorMessages.push("No FREDFile found")
      }
    } else {
      this.errorMessages.push("No Data Frame found")
    }
  }

  private getFREDType(): [string,string] {
    return this.dataFrame['ARINC STANDARD'] === 'A767' ? ['FRED767', 'Parameter767'] : ['FREDDXS', 'ParameterDXS'];
  }

  public generateErrorMessages(): void {
    this.isLoading = true;
    this.parameterErrors = [];
    this.validateDataFrame();
    this.validateParameter();
    this.isLoading = false;
  }

  public validateDataFrame(): void {
    const [fredType, _] = this.getFREDType();
    if (hasOwnPropertyCheck(this.dataFrame.FREDFile[fredType].Header, "File_Revision")) {
      if (!isNotEmptyString(this.dataFrame.FREDFile[fredType].Header.fileRevision)) {
        this.pushError("File Revision", DataFrameAnalyseAttributes.GENERAL ,"Revision cannot be left blank", true);
      } else {
        if (!((typeof this.dataFrame.FREDFile[fredType].Header.File_Revision) === "string")) {
          this.pushError("File Revision", DataFrameAnalyseAttributes.GENERAL ,"File Revision should be string.", true);
        }
      }
    } else {
      this.pushError("File Revision" , DataFrameAnalyseAttributes.GENERAL,"File Revision is required.", true);
    }
    this.frameIdentification.forEach((row) => {
      if (hasOwnPropertyCheck(row, "Frame_ID")) {
        if (!isNotEmptyString(row.Frame_ID)) {
          this.pushError("Frame ID", DataFrameAnalyseAttributes.FRAME_DETAILS, "Frame ID cannot be left blank.", true);

        } else {
          if (!Number.isInteger(row.Frame_ID)) {
            this.pushError("Frame ID", DataFrameAnalyseAttributes.FRAME_DETAILS ,"Frame ID should be an integer", true);
          }
          if (row.Frame_ID < 0) {
            this.pushError("Frame ID", DataFrameAnalyseAttributes.FRAME_DETAILS ,"Frame ID should be greater than 0", true);
          }
        }
      } else {
        this.pushError("Frame ID", DataFrameAnalyseAttributes.FRAME_DETAILS ,"Frame ID is required", true);
      }
      if (hasOwnPropertyCheck(row, "Frame_Title")) {
        if (!isNotEmptyString(row.Frame_Title)) {
          this.pushError("Frame Title", DataFrameAnalyseAttributes.FRAME_DETAILS ,"Frame Title cannot be left blank", true);
        } else {
          if (!(typeof (row.Frame_Title) === "string")) {
            this.pushError("Frame Title", DataFrameAnalyseAttributes.FRAME_DETAILS ,"Frame Title should be string", true);
          }
        }
      } else {
        this.pushError("Frame Title", DataFrameAnalyseAttributes.FRAME_DETAILS,"Frame Title is required", true);
      }
      if (hasOwnPropertyCheck(row, "Frame_Type")) {
        const found = this.frameType.some(type => type === row.Frame_Type);
        if (!found) {
          this.pushError("Frame Type", DataFrameAnalyseAttributes.FRAME_DETAILS , "Frame Type should be \"Event\" or \"Scheduled\"", true);
        }
        if (hasOwnPropertyCheck(row, "Recording_Phase")) {
          if (!Number.isInteger(row.Recording_Phase)) {
            this.pushError("Recording Phase", DataFrameAnalyseAttributes.FRAME_DETAILS , "Recording Phase should be an integer", true);
          }
          if (row.Recording_Phase < 0)
            this.pushError("Recording Phase", DataFrameAnalyseAttributes.FRAME_DETAILS , "Recording Phase should not be less than 0", true);
        }
      } else {
        this.pushError("Recording Type", DataFrameAnalyseAttributes.FRAME_DETAILS , "Frame Type is required", true);
      }
      if (hasOwnPropertyCheck(row, "Recording_Rate")) {
        if (hasOwnPropertyCheck(row.Recording_Rate, "Numerator")) {
          if (!isNotEmptyString(row.Recording_Rate.Numerator)) {
            this.pushError("Recording Rate", DataFrameAnalyseAttributes.FRAME_DETAILS , "Recording Rate Numerator cannot be left blank", true);
          }
          if (!Number.isInteger(row.Recording_Rate.Numerator)) {
            this.pushError("Recording Rate", DataFrameAnalyseAttributes.FRAME_DETAILS , "Recording Rate Numerator should be an integer", true);
          }
          if (row.Recording_Rate.Numerator < 0) {
            this.pushError("Recording Rate", DataFrameAnalyseAttributes.FRAME_DETAILS , "Recording Rate Numerator should be greater than 0", true);
          }
        } else {
          this.pushError("Recording Rate", DataFrameAnalyseAttributes.FRAME_DETAILS , "Recording Rate Numerator is required", true);
        }

        if (hasOwnPropertyCheck(row.Recording_Rate, "Denominator")) {
          if (row.Recording_Rate.Numerator && !Number.isInteger(row.Recording_Rate.Denominator)) {
            this.pushError("Recording Rate", DataFrameAnalyseAttributes.FRAME_DETAILS , "Recording Rate Denominator should be an integer", true);
          }
          if (row.Recording_Rate.Denominator < 0) {
            this.pushError("Recording Rate", DataFrameAnalyseAttributes.FRAME_DETAILS , "Recording Rate Denominator should be greater than 0", true);
          }
          if (row.Recording_Rate.Denominator && !row.Recording_Rate.Numerator) {
            this.pushError("Recording Rate", DataFrameAnalyseAttributes.FRAME_DETAILS , "Recording Rate Denominator is required", true);
          }
        }
      } else {
        this.pushError("Recording Rate", DataFrameAnalyseAttributes.FRAME_DETAILS , "Recording Rate is required", true);
      }
    })

  }

  public validateParameter(): void {
    this.dataParameter.forEach((row) => {
      this.bitLength = Math.pow(2, row.Number_of_Bits) - 1;

      if (hasOwnPropertyCheck(row, "Mnemonic_Code")) {
        if (!isNotEmptyString(row.Mnemonic_Code)) {
          this.pushError(row.Mnemonic_Code, DataFrameAnalyseAttributes.MNEMONIC, "Mnemonic cannot be left blank");
        }
        if (!(typeof (row.Mnemonic_Code) === "string")) {
          this.pushError(row.Mnemonic_Code, DataFrameAnalyseAttributes.MNEMONIC, "Mnemonic should be string");
        }
      } else {
        this.pushError(row.Mnemonic_Code, DataFrameAnalyseAttributes.MNEMONIC, "Mnemonic is required");
      }

      if (hasOwnPropertyCheck(row, "Name")) {
        if (!isNotEmptyString(row.Name)) {
          this.pushError(row.Mnemonic_Code, DataFrameAnalyseAttributes.NAME, "Name cannot be left blank");
        } else {
          if (!(typeof (row.Name) === "string")) {
            this.pushError(row.Mnemonic_Code, DataFrameAnalyseAttributes.NAME, "Name should be string");
          }
          const found = this.frameNameArray.includes(row.Name);
          if (!found) {
            this.pushError(row.Mnemonic_Code, DataFrameAnalyseAttributes.NAME, "Name is required in the Frame");
          }
        }
        if (row.Name.length > 64) {
          this.pushError(row.Mnemonic_Code, DataFrameAnalyseAttributes.NAME, "Name should not exceed 64 characters");
        }
      } else {
        this.pushError(row.Mnemonic_Code, DataFrameAnalyseAttributes.NAME, "Name is required");
      }

      if (hasOwnPropertyCheck(row, "Operational_Range")) {
        if (hasOwnPropertyCheck(row.Operational_Range, "RealRange_Start")) {
          if (!isNotEmptyString(row.Operational_Range.RealRange_Start)) {
            this.pushError(row.Mnemonic_Code, DataFrameAnalyseAttributes.GENERAL, "Minimum Operational Range cannot be left blank");
          } else {
            if (!(typeof row.Operational_Range.RealRange_Start === "number")) {
              this.pushError(row.Mnemonic_Code, DataFrameAnalyseAttributes.GENERAL, "Minimum Operational Range should be number");
            } else {
              if (row.Operational_Range.RealRange_Start > row.Operational_Range.RealRange_End) {
                this.pushError(row.Mnemonic_Code, DataFrameAnalyseAttributes.GENERAL, "Minimum Operational Range value greater than Maximum Operational Range");
              }
            }
          }
        } else {
          this.pushError(row.Mnemonic_Code, DataFrameAnalyseAttributes.GENERAL, "Min Operational Range is required");
        }

        if (hasOwnPropertyCheck(row.Operational_Range, "RealRange_End")) {
          if (!isNotEmptyString(row.Operational_Range.RealRange_Start)) {
            this.pushError(row.Mnemonic_Code, DataFrameAnalyseAttributes.GENERAL, "Maximum Operational Range cannot be left blank");
          } else {
            if (!(typeof row.Operational_Range.RealRange_End === "number")) {
              this.pushError(row.Mnemonic_Code, DataFrameAnalyseAttributes.GENERAL, "Maximum Operational Range should be number");
            } else {
              if (row.Operational_Range.RealRange_Start > row.Operational_Range.RealRange_End) {
                this.pushError(row.Mnemonic_Code, DataFrameAnalyseAttributes.GENERAL, "Maximum Operational Range value less than Minimum Operational Range");
              }
            }
          }
        } else {
          this.pushError(row.Mnemonic_Code, DataFrameAnalyseAttributes.GENERAL, "Maximum Operational Range is required");
        }
      }
      if (hasOwnPropertyCheck(row, "Resolution")) {
          if (hasOwnPropertyCheck(row, "Range")) {
            if (hasOwnPropertyCheck(row.Range, "Data_Type")) {
              if (!isNotEmptyString(row.Range.Data_Type)) { /* empty */ } else {
                if (row.Range.Data_Type === "ASCII") {
                  if (row.Resolution != 0) {
                    this.pushError(row.Mnemonic_Code, DataFrameAnalyseAttributes.EU_CONVERSION, "Resolution should be 0 for \"ASCII\" Data Type");
                  }
                }
                if (row.Range.Data_Type != "ASCII") {
                  if (row.Resolution === 0) {
                    this.pushError(row.Mnemonic_Code, DataFrameAnalyseAttributes.EU_CONVERSION, "Resolution should not be 0");
                  }
                }
              }
            }
          }
      } else {
        this.pushError(row.Mnemonic_Code, DataFrameAnalyseAttributes.EU_CONVERSION, "Resolution is required");
      }
      if (hasOwnPropertyCheck(row, "Number_of_Bits")) {
        if (!isNotEmptyString(row.Number_of_Bits)) {
          this.pushError(row.Mnemonic_Code, DataFrameAnalyseAttributes.RECORDING, "Bit Length cannot be left blank");
        } else {
          if (row.Range.Data_Type === "ASCII") {
            if (!(typeof row.Number_of_Bits === "number")) {
              this.pushError(row.Mnemonic_Code, DataFrameAnalyseAttributes.RECORDING, "Bit Length should be number");
            } else {
              if (!Number.isInteger(row.Number_of_Bits)) {
                this.pushError(row.Mnemonic_Code, DataFrameAnalyseAttributes.RECORDING, "Bit Length should be signed integer");
              }
            }
            if (row.Number_of_Bits != 8) {
              this.pushError(row.Mnemonic_Code, DataFrameAnalyseAttributes.RECORDING, "Bit Length should be 8 for \"ASCII\" Data Type");
            }
          } else {
            if (row.Number_of_Bits < 0) {
              this.pushError(row.Mnemonic_Code, DataFrameAnalyseAttributes.RECORDING, "Bit Length should be greater than 0");
            }
          }
        }
      } else {
        this.pushError(row.Mnemonic_Code, "Factor", "Factor is required");
      }

      if (hasOwnPropertyCheck(row, "Range")) {
        if (hasOwnPropertyCheck(row.Range, "Data_Type")) {
          if (!isNotEmptyString(row.Range.Data_Type)) {
            this.pushError(row.Mnemonic_Code, DataFrameAnalyseAttributes.RECORDING, "Data Type cannot be left blank");
          } else {
            const found = DataFrameConstants.enumType.some(type => type === row.Range.Data_Type);
            if (!found) {
              this.pushError(row.Mnemonic_Code, DataFrameAnalyseAttributes.RECORDING, "Data Type should not match with JSON");
            }
          }
        }
        if (hasOwnPropertyCheck(row.Range, "Raw_Range")) {
          if (!isNotEmptyString(row.Range.Raw_Range)) {
            this.pushError(row.Mnemonic_Code, DataFrameAnalyseAttributes.RECORDING, "Raw Range cannot be left blank");
          } else {
            if (hasOwnPropertyCheck(row.Range, "Raw_Range")) {
              if (!isNotEmptyString(row.Range.Raw_Range.IntRange_Start) && row.Range.Raw_Range.IntRange_Start != 0) {
                this.pushError(row.Mnemonic_Code, DataFrameAnalyseAttributes.RECORDING, "Raw Range Minimum cannot be left blank");
              } else {
                if (!(typeof row.Range.Raw_Range.IntRange_Start === "number")) {
                  this.pushError(row.Mnemonic_Code, DataFrameAnalyseAttributes.RECORDING, "Raw Range Minimum should be number");
                } else {
                  if (!Number.isInteger(row.Range.Raw_Range.IntRange_Start)) {
                    this.pushError(row.Mnemonic_Code, DataFrameAnalyseAttributes.RECORDING, "Raw Range Minimum should be signed integer");
                  }
                }
                if (row.Range.Raw_Range.IntRange_Start < 0) {
                  this.pushError(row.Mnemonic_Code, DataFrameAnalyseAttributes.RECORDING, "Raw Range Minimum should not be less than 0");
                }
              }
            } else {
              this.pushError(row.Mnemonic_Code, DataFrameAnalyseAttributes.RECORDING, "Raw Range Minimum is required");
            }
            if (!isNotEmptyString(row.Range.Raw_Range.IntRange_End) && row.Range.Raw_Range.IntRange_End != 0) {
              this.pushError(row.Mnemonic_Code, DataFrameAnalyseAttributes.RECORDING, "Raw Range Maximum cannot be left blank");
            } else {
              if (!(typeof row.Range.Raw_Range.IntRange_End === "number")) {
                this.pushError(row.Mnemonic_Code, DataFrameAnalyseAttributes.RECORDING, "Raw Range Maximum should be number");
              } else {
                if (!Number.isInteger(row.Range.Raw_Range.IntRange_End)) {
                  this.pushError(row.Mnemonic_Code, DataFrameAnalyseAttributes.RECORDING, "Raw Range Maximum should be signed integer");
                }
              }
              if (row.Range.Raw_Range.IntRange_End > this.bitLength) {
                this.pushError(row.Mnemonic_Code, DataFrameAnalyseAttributes.RECORDING, "Value should not be less than (2 ^ Bit Length) -1");
              }
            }
          }
        }
      } else {
        this.pushError(row.Mnemonic_Code, DataFrameAnalyseAttributes.RECORDING, "Raw Range is required");
      }
      if (hasOwnPropertyCheck(row, "EU_Type")) {
        if (hasOwnPropertyCheck(row.EU_Type, "Polynomial")) {
          if (hasOwnPropertyCheck(row, "Range")) {
            if (hasOwnPropertyCheck(row.Range, "Conversion_Step")) {
              if (hasOwnPropertyCheck(row.Range.Conversion_Step, "Poly_Coeff")) {
                const polyArray = row.Range.Conversion_Step.Poly_Coeff;
                if (!isNotEmptyString(polyArray)) {
                  this.pushError(row.Mnemonic_Code, DataFrameAnalyseAttributes.RECORDING, "Polynomial Coefficient cannot be left blank");
                } else {
                  polyArray.map((value) => {
                    if (hasOwnPropertyCheck(value, "Coeff_Pair")) {
                      if (hasOwnPropertyCheck(value.Coeff_Pair, "index")) {
                        if (!isNotEmptyString(value.Coeff_Pair.index)) {
                          this.pushError(row.Mnemonic_Code, DataFrameAnalyseAttributes.RECORDING, "Polynomial Coefficient Index cannot be left blank");
                        }
                        if (!value.Coeff_Pair["#text"] != ("" || null || undefined)) {
                          this.pushError(row.Mnemonic_Code, DataFrameAnalyseAttributes.RECORDING, "Polynomial Coefficient Value cannot be left blank");
                        }
                      }
                    }
                  });
                }
              } else {
                this.pushError(row.Mnemonic_Code, DataFrameAnalyseAttributes.RECORDING, "Polynomial Coefficient is required");
              }
            } else {
              this.pushError(row.Mnemonic_Code, DataFrameAnalyseAttributes.RECORDING, "Conversion Step is required");
            }
          }
        } else if (hasOwnPropertyCheck(row.EU_Type, "Integer-Real Table")) {
          if (hasOwnPropertyCheck(row, "Range")) {
            if (hasOwnPropertyCheck(row.Range, "Conversion_Step")) {
              if (hasOwnPropertyCheck(row.Range.Conversion_Step, "Integer_Real_Table")) {
                const IntArray = row.Range.Conversion_Step.Integer_Real_Table;
                if (!isNotEmptyString(IntArray)) {
                  this.pushError(row.Mnemonic_Code, "Integer-Real Table", "Int-Real Pair cannot be left blank");
                } else {
                  IntArray.map((value) => {
                    if (hasOwnPropertyCheck(value, "Integer_Real_Pair")) {
                      if (hasOwnPropertyCheck(value.Integer_Real_Pair, "index")) {
                        if (!isNotEmptyString(value.Integer_Real_Pair.index)) {
                          this.pushError(row.Mnemonic_Code, "Integer-Real Table", "Int-Real Pair Index cannot be left blank");
                        }
                        if (!value.Integer_Real_Pair["#text"] != ("" || null || undefined)) {
                          this.pushError(row.Mnemonic_Code, "Integer-Real Table", "Int-Real Pair Value cannot be left blank");
                        }
                      }
                    }
                  });
                }
              } else {
                this.pushError(row.Mnemonic_Code, "Integer-Real Table", "Int-Real Pair is required");
              }
            } else {
              this.pushError(row.Mnemonic_Code, DataFrameAnalyseAttributes.RECORDING, "Conversion Step is required");
            }
          }
        } else if (hasOwnPropertyCheck(row.EU_Type, "Real-Real Table")) {
          if (hasOwnPropertyCheck(row, "Range")) {
            if (hasOwnPropertyCheck(row.Range, "Conversion_Step")) {
              if (hasOwnPropertyCheck(row.Range.Conversion_Step, "Real_Real_Table")) {
                const RealArray = row.Range.Conversion_Step.Real_Real_Table;
                if (!isNotEmptyString(RealArray)) {
                  this.pushError(row.Mnemonic_Code, "Integer-Real Table", "Real Real_Pair cannot be left blank");
                } else {
                  RealArray.map((value) => {
                    if (hasOwnPropertyCheck(value, "Real Real_Pair")) {
                      if (hasOwnPropertyCheck(value.Real_Real_Pair, "index")) {
                        if (!isNotEmptyString(value.Real_Real_Pair.index)) {
                          this.pushError(row.Mnemonic_Code, "Integer-Real Table", "Real Real_Pair Index cannot be left blank");
                        }
                        if (!value.Real_Real_Pair["#text"] != ("" || null || undefined)) {
                          this.pushError(row.Mnemonic_Code, "Integer-Real Table", "Real Real_Pair Value cannot be left blank");
                        }
                      }
                    }
                  });
                }
              } else {
                this.pushError(row.Mnemonic_Code, "Integer-Real Table", "Real Real_Pair is required");
              }
            } else {
              this.pushError(row.Mnemonic_Code, DataFrameAnalyseAttributes.RECORDING, "Conversion Step is required");
            }
          }
        }
      }
      if (hasOwnPropertyCheck(row.Range, "Text_Conversion")) {
        if (row.Range.Data_Type === "Unsigned Binary") {
          if (hasOwnPropertyCheck(row.Range.Text_Conversion, "Range_Text_Pair")) {
            const discreteArray = row.Range.Text_Conversion.Range_Text_Pair;
            discreteArray.map((value, index) => {
              if (!(typeof value.min === "number")) {
                this.pushError(row.Mnemonic_Code, DataFrameAnalyseAttributes.DISCRETE, "Discrete Minimum value should be number");
              } else {
                if (!Number.isInteger(value.min)) {
                  this.pushError(row.Mnemonic_Code, DataFrameAnalyseAttributes.DISCRETE, "Discrete Minimum value should be signed integer");
                }
              }
              if (!(typeof value.max === "number")) {
                this.pushError(row.Mnemonic_Code, DataFrameAnalyseAttributes.DISCRETE, "Discrete Maximum value should be number");
              } else {
                if (!Number.isInteger(value.max)) {
                  this.pushError(row.Mnemonic_Code, DataFrameAnalyseAttributes.DISCRETE, "Discrete Maximum value should be signed integer");
                }
              }
              if (!isNotEmptyString(value.text)) {
                this.pushError(row.Mnemonic_Code, DataFrameAnalyseAttributes.DISCRETE, "For each value defined in the Discrete Text there must be a Discrete Text");
              }
              if (value.min < 0 && value.min != 0) {
                this.pushError(row.Mnemonic_Code, DataFrameAnalyseAttributes.DISCRETE, "Discrete Minimum value should not be less than 0");
              }
              if (value.min > this.bitLength) {
                this.pushError(row.Mnemonic_Code, DataFrameAnalyseAttributes.DISCRETE, "Discrete Minimum value should not be greater than Associated Maximum");
              }
              if (value.max < 0 && value.max != 0) {
                this.pushError(row.Mnemonic_Code, DataFrameAnalyseAttributes.DISCRETE, "Discrete Maximum value should not be less than 0");
              }
              if (value.max > this.bitLength) {
                this.pushError(row.Mnemonic_Code, DataFrameAnalyseAttributes.DISCRETE, "Discrete Maximum value should not be greater than Associated Maximum");
              }
              const minfound = discreteArray.some((el, idx) => el.min === value.min && index != idx);
              if (minfound) {
                this.pushError(row.Mnemonic_Code, DataFrameAnalyseAttributes.DISCRETE, "Discrete Minimum value should be unique");
              }
              const maxfound = discreteArray.some((el, idx) => el.max === value.max && index != idx);
              if (maxfound) {
                this.pushError(row.Mnemonic_Code, DataFrameAnalyseAttributes.DISCRETE, "Discrete Maximum value should be unique");
              }
            });
          } else {
            this.pushError(row.Mnemonic_Code, "Range Text", "Range Text Pair is required");
          }
        }
      }
    })
  }

}

// export default Analyse767DXSDfComponent