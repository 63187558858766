// Environment file for running under AWS DEV env

const aws_api_url = 'https://api-spectrumdev.teledynecontrols.com/';

export const environment = {
  configurationName: "development",
  production: false,
  oktaUrl: 'https://teledynecontrolsdev.okta.com',
  oktaClientAppId: '0oa464ynfmwVaKrPY697',
  apiBase: aws_api_url,
  apiUrl: {
    'admin': aws_api_url + 'administration/',
    'config': aws_api_url + 'configuration/',
    'datadelivery': aws_api_url + 'data-delivery/',
    'security': aws_api_url + 'security/',
    'common': aws_api_url + 'common/',
    'sendToTdy': aws_api_url + 'send-to-tdy/',
    'dataDeliveryInsights': aws_api_url + 'data-delivery-insights/',
  },
  encryptedApplicationName:{
    'administration':'gAAAAABm_p7WVb6rqi-BrUbdL0mUQ6RmL2Lx-tH7J7S_4Yft0KwrdThS-c_waAUB-NL42J22SjGsVPWn7l_6GP9wQh48gHX5rg==',
    'configuration':'gAAAAABm_p7W8gcIloivop4F7jkxp4l2M0BRv1Ce7zSmGcWwAaqSdpgVUp_zniNTTokuT4xY_sVKxOe6ZOooMOL7xh4hFkCLUQ==',
    'data-delivery':'gAAAAABm_p7Wbuqj4iIt3iFkztNphsuafQxfZBOjDoZ3Dt9eAeeou459Of3-tIcakK5LTV6_736OsxE4_TSEBwX93ed63HAzPA==',
    'send-to-tdy':'gAAAAABm_p7WnZxDAT3MLQgmvLbHiBRKDI0XbigPqHT4w33tq5E1wwUp_4QvFQykbH9UGWw83WyH2mCb0xentxjKXvSQhjfT3YqdwdOZv4NZDpB898m2s6k=',
    'endpoint-data-delivery':'gAAAAABm_p7WRT6CY__IpR9iUakP9EGrJBqgLjmOyf-P8rJuA5TAOJHk86QaEL00OkFFxRHu9FgeKy2-WzLjZpgl_W-tjhcVZc2T9lC2iExDSB-znEMswFY='
  }
};
