import { forkJoin } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AircraftModel } from 'src/app/features/administration/models/aircraftModel';
import { BreadcrumbService } from 'src/app/common/services/breadcrumb.service';
import { Action, BaseViewComponent } from 'src/app/common/components/base-view.component';
import { AirframerService } from 'src/app/features/administration/services/airframer.service';
import { AircraftModelService } from 'src/app/features/administration/services/aircraft-model.service';
import { Airframer } from 'src/app/features/administration/models/airframer';
import { SecurityUserService } from 'src/app/security/services/security-user.service';
import { permissions } from 'src/app/security/models/permissions';
import { Components } from '../../integration/administration.components';

@Component({
  selector: 'app-aircraft-model-details',
  templateUrl: './aircraft-model-details.component.html',
  styleUrls: ['./aircraft-model-details.scss']
})
export class AircraftModelDetailsComponent extends BaseViewComponent implements OnInit {
  pageTitle = Components.AircraftModelDetails.label;
  aircraftModel: AircraftModel;
  airframers: Airframer[];
  isReadOnly = false;
  messageLabel = 'Aircraft Model';

  @ViewChild('modelForm') modelForm: NgForm;

  /**
   * Constructor for the component.
   *
   * @param userService - Gets user information.
   * @param messageService - Used to display messages.
   * @param confirmationService - Used to confirm the user's intentions.
   * @param route - Used to get the route's query parameters.
   * @param router - Used to route to another page.
   * @param breadcrumbService - Used to update the breadcrumb.
   */
  constructor(private aircraftmodelService: AircraftModelService,
    private airframerService: AirframerService,
    private route: ActivatedRoute,
    private securityUserService: SecurityUserService,
    messageService: MessageService,
    confirmationService: ConfirmationService,
    router: Router,
    breadcrumbService: BreadcrumbService) {
    super(messageService, confirmationService, router, breadcrumbService);

    // Note: Service won't update breadcrumb if caller already did so.
    this.breadcrumbService.setItems(route, [
      { label: Components.AircraftModels.label, routerLink: Components.AircraftModels.path },
      { label: (this.route.snapshot.queryParamMap.get('name')) ? Components.AircraftModelDetails.label + ` (${this.route.snapshot.queryParamMap.get('name')})` : Components.AircraftModelDetails.label },
    ]);
  }

  ngOnInit(): void {
    const canAdd = this.securityUserService.userHasPermission(permissions.admin.aircraft_models.create);
    const canEdit = this.securityUserService.userHasPermission(permissions.admin.aircraft_models.manage);

    const isEdit = !!this.route.snapshot.queryParamMap.get('m'); //tail number

    if (!isEdit) {
      this.isReadOnly = !canAdd;
      this.addModel();
    }
    else {
      this.isReadOnly = !canEdit;
      this.loadModel(+this.route.snapshot.queryParamMap.get('m'));
    }
  }

  /**
   * Loads the model specified in the query parameters.
   *
   * @param modelID - The model identifier.
   */
  loadModel(modelID: number) {
    this.loadingCount++;

    forkJoin({
      model: this.aircraftmodelService.getAircraftModel(modelID),
      airframers: this.airframerService.listAirframers()
    }).pipe(
      takeUntil(this.ngUnsubscribe)).subscribe({
        next: results => {
          this.airframers = results.airframers;
          this.aircraftModel = {
            aircraftmodel_id: results.model.aircraftmodel_id,
            aircraftmodel_model: results.model.aircraftmodel_model,
            aircraftmodel_sub_model: results.model.aircraftmodel_sub_model,
            airframer_id: results.model.airframer_id,
            displayName: results.model.displayName,
            engine_count: results.model.engine_count
          }

          // Update breadcrumb with model number
          this.updateBreadcrumb(Components.AircraftModelDetails.label + ' (' + this.getDisplayName(this.aircraftModel) + ')')
        },
        error: error => {
          this.showErrorMsg(error, Action.Get, `${this.messageLabel}`);
          this.loadingCount--;
        },
        complete: () => {
          this.loadingCount--;
        }
      }
      );
  }

  /**
   * Method that constructs everything needed for adding a new model
   */
  addModel() {
    this.loadingCount++;

    // Update page title and breadcrumb with New...
    this.updateBreadcrumb('New Aircraft Model');
    this.pageTitle = "New Aircraft Model";

    this.airframerService.listAirframers()
      .pipe(
        takeUntil(this.ngUnsubscribe)).subscribe({
          next: results => {
            this.airframers = results;

            // Create the aircraft model
            this.isNew = true;
            this.aircraftModel = new AircraftModel();
            this.aircraftModel.aircraftmodel_id = -1;
          },
          error: error => {
            this.showErrorMsg(error, Action.Add, `${this.messageLabel}`);
            this.loadingCount--;
          },
          complete: () => {
            this.loadingCount--;
          }
        });
  }

  /**
   * Event handler for when the modal panel is submitted. Saves the new or updated model.
   */
  onSubmit() {
    this.loadingCount++;

    if (this.isNew) {
      this.aircraftmodelService.addAircraftModel(this.aircraftModel).pipe(
        takeUntil(this.ngUnsubscribe)).subscribe({
          next: id => {
            this.aircraftModel.aircraftmodel_id = id;
            this.router.navigate([], {
              relativeTo: this.route,
              queryParams: {
                m: this.aircraftModel.aircraftmodel_id
              },
              queryParamsHandling: 'merge'
            }).then();

            this.updateBreadcrumb(Components.AircraftModelDetails.label + ' (' + this.getDisplayName(this.aircraftModel) + ')');
          },
          error: error => {
            this.showErrorMsg(error, Action.Add, `${this.messageLabel}`);
            this.loadingCount--;
          },
          complete: () => {
            this.showSuccessMsg(Action.Add, `${this.messageLabel}`, `${this.getDisplayName(this.aircraftModel)}`);
            this.isNew = false;
            this.pageTitle = Components.AircraftModelDetails.label;
            this.modelForm.resetForm(this.modelForm.value);
            this.loadingCount--;
          }
        }
        );
    } else {
      this.aircraftmodelService.updateAircraftModel(this.aircraftModel).pipe(
        takeUntil(this.ngUnsubscribe)).subscribe({
          next: () => {
            this.updateBreadcrumb(Components.AircraftModelDetails.label + ' (' + this.getDisplayName(this.aircraftModel) + ')');
          },
          error: error => {
            this.showErrorMsg(error, Action.Update, `${this.messageLabel}`);
            this.loadingCount--;
          },
          complete: () => {
            this.showSuccessMsg(Action.Update, `${this.messageLabel}`, `${this.getDisplayName(this.aircraftModel)}`);
            this.modelForm.resetForm(this.modelForm.value);
            this.loadingCount--;
          }
        }
        );
    }
  }

  onReset() {
    this.ngOnInit();
    this.modelForm.resetForm();
  }

  onCancel() {
    if (this.modelForm.dirty)
      this.confirmCancel(Components.AircraftModels.path);
    else
      this.router.navigate([Components.AircraftModels.path]).then();
  }

  getDisplayName(aircraftModel: AircraftModel): string {
    return aircraftModel.aircraftmodel_model + '-' + aircraftModel.aircraftmodel_sub_model;
  }

}
