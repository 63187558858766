import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ConversionType, DataType, Redaction, ZipType, flightDataType } from '../../models/ClientPackage';
import { EnhancedPackages, EnhancedPackage } from '../../models/EnhancedPackage';
import { ConfirmationService, MessageService } from 'primeng/api';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
  NgForm,
} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Components } from '../../integration/datadelivery.components';
import { CommonService } from 'src/app/common/services/common.service';
import { BreadcrumbService } from '../../../../../common/services/breadcrumb.service';
import { Action, BaseViewComponent } from 'src/app/common/components/base-view.component';
import { forkJoin, takeUntil } from 'rxjs';
import { EnhancedPackagesService } from '../../services/enhancedPackage.service';
import { SecurityUserService } from 'src/app/security/services/security-user.service';
import { permissions } from '../../integration/datadelivery.permissions';
@Component({
  selector: 'app-enhancedpackage',
  templateUrl: './enhancedpackage.component.html',
  styleUrls: ['./enhancedpackage.component.css']
})
export class EnhancedPackageComponent extends BaseViewComponent implements OnInit {
  @ViewChild('clientpackageForm') clientpackageForm!: NgForm;
  pageTitle = Components.EnhancedPackages.label;
  public canAdd: boolean;
  public canDelete: boolean;
  public canEdit: boolean;
  public modalDialog?: boolean;
  public submitted?: boolean;
  isDataFrame = false;
  today = new Date();
  todayAsStr = `${this.today.getFullYear()}-${this.today.getMonth()}-${this.today.getDate()}`;
  hour = this.today.getHours()
  min = this.today.getMinutes()
  seconds = this.today.getSeconds()
  cols: any[] = [];
  clientpackages: EnhancedPackages[] = [];
  clientpackage!: EnhancedPackage;
  redactionType: Redaction[] = [];
  dataType: DataType[] = [];
  zipType: ZipType[] = [];
  flightdataType: flightDataType[] = [];
  conversionType: ConversionType[] = [];
  uniqueError = false;
  filesarray: any[] = [];
  pattern!: any;
  encryptedtext: any;
  encryptfilename: any;
  encryptfilepassword: any;
  encrypted!: boolean;
  year!: number;
  month!: string;
  day!: string;
  opt_name!: string;
  messageLabel = "enhanced package";
  isClone = 1;
  constructor(router: Router,
    private route: ActivatedRoute,
    breadcrumbService: BreadcrumbService, confirmationService: ConfirmationService,
    messageService: MessageService, private enhancedPackagesService: EnhancedPackagesService,
    private realUserService: SecurityUserService) {
    super(messageService, confirmationService, router, breadcrumbService);
    this.getBreadCrums();
  }
  getBreadCrums() {
    this.breadcrumbService.setItems(this.route, [
      { label: Components.EnhancedPackages.label }
    ]);
  }
  intialVal() {
    this.clientpackage = new EnhancedPackage;
    this.loadClientPackages();
  }
  packageForm!: FormGroup;

  ngOnInit() {
    this.getBreadCrums();
    this.canAdd = this.realUserService.userHasPermission(permissions.datadelivery_client_packages.create);
    this.canEdit = this.realUserService.userHasPermission(permissions.datadelivery_client_packages.manage);
    this.canDelete = this.realUserService.userHasPermission(permissions.datadelivery_client_packages.delete);
    this.year = new Date().getFullYear();
    this.month = String(new Date().getMonth() + 1).padStart(2, '0');
    this.day = String(new Date().getDate()).padStart(2, '0');

    this.opt_name = `^${this.year}\-${this.month}\-${this.day}\-[a-zA-Z0-9]*\-[a-zA-Z0-9]*\-[a-zA-Z0-9]*\-[a-zA-Z0-9]*\-[a-zA-Z0-9]*$`;
    this.pattern = `^[a-zA-Z0-9]*\-[a-zA-Z0-9]*\-${this.todayAsStr}-${this.hour}-${this.min}-${this.seconds}$`;
    this.cols = [
      {
        field: 'name',
        header: 'Name',
      },
      {
        field: 'description',
        header: 'Description',
      },

      {
        field: 'conversiontype_name',
        header: 'Conversion Type',
      },
      {
        field: 'datatype',
        header: 'Data Type',
      },
      {
        field: 'ziptype',
        header: 'Zip Options',
      },
      {
        field: 'primary',
        header: 'Primary Option',
      },
      {
        field: 'secondary',
        header: 'Secondary Option',
      },
      {
        field: 'Action',
        header: '',
      },
      {
        field: 'Action',
        header: '',
      },
    ];

    this.intialVal();
  }

  openNew() {
    if (!this.canAdd) {
      this.showErrorMsg('User is not authorized to add Enhanced Packages.', Action.Add, `${this.messageLabel}`);
    } else {
      this.router.navigate([Components.AddEnhancedPackages.path]).then();
    }
  }

  deleteClientPackage(packages: EnhancedPackages) {
    if (!this.canDelete) {
      this.showErrorMsg(`User is not authorized to delete an enhanced package.`, Action.Delete, `${this.messageLabel}s`);
    } else {
      this.confirmationService.confirm({
        message: 'Are you sure that you want to delete the selected Enhanced Package?',
        accept: () => {
          this.enhancedPackagesService.deleteClientPackage(packages.package_id).subscribe({
            next: () => {
              this.showSuccessMsg(Action.Delete, `${this.messageLabel}`, `${packages.name}`);
              // Refresh the list of packages
              this.loadClientPackages();
            },
            error: error => {
              this.showErrorMsg(`${error}`, Action.Delete, `${this.messageLabel}s`);
            }
          });
        }
      });
    }
  }

  clonePackage(cloned: any) {
    this.router.navigate([Components.AddEnhancedPackages.path], {
      queryParams: { packageId: cloned.package_id, name: cloned.name, clone: true },
      queryParamsHandling: "merge"
    }).then();
  }

  loadClientPackages() {
    this.loadingCount++;
    forkJoin({
      getclientpackages: this.enhancedPackagesService.getClientPackages(),
    }).pipe(
      takeUntil(this.ngUnsubscribe)).subscribe({
        next: results => {
          this.clientpackages = results.getclientpackages;
        },
        error: error => {
          this.showErrorMsg(`${error}`, Action.Get, `${this.messageLabel}s`);
          this.loadingCount--;
        },
        complete: () => {
          this.loadingCount--;
        }
      });
  }

  onRowSelect(event) {
    this.router.navigate([Components.AddEnhancedPackages.path], {
      queryParams: {
        packageId: event.data.package_id,
        name: event.data.name
      }
    }).then();
  }
}
