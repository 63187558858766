import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { takeUntil } from 'rxjs';
import { Action } from 'src/app/common/components/base-view.component';
import { BreadcrumbService } from 'src/app/common/services/breadcrumb.service';
import {
  ILcpMapView,
  IMapViewColItem,
  IMapViewRow
} from 'src/app/features/configuration/models/dataframe717-767';
import { TransferdataService } from 'src/app/features/configuration/services/transferdata.service';
import { SecurityUserService } from 'src/app/security/services/security-user.service';
import { Components } from '../../../integration/configuration.components';
import { DataFrameService } from '../../../services/data-frame.service';
import { MapViewComponent } from '../../dataframe-acms/dataframe/data-frame-mapview/mapview.component';
import { DataFrameConstants } from '../../dataframe-acms/dataframe/shared/constants/constants';
import { DEFAULT_BIT_LENGTH, IColHeadItem, colHeadList } from './mapview717.constants';

@Component({
  selector: 'app-mapview717',
  templateUrl: './mapview717.component.html',
  styleUrls: ['./mapview717.component.scss'],
})
export class Mapview717Component extends MapViewComponent {
  mapView: IMapViewRow[] = [];
  resultView: IMapViewRow[] = [];
  mapview717Data: ILcpMapView[] = [];
  map717View: ILcpMapView[] = [];
  colHeads?: IColHeadItem[];
  tempword: string[] = [];
  editData: any;
  isGetBlank = false;
  updatedMnemonic: any;
  dictSourceIndex: any;
  rowsPerPageOptions: any[];
  defaultBitLen = DEFAULT_BIT_LENGTH

  constructor(
    router: Router,
    breadcrumbService: BreadcrumbService,
    confirmationService: ConfirmationService,
    messageService: MessageService,
    appService: TransferdataService,
    route: ActivatedRoute,
    dataframeservice: DataFrameService,
    realUserService: SecurityUserService
  ) {
    super(
      router,
      breadcrumbService,
      confirmationService,
      messageService,
      appService,
      route,
      dataframeservice,
      realUserService
    );
    // initial data.
    this.noDataFoundLabel = 'No 717 Data Frame MapView Found'
    this.messageLabel = 'Data Frame 717.'
    this.pageTitle = Components.MapView717.label;
    this.rowsPerPageOptions = DataFrameConstants.rowsPerPageOptionsMapView;
    this.mapviewComponent = Components.MapView717;
    this.setBreadCrumbs(Components.ListView717.path);
  }

  getDataFrame(versionId: any) {
    this.colHeads = colHeadList;
    this.map717View = [];
    this.updatedMnemonic = [];
    this.dataFrame = [];
    this.showLoader()
    this.dataframeservice.IdentifyDataFrame(versionId).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: (data) => {
        const response = JSON.parse(data.content);
        this.dataFrame = response;
        for (const key in response.FRA.AcquiredParams) {
          for (const key1 in response.FRA.AcquiredParams[key]) {
            const lcpArray = response.FRA.AcquiredParams[key][key1].LCP;
            const Rate = [...new Set(lcpArray.map(item => item.LCP_SAMPLE_NUMBER))];
            response.FRA.AcquiredParams[key][key1].PRM_RATE = Rate.length;
            this.map717View.push({
              name: response.FRA.AcquiredParams[key][key1].PRM_MNEMONIC,
              lcp: response.FRA.AcquiredParams[key][key1].LCP,
              acquiredParam: response.FRA.AcquiredParams[key][key1],
            });
          }
        }

        const updatedframe = JSON.parse(sessionStorage.getItem('FrameDetails'));
        if (updatedframe != (undefined || null)) {
          this.showWarning();
          this.updatedFrame = JSON.parse(sessionStorage.getItem('FrameDetails'));
          this.dataFrame.FRA = this.updatedFrame.FRA;
          sessionStorage.setItem('FrameDetails', JSON.stringify(this.updatedFrame))
        }
        const updatedRow = JSON.parse(sessionStorage.getItem('Mnemonics'));
        if (updatedRow != (undefined || null)) {
          this.showWarning();
          this.updatedParameters();
        }
        const deleteRow = JSON.parse(sessionStorage.getItem('DeletedMnemonics'));
        if (deleteRow != (undefined || null)) {
          this.showWarning();
          this.deleteMnemonics(deleteRow);
        }
        this.mapview717Data = this.map717View as ILcpMapView[];
      },
      error: (err) => {
        this.showErrorMsg(err, Action.Get, `${this.messageLabel}`);
        this.hideLoader()
      },
      complete: () => {
        this.displayMapview();
        this.hideLoader()
      }
    });
  }

  deleteMnemonics(deletedMnemonics: any) {
    deletedMnemonics.forEach(element => {
      // deleting mnemonics from dataFrame.
      for (const key in this.dataFrame.FRA.AcquiredParams) {
        for (const key1 in this.dataFrame.FRA.AcquiredParams[key]) {
          if (this.dataFrame.FRA.AcquiredParams[key][key1].PRM_MNEMONIC === element) {
            this.dataFrame.FRA.AcquiredParams.splice(key, 1);
          }
        }
      }
      // delete mnemonics from mapview.
      this.map717View.splice(this.map717View.findIndex(e => e.name === element), 1);
      this.updatedMnemonic = JSON.parse(sessionStorage.getItem('Mnemonics'))
      // delete mnemonics from updatedMnemonic.
      for (const key in this.updatedMnemonic) {
        for (const key1 in this.updatedMnemonic[key]) {
          for (const key2 in this.updatedMnemonic[key][key1]) {
            if (this.updatedMnemonic[key][key1][key2].PRM_MNEMONIC === element) {
              this.updatedMnemonic.splice(key, 1);
            }
          }
        }
      }
    });

    this.setupMnemonics({
      updatedMnemonic: this.updatedMnemonic,
      deletedMnemonics
    })
  }

  prepareDefaultCols() {
    return Array(this.defaultBitLen).fill(1).map((_, i) => ({ colIndex: i + 1, word: '', sf: '', length: 1, source: 0, rowspan: 4 }));
  }

  sortWordList(list: IMapViewRow[], key: any) {
    list.sort((a, b) => {
      if (a[key] == null && b[key] != null) {
        return 1;
      } else if (a[key] != null && b[key] == null) {
        return -1;
      } else if (a[key] != null && b[key] != null) {
        return a[key] - b[key];
      } else {
        return 0;
      }
    });
  }

  displayMapview() {
    if (this.mapview717Data.length > 0) {
      const lcpWordList: IMapViewRow[] = []
      this.mapview717Data.forEach((element) => {
        element.lcp?.forEach((lcp, index) => {
          lcpWordList.push({
            sf: index + 1,
            word: lcp.LCP_WORD_NB,
            isBlank: false,
            name: element.name,
            source: lcp.LCP_BIT_SOURCE_LSB,
            length: lcp.LCP_BIT_LENGTH,
            subFrame: lcp.LCP_SUBFRAME,
            lcp: lcp,
            acquiredParam: element.acquiredParam,
          });
        });
      });
      this.sortWordList(lcpWordList, 'sf');
      this.sortWordList(lcpWordList, 'word');

      let colLength = 0;
      lcpWordList.forEach((wordItem) => {
        let blankValues = [];
        if (this.resultView.length > 0) {
          let isPush = false;
          if (wordItem.length != this.defaultBitLen) {
            const existingResultViewItem = this.resultView.find((b) => b.word == wordItem.word);
            if (existingResultViewItem != undefined) {
              if (existingResultViewItem.length != this.defaultBitLen) {
                colLength = existingResultViewItem.cols.filter((a) => a.word != '').length;
              }
            }
            if (existingResultViewItem === undefined) {
              const defaultCols = this.prepareDefaultCols();
              defaultCols.forEach((colItem) => {
                if (wordItem.length === 1) {
                  if (colItem.colIndex == wordItem.source) {
                    if (colItem.word === '') {
                      colItem.word = wordItem.name;
                      colItem.sf = wordItem.subFrame.toString();
                      (colItem.source = wordItem.source), (colItem.length = wordItem.length);
                    }
                  }
                } else {
                  if (!this.isGetBlank) {
                    blankValues = this.prepareBlankValues(wordItem.source, wordItem.length);
                  }
                  if (blankValues.includes(colItem.colIndex)) {
                    if (colItem.word === '') {
                      colItem.word = wordItem.name;
                      colItem.sf = wordItem.subFrame.toString();
                      colItem.source = wordItem.source;
                      colItem.length = wordItem.length;
                    }
                  }
                }
              });
              this.updateResultView({
                wordItem,
                cols: defaultCols
              })
            } else if (colLength <= this.defaultBitLen) {
              const emptyCols = this.getEmptyVal(
                wordItem.source,
                wordItem.length,
                this.resultView,
                wordItem.word
              );
              let doesConflict = false;
              let conflictedName = '';
              // TODO: code seems to be redundant unused.
              if (emptyCols == null) {
                isPush = false;
              } else {
                emptyCols.forEach((emptyColItem) => {
                  if (wordItem.length === 1) {
                    if (wordItem.subFrame === existingResultViewItem.subFrame && wordItem.source == existingResultViewItem.source) {
                      doesConflict = true;
                      conflictedName = `${existingResultViewItem.name} / ${wordItem.name}`
                    } else {
                      conflictedName = wordItem.name
                    }
                    if (emptyColItem.colIndex == wordItem.source) {
                      if (emptyColItem.word === '') {
                        emptyColItem.word = conflictedName;
                        emptyColItem.sf = wordItem.subFrame.toString();
                        emptyColItem.length = wordItem.length;
                        emptyColItem.source = wordItem.source;
                        isPush = true;
                      }
                    }
                  } else {
                    if (!this.isGetBlank) {
                      blankValues = this.prepareBlankValues(wordItem.source, wordItem.length);
                    }
                    if (blankValues.includes(emptyColItem.colIndex)) {
                      if (wordItem.subFrame === existingResultViewItem.subFrame && wordItem.source == existingResultViewItem.source) {
                        doesConflict = true;
                        conflictedName = `${existingResultViewItem.name} / ${wordItem.name}`
                      } else {
                        conflictedName = wordItem.name
                      }
                      if (emptyColItem.word === '') {
                        emptyColItem.word = conflictedName;
                        emptyColItem.sf = wordItem.subFrame.toString();
                        emptyColItem.length = wordItem.length;
                        emptyColItem.source = wordItem.source;
                        isPush = true;
                      }
                    }
                  }
                });
              }
              if (isPush) {
                this.updateResultView({
                  wordItem: {
                    ...wordItem,
                    name: conflictedName,
                  },
                  isConflicted: doesConflict,
                  cols: emptyCols
                })
              } else {
                if (wordItem.subFrame === existingResultViewItem.subFrame && wordItem.source == existingResultViewItem.source) {
                  doesConflict = true;
                  conflictedName = `${existingResultViewItem.name} / ${wordItem.name}`
                } else {
                  conflictedName = wordItem.name
                }
                const cols = this.prepareMapViewCols({ ...wordItem, name: conflictedName }, true);
                this.updateResultView({
                  wordItem: {
                    ...wordItem,
                    name: conflictedName,
                  },
                  isConflicted: doesConflict,
                  cols
                })
              }
            }
          } else if (wordItem.length == this.defaultBitLen) {
            this.updateResultView({
              wordItem
            })
          }
        } else {
          const cols = this.prepareMapViewCols(wordItem)
          this.updateResultView({
            wordItem,
            cols
          })
        }
        this.isGetBlank = false;
      });

      let duplicatedWord: any;
      this.resultView.forEach((lcp) => {
        if (duplicatedWord === lcp.word) {
          const wordIndices = this.mapView.map((item, index) => {
            if (item.word === lcp.word && (lcp.subFrame == item.subFrame || (lcp.subFrame.includes(item.sf.toString()) && lcp.subFrame !== item.subFrame))) {
              return index
            }
            return null
          }).filter(index => index !== null);

          if (!wordIndices.length) {
            return;
          }

          wordIndices.forEach((wordIndex) => {

            this.mapView[wordIndex] = {
              ...this.mapView[wordIndex],
              word: lcp.word,
              isBlank: true,
              name: lcp.name,
              source: lcp.source,
              length: lcp.length,
              subFrame: lcp.subFrame,
              lcp: lcp.lcp,
              acquiredParam: lcp.acquiredParam,
              cols: lcp.cols,
              isConflicted: lcp.isConflicted
            }
          })

          return;
        }
        duplicatedWord = lcp.word;

        for (let i = 1; i <= 4; i++) {
          this.mapView.push({
            sf: i,
            word: lcp.word,
            isBlank: true,
            name: lcp.name,
            length: lcp.length,
            source: lcp.source,
            lcp: lcp.lcp,
            subFrame: lcp.subFrame,
            acquiredParam: lcp.acquiredParam,
            rowIndex: i,
            isConflicted: lcp.isConflicted,
            cols: lcp.cols
          });
        }
      });
    }
  }

  prepareMapViewCols(wordItem: any, disableSourceLength = false) {
    const defaultCols = this.prepareDefaultCols();
    return defaultCols.map((colItem) => {
      if (wordItem.length === 1) {
        if (colItem.colIndex == wordItem.source) {
          if (colItem.word === '') {
            colItem.word = wordItem.name;
            colItem.sf = wordItem.subFrame.toString();
            if (!disableSourceLength) {
              colItem.length = wordItem.length;
              colItem.source = wordItem.source;
            }
          }
        }
      } else {
        let blankValues = []
        if (!this.isGetBlank) {
          blankValues = this.prepareBlankValues(wordItem.source, wordItem.length);
        }
        if (blankValues.includes(colItem.colIndex)) {
          if (colItem.word === '') {
            colItem.word = wordItem.name;
            colItem.sf = wordItem.subFrame.toString();
            colItem.length = wordItem.length;
            colItem.source = wordItem.source;
          }
        }
      }
      return colItem;
    });
  }

  updateResultView({
    wordItem,
    cols,
    isConflicted
  }: { wordItem: Partial<IMapViewRow>, cols?: IMapViewColItem[], isConflicted?: boolean }) {
    let colsData = {};
    if (cols && cols.length) {
      colsData = {
        cols,
        total: cols.filter(() => (wordItem.word as unknown as string) != '').length,
      }
    }
    this.resultView.push({
      sf: wordItem.sf,
      word: wordItem.word,
      isBlank: false,
      name: wordItem.name,
      source: wordItem.source,
      length: wordItem.length,
      subFrame: wordItem.subFrame,
      lcp: wordItem.lcp,
      acquiredParam: wordItem.acquiredParam,
      isConflicted,
      ...colsData
    });
  }

  getEmptyVal(
    source: number,
    len: number,
    listView: IMapViewRow[],
    word: number
  ) {
    let temp = 0;
    let resultCols;
    let isPush = false;
    let colIndexMap = [];
    listView.forEach((c) => {
      if (c.word === word) {
        colIndexMap = [];
        if (c.length != this.defaultBitLen) {
          if (!isPush) {
            c.cols.filter((a) => {
              if (len === 1) {
                if (a.colIndex == source && a.word === '') {
                  temp += 1;
                }
              } else {
                if (colIndexMap.length === 0) {
                  const des = source + len;
                  for (let i = source; i < des; i++) {
                    colIndexMap.push(i);
                  }
                }
                if (colIndexMap.includes(a.colIndex)) {
                  if (a.word === '') {
                    temp += 1;
                  }
                }
              }
            });
            if (temp === len) {
              resultCols = c.cols;
              isPush = true;
            }
          }
        }
      }
    });
    return isPush ? resultCols : null;
  }

  prepareBlankValues(source: number, len: number) {
    const blankValueList = [];
    const des = source + len;
    for (let i = source; i < des; i++) {
      blankValueList.push(i);
    }
    this.isGetBlank = true;
    return blankValueList;
  }

  onCellClick(name: string) {
    let checkName = name;
    if (name.includes('/')) {
      // in case of conflict we will open the second word to edit.
      checkName = name.split('/')[1]
    }
    this.editData = this.mapview717Data.filter((a) => a.name == checkName.trim());
    this.toEditView(this.editData);
  }

  getFillColor(colLen: number, colWord: string, dataCols: any, currentColIndex: number, colSf: string, dataSf: string, dataWord: number, isConflicted: boolean) {
    let className = "";
    if (isConflicted) {
      return `rowRedColor`
    }
    if (colLen > 0) {
      dataCols.forEach((a) => {
        if (a.colIndex == currentColIndex && a.word == colWord) {
          if (a.sf === 'ALL' || colSf.toString().includes(dataSf.toString())) {
            className = "rowColor";
          }
          else {
            className = "";
          }
        }
      });
    }
    if (this.resultView.filter(a => a.word == dataWord).length > 1) {
      const isRepeat = this.getDuplicate(dataWord);
      if (isRepeat) {
        className = "rowRedColor"
      }
    }
    return className;
  }

  getDuplicate(currentColIndex: number) {
    let isBool = false;
    try {
      this.resultView.filter(item => {
        item.cols.forEach((a) => {
          if (a.colIndex == currentColIndex) {
            if (this.dictSourceIndex && this.dictSourceIndex == a.source) {
              isBool = true;
              this.dictSourceIndex = undefined;
            } else {
              this.dictSourceIndex = a.source;
              isBool = false;
            }
          }
        })
      })
    } catch (error) { }
    return isBool;
  }

  getCellClass(word: number, length: number) {
    let cellClass = "rowColor";
    if (this.resultView.filter(a => a.word == word).length > 1) {
      this.resultView.forEach(item => {
        if (item.length == length) {
          cellClass = "rowRedColor"
          return;
        }
      })
    }
    return cellClass;
  }

  doesSubFrameExist(source: string, des: string) {
    if (!!source && source.toString().includes(des.toString())) {
      return true;
    }
    return false;
  }

  getColVal(word: string) {
    this.tempword.push(word);
    if (this.tempword.filter((a) => a == word).length > 1) {
      return '';
    } else {
      return word;
    }
  }

  getClear() {
    this.tempword = [];
  }

  toEditView(row: any) {
    this.navigateTo({
      path: Components.DataFrameDynamic717.path,
      query: {
        editName: row[0].name,
        isMapView: 1,
      },
    })
  }

  toListView() {
    this.navigateTo({
      path: Components.ListView717.path
    })
  }

  toAnalyseDataFrame() {
    this.navigateTo({
      path: Components.DataFrameAnalyse717.path,
      query: {
        editName: 'Analyse',
        isMapView: 1,
      },
    })
  }

  toDataFrameDetails() {
    this.navigateTo({
      path: Components.DataFrame717.path,
      query: {
        isMapView: 1,
      },
    })
  }

  onAddDataFrame() {
    this.navigateTo({
      path: Components.DataFrameDynamic717.path,
      query: { 'add': 1 }
    });
  }

  updatedParameters() {
    this.updatedMnemonic = JSON.parse(sessionStorage.getItem('Mnemonics'));
    for (const key in this.updatedMnemonic) {
      for (const key1 in this.updatedMnemonic[key]) {
        for (const key2 in this.updatedMnemonic[key][key1]) {

          const lcpArray = this.updatedMnemonic[key][key1][key2].LCP;
          const Rate = [...new Set(lcpArray.map(item => item.LCP_SAMPLE_NUMBER))];
          this.updatedMnemonic[key][key1][key2].PRM_RATE = Rate.length;
          const name = this.updatedMnemonic[key][key1][key2].PRM_MNEMONIC;
          if (key1 === "Edit") {
            this.map717View[key2] = {
              name: this.updatedMnemonic[key][key1][key2].PRM_MNEMONIC,
              lcp: this.updatedMnemonic[key][key1][key2].LCP,
              acquiredParam: this.updatedMnemonic[key][key1][key2],
            }
            this.dataFrame.FRA.AcquiredParams[key2] = { [name]: this.updatedMnemonic[key][key1][key2] };
          }
          else if (key1 === "Clone") {
            this.map717View.push({
              name: this.updatedMnemonic[key][key1][key2].PRM_MNEMONIC,
              lcp: this.updatedMnemonic[key][key1][key2].LCP,
              acquiredParam: this.updatedMnemonic[key][key1][key2],
            });
            this.dataFrame.FRA.AcquiredParams.push({ [name]: this.updatedMnemonic[key][key1][key2] })
          }
          else if (key1 === "Add") {
            this.map717View.push({
              name: this.updatedMnemonic[key][key1][key2].PRM_MNEMONIC,
              lcp: this.updatedMnemonic[key][key1][key2].LCP,
              acquiredParam: this.updatedMnemonic[key][key1][key2],
            });
            this.dataFrame.FRA.AcquiredParams.push({ [name]: this.updatedMnemonic[key][key1][key2] })
          }
        }

      }
    }
  }

  resetDataFrame() {
    this.mapview717Data = [];
    this.map717View = [];
    this.mapView = [];
    this.resultView = [];
    this.resetMapView();
  }
}
