<app-spinner [isLoading]="isLoading"></app-spinner>
<div class="col-12 card p-3 mapViewTable ">
    <app-list-view-header [pageTitle]="pageTitle"
        [showMessage]="showMessage"
        [messages]="messages"
        [userCanManage]="userCanManage"
        [isMapView]="true"
        [isMapView]="true"
        (onNavigateToDFDetails)="toDataFrameDetails()"
        (onNavigateToListView)="toListView()"
        (onNavigateToAnalyseDF)="toAnalyseDataFrame()"
        (onAddDataFrame)="onAddDataFrame()">
    </app-list-view-header>
    <div class="grid ng-star-inserted">
        <div class="col-4">
            <span class="p-float-label">
                <p-dropdown autoWidth="false" [(ngModel)]="selectedFrameItem" placeholder="Select Frame"
                    [style]="{'width':'100%'}" optionLabel="Frame_Title" optionValue="Frame_Title" [options]="frames"
                    (onChange)="selectFrame($event)">
                    <ng-template let-item pTemplate="item">
                        {{item.Frame_ID}} - {{item.Frame_Title}}
                    </ng-template>
                    <ng-template let-item pTemplate="item" pTemplate="selectedItem">
                        {{item.Frame_ID}} - {{item.Frame_Title}}
                    </ng-template>
                </p-dropdown>

            </span>
        </div>
        <div class="col flex justify-content-left align-items-center rate-title">
            <label>Rate: &nbsp;</label>{{hzLabel}}
        </div>

    </div>

    <p-table [value]="maptableParameters" [filterDelay]="0" [tableStyle]="{'width':'100%'}" selectionMode="single"
        (onRowSelect)="toEditView($event)" [showCurrentPageReport]="true"
        [paginator]="maptableParameters.length > defaultTableRows" [rowsPerPageOptions]="[20,50,100,200]"
        [rows]="defaultTableRows" currentPageReportTemplate="{{currentPageReport + ' ' + pageTitle}}"
        [scrollable]="false" [totalRecords]="maptableParameters.length" [(first)]="first">
        <ng-template pTemplate="header" let-columns pTemplate="header" #dtable>
            <tr>
                <th *ngFor="let tableColHead of prepareTableColHead()" style="text-align:center;">
                    <div class="flex justify-content-start align-items-center">
                        {{tableColHead.label}}
                    </div>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-data let-columns="columns">
            <tr (click)="toEditView(data)">
                <ng-container>
                    <td [pTooltip]="data.Mnemonic_Code" *ngFor="let colHead of prepareTableColHead()"
                        style="text-align:left;width: 5%;"
                        [attr.colspan]="colHead.index >=1 && colHead.index <= data.Bit_Length"
                        [ngClass]="{'rowColor':colHead.index >=1 && colHead.index <= data.Bit_Length}">
                        <span style="text-align:left; " *ngIf="colHead.index==1">
                            {{data.Mnemonic_Code}}</span>
                    </td>
                </ng-container>

            </tr>
        </ng-template>

        <ng-template pTemplate="emptymessage">
            <tr>
                <td [attr.colspan]="bitLength">
                    {{noDataFoundLabel}}
                </td>
            </tr>
        </ng-template>
    </p-table>
    <div *ngIf="maptableParameters.length > 0 && defaultTableRows >= maptableParameters.length"
        style="text-align: center;">
        <p class="row-paging">Showing 1 to
            {{maptableParameters.length}} of {{maptableParameters.length}} {{pageTitle}}</p>
    </div>
    <app-data-frame-footer-action
        [saveDisabled]="!showMessage"
        [resetDisabled]="!showMessage"
        [userCanManage]="userCanManage"
        (onCancel)="onCancel()"
        (onReset)="resetDataFrame()"
        (onSave)="saveDataFrame()">
    </app-data-frame-footer-action>
</div>
