import { DOCUMENT } from "@angular/common";
import { Inject, Injectable, Renderer2, RendererFactory2 } from "@angular/core";

// Interface for theme structure
interface Theme {
    name: string;
    file: string;
    type?: string;
}

@Injectable({
    providedIn: 'root'
})

export class ThemeService {
    private readonly storageKey = 'selected-theme';
    private readonly themePath = '../../../assets/css/'
    private renderer: Renderer2;

    public selectedThemes: Record<string, string> = {};
    public currentApplication: string = '';

    // Store available themes and selected themes
    private Themes: Record<string, Theme[]> = {
        administration: [
            { name: 'Default', file: 'layout-light-custom.css', type: 'default' },
            { name: 'Dark Mode', file: 'layout-dark-custom.css' }
        ],
        configuration: [
            { name: 'Default', file: 'layout-light-custom.css', type: 'default' },
            { name: 'Dark Mode', file: 'layout-dark-custom.css' },
            { name: 'App Mode', file: 'dark-orange.css' }
        ],
        datadelivery: [
            { name: 'Light Mode', file: 'layout-light-custom.css' },
            { name: 'Dark Mode', file: 'layout-dark-custom.css' },
            { name: 'Default', file: 'royal-purple.css', type: 'default' }
        ],
        endpointdatadelivery: [
            { name: 'Light Mode', file: 'layout-light-custom.css' },
            { name: 'Dark Mode', file: 'layout-dark-custom.css' },
            { name: 'Default', file: 'connectivity-theme.css', type: 'default' }
        ],
        sendtoteledyne: [
            { name: 'Light Mode', file: 'layout-light-custom.css' },
            { name: 'Dark Mode', file: 'layout-dark-custom.css' },
            { name: 'Default', file: 'data-loading.css', type: 'default' }
        ],
    };


    constructor(@Inject(DOCUMENT) private document: Document, private rendererFactory: RendererFactory2) {
        this.renderer = this.rendererFactory.createRenderer(null, null);
        this.loadStoredThemes();
    }

    // Retrieve available themes for an application
    getThemeOptions(app: string): Theme[] {
        return this.Themes[app] || [];
    }

    // Get the current application based on the URL
    getCurrentApplication(url: string): string {
        const baseRoute = url.split('/')[1].toLowerCase();
        return this.Themes[baseRoute] ? baseRoute : this.getAppFromQueryString(url);
    }

    // Initialize theme for the current application
    initializeAppTheme(app: string): void {
        this.currentApplication = app;
        if (!this.selectedThemes[app]) {
            this.setDefaultThemeForApp(app);
        } else {
            this.applyTheme(this.selectedThemes[app]);
        }
    }

    // Change theme and save it to localStorage
    changeTheme(app: string, themeFile: string): void {
        this.selectedThemes[app] = themeFile;
        this.applyTheme(themeFile);
        this.saveThemesToLocalStorage();
    }

    // Apply the theme by updating the stylesheet link in the DOM
    private applyTheme(themeFile: string): void {
        const existingTheme = this.document.getElementById('app-theme');
        if (existingTheme) {
            existingTheme.setAttribute('href', this.themePath + themeFile);
        } else {
            this.appendThemeLink(themeFile);
        }
    }

    // Append a new link element for the theme
    private appendThemeLink(themeFile: string): void {
        const link = this.renderer.createElement('link');
        this.renderer.setAttribute(link, 'id', 'app-theme');
        this.renderer.setAttribute(link, 'rel', 'stylesheet');
        this.renderer.setAttribute(link, 'type', 'text/css');
        this.renderer.setAttribute(link, 'href', this.themePath + themeFile);
        this.renderer.appendChild(this.document.head, link);
    }

    // Set the default theme for the application
    private setDefaultThemeForApp(app: string): void {
        const defaultTheme = this.Themes[app]?.find(theme => theme.type === 'default')?.file;
        if (defaultTheme) {
            this.selectedThemes[app] = defaultTheme;
            this.applyTheme(defaultTheme);
            this.saveThemesToLocalStorage();
        }
    }

    // Load themes from localStorage if available
    private loadStoredThemes(): void {
        const storedThemes = localStorage.getItem(this.storageKey);
        if (storedThemes) {
            this.selectedThemes = JSON.parse(storedThemes);
        }
    }

    // Save themes to localStorage
    private saveThemesToLocalStorage(): void {
        localStorage.setItem(this.storageKey, JSON.stringify(this.selectedThemes));
    }

    // Helper function to get application from query string
    private getAppFromQueryString(url: string): string {
        const queryParams = new URLSearchParams(url.split('?')[1]);
        const appParam = queryParams.get('app');
        return appParam ? appParam.toLowerCase() : '';
    }
}
