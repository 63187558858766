<div class="card-header">
  <h5>{{pageTitle}} </h5>
  <div class="warningmsg" *ngIf="showMessage">
      <p-messages [(value)]="messages" [enableService]="false" [closable]="false"></p-messages>
  </div>
  <div class="p-toolbar-group-end">
      <span>
          <button id="frame_Button" name="frame_Button" type="button" label="Data Frame Details"
              class="p-button-secondary mr-2"   pButton pRipple
              icon="pi pi-external-link" pTooltip="Data Frame Details" (click)="navigateToDFDetails()"></button>
      </span>
      <span *ngIf="!isMapView">
          <button id="map_Button" name="map_Button" type="button" label="Frame Layout"
              class="p-button-secondary mr-2"   pButton pRipple
              icon="pi pi-chart-line" pTooltip="Map View" (click)="navigateToMapView()"></button>
      </span>
      <span *ngIf="isMapView">
          <button id="list_Button" name="list_Button" type="button" label="List View"
              class="p-button-secondary mr-2"   pButton pRipple
              icon="pi pi-list" pTooltip="Map View" (click)="navigateToListView()"></button>
      </span>
      <span>
          <button id="analyse_Button" name="analyse_Button" type="button" label="Analyse"
              class="p-button-secondary mr-2"   pButton pRipple icon="pi pi-search"
              pTooltip="Analyse" (click)="navigateToAnalyseDF()"></button>
      </span>
      <span>
          <button id="add_Button" name="add_Button" type="button" label="Add" pButton pRipple icon="pi pi-plus"
              *ngIf="userCanManage" (click)="addDataFrame()"></button>
      </span>
  </div>

</div>